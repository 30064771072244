@import "~common";

.article {
  padding-top: 30px;
  background-color: #FFFFFF; }

.article__container {
  max-width: 770px;
  margin: 0 auto;
  padding: 0 15px; }


.article__container--large {
  max-width: 1120px;
  margin: 0 auto;

  @include max-w(1150) {
    padding: 0 15px; } }

.article__wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;

  @include max-w(480) {
    margin-bottom: 10px; } }

.article__author {
  display: flex;
  align-items: center;

  @media (max-width: 480px) {
    flex-grow: 1; } }

.article__user,
.article__contest-logo {
  flex-shrink: 0;
  height: 60px;
  width: 60px;
  margin-right: 20px;

  @media (max-width: 700px) {
    height: 40px;
    width: 40px; }

  @media (max-width: 480px) {
    margin-right: 10px; }

  > img,
  > .avatar {
    width: 100%;
    height: 100%; }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; } }

.article__info {

  @media (max-width: 480px) {
    flex-grow: 1; } }

.article__subscribe-btn-wrap {
  display: flex;
  align-items: center;

  @include max-w(768) {
    display: none; } }

.article__subscribe-btn {
  height: 40px;
  line-height: 40px;
  border: 1px solid #389A52;
  box-sizing: border-box;
  border-radius: 4px;
  text-decoration: none;
  padding: 0 18px;
  transition: background-color .2s, color .2s;

  &:hover {
    background-color: #389A52;
    color: #fff; } }

.article__author-title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #212121;
  text-decoration: none; }

.article__date-wrap {
  display: flex;
  margin-top: 3px;
  @media (max-width: 700px) {
    flex-wrap: wrap; } }

.article__date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #A0A0A0;
  margin-right: 25px;
  white-space: nowrap;

  @media (max-width: 480px) {
    margin-right: 8px; }

  @media (max-width: 370px) {
    width: 100%;
    margin-right: 0; } }

.article__time {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #A0A0A0;
  margin-right: 25px;

  @media (max-width: 700px) {
    margin-right: 2px;
    margin-left: 6px;
    padding-left: 10px; }

  &::before {
    position: absolute;
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    background-image: url("./clock.svg");
    background-repeat: no-repeat;
    left: -17px;
    top: 2px;
    @media (max-width: 700px) {
      left: -7px; } } }

.article__watches {
  position: relative;
  margin-left: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #A0A0A0;
  @media (max-width: 700px) {
    padding-left: 20px; }
  @media (max-width: 400px) {
    padding-left: 20px;
    width: 20%; }

  &::before {
    position: absolute;
    content: '';
    display: block;
    width: 14px;
    height: 10px;
    background-image: url("./watches.png");
    background-repeat: no-repeat;
    left: -20px;
    top: 2px;
    @media (max-width: 700px) {
      left: 0; } } }

.article__subscribe-more {
  display: inline-block;
  position: relative;
  margin-right: 15px;
  padding: 17px 18px;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 4px;
  line-height: 0;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3; }

  @media (max-width: 700px) {
    border: none;
    padding: 0; }

  @media (max-width: 480px) {
    margin-right: 0px; } }

.article__subscribe {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @media (max-width: 480px) {
    .button {
      display: none; } } }

.article__subscription {
  text-decoration: none;
  border: 1px solid #389A52;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #389A52;
  padding: 10px 19px;

  @media (max-width: 700px) {
    display: none; } }

.article__body {
  width: 100%; }

.article__title {
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 45px;
  color: #212121;
  margin-bottom: 25px;
  @media (max-width: 700px) {
    font-size: 28px;
    line-height: 36px; } }

.article__paragraph {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #212121;
  margin-bottom: 20px; }

.article__color {
  color: #389A52;
  padding: 0 5px; }

.article__bold {
  font-weight: 700;
  padding: 0 5px; }

.article__img {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 50px;

  img {
    width: 100%;
    height: 100%; } }

.article__strike {
  padding: 0 5px; }

.article__hashtag {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 40px;
  padding-bottom: 27px;
  border-bottom: 1px solid #E5E5E5; }

.article__tag {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #A6A6A6;
  border: 2px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 6px 9px;
  text-transform: uppercase;
  margin-right: 10px;
  margin-bottom: 5px;
  text-decoration: none;

  &:last-child {
    margin-right: 0; } }

.article__actions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #E5E5E5; }

.article__likes {
  display: flex;
  align-items: center; }

.article__likes-wrap {
  display: flex;

  > .button-voting {
    margin: 0;

    &:first-child {
      margin-right: 10px; } } }

.article__count {
  margin-left: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #C1C1C1;

  @include max-w(350) {
    margin-left: 10px; } }

.article__count--left {
  margin-left: 9px; }

.article__rate {
  display: flex;
  align-items: center;
  margin-left: 15px;

  @media (max-width: 374px) {
    margin-left: 5px; } }

.br-wrapper br-widget {
  display: inline-block;
  vertical-align: middle;
  line-height: 24px; }

.br-widget a {
  display: block;
  float: left;
  width: 24px;
  height: 24px;
  margin-left: 8px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(./star-default.svg);
  overflow: hidden;
  position: relative;
  text-decoration: none; }

a.br-active {
  background-image: url(./star-over.svg); }

.br-current-rating {
  display: none; }

#exist_rating {
  color: #acacac;
  vertical-align: middle;
  line-height: 24px;
  margin-top: 3px; }

#exist_rating:before {
  content: '';
  display: inline-block;
  height: 24px;
  width: 24px;
  margin-right: 8px;
  background: url(./star-default.svg) no-repeat 50% 50%;
  vertical-align: middle;
  position: relative;
  left: 5px;
  top: -2px; }

.article__logic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 17%;
  @media (max-width: 700px) {
    width: 35%; }

  &--contest {
    width: auto; } }

.article__logic-elem {
  display: flex;
  text-decoration: none;
  cursor: pointer;
  align-items: center; }

.article__img-flag {
  width: 15px;
  height: 20px; }

.article__img-share {
  width: 25px;
  height: 15px; }

.article__img-comment {
  display: block;
  width: 22px;
  height: 20px; }

.article__subscribers {
  margin-top: 28px;
  margin-bottom: 72px;

  @media (max-width: 480px) {
    .article__wrap {
      flex-direction: column; } } }

.article__members {
  display: flex;
  justify-content: flex-start;
  width: 100%; }

.article__member {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 60px;
  width: 60px;
  margin-right: 20px;
  background-color: #CDD8B7;
  border-radius: 50%;
  overflow: hidden;
  text-decoration: none;

  img {
    width: 100%;
    height: 100%; } }

.article__inform {
  width: 70%;
  margin-top: 10px;

  @media (max-width: 480px) {
    margin-top: 0; } }

.article__member-title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #212121;
  text-decoration: none; }

.article__counters {
  display: flex;
  margin-top: 10px;

  @media (max-width: 480px) {
    margin-top: 5px; } }

.article__counters-elem {
  display: flex;
  margin-right: 14px;
  text-decoration: none;

  &:last-child {
    margin-right: 0; } }

.article__counters-value {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #389A52;
  margin-right: 5px;
  text-decoration: none; }

.article__counters-descr {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #C1C1C1; }

.article__subscribe-member {
  display: flex;
  align-items: center;

  .button {
    border: 1px solid #C1C1C1; }

  @media (max-width: 480px) {
    margin-top: 0;
    margin-left: 80px; } }

.article__subscription-member {
  border: 1px solid #C1C1C1;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #212121;
  padding: 10px 19px;
  text-decoration: none;
  margin-top: 10px; }

.article__wrapper-gray {
  background-color: #fafafa; }

.article__refused-title {
  margin-top: 50px;
  margin-bottom: 29px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #000000; }

.article__refused {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-bottom: 71px;
  padding: 25px;

  @include max-w(991) {
    padding: 20px; } }

.article__refused--distance {
  margin-bottom: 0; }

.article__refused-author {
  display: flex; }

.article__refused-img {
  width: 40px;
  height: 40px;
  margin-right: 14px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%; } }

.article__refused-user {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: #212121;
  margin-top: 10px; }

.article__area {
  width: 100%;
  margin-top: 20px;
  resize: none;
  border: none;
  min-height: 150px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  color: #212121;
  outline: none;

  &::placeholder {
    color: #C1C1C1;
    opacity: 1; }

  &:focus::placeholder {
    opacity: 0.5; } }

.article__box {
  width: 100%;
  padding-bottom: 1px; }

.article__box-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  margin-bottom: 28px;

  @include max-w(480) {
    font-size: 20px;
    line-height: 27px; } }

.article__elements {
  display: flex;
  margin-bottom: 58px;
  flex-wrap: wrap;
  width: calc(100% + 9px);
  margin-left: -9px;

  @include max-w(768) {
    width: 100%;
    margin-left: auto; } }


.article__elem-wrap {
  width: calc(100% / 3);
  box-sizing: border-box;

  @include max-w(768) {
    width: 100%; } }

.article__elem {
  box-sizing: border-box;
  padding: 0 9px;
  margin-bottom: 18px;

  @include max-w(768) {
    width: 100%;
    padding: 0;
    margin-bottom: 20px; } }

.article__elem-inner {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 18px;
  transition: box-shadow .2s, border .2s;

  &:hover {
    border: 1px solid transparent;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1); } }

.article__elem-author {
  display: flex; }

.article__elem-img-link {
  text-decoration: none; }

.article__elem-img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  text-decoration: none;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%; } }

.article__elem-info {
  margin-top: 1px; }

.article__elem-author {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #212121;
  margin-bottom: 4px;
  text-decoration: none; }

.article__elem-date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #A1A1A1; }

.article__elem-body {
  position: relative;
  height: 180px;
  overflow: hidden;
  border-radius: 4px;
  margin-top: 20px; }

.article__elem-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: {
    color: #389A52;
    image: url("./bg-empty.png");
    position: center;
    size: cover; }
  overflow: hidden;

  &--with-preview:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    z-index: 0; } }

.article__elem-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px; }

.article__elem-title {
  display: -webkit-box;
  display: block;
  width: 100%;
  max-height: 66px;
  margin-bottom: 10px;
  padding: 0 40px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #FFFFFF;
  text-decoration: none;
  text-align: center;
  overflow: hidden; }

.article__elem-descr {
  width: 100%;
  padding: 0 20px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #FFFFFF;
  opacity: 0.85;
  text-decoration: none;

 }  //+line-clamp(2, (18 / 13))

.article__elem-flag {
  position: absolute;
  top: 17px;
  right: 17px; }

.article__elem-actions {
  display: flex;
  margin-top: 10px;
  margin-left: 5px; }

.article__elem-action {
  display: flex;
  align-items: center;
  margin-right: 20px;
  text-decoration: none;

  @include max-w(350) {
    margin-right: 15px; } }

.article__elem-action--mo-marg {
  margin: 0; }

.article__elem-action--right {
  margin-right: 0;
  margin-left: auto; }

.article__sidebar {
  position: fixed;
  top: 198px;
  left: calc(50% - 425px);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  transform: translate(-100%, 0);

  @media (max-width: 991px) {
    bottom: 0;
    left: 0;
    top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #e5e5e5e5;
    transform: none; } }

.article__admin-panel {
  padding: 25px 13px;
  background: #FAFAFA;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 100px;

  @media (max-width: 991px) {
    padding: 7px;
    margin: -10px;
    display: flex;
    justify-content: space-around;
    border-radius: 0;
    flex-grow: 1;
    min-width: 0;
    border: none; } }

.article__admin-elem {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 20px;

    @media (max-width: 991px) {
      margin-bottom: 0; } } }

.article__admin-divider {
  margin-bottom: 20px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.06);

  @media (max-width: 991px) {
    display: none; } }

.article__admin-img {
  width: 28px;
  height: 28px;
  margin: 0 auto;
  margin-bottom: 7px;
  transform: scale(1);
  transition: 0.15s transform;

  .article__admin-elem:hover & {
    width: 32px;
    height: 32px;
    margin-top: -3px;
    margin-bottom: 6px; }

  @media (max-width: 700px) {
    margin-bottom: 3px; }

  img {
    width: 100%;
    height: 100%; } }

.article__admin-descr {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #000000; }

.article__sb-user-wrap-likes {
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    flex-direction: row; }

  > .button-voting {
    margin-left: 0;
    margin-bottom: 10px;

    @media (max-width: 991px) {
      margin: 0;
      margin-right: 10px; } } }

.article__sb-user-wrap-actions {
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    flex-direction: row;
    align-items: center; } }

.article__sb-user-elem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 2px 8px;
  text-decoration: none;

  .toggle-bookmark {
    margin-left: 4px; }

  .article__count {
    margin-right: 7px; }

  .article__img-share {
    margin-top: 0; }

  @include max-w(991) {
    margin-bottom: 0; } }

.article__sb-user-elem--likes {
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 2px 8px;
  @media (max-width: 991px) {
    &:nth-of-type(1) {
      margin-right: 8px; } } }

.article__sb-user-share {}
.article__sb-user-share {
  margin-right: 3px; }

.article__sb-user-flag {
  margin-left: 2px; }

.position {
  position: absolute; }


.sb-user-position {
  position: absolute; }


.article__comments {
  width: 100%;
  margin-bottom: 20px;
  padding-top: 50px; }

.article__upload-media,
.article__list {
  input[type="file"] {
    display: none; } }

.article__comments-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  @media (max-width: 700px) {
    flex-direction: column; } }

.article__comments-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #000000; }

.article__comments-sort {
  display: flex; }

.article__comments-sort-title {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #C1C1C1;
  margin-right: 19px;
  @media (max-width: 700px) {
    display: none; } }

.article__comments-select {
  width: 205px;
  @media (max-width: 700px) {
    width: 100%;
    margin-top: 15px;
    padding: 5px 0; }

  .select-dropdown .selectize-input {
    @media (max-width: 700px) {
      padding: 14px 20px; } }

  .select-dropdown .selectize-input > .item {
    color: #000; }

  .select-dropdown .selectize-input:not(.input-active) {
    background: transparent; } }

.article__upload-media {
  display: flex;
  align-items: center;
  width: 240px;
  height: 40px;
  background-image: url("./upload-media.png");
  background-repeat: no-repeat;
  cursor: pointer;
  padding-left: 55px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #C1C1C1;

  &--white {
    background-image: url("./upload-media-white.svg"); }

  &:hover {
    background-image: url("./upload-media-hover.svg"); }

  @media (max-width: 700px) {
    text-indent: -99999px;
    background-image: url("./upload-media-mobile.png");
    width: 40px;
    height: 50px; } }

.article__refused-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px; }

.article__list {
  display: flex;
  list-style: none;
  flex-wrap: wrap;

  li {
    position: relative;
    width: 80px;
    height: 80px;
    margin-right: 14px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: #f5f5f5;
      border: 1px solid #E5E5E5;
      box-sizing: border-box;
      border-radius: 4px; } } }

.article__list--distance {
  margin-bottom: 20px; }

.article__delete {
  position: absolute;
  width: 30px;
  height: 30px;
  background-image: url("./delete.png");
  background-repeat: no-repeat;
  top: -12px;
  right: -12px;
  cursor: pointer; }

.article__comments-body {
  margin-bottom: 27px; }

.article__answers {
  margin-bottom: 44px;

  &.is-loading {
    opacity: 0.5;
    pointer-events: none; } }

.article__answer {
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-top: 20px;

  &:first-child {
    margin-top: 0; }

  .article__answers--has-more & {
    &:nth-child(n+3) {
      display: none; } } }

.article__answer-wrap {
  padding: 25px;
  width: 100%; }

.article__answer-wrap--reply {
  display: flex; }

.article__answer--reply {
  margin-top: 0;
  display: flex;
  box-shadow: none;
  border-radius: 0; }

.article__answer-dots {
  width: 30px;
  flex-shrink: 0; }

.article__answer-box {
  flex-grow: 1;
  min-width: 0; }

.article__answer-dot {
  width: 6px;
  height: 6px;
  background: #C4C4C4;
  border-radius: 50%;
  margin-top: 18px; }

.article__answer-head {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px; }

.article__answer-user {
  display: flex; }

.article__answer-img {
  margin-right: 15px;
  width: 40px;
  height: 40px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%; } }

.article__answer-info {
  margin-right: 15px; }

.article__answer-title {
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: #212121;
  margin-top: 2px; }

.article__answer-date {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #C1C1C1; }

.article__answer-changed {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #A5A5A5;
  margin-top: 2px; }

.article__statistic {
  display: flex; }

.article__answer-body {
  width: 100%;
  margin-bottom: 25px; }

.article__answer-comment,
.article__area {
  font-style: normal;
  font-weight: normal;
  word-break: break-word;

  a {
    text-decoration: none; }

  p {
    font-size: 15px;
    line-height: 25px;
    color: #212121; }

  ul, ol {
    padding-left: 20px; } }

.article__answer-actions {
  display: flex; }

.article__answer-likes {
  display: flex;

  > .button-voting {
    margin: 0;
    margin-right: 10px; } }

.article__answer-elem {
  display: flex;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px 10px;
  margin-right: 10px;
  text-decoration: none; }

.article__answer-response {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #C1C1C1;
  margin-left: 6px;
  text-decoration: none; }

.article__answer-color {
  padding: 0 5px 0 0;
  color: $color-main; }

.article__answer-show-more {
  width: 100%;
  display: none;
  text-decoration: none;
  padding: 10px 0;
  margin-top: 20px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #212121;

  .article__answers--has-more & {
    display: block; } }

.article__answer-showing {
  margin-top: 20px;
  display: none; }

.article__answer-magnific {
  img.mfp-img {
    padding: 0!important; } }

.article__tooltip {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  width: 190px;
  margin-top: 22px;
  padding: 20px 25px 20px 25px;
  background: #FFFFFF;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);

  @media (max-width: 860px) {
    left: auto;
    right: -15px;
    transform: translateX(0); }

  @media (max-width: 860px) {
    right: -10px; }

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 20px;
    height: 20px;
    background-color: #ffffff;

    @media (max-width: 860px) {
      left: auto;
      right: 17px; }

    @media (max-width: 700px) {
      right: 0px; } } }

.tooltip-open {
  left: 590px;
  width: 145px;
  top: 50px;
  @media (max-width: 700px) {
    left: 57%; }
  @media (max-width: 320px) {
    left: 50%; }
  &::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    transform: rotate(45deg);
    top: -10px;
    left: 65px;
    @media (max-width: 700px) {
      left: 100px; } } }

.tooltip-open--first-cm {
  left: 555px;
  @media (max-width: 700px) {
    left: 57%; }
  @media (max-width: 320px) {
    left: 50%; } }

.tooltip-open--second-cm {
  left: 520px;
  @media (max-width: 700px) {
    left: 57%; }
  @media (max-width: 320px) {
    left: 50%; } }

.article__tooltip-elem {
  display: block;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #A5A5A5;
  text-decoration: none;

  &:not(:first-child) {
    margin-top: 12px; } }

.article__tooltip-elem-divider {
  margin-top: 12px;
  margin-right: -25px;
  margin-left: -25px;
  height: 1px;
  background-color: #EFEFEF; }

.article__tooltip-elem--delete {
  color: #F0443D;
  margin-bottom: 0; }

.article__tooltip-elem--color {
  color: #F0443D;
  margin-bottom: 0;

  &:hover {
    color: #F0443D; } }

.article-display {
  display: none; }

.article__reply-wrap,
.article__edit-wrap {
  display: none;
  margin-top: 20px; }

.article__contest {
  display: flex;
  align-items: center;
  position: relative;

  @include max-w(768) {
    align-items: flex-start; }

  @include max-w(480) {
    width: 100%;
    padding-bottom: 40px; } }

.article__contest-name {
  font-size: 15px;
  line-height: 20px;
  color: #212121;
  text-decoration: none;
  font-weight: bold;

  @include max-w(768); }


.article__contest-label {
  margin-right: 15px;
  padding-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include max-w(480) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    order: 2;
    width: 100%;
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: 12px; }


  img {
    //position: absolute
    //left: 12px
    //top: 50%
    //transform: translateY(-50%)
    margin-right: 7px;
    max-width: 14px;
    max-height: 12px; } }

.article__contest-info {
  display: flex;
  align-items: center;
  margin-top: 5px;

  @include max-w(480) {
    flex-direction: column;
    align-items: flex-start; } }

//Rival

.article__rival {
  padding-top: 40px;
  padding-bottom: 100px; }

.article__rival-elements {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 18px);
  margin-left: -9px; }

.article__rival-item {
  width: 50%;
  box-sizing: border-box;

  @include max-w(768) {
    width: 100%;
    padding: 0; } }

.article__rival-title {
  margin-bottom: 25px;
  font-size: 24px;
  color: #000;

  @include max-w(768) {
    font-size: 20px;
    margin-bottom: 20px; } }

.article__rival-more {
  display: block;
  margin-top: 20px;
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  background-color: #fff;
  text-align: center;
  text-decoration: none;
  color: #212121;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  transition: color .2s, background-color .2s;

  &:hover {
    color: #212121;
    background-color: #f8f8f8; } }

//CONTEST

.article__contestant {
  margin-bottom: 30px;

  @include max-w(768) {
    flex-direction: column; } }

.article__contest-title {
  font-size: 38px;
  line-height: 50px;
  font-weight: bold;
  margin-bottom: 45px;
  color: #212121;

  @include max-w(768) {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 23px; } }

.article__contest-condition {
  font-size: 20px;
  line-height: 32px;
  color: #212121;
  margin-bottom: 25px; }

.article__contestant {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5; }

.article__contestant-description {
  display: flex;

  @include max-w(768) {
    margin-bottom: 13px; }

  @include max-w(480) {
    width: 100%; } }

.article__contestant-logo {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F5F5F5;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 16px;
    height: 19px; } }

.article__contestant-describe {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.article__contestant-caption {
  font-size: 12px;
  color: #a0a0a0;
  text-transform: uppercase;
  font-weight: bold; }

.article__contestant__text {
  font-size: 15px;
  line-height: 24px;
  color: #212121; }

.article__contestant-btn {
  display: inline-block;
  text-decoration: none;
  padding: 0 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  background: #FAFAFA;
  border: 1px solid rgba(0, 0, 0, 0.06);
  font-size: 15px;
  color: #212121;
  transition: background-color .3s, color .3s;
  white-space: nowrap;
  text-align: center;

  &:hover {
    color: #212121;
    background-color: #efefef; }

  @include max-w(768) {
    width: 100%;
    max-width: 380px; }

  @include max-w(480) {
    max-width: initial; } }


// PROVISO
.article__proviso {
  padding-top: 15px;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: (28 / 18);
  color: #212121;

  h1,h2,h3,h4,h5,h6 {
    margin-bottom: 1.3em; }

  ul, ol {
    margin-bottom: 28px;

    li {
      font-size: inherit;
      line-height: inherit;
      color: inherit; }

    li + li {
      margin-top: 20px; } }

  ul {
    list-style: none;

    li {
      position: relative;
      padding-left: 40px;

      &::before {
        position: absolute;
        content: '';
        top: 9px;
        left: 10px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #389A52; } } }

  ol {
    list-style-type: none;
    counter-reset: competitions-list-item 0;

    li {
      position: relative;
      padding-left: 40px;
      counter-increment: competitions-list-item;

      &::before {
        position: absolute;
        content: counter(competitions-list-item) ".";
        left: 6px;
        top: 1px; } } }


  h1 {
    font-size: 38px;
    line-height: (45 / 38); }

  h2 {
    font-size: 32px;
    line-height: (45 / 38); }

  h3 {
    font-size: 24px;
    line-height: (36 / 24);
    color: #212121;
    font-weight: bold;
    margin-bottom: 35px;

    @include max-w(768) {
      font-size: 22px;
      line-height: (29 / 22);
      margin-bottom: 21px; } }

  p {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    margin-bottom: 10px; } }

.article__proviso-list {
  list-style: none; }

.article__proviso-item {
  font-size: 18px;
  margin-bottom: 23px;
  margin-left: 15px;
  padding-left: 21px;
  position: relative;

  &:last-of-type {
    margin-bottom: 0; }

  &::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #389A52;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); } }


//REWARD
.article__reward {
  padding: 25px 30px;
  background: #F8F8F8;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  @include max-w(768) {
    padding: 25px 20px; }

  @include max-w(640) {
    display: flex;
    flex-direction: column; } }

.article__reward-describe {
  display: flex;

  @include max-w(640) {
    margin-bottom: 20px; }

  @include max-w(480) {
    width: 100%; } }

.article__reward-description {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.article__reward-btn {
  display: inline-block;
  text-decoration: none;
  height: 40px;
  line-height: 40px;
  background-color: #389A52;
  border-radius: 4px;
  font-size: 15px;
  color: #fff;
  padding: 0 20px;
  border: 1px solid #389A52;
  transition: color .2s, background-color .2s;
  white-space: nowrap;
  text-align: center;

  &:hover {
    background-color: transparent;
    color: #389A52; }

  @include max-w(640) {
    width: 100%;
    height: 50px;
    line-height: 50px; } }

.article__reward-logo {
  margin-right: 15px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  flex-shrink: 0;

  img {
    max-width: 19px;
    max-height: 20px; } }

.article__reward-title {
  font-size: 13px;
  line-height: 18px;
  color: #a0a0a0;
  font-weight: 600; }

.article__reward-raiting {
  color: #212121;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px; }


//Winner
.article__winner {
  padding: 30px 80px 30px 30px;
  background-color: #F8F8F8;
  border-radius: 4px;
  display: flex;

  @include max-w(768) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px; } }

.article__winner-description {

  @include max-w(768) {
    text-align: center; } }

.article__winner-avatar {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 28px;
  flex-shrink: 0;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover; }


  @include max-w(768) {
    width: 85px;
    height: 85px;
    margin-bottom: 18px; } }

.article__winner-title {
  font-size: 24px;
  margin-bottom: 8px;

  @include max-w(768) {
    text-align: center;
    font-size: 20px;
    line-height: 22px; } }

.article__winner-describe {
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 15px;

  @include max-w(768) {
    text-align: center;
    font-size: 13px;
    line-height: 19px; } }

.article__winner-link {
  text-decoration: none;
  font-size: 15px;
  line-height: 25px;
  color: #000; }

.article__winner-btn {
  display: inline-block;
  text-decoration: none;
  height: 40px;
  line-height: 40px;
  background-color: #389A52;
  border-radius: 4px;
  font-size: 15px;
  color: #fff;
  padding: 0 20px;
  border: 1px solid #389A52;
  transition: color .2s, background-color .2s;

  &:hover {
    background-color: transparent;
    color: #389A52; }

  @include max-w(480) {
    width: 100%;
    height: 50px;
    line-height: 50px; } }

.article__contest-logo-img {
  border-radius: 50%; }
