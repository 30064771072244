.Blockquote {
  max-width: 770px;
  margin: 0 auto;
  margin-bottom: 28px;
  padding: 0 15px;
  border: none; }

.Blockquote__InnerWrap {
  position: relative;
  padding-left: 85px;

  &:before {
    content: "";
    position: absolute;
    top: 7px;
    left: 0;
    display: block;
    width: 44px;
    height: 34px;
    background: {
      image: url("./quote.svg");
      repeat: no-repeat; } } }

.Blockquote__Quote {
  margin-bottom: 15px;
  //min-height: (2em * (32 / 24))
  color: #212121;
  font-size: 24px;
  line-height: (32 / 24);
  font-weight: 300;
  font-style: italic;
  cursor: text;

  &[contenteditable=true]:empty:before {
    display: inline-block;
    content: attr(placeholder); }

  &[contenteditable=true]:focus:empty:before {
    opacity: 0.5; } }

.Blockquote__Author {
  color: #A0A0A0;
  font-weight: bold;
  font-size: 16px;
  line-height: 12px;
  cursor: text;

  &[contenteditable=true]:empty:before {
    display: inline-block;
    content: attr(placeholder); }

  &[contenteditable=true]:focus:empty:before {
    opacity: 0.5; } }
