@import "~/common";

.menu-tab {
  display: flex;
  margin-bottom: 25px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: auto;
  overflow-scrolling: touch;

  @include max-w(767) {
    margin-bottom: 15px;

    &:after {
      content: "";
      position: sticky;
      flex-shrink: 0;
      right: 0;
      top: 0;
      width: 45px;
      margin-left: -45px;
      height: 53px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
      pointer-events: none; } }

  a {
    flex-shrink: 0;
    position: relative;
    padding: 18px 0;
    margin-right: 25px;
    display: inline-block;
    font-size: 15px;
    line-height: 1;
    color: #A8A8A8;
    text-decoration: none;

    .count {
      display: inline-block;
      margin-left: 8px; }

    &:hover {
      color: $color-main; }

    &.is-active {
      color: $color-main;

      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: $color-main; } } } }

.menu-tab__tabs {
  display: flex;
  padding: 0 27px;
  flex-shrink: 0;

  @include max-w(767) {
    padding: 0 15px; } }
