@import "~common";

.delete-this-selector {
  display: none; }

.stat_table {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px rgba(0, 0, 0, 0.09);
  @include rounded(2px);

  .stat_table_head {
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    > .row {
      display: flex;
      justify-content: space-between;

      @media (max-width: 1024px) {
        flex-wrap: wrap;
        overflow: hidden;
        overflow-x: scroll; }

      &:before,
      &:after {
        display: none; }

      > .col-md-5 {
        width: auto;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        min-width: 0;

        //@media (max-width: 1024px)
 }        //width: 100%

      > .col-md-7 {
        width: auto;
        flex-shrink: 0;
        margin-left: auto;

        //@media (max-width: 1024px)
 } }        //width: 100%

    h4 {
      padding: 0 0 0 32px;
      margin: 0;
      white-space: normal;
      color: #000;
      font-size: 15px;
      font-weight: 600;
      line-height: 24px;
      //height: 24px
      background: asset-url(body_icons_materials) no-repeat 0 0;
      word-break: break-word;
      overflow: hidden;

      &.graph {
        background: asset-url(body-icons-time) no-repeat 0 50%; } }

    .alignright {
      text-align: right;
      vertical-align: middle; }

    form {
      display: inline;

      select {
        display: none; } }

    a, span#stat_els_per_page-button, span#filter_action_id-button, span#month_visit-button, span#month_time-button {
      display: inline-block;
      line-height: 24px;
      height: 24px;
      font-size: 15px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.38);
      text-decoration: none;
      vertical-align: middle;
      margin-left: 21px;
      margin-right: 0;

      &.active {
        color: $link; }

      &.export_act {
        width: 24px;
        text-indent: -9999em;
        color: transparent;
        background: asset-url(icon-share) no-repeat 50% 50%;
        overflow: hidden; } }


    input#date_ {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      opacity: 0;
      visibility: hidden;
      width: 1px;
      height: 24px;
      display: inline-block;
      margin: 0; }

    .date_from_field, .date_till_field {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 0 none;
      background: transparent;
      height: 24px;
      padding: 0;
      line-height: 24px;
      font-size: 14px;
      color: $link;
      width: 110px;
      cursor: pointer;
      display: inline-block;
      outline: none;
      box-shadow: none;
      vertical-align: middle; }

    .date_from_field {
      text-align: right;

      & + span {
        width: 30px;
        display: inline-block;
        text-align: center;
        color: rgba(0,0,0,.38);
        font-weight: 700;
        font-size: 13px;
        line-height: 24px;
        vertical-align: middle;

        &:before {
          content: '\2013'; } } } } }


.top_32 {
  margin-top: 32px; }

#datepicker_div {
  padding: 0px;
  background: #fff;
  border: 1px solid rgba(0,0,0,.2);
  -webkit-box-shadow: 0 0 6px 1px rgba(0,0,0,.2);
  box-shadow: 0 0 6px 1px rgba(0,0,0,.2);
  font-size: 13px;
  margin-top: 2px;
  position: absolute;
  z-index: 1110;
  min-width: 266px;
  max-width: 266px;
  display: none;

  .datepicker_links {
    margin: 0!important;
    border-width: 1px 0;
    height: 46px !important;
    line-height: 46px !important;
    position: relative;
    background: $link;
    color: #fff;
    text-align: center;
    z-index: 1;

    &:before, &:after {
      @include clear(); }

    .datepicker_current {
      display: none;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: -9999em;
      left: -9999em; }

    .datepicker_prev, .datepicker_next {
      height: 46px;
      width: 17%;
      float: left;
      a {
        display: block;
        line-height: 46px;
        text-decoration: none;
        font-size: 18px;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        color: #fff; } }

    .datepicker_next {
      float: right !important; } }

  .datepicker_header {
    margin: -46px 17% 8px;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    line-height: 46px;
    height: 46px;
    background: transparent;
    position: relative;
    z-index: 2; }

  table.datepicker {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;
    font-size: .9em;
    margin: 0 0 .4em;
    height: 14.167em;

    thead {

      td {
        color: #282828;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        border: 0;
        padding: .714em .45em .714em 0;
        a {
          text-decoration: none;
          color: #282828;
          font-weight: 400; } } }

    tbody {

      td {
        border: 0 none;
        text-align: center;
        color: #b0b0b0;
        vertical-align: middle;
        font-size: 15px;
        font-weight: 400;
        min-height: 2.571em;
        line-height: 2.571em;
        padding: 0;

        a {
          min-height: 2.571em;
          line-height: 2.571em;
          padding: 0;
          text-align: center;
          display: block;
          overflow: hidden;
          outline:  none !important;
          cursor: pointer;
          @include rounded(50%);
          color: #282828;
          text-decoration: none;
          font-size: 15px;
          font-weight: 400;

          &:hover {
            background: lighten($link, 35%); } }

        &.datepicker_today {

          a {
            background: lighten($link, 20%); } }

        &.datepicker_currentDay {

          a {
            background: $link;
            color: #fff; } } } } }

  .datepicker_close {
    display: none; } }





.stat_table_graph {
  width: calc(100% - 2px); }








