.avatar {
  display: block;
  background: #CDD8B7;
  color: #FFF !important;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -.5px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  text-decoration: none;
  padding: 0 !important;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;

  &.avatar_60 {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    letter-spacing: .3px; }

  &.avatar_80 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 22px;
    letter-spacing: .3px; }

  &.avatar_100 {
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 28px;
    letter-spacing: .6px; }
  &.avatar_120 {
    width: 120px;
    height: 120px;
    line-height: 120px;
    font-size: 34px;
    letter-spacing: .6px; }

  &.centered {
    display: block;
    margin-left: auto;
    margin-right: auto; } }

div.avatar {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1; }
