@import '~common';

.contest-popup {
  width: 100%;
  max-width: 782px;
  padding: 100px 40px;
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;

  @include max-w(768) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    align-items: center; }

  @include max-w(480) {
    padding: 40px 15px; } }

.contest-popup__close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 5px;
  line-height: 0;
  z-index: 11;

  @include max-w(480) {
    position: fixed;
    right: 15px;
    top: 15px; }

  img {
    width: 100%;
    height: 100%;
    pointer-events: none; }

  &:active {
    top: 20px; } }

.contest-popup__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 460px; }

.contest-popup__avatar {
  width: 105px;
  height: 105px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 25px; }

.contest-popup__title {
  font-size: 24px;
  line-height: 28px;
  color: #000;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;

  @include max-w(768) {
    font-size: 20px;
    line-height: 26px; } }

.contest-popup__subtitle {
  font-size: 15px;
  line-height: 22px;
  color: #000;
  margin-bottom: 23px;
  text-align: center; }

.contest-popup__btn {
  height: 40px;
  line-height: 38px;
  background-color: #389a52;
  color: #fff;
  border-radius: 4px;
  padding: 0 20px;
  text-decoration: none;
  border: 1px solid #389a52;
  text-align: center;
  transition: border-color .2s, background-color .2s;

  &:hover {
    background-color: #fff;
    color: #389a52; }

  @include max-w(768) {
    width: 100%;
    height: 50px;
    line-height: 50px; }

  @include max-w(480) {
    position: fixed;
    bottom: 15px;
    left: 15px;
    right: 15px;
    width: calc(100% - 30px); } }
