@import "~common";

.user-info {
  padding: 25px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);

  @include max-w(480) {
    padding: 22px 15px; } }

.user-info__section {

  & + & {
    margin-top: 20px; } }

.user-info__title {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 15px;
  color: #000;

  @include max-w(480) {
    font-size: 14px;
    margin-bottom: 11px; } }

.user-info__content {}

.user-info__roles {

  .badge {
    font-size: 12px;
    padding: 3px 8px;
    height: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    text-decoration: none; }

  a.badge:hover {
      text-decoration: underline; }


  .badge.default {
    border: 2px solid #EDEDED;
    border-radius: 2px; } }
