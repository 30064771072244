@import "~common";

.comment-article {
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);

  @include max-w(480) {
    padding: 12px 16px; } }

.comment-article__title {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 24px;
  font-weight: 700;
  color: #000;

  @include max-w(480) {
    margin-bottom: 13px;
    font-size: 12px;
    line-height: (18 / 12); } }

.comment-article__article-category {
  color: #308446;
  text-decoration: none;

  &:hover {
    text-decoration: underline; } }

.comment-article__article-link {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline; } }

.comment-article__info {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.comment-article__author {
  font-size: 15px;
  color: #A0A0A0;

  @include max-w(480) {
    font-size: 12px; } }

.comment-article__author-name {
  text-decoration: none;
  color: inherit; }

.comment-article__date {}

.comment-article__count {
  color: #ACACAC;
  font-size: 12px;
  font-weight: 600;
  flex-shrink: 0;
  margin-left: 15px;
  text-decoration: none;

  img {
    vertical-align: middle;
    margin-left: 9px; } }

