$font: 'Open Sans', Arial, Helvetica, sans-serif;
$icons: 'FontAwesome';

$body_bg: #fafafa;
$body_bg_mobile: #f5f5f5;
$header_bg: #389a52;
$link: #389a52;
$link_dark: #0b753f;
$yellow: #fffb01;
$red: #f44337;
$red-unsubscribe: #F0443D;
$header_btns: 33px;
$color2: #308446;

$form_height: 40px;

$color-main: #389A52;
$color-error: #F0443D;
$color-second: lighten($color-main, 5%);
