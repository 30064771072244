@import "~common";

.post {
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: calc(50% - 30px);
  margin: 15px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  .full-width-post & {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }

  @include max-w(800) {
    width: 100%;
    margin-top: 7px;
    margin-bottom: 7px; }

  &:hover {

    transition: 0.15s box-shadow;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    border-color: transparent; } }

.post__main {
  flex-grow: 1;
  padding: 25px 25px 20px;

  @include max-w(480) {
    padding: 15px; }

  .post--contest & {

    @include max-w(991) {
      padding: 50px 25px 20px; }

    @include max-w(768) {
      padding: 45px 15px 20px; } } }


.post__bottom {}

.post__avatar-wrap {
  text-decoration: none; }

.post__avatar {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  background-color: #000000;
  border-radius: 50%; }

.post__header {
  display: flex;
  position: relative;
  align-items: center; }

.post__date {
  padding-top: 9px;
  color: #A8A8A8;
  font-size: 12px; }

.post__name {
  display: block;
  text-decoration: none;
  color: $link;
  font-size: 15px;
  font-weight: bold; }

.post__description {
  $lh-descr: 19 / 13;
  max-height: calc(#{$lh-descr} * 4 * 1em);
  margin-top: 16px;
  font-size: 13px;
  line-height: $lh-descr;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: 300ms; }

.post__bottom {
  margin-top: 20px; }

.post__body {
  padding: 0 25px 20px;

  @include max-w(480) {
    padding: 0 0 20px; } }

.post__cover {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 285px;
  padding: 40px 20px;
  border-radius: 4px;
  background: {
    color: #389A52;
    image: url("./bg-empty.png");
    position: center;
    size: cover; }
  overflow: hidden;

  &--with-preview:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    z-index: 0; }

  @include max-w(480) {
    padding: 20px;
    border-radius: 0; } }

.post__content {
  position: relative;
  text-align: center;
  width: 100%; }

.post__quiz-mark {
  display: inline-block;
  padding: 6px 16px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 13px;
  color: #fff;
  background: #F4A512;
  border-radius: 500px; }

.post__quiz-mark-inner {
  display: flex; }

.post__quiz-icon {
  margin-right: 7px; }

.post__quiz-text {}

.post__title {
  display: -webkit-box;
  display: block;
  max-width: 100%;
  min-width: 0;
  max-height: 112px;
  padding: 0 10px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  //+line-clamp(4, 28 / 18)
  word-break: break-all; // ie11
  word-break: break-word;

  &:hover {
    color: $color-main; }

  @include max-w(480) {
    max-height: 88px;
    font-size: 15px;
    line-height: 22px; } }

.post__favorite {
  position: absolute;
  padding: 0;
  top: 20px;
  right: 20px; }

.toggle-bookmark {
  display: block;
  user-select: none;
  cursor: pointer;

  svg {
    width: 13px;
    height: 19px; }

  svg {
    display: block;
    stroke: #C1C1C1; }

  &.is-check svg {
    fill: #C1C1C1; }

  &.is-check.toggle-bookmark--prime svg {
    stroke: $color-main;
    fill: $color-main; } }

.post__favorite-icon {
  width: 13px;
  height: 19px;

  @include max-w(480) {
    display: none; } }


.post__community {
  display: block;
  font-size: 15px;
  line-height: 1;
  margin-top: 7px;
  text-align: center;
  color: rgba(255, 255, 255, 0.85);
  text-decoration: none; }

.post__link {
  display: inline-block;
  font-size: 15px;
  color: #212121;
  margin-top: 20px;
  padding: 9px 19px;
  background-color: #ffffff;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    color: inherit; } }




.icon-share {
  margin-right: 10px; }

.post__footer {
  display: flex;
  padding: 0 25px 20px;

  @include max-w(480) {
    padding: 0 15px 20px; } }

.post__reactions {
  display: flex;
  margin-left: auto; }

.post__social {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: none;
  font-size: 12px;
  color: #c1c1c1;
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;

  &:first-child {
    margin-left: 0; }

  &:hover {
    color: #389A52;
    svg {
      path {
        fill: #389A52; } } } }

.post__social--comments {

  &:hover {
    color: #389A52;
    svg {
      path {
        fill: none;
        stroke: #389A52; } } } }




.post__socials {
  display: flex; }

.social__count {
  margin-left: auto; }

.post__edit {
  padding: 2px;
  margin-left: auto;
  cursor: pointer;
  user-select: none;

  &:hover {
    svg {
      path {
        fill: #212121; } } } }

.post__dropdown {
  position: absolute;
  display: none;
  right: -70px;
  top: 50px;
  flex-direction: column;
  padding: 20px 25px;
  background-color: #ffffff;
  z-index: 10;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: calc(50% - 10px);
    transform: rotate(45deg);
    background-color: #ffffff;
    width: 20px;
    height: 20px; } }

.post__dropdown--active {
  display: flex; }

.post__dropdown--offset {
  right: -15px;

  &:before {
    left: inherit;
    right: 15px; }

  @include max-w(479) {
    right: 10px; } }

.post__action {
  text-decoration: none;
  font-size: 15px;
  color: #A5A5A5;

  &:last-child {
    margin-top: 9px;

    &:hover {
      color: #F0443D; } } }


.post__show-more {
  display: none;
  margin-top: 5px;
  font-size: 13px;
  color: #A8A8A8;
  cursor: pointer; }

.post__details {
  display: flex;
  margin-left: auto;
  align-items: center; }

.post__pin {
  margin-right: 10px;
  color: #C1C1C1;

  &:hover {
    color: $color-main; } }

.post__label {
  text-transform: uppercase;
  border-radius: 2px;
  margin-right: 15px;
  font-weight: 600;
  height: 24px;
  line-height: 24px;

  @include max-w(1200) {
    font-size: 10px; }

  @include max-w(991) {
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px; }

  &.label--green {
    .post__label-icon {
      stroke: #389A52; } }

  &.label--yellow {
    .post__label-icon {
      stroke: #F4A512; } }

  &.label--grey {
    .post__label-icon {
      stroke: #C1C1C1; } } }

.post__label-icon {
  display: none;

  @include max-w(991) {
    display: block; } }


