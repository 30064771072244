@import "~common";

.Videos {
  max-width: 740px;
  margin: 0 auto;
  user-select: none; }

.Videos--FullWidth {
  max-width: 1140px; }

.Videos--FullScreen {
  margin-right: calc(-100vw / 2 + 770px / 2);
  margin-left: calc(-100vw / 2 + 770px / 2);
  max-width: none; }

.Videos__Wrapper {
  position: relative;

  .Videos--Float & {
    float: left;
    width: 50%;
    margin-right: 20px;
    margin-bottom: 15px; } }

.Videos__Preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.Videos__Video {
  //overflow: hidden
  position: relative;
  background-color: #f2f2f2;
  border-radius: 4px;

  &:before {
    content: "";
    display: block;
    padding-top: 56.25%; } } // 16:9

.Videos__VideoEmbed {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 4px; }

.Videos__VideoPlayer {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  white-space: initial;
  word-break: initial;

  .plyr {
    flex-grow: 1;
    flex-basis: 0;
    min-height: 0;
    border-radius: 12px; }

  .plyr--full-ui input[type=range] {
    color: $color-main; }

  .plyr--video .plyr__control.plyr__tab-focus,
  .plyr--video .plyr__control:hover,
  .plyr--video .plyr__control[aria-expanded=true] {
    background-color: $color-main; }

  .plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
    background-color: $color-main; }

  .plyr--video [role=menu] .plyr__control:hover,
  .plyr--video .plyr__control.plyr__control--back:hover {
    background-color: rgba(#000, 0.05);
    color: #000; }

  .plyr--video.plyr--menu-open {
    overflow: hidden; }

  .plyr__menu__container::after {
    right: 14px; }

  .plyr__menu__container [role='menu'] {
    padding: 10px * 0.7; }

  .plyr__menu__container .plyr__control {
    padding-bottom: 10px * 0.7 / 1.5;
    padding-left: 10px * 0.7 / 1.5;
    padding-top: 10px * 0.7 / 1.5;
    padding-right: 10px * 0.7 / 1.5; }

  .plyr__menu__container .plyr__control--forward {
    padding-right: 10px * 0.7 * 4; }

  .plyr__menu__container .plyr__control--back {
    margin: 10px * 0.7;
    width: calc(100% - (10px * 0.7 * 2)); }

  .plyr__menu__container .plyr__control::after {
    right: 10px * 0.7 * 1.5 - 4; }

  .plyr__menu__container .plyr__menu__value {
    padding-left: 10px * 0.7 * 3.5;
    margin-right: 0; }

  .plyr__menu__container .plyr__control[role='menuitemradio']::after {
    left: 9px; } }

.Videos__Video--WithMenu {
  box-shadow: 0 0 0 2px $color-main; }

.Videos__VideoOverflow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.Videos__Caption {
  display: block;
  width: 100%;
  margin-top: 10px;
  color: #BBBBBB;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  border: none;
  outline: none;

  &::placeholder {
    color: #BBBBBB;
    outline: none; } }

.Videos__Btn {
  position: absolute;
  top: 20px;
  right: 20px; }
