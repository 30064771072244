@import "~common";

.tag-editor-hidden-src {
	position: absolute !important;
	left: -99999px;
}
.tag-editor {
	list-style-type: none;
	margin: 0;
	overflow: hidden;
	cursor: text;
	background: #fff;
	line-height: 40px;
	display: block;
	li {
		display: block;
		width: 100%;
		padding: 0px 55px 0px 5px;
		overflow: hidden;
		position: relative;
		&:first-child {
			display: none;
		}
	}
	.placeholder {
		padding: 0 8px;
		color: #9f9f9f;
		font-weight: 400;
		font-size: 15px;
	}
	.tag-editor-spacer {
		padding: 0;
		width: 5px;
		overflow: hidden;
		color: transparent;
		background: none;
		position: absolute;
		top: 0;
		left: 0;
	}
	input {
		vertical-align: inherit;
		border: 0;
		outline: none;
		padding: 0;
		margin: 0;
		cursor: text;
		color: #212121;
	}
	.tag-editor ::-ms-clear {
		display: none;
	}
	.tag-editor-tag {
		color: #212121;
		white-space: nowrap;
		overflow: hidden;
		cursor: pointer;
		padding: 0;
		font-size: 15px;
		font-weight: 400;
		line-height: 40px;
	}
	.tag-editor-delete {
		position: absolute;
		top: 0;
		right: 0;
		width: 40px;
		height: 100%;
		background: transparent asset-url(close-black) no-repeat 50% 50%;
		background-size: 16px 16px;
		cursor: pointer;
	}
}
