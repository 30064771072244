@import "~/common";

.competitions {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;

  [class*=col-] {
    display: flex;
    padding: 0 8px;
    margin-bottom: 14px;
    min-width: 0; } }

.new-competition-btn-holder {

  @include max-w(767) {
    margin-bottom: 35px; } }
