@import '~common';

.Guide {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 290px;
  background: #FAFAFA;
  box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.05);
  z-index: 10;

  @include max-w(576) {
    top: 0;
    align-items: center; } }

.Guide__Sections {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1158px;
  padding: 20px 0;
  margin: 0 auto;
  flex-grow: 1;
  flex-direction: column;

  @include max-w(576) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between; } }

.Guide__Section {
  position: relative;
  width: 710px;
  margin: 0 auto;
  padding: 0 15px;
  text-align: center;

  @include max-w(700) {
    width: auto; }

  > {
    h2 {
      min-height: 54px;
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 33px; }
    p {
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 1em;

      > span {
        background: #558EFF;
        color: #fff; } } } }

.Guide__Arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 20px;
  background-image: url("./arrow.svg");
  background-size: cover;
  cursor: pointer;

  @include max-w(576) {
    display: none; } }

.Guide__Arrow--Left {
  left: 10px;
  transform: translateY(-50%) rotate(180deg); }


.Guide__Arrow--Right {
  right: 10px; }

.Guide__Nav {
  display: flex;
  justify-content: center;

  @include max-w(576) {
    display: none; } }

.Guide__NavItem {
  position: relative;
  padding: 10px 0 16px;
  margin: 0 12px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: $color-main;
  cursor: pointer;
  background-color: transparent;
  border: none; }

.Guide__NavItem--Active:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: $color-main; }

.Guide__NavItemIcon {
  color: #A0A0A0;
  vertical-align: middle;

  .Guide__NavItem:hover & {
    color: $color-main;
    margin-left: 5px;
    margin-right: -5px; } }

.Guide__NavItemWrap {
  position: relative;
  vertical-align: middle; }

.Guide__NavItemText {
  opacity: 0;

  .Guide__NavItem:hover &,
  .Guide__NavItem--Active & {
    opacity: 1; } }

.Guide__NavItemTextOverlay {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  color: #A0A0A0;
  font-weight: 400;

  .Guide__NavItem:hover &,
  .Guide__NavItem--Active & {
    opacity: 0; } }


.Guide__Close {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 40px;
  font-weight: 300;
  line-height: 0;
  color: #C1C1C1;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;

  &:hover {
    color: #000; } }

.Guide__Header {
  width: 100%;
  padding: 10px 15px 22px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);

  @include min-w(576) {
    display: none; } }


.Guide__Help {
  color: #212121;
  font-weight: bold;
  font-size: 20px; }

.Guide__Button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  border-radius: 4px;
  font-size: 15px;
  cursor: pointer; }


.Guide__Button--FAQ {
  margin-bottom: 13px;
  color: #A0A0A0;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-color: transparent;

  &:hover {
    color: #A0A0A0; } }

.Guide__Button--Next {
  color: #ffffff;
  background-color: #389a52;
  border: none; }


.Guide__image {
  max-width: 100%; }

.Guide__Buttons {
  width: calc(100% - 30px);

  @include min-w(576) {
    display: none; } }

.Guide__icon {
  width: 16px;
  height: 16px;
  margin-left: 13px; }
