@import "~common";

.competition-popup{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &__head{
    padding: 0 0 35px;
    position: relative;

    &::after{
      content: '';
      display: block;
      width: 1000px;
      height: 1px;
      background-color:#efefef;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    @media (max-width: 767px){
      padding: 0 0 20px;
    }
  }

  &__row{
    margin-bottom: 30px;
    position: relative;
    z-index: 10;

    &:last-child{
      margin: 35px 0 0;

      @media(max-width: 768px){
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 0 15px 15px;
        background-color: #fff;

        &::before{
          content: '';
          position: absolute;
          display: block;
          top: -30px;
          left: 0;
          right: 0;
          height: 50px;
          pointer-events: none;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 58.85%, #FFFFFF 100%);
          z-index: 9;
        }
      }
    }

    &--center{
      text-align: center;
    }

    @media (max-width: 767px){
      margin-bottom: 13px;
    }
  }

  &__title{
    text-align: center;
    margin: 0!important;

    @media (max-width: 767px){
      font-size: 20px!important;
      line-height: 27px!important;
      color: #212121;
      text-align: left;
    }
  }

  &__label{
    display: block;
    margin-bottom: 13px;
  }

  &__raiting{
    border: 1px solid #d0d0d0;
    height: 50px;
    border-radius: 4px;
    width: 100%;
    outline: none;
    padding-left: 65px;
    font-size: 15px;
    font-weight: 600;
    background-image: url("./beaker.svg");
    background-repeat: no-repeat;
    background-position: 20px 12px;
    background-size: 22px 20px;
  }

  &__inner{
    position: relative;
    width: 100%;
    max-width: 585px;
  }

  &__close{
    cursor: pointer;
    position: absolute;
    z-index: 100;
    right: -2px;
    top: 6px;
    width: 21px;
    height: 21px;
  }

  &__create-btn{
    height: 40px;
    line-height: 38px;
    color: #fff!important;
    background-color: #389A52!important;
    padding: 0 23px!important;
    font-size: 15px;
    border-radius: 4px;
    border: 1px solid #389A52!important;
    transition: all .2s;
    margin: 0 auto;
    outline: none;
    cursor: pointer;
    position: relative;
    z-index: 11;

    &:hover{
      background-color: #fff!important;
      color: #389A52!important;
      outline: none;
    }

    @media (max-width: 767px){
      width: 100%;
      height: 50px;
      line-height: 1;
    }
  }

  &__datepicker{
    background-color: #fff;
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    height: 50px;
    width: 100%;
    max-width: 257px;
    padding: 0 27px;
    font-size: 15px;
    color: #000;
    background-image: url("./calendar.svg");
    background-repeat: no-repeat;
    background-position: 210px 15px;
    background-size: 18px 20px;

    @media (max-width: 767px){
      max-width: 100%;
      background-position: 94% 13px;
      margin-bottom: 16px;
    }

  }

  &__form{
    padding: 35px 0 0;

    @media (max-width: 767px){
      padding: 22px 0 50px;
    }
  }

  &__time{
    display: flex;
    align-items: center;
  }

  &__field{
    display: flex;
    align-items: center;

    @media (max-width: 767px){
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__location{
    color: #a0a0a0;
    display: inline-block;
    margin-left: 12px;
    white-space: nowrap;

    @media (max-width: 480px){
      font-size: 15px;
      margin-left: 18px;
    }

    @media (max-width: 360px){
      margin-left: 5px;
    }
  }

  &__devider,
  &__from{
    margin-right: 8px;
    margin-left: 8px;
  }

  &__from{
    @media (max-width: 768px){
      display: none;
    }
  }
  &__devider{
    @media (max-width: 768px){
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .competitions-select-button,
  .competitions-select-button-date{
    background-color: #fff;
    height: 50px;
    line-height: 50px;
    padding: 0 26px;
    font-size: 15px;
    color: #000;
    width: 100%;

    .ui-icon{
      background-image: url('./arrow-down.svg')!important;
      background-position: -5px -7px!important;
      width: 20px;
      height: 16px;
      top: 14px;
    }
    &.ui-selectmenu-button-open{
      box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.15);

      .ui-icon{
        transform: rotate(180deg);
      }
    }
  }
}

.competition-popup, .ModalCommunity {

  .mce-panel{
    border: 0;
  }

  .mce-tinymce{
    border-radius: 4px;
    border: 1px solid #d0d0d0;
    box-shadow: none;
    overflow: hidden;
  }

  .mce-top-part::before{
    display: none;
  }

  .mce-container-body,
  .mce-btn button{
    background-color: #F8F8F8;
    border: none;
  }

  .mce-btn-group{
    padding: 6px 10px;
    border: 0;
    position: relative;

    @media (max-width: 768px){
      padding: 6px 8px;
    }
  }
  .mce-btn-group:not(:first-child){

    &::after{
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 24px;
      width: 1px;
      background-color: #D0D0D0;
      display: inline-block;
    }
  }

  .mce-btn-group .mce-btn{
    border: none;
  }
}

#competition_description_ifr{
  min-height: 140px;
}

.competitions-selectmenu-menu.ui-selectmenu-menu,
.competitions-selectmenu-date{
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.15);

  .ui-widget.ui-widget-content{
    width: 585px!important;
    max-width: initial!important;
    max-height: 300px;

    &::after,
    &::before{
      display: none!important;
    }
  }
}

.competitions-selectmenu-date.ui-selectmenu-menu{
  .ui-widget.ui-widget-content{
    width: 104px!important;
    min-width: initial!important;
  }
}

//Modal basic styles

.modal--new-competiton{

  &.modal{
    background-color: rgba(0, 0, 0, 0.4);

    .modal_wrap{

      .modal_inner{
        display: block;

        .modal_content{
          height: 100%;
          max-width: 782px;

          @media (max-width: 800px){
            width: 100%!important;
            max-width: none;
            padding: 25px 15px!important;
          }
        }

      }
    }
  }
}

