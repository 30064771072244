.FormCheckbox {
  position: relative;
  display: inline-block;
  cursor: pointer; }

.FormCheckbox__Input {
  position: absolute;
  appearance: none;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  opacity: 0.005; }

.FormCheckbox__Control {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #FFFFFF;
  border: 1px solid #C1C1C1;
  box-sizing: border-box;
  border-radius: 4px;
  vertical-align: middle;
  user-select: none;

  &:after {
    content: "";
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 8px;
    margin-top: -4px;
    margin-left: -5px;
    background-image: url("./check.svg");
    background-position: center;
    background-repeat: no-repeat; }

  input:checked + & {
    background-color: #389A52;
    border-color: #389A52;

    &:after {
      opacity: 1;
      visibility: visible; } }

  input:disabled + & {
    opacity: 0.5;
    cursor: not-allowed; } }

.FormCheckbox__Label {
  display: inline-block;
  font-size: 15px;
  line-height: 22px;
  text-align: right;
  color: #000000;
  vertical-align: middle;
  user-select: none;
  margin-left: 10px;

  input:disabled ~ & {
    opacity: 0.5;
    cursor: not-allowed; } }

