.Info {
  display: flex;
  padding: 10px 15px;
  background: #FAFAFA;
  border-radius: 4px;

  .form-control-group + & {
    margin-top: 15px; } }

.Info__IconWrap {
  flex-shrink: 0;
  margin-right: 10px; }

.Info__Icon {
  width: 35px;
  height: 35px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  background: {
    image: url("./icon-info.svg");
    repeat: no-repeat;
    position: center; } }

.Info__Content {
  flex-grow: 1;
  min-width: 0;
  font-size: 13px;
  line-height: 18px;
  color: #A0A0A0; }
