@import "~common";

.user-badge {
  display: flex;
  padding: 20px;
  background: #F8F8F8;
  border-radius: 2px;

  @include max-w(480) {
    padding: 15px; }

  & + & {
    margin-top: 16px; } }

.user-badge__icon-wrap {
  flex-shrink: 0;
  margin-right: 19px;

  @include max-w(480) {
    margin-right: 16px; } }

.user-badge__icon {
  width: 75px;
  height: 75px;

  @include max-w(480) {
    width: 60px;
    height: 60px; } }

.user-badge__info {
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0; }

.user-badge__info-inner {
  width: 100%; }

.user-badge__title {
  color: #212121;
  font-size: 17px;
  font-weight: 700;

  @include max-w(480) {
    font-size: 16px; } }

.user-badge__description {
  color: #212121;
  font-size: 15px;
  line-height: (20 / 15);

  @include max-w(480) {
    font-size: 13px;
    line-height: (18 / 13); } }
