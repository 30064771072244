@import "~common";

.help-info-menu {
  position: relative;
  margin-top: -15px;
  padding: 15px 0;
  border-top: 1px solid #389a52;

  @include max-w(767) {
    margin-top: 0;
    border: none; } }

.help-info-menu__title {
  font-size: 16px;
  margin-bottom: 10px;
  text-transform: uppercase;

  @include max-w(767) {
    display: none; } }

.help-info-menu__selected {
  position: relative;
  color: rgba(0,0,0,0.5);
  padding: 10px 15px;
  border-radius: 2px;
  border: 1px solid rgba(0,0,0,0.15);
  white-space: nowrap;
  text-overflow: ellipsis;

  @include min-w(768) {
    display: none; }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    right: 10px;
    top: 50%;
    margin-top: -5px;
    background: {
      image: url("./arrow.svg");
      repeat: no-repeat;
      position: center; } }

  &.is-open:after {
    transform: rotate(180deg); } }

.help-info-menu__list {
  list-style: none;

  @include max-w(767) {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 10px;
    background: #fff;
    border: 1px solid rgba(0,0,0,0.15);
    z-index: 1;

    &.is-open {
      display: block; } } }

.help-info-menu__link {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 13px 0;
  color: rgba(0, 0, 0, 0.38);
  text-decoration: none;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

  &:hover {
    color: #000; }

  .is-active & {
    color: #000;

    &:after {

      @include min-w(768) {
        border-bottom-color: #000; } } } }
