.editor {
  &__crop {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 0 auto;
    max-height: 90vh;
    width: auto; }

  &__update {
    display: block;
    width: fit-content;
    margin: 18px 0 0 auto;
    padding: 12px 25px;
    background-color: #fff;
    color: #389a52;
    font-size: 15px;
    font-weight: 400;
    border-color: #389a52;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    transition: 0.3s all;

    &:hover {
      background-color: #389a52;
      color: #fff; } } }

.ReactCrop {
  width: fit-content;
  margin: 0 auto; }




