.CustomCheckbox {
  position: relative;
  display: inline-block;
  line-height: 0;
  cursor: pointer;
  margin: 0 !important; }

.CustomCheckbox__Input {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0.001;
  appearance: none; }

.CustomCheckbox__Control {
  display: block;
  position: relative;
  width: 33px;
  height: 20px;
  background: #ECECEC;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 100px;

  input:checked + & {
    background: #389A52;
    border-color: #389A52; }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 3px;
    width: 14px;
    height: 14px;
    margin-top: -7px;
    border-radius: 50%;
    background: #AAAAAA;
    transition: 0.2s left;

    input:checked + & {
      left: 14px;
      background: #fff; } } }
