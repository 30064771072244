@import '~common';

#app-editor {
  @include max-w(991) {
    height: 100%; } }

.Editor2 {
  min-height: 100vh;
  background: #fff;

  @include max-w(991) {
    min-height: 100%;
 } }    //height: 100%

.Editor2__BtnGuide {
  position: fixed;
  bottom: 30px;
  right: 30px;
  padding: 0;
  background: none;
  border: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  z-index: 1;

  > img {
    display: block; }

  @include max-w(991) {
    display: none; } }

.Editor2__Head {
  position: relative;
  padding-top: 30px;
  padding-bottom: 70px;
  background: #FAFAFA;
  z-index: 2;

  @include max-w(991) {
    padding-top: 15px;
    padding-bottom: 30px; } }

.Editor2__HeadContainer {
  position: relative;
  max-width: 1140px;
  margin: 0 auto; }

.Editor2__Close {
  position: absolute;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  padding: 0;
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;

  > img {
    display: block; }

  @include max-w(991) {
    position: relative;
    right: auto;
    top: auto;
    transform: none;
    margin-left: 10px; } }

.Editor2__HeadInner {
  padding-top: 75px;
  max-width: 770px;
  margin: 0 auto; }

.Editor2__HeadTop {
  background: #FAFAFA;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 11;

  .ReactModalHtmlOpen & {
    margin-right: 17px;

    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      margin-right: 10px; } }

  @include max-w(991) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06); } }

.Editor2__HeadTop-inner {
  max-width: 770px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 30px 15px;

  @include max-w(991) {
    padding: 15px; } }

.Editor2__HeadInfo {
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0; }

.Editor2__User {
  display: flex;
  align-items: center;
  margin-right: 20px;

  @include max-w(991) {
    margin-right: 0; } }

.Editor2__UserAvatarWrap {
  flex-shrink: 0;
  margin-right: 15px; }


.Editor2__UserAvatar-inner {
  display: flex;
  justify-content: space-between;

  @include max-w(991) {
    display: block; } }

.Editor2__UserAvatarImg {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc; }

.Editor2__UserName {
  font-size: 15px;
  line-height: 20px;
  color: #212121;

  @include max-w(991) {
    font-size: 13px;
    line-height: 18px;
    font-weight: bold; } }

.Editor2__Date {
  font-size: 15px;
  line-height: 20px;
  color: #A0A0A0;
  margin-left: 20px;

  @include max-w(991) {
    margin-left: 0;
    font-size: 13px;
    line-height: 18px; }

  &--mobile {
    display: none; }

  @include max-w(543) {
    display: none;

    &--mobile {
      display: block; } } }


.Editor2__HeadActions {
  display: flex;
  flex-shrink: 0; }

.Editor2__HeadAction {
  margin-left: 15px;

  @include max-w(991) {
    margin-left: 0;

    &:nth-child(2) {
      display: none; } } }

.Editor2__BtnMore {
  position: relative;
  padding: 0;
  width: 46px;

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

  @include max-w(991) {
    border: none;
    opacity: .3;
    width: 40px; } }


.Editor2__CommunitySelect {
  padding: 0 15px; }

.Editor2__CommunitySelectTitle {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  color: #212121;

  @include max-w(991) {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px; } }

.Editor2__CommunitySelectControl {}

.Editor2__Content {
  padding: 60px 0; }

.Editor2__footer-mobile {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  display: none;
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  padding: 10px 15px;
  display: none;
  z-index: 10;

  @include max-w(991) {
    display: block; } }

.Editor2__footer-inner {
  max-width: 740px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto; }

.Editor2__footer-help {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.Editor2__footer-help-text {
  font-size: 15px;
  line-height: 20px;
  color: #A5A5A5;
  margin-left: 10px; }

.Editor2__BtnGuide-mobile {
  width: 25px;
  height: 25px;
  padding: 0;
  background: none;
  border: none;
  appearance: none;
  outline: none;
  cursor: pointer;

  img {
    display: block;
    height: auto;
    width: 100%; } }

.Editor2__footer-public {
  display: block;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #389A52; }
