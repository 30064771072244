.button-voting {
  display: flex;
  align-items: center;
  padding: 7px 13px 7px;
  width: 68px;
  margin-left: 10px;
  border: 1px solid #EFEFEF;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 12px;
  color: #C1C1C1;
  cursor: pointer;
  outline: none;

  > span {
    font-weight: 600;
    margin-left: auto; } }

.icon-dislike,
.icon-like {
  width: 18px;
  height: 18px; }

.button-voting--dislike:hover,
.button-voting--dislike.button-voting--active {
  color: #212121;
  svg {
    path {
      fill: #212121; } } }

.button-voting--like:hover,
.button-voting--like.button-voting--active {
  color: #389A52;
  svg {
    path {
      fill: #389A52; } } }
