@import "~common";

.subs-header {
  padding: 22px 25px;
  background: #FFFFFF;
  font-size: 18px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-bottom: none;

  @include max-w(480) {
    padding: 16px 15px; } }

.user-subs {
  display: flex;
  padding: 22px 25px;

  @include max-w(480) {
    padding: 15px;
    padding-bottom: 20px; } }

.user-subs__avatar-wrap {
  flex-shrink: 0;
  margin-right: 25px;
  text-decoration: none;

  @include max-w(480) {
    margin-right: 13px; } }

.user-subs__avatar {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  font-size: 23px;
  line-height: 74px;

  @include max-w(480) {
    width: 60px;
    height: 60px;
    line-height: 60px; } }

.user-subs__content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  max-width: 650px;
  min-width: 0; }

.user-subs__name {
  margin-bottom: 4px;
  color: #000;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;

  @include max-w(480) {
    font-size: 14px; } }

.user-subs__about {
  margin-bottom: 8px;
  color: #A0A0A0;
  font-size: 12px;
  line-height: (16 / 12); }

.user-subs__subscribe,
.user-subs__un-subscribe {
  display: inline-block;

  font-size: 12px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline; } }

.user-subs__subscribe {
  color: #479B55; }

.user-subs__un-subscribe {
  color: #F0443D; }

.user-subs__subscribe-button,
.user-subs__unsubscribe-button {
  font-size: 13px;
  cursor: pointer;
  text-decoration: none;
  padding: 11px 13px;
  background: $link;
  border-radius: 4px;
  line-height: 18px;
  color: #ffffff;
  width: 108px;
  text-align: center;

  @media screen and (max-width: 480px) {
    width: 100%; }

  &:hover {
    text-decoration: none; } }

.user-subs__subscribe-button {
  color: #ffffff;
  &:hover {
    color: #ffffff; } }

.user-subs__unsubscribe-button {
  background: #ffffff;
  color: #F0443D;
  border: 1px solid #F0443D;

  &:hover {
    color: #F0443D; } }



