.star-ratings {
  line-height: 0;
  font-size: 0;
  img {
    margin-left: 6px; }

  &__stars {

    @media screen and (max-width: 480px) {
      display: none; } }

  &__single {
   display: none;

   @media screen and (max-width: 480px) {
    display: flex;
    align-items: center; } }

  &__single-score {
    margin-left: 8px;
    font-weight: 600;
    font-size: 12px;
    color: #C2C2C2; } }
