.QuizShow {
  padding: 45px 40px;
  color: #ffffff;
  font-size: 14px;
  background-color: #346C32;
  background-image: url("./bg-quiz.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 4px;

  @media (max-width: 480px) {
    padding: 35px 20px;
    margin: 0 -20px; }

  &--ThemeLight {
    background: #ffffff;
    color: #000;
    border: 1px solid #E5E5E5; }

  &__heading {
    text-align: center; }

  &__footer {
    margin-top: 1.2rem;
    display: flex;
    justify-content: center;
    &--space--between {
      justify-content: space-between; }
    @media (max-width: 420px) {
      flex-direction: column; } }

  &__total {
    text-align: center;
    font-weight: 600; }

  &__type {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;

    & > * {
      font-size: 15px;
      line-height: 21px;

      @media (max-width: 480px) {
        font-size: 13px;
        line-height: 19px; } } }

  &__title {
    margin-top: 0.5rem;
    font-weight: 800;
    font-size: 30px;
    line-height: (40 / 30);

    @media (max-width: 640px) {
      font-size: 20px;
      line-height: (27 / 20); } }

  &__finish {
    padding: 0.3rem 1rem;
    border: 1px solid #ffffff;
    border-radius: 4px;
    display: inline-block;
    margin-top: 1rem;
    .QuizShow--ThemeLight & {
      border-color: #C4C6CD; }

    span {
      font-weight: 600; } }

  &__form {
    display: flex;
    flex-direction: column; }

  &__body {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem; }

  &__QuestionList {
    list-style: none;
    padding: 0;
    margin: 0;
    & > *:not(:first-child) {
      margin-top: 0.6rem; } }

  &__btn {
    border-radius: 4px;
    border: none;
    font-size: 15px;
    color: #000000;
    background-color: #fff;
    width: 100%;
    padding: 15px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    appearance: none;
    outline: none;

    &:hover {
      background-color: rgba(#fff,0.85); }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none; }

    .QuizShow--ThemeLight & {
      background-color: #389A52;
      color: #fff;

      &:hover {
        background-color: #3fad5c; }

      &:disabled {
        opacity: 0.4; } } }

  &__circle {
    width: 5px;
    height: 5px;
    background-color: #ffffff;
    display: inline-block;
    border-radius: 50%;
    margin: 0 .5em;

    .QuizShow--ThemeLight & {
      background-color: #8F8F8F; } }


  &__UsersList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }

  &__UsersWrap {
    display: flex;
    align-items: center;
    margin-right: 10px; }

  &__user {
    height: 37px;
    width: 37px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    position: relative;

    .avatar {
      display: block;
      width: 100%;
      height: 100%;
      line-height: 35px; }

    &:nth-of-type(2) {
      margin-left: -12px; }

    &:nth-of-type(3) {
      margin-left: -12px; } } }





