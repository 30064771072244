@import "~common";

.SizePopover {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  background: #212121;
  border-radius: 4px; }

.SizePopover__Item {
  flex-shrink: 0;
  padding: 7px 8px;
  cursor: pointer;
  color: #fff;

  &--Active {
    color: $color-main; } }

.SizePopover__Icon {
  display: block; }
