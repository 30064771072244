@import "~common";

$ph-color: rgba($color-main, 0.15);

@import "~placeholder-loading/src/scss/placeholder-loading";
@import "./grid12.scss";
@import "./pomoika.scss";

.mfp-bg {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.4); }

#complaints a {
  text-decoration: none; }
