.TextAreaCounter {
  position: relative;

  textarea {
    overflow: auto; } }

.TextAreaCounter__Control,
.TextAreaCounter__Control.form-control {
  padding-bottom: 40px;
  resize: none; }

.TextAreaCounter__Counter {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 12px;
  color: #A0A0A0;


  span {
    font-weight: 600;
    color: #212121; } }
