@import "~common";

.CarouselEditor {
  background-color: #FAFAFA;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  user-select: none; }

.CarouselEditor__Head {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06); }

.CarouselEditor__HeadTitle {
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0; }

.CarouselEditor__HeadTitle,
.CarouselEditor__Counter {
  font-size: 15px;
  color: #212121; }

.CarouselEditor__HeadActions {
  display: flex;
  align-items: center; }

.CarouselEditor__HeadAction {
  margin-left: 10px; }

.CarouselEditor__ImagesWrap {
  padding: 17px 20px; }

.CarouselEditor__Images {
  margin: -5px;

  &:after {
    content: "";
    display: table;
    width: 100%; } }

.CarouselEditor__ImageItem {
  position: relative;
  width: 25%;
  float: left;
  padding: 5px; }

.CarouselEditor__ImageProgress {
  position: absolute; }

.CarouselEditor__Image {
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  background: {
    color: #f2f2f2;
    size: cover;
    position: center;
    repeat: no-repeat; }
  border: 1px solid #E5E5E5;
  cursor: grab;

  &:before {
    content: "";
    display: block;
    padding-top: 100%; }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: {
      color: rgba(0, 0, 0, 0.65);
      // image: url("./icon-img-hover.svg")
      repeat: no-repeat;
      position: center; }
    border-radius: 4px;
    transition: 0.3s opacity; }

  &:hover:after {
    opacity: 1; } }

.CarouselEditor__Image--Uploading:after {
  display: none; }

.CarouselEditor__ImageRemove {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 5px;
  right: 5px;
  margin-top: -7px;
  margin-right: -7px;
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #F0443D;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: none;
  z-index: 1;
  appearance: none;
  outline: none;

  &:hover {
    color: #fff;
    background: #F0443D; } }

.CarouselEditor__AddImg {
  display: block;
  position: relative;
  border-radius: 4px;
  border: 1px dashed #E5E5E5;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    border-color: darken(#E5E5E5, 20%); }

  &:before {
    content: "";
    display: block;
    padding-top: 100%; } }

.CarouselEditor__AddInput {
  position: absolute;
  width: 1px;
  height: 1px;
  top: 0;
  left: 0;
  overflow: hidden;
  appearance: none;
  opacity: 0.01; }

.CarouselEditor__AddImgInner {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: #A1A1A1;
  text-align: center;
  transform: translateY(-50%);

  .CarouselEditor__AddImg:hover & {
    color: #000; } }

.CarouselEditor__ImageProgress {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: $color-main;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px; }
