@import "~common";

.EditorContent {}

.EditorContent__loading {
  max-width: 770px;
  padding: 0 15px;
  margin: 0 auto; }

.EditorContent__TitleWrap {
  max-width: 770px;
  padding: 0 15px;
  margin: 0 auto;
  margin-bottom: 15px;

  @include max-w(480) {
    margin-bottom: 25px; } }

.EditorContent__TextArea {
  width: 100%;
  resize: none;
  border: none;
  outline: none;
  overflow: hidden; }


.EditorContent__Title {
  padding: 0;
  color: #000;
  font-size: 38px;
  font-weight: 700;
  $lh-title: (45 / 38);
  line-height: $lh-title;
  cursor: text;
  min-height: calc(1em * 1.3);
  word-break: break-word;

  @include max-w(991) {
    font-size: 28px;
    line-height: 36px; }

  @include max-w(480) {
    font-size: 26px;
    line-height: 32px; }

  &::placeholder {
    color: #BBBBBB; }

  &:focus::placeholder {
    color: #BBBBBB;
    opacity: 0.35; }

  &[contenteditable=true]:empty:before {
    display: inline-block;
    content: attr(placeholder);
    color: #BBBBBB; }

  &[contenteditable=true]:focus:empty:before {
    opacity: 0.5; } }

.EditorContent__Content {
  position: relative;

  &:after {
    content: "";
    display: table;
    width: 100%; }

  .megadraft-editor {

    @include max-w(991) {
      .toolbar.toolbar--open {
        position: fixed !important;
        top: auto !important;
        bottom: 0 !important;
        left: 50% !important;
        transform: translateX(-50%);
        z-index: 99999;
        background: #f5f5f5;
        width: 100%;

        &.toolbar--error {

          .toolbar__error-msg {
            color: red;
            position: absolute;
            bottom: 40px;
            left: 0; } }


        .toolbar__arrow {
          display: none; }

        div {
          position: fixed !important;
          bottom: 0 !important;
          left: 0 !important;
          display: flex;
          align-items: center;

          .toolbar__wrapper {
            background-color: #fff;
            border-radius: 0;
            box-shadow: none;
            width: 100%;
            outline: 1px solid red;

            div {
              width: 100%; }

            .toolbar__input {
              width: 100%;
              color: #000;
              background: #fff; }

            .toolbar__list {
              margin: 0 auto; }

            .toolbar__item--separator {
              border-color: rgba(33, 33, 33, 0.3); } } } } }

    .sidebar {
      max-width: 740px;
      margin: 0 auto;
      z-index: 10; }

    .editor__link {
      color: $color-main;
      text-decoration: none;

      &:hover {
        text-decoration: underline; } }

    .toolbar__item {
      vertical-align: middle;

      @include max-w(991) {
        background: #fff;
        color: #a5a5a5; } }

    .toolbar__item--active {
      color: $color-main; }

    .toolbar__button {
      width: 30px;
      height: 44px;
      line-height: 48px; }

    .sidemenu {
      width: auto; }

    .sidebar__menu {

      @media (max-width: 860px) {
        left: auto;
        right: 15px; } }


    .sidemenu__button {
      display: block;
      margin: 0;
      background: #000;
      border-radius: 50%;

      &:before {
        display: none; } }

    .sidemenu__item {
      display: table-cell;
      width: auto;
      height: auto;

      .sidemenu__button {
        transition: none; } }

    .sidemenu__items {
      display: table;
      position: absolute;
      top: 50%;
      left: 100%;
      width: auto;
      max-height: none !important;
      margin: 0;
      margin-left: 15px;
      padding: 12px;
      background: #000;
      border-radius: 4px;
      transform: translate(5px, -50%) translateZ(0);
      visibility: hidden;
      opacity: 0;
      transition: 0.3s all, 0s visibility 0.1s;

      @include max-w(860) {
        left: auto;
        right: 100%;
        margin-left: 0;
        margin-right: 15px; }

      &--open {
        opacity: 1;
        visibility: visible;
        transform: translate(0, -50%) translateZ(0);
        transition: 0.3s all, 0s visibility; } }

    .public-DraftEditorPlaceholder-root {
      left: 0;
      width: 100%; } }

  .public-DraftEditorPlaceholder-inner {
    max-width: 770px;
    margin: 0 auto;
    padding: 0 15px;
    color: #BBBBBB;
    opacity: 1; }

  .public-DraftEditorPlaceholder-hasFocus {

    .public-DraftEditorPlaceholder-inner {
      opacity: 0.5; } }

  .DraftEditor-editorContainer {
    .public-DraftEditor-content {
      padding: 0 15px;
      margin: 0 auto;
      max-width: 770px; } }

  .DraftEditor-editorContainer > div > div > {
    blockquote {
      margin-bottom: 28px;
      padding: 0;
      border: none;
      min-height: (2em * (32 / 24));
      color: #212121;
      font-size: 24px;
      line-height: (32 / 24);
      font-style: italic;

      &:before {
        display: none; }

      > div {
        position: relative;
        padding-left: 85px;

        &:before {
          content: "";
          position: absolute;
          top: 7px;
          left: 0;
          display: block;
          width: 44px;
          height: 34px;
          background: {
            image: url("./icons/quote.svg");
            repeat: no-repeat; } } } }

    .paragraph {
      // max-width: 770px
      padding: 0;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 28px;
      color: #212121;

      //+max-w(991)
 }      //  padding: 0 15px

    a {
      text-decoration: none; }

    h1, h2, h3, h4, h5, h6, ul, ol {
      padding: 0;
      margin: 0;
      margin-bottom: 28px;
      color: #212121;
      line-height: normal; }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 35px;
      margin-bottom: 15px;

      &:first-child {
        margin-top: 0; } }

    h1 {
      font-size: 38px;
      line-height: 45px; }

    h2 {
      font-size: 24px;
      line-height: 36px; }

    ul, ol {
      list-style: none;

      li + li {
        margin-top: 20px; }

      > li {
        position: relative;
        padding: 0;
        margin: 0;
        padding-left: 40px; } }

    ul > li {

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 10px;
        left: 10px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $color-main; } }

    ol {
      counter-reset: editor-list-item 0; // давать имя list-item не можно

      > li {
        counter-increment: editor-list-item;

        &:before {
          position: absolute;
          content: counter(editor-list-item) ".";
          left: 6px;
          top: 1px; } } } } }

.megadraft-editor .paragraph + .paragraph {
  margin-top: 10px; }
