@import "~common";
.alert {
  position: fixed;
  padding: 15px 40px;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1300;
  color: #fff;
  text-align: center;
  line-height: 20px;
  font-size: 15px;
  &.alert-success {
  	background: $link; }
  &.alert-danger {
    background: #f44337; }
  &.alert-error {
    background: #f44337; }
  &:after {
  	width: 24px;
  	height: 24px;
  	position: absolute;
  	content: '';
  	top: 50%;
  	right: 16px;
  	background: asset-url(close-white) no-repeat 50% 50%;
  	background-size: 16px 16px;
  	cursor: pointer;
  	margin-top: -12px; } }
