@import "~common";

.subscribers-popup {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 550px;
  height: 521px;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;

  @media (max-width: 768px) {
    height: inherit;
  }
}

.subscribers-popup__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  border-bottom: 1px solid #EFEFEF;

  .mfp-close {
    margin-right: -10px;
    font-family: $font;
    font-weight: 300;
    font-size: 45px;
    position: inherit;
    color: #C2C2C2;
    opacity: 1;
    &:hover {
      color: #212121;
    }
  }

}

.subscribers-popup__title {
  color: #000000;
  font-weight: bold;
  line-height: 25px;
  font-size: 18px;
}

.mfp-close-btn-in .mfp-close {
  color: #C2C2C2;
}


.subscribers-popup__wrapper {
  display: flex;
  align-items: center;
  padding: 15px;
}

.subscribers-popup__community{
  margin: 25px 25px 10px;
  background: #F5F5F5;
  border-radius: 4px;
}

.subscribers-popup__image-link {
  flex-shrink: 0;
  margin-right: 13px;
}
.subscribers-popup__image {
  width: 50px;
  height: 50px;
  border: none;
  background-color: #b4b4b4;
  border-radius: 50%;
  overflow: hidden;
}

.subscribers-popup__head {
  color: #212121;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-decoration: none;
}

.subscribers-popup__policy {
  font-size: 13px;
  line-height: 18px;
  color: #999999;
}

.subscribers-popup__subscriber-list {
  flex-grow: 1;
  min-height: 0;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #ffffff
  }

  &::-webkit-scrollbar-thumb {
    background-color: #389A52
  }
}
.subscribers-popup__subscriber {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin: 25px 25px 0;
  border-bottom: 1px solid #EFEFEF;
  color: inherit;
  text-decoration: none;
}

.subscribers-popup__avatar {
  margin-right: 15px;
  flex-shrink: 0;
  text-decoration: none;

  .avatar {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

}

.subscribers-popup__text {
  font-size: 13px;
  line-height: 18px;
  color: #212121;
}

.subscribers-popup__name {
  font-size: 13px;
  line-height: 18px;
  color: #212121;
  text-decoration: none;
}


