@import "~common";

.form-control {
  width: 100%;
  min-height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  font-size: 15px;
  color: #212121;
  border: 1px solid #D0D0D0;
  outline: none;

  &.is-error {
    border-color: $color-error;
  }

  &::placeholder {
    opacity: 1;
    color: #A0A0A0;
  }

  &:focus::placeholder {
    opacity: 1;
    color: #626262;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

textarea.form-control {
	min-height: 150px;
	padding-top: 10px;
	padding-bottom: 10px;
  line-height: (24 / 15);

	&.not-size {
		resize: none;
	}
}

.form-group{
	label {
		display: block;
		margin-bottom: 18px;
		font-size: 15px;
		font-weight: 400;
    color: #000000;

    > span {
      color: #A1A1A1
    }
	}

	& + & {
		margin-top:  24px;
	}
}

.form-btns {
  margin-top: 30px;
}

.form-group-right{
	text-align: right;
}

.form-control-group {
  display: flex;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  > .form-control {

  }

  > .button {
    height: auto;
    margin-left: 15px;
    flex-shrink: 0;

    @media (max-width: 700px) {
      min-height: 50px;
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
    }
    }
  }


// TODO: все что ниже желательно удалть

.form_descr_simple{
	position: relative;
	padding: 8px 0;
	margin: 26px 0 0;
	z-index: 3;
	min-height: 40px;
	font-size: 15px;
	font-weight: 400;
	color: #212121;
	cursor: text;
	resize: none;
	border: none;
	outline: none;
	height: auto;
	width: 100%;
	line-height: 1.5;

	&::placeholder{
		color: #9f9f9f;
		font-weight: 400;
		font-size: 15px;
		cursor: text;
	}

	&:focus::placeholder{
		opacity: 0.5;
	}
}

.form_item {
	position: relative;
	height: $form_height + 1;
	background: #FFF;
	z-index: 1;
	&:before {
		content: '';
		position: absolute;
		width: auto;
		bottom: 0;
		left: 50px;
		right: 0;
		height: 0;
		border-bottom: 1px solid #dcdcdc;
	}
	input {
		background: transparent !important;
		border: 0 none;
		outline: none;
		padding: 5px 5px 5px 55px;
		z-index: 3;
		position: relative;
		height: $form_height;
		line-height: $form_height - 10;
		font-size: 15px;
		font-weight: 400;
		color: #212121;
		display: block;
		width: 100%;
		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0px 1000px white inset;
		}
		&::-ms-clear {
			display: none;
		}
	}
	textarea {
		background: transparent !important;
		border: 0 none;
		outline: none;
		padding: 8px 5px;
		z-index: 3;
		position: relative;
		height: $form_height;
		line-height: $form_height - 10;
		font-size: 15px;
		font-weight: 400;
		color: #212121;
		display: block;
		width: 100%;
		resize: none;
		overflow: hidden;
		max-height: 4000px;
	}
	.textarea_hidden {
		position: absolute;
		visibility: hidden;
		z-index: -1;
		padding: 5px;
		font-size: 15px;
		line-height: $form_height - 10;
		font-weight: 400;
		color: transparent;
		opacity: 0;
		color: transparent;
		width: 100%;
	}
	label {
		position: absolute;
		z-index: 1;
		height: $form_height;
		line-height: $form_height;
		padding: 0 0 0 55px;
		color: #9f9f9f;
		font-weight: 400;
		font-size: 15px;
	}
	&.active {
		border-color: #c1c1c1;
		label {
			display: none;
		}
	}
	&.form_item_bold {
		label, input, textarea {
			font-weight: 700;
		}
	}
	.fa {
		width: $form_height;
		height: $form_height;
		line-height: $form_height;
		text-align: center;
		color: #9f9f9f;
		font-size: 15px;
		position: absolute;
		display: block;
		top: 0;
		left: 0;
	}
	&.form_error {
		&:before {
			border-color: #e9322d;
		}
		.fa {
			color: #e9322d;
		}
	}
	& + .form_item, & + .form_row {
		margin-top: 16px;
	}
	& + .form_checkbox {
		margin-top: 22px;
	}
	& + .form_desc {
		margin-top: 6px;
	}
}

.field_with_errors_text {
	display: block;
	padding: 6px 0 0 55px;
	font-size: 12px;
	line-height: 16px;
	color: #e9322d;
	font-weight: 500;
}

.form_row + .form_item {
	margin-top: 16px;
}


.form_desc {
	padding: 0 0 0 55px;
	font-size: 12px;
	color: #707070;
	p {
		font-size: 12px;
		font-weight: 400;
		color: #707070;
		& + p {
			margin-top: 9px;
		}
	}
	& + .form_item {
		margin-top: 16px;
	}
}

.form_checkbox {
	height: $form_height / 2;
	line-height: $form_height / 2;
	position: relative;
	overflow: hidden;
	padding-left: 55px;
	label {
		display: block;
		position: relative;
		cursor: pointer;
		height: $form_height / 2;
		line-height: $form_height / 2;
		font-size: 15px;
		color: #212121;
		font-weight: 400;
		padding-left: 24px;
	}
	input[type=checkbox] {
		display: none;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		width: 0;
		height: 0;
		top: -9999em;
		left: -9999em;
		& + label:before {
			font-family: $icons;
			color: $link;
			font-size: 15px;
			content: '\f096';
			position: absolute;
			top: 0;
			left: 0;
			line-height: $form_height / 2 + 3;
		}
		&:checked + label:before {
			content: '\f14a';
		}
	}
	& + .form_item {
		margin-top: 16px;
	}
}

.form_checkbox_toggle {
	position: relative;
	margin-top: 16px;
	white-space: nowrap;
	input[type=checkbox] {
		display: none;
		visibility: hidden;
		opacity: 0;
		width: 0;
		height: 0;
		& + label {
			display: block;
			cursor: default;
			padding: 0 60px 0 0;
			position: relative;
			color: #9f9f9f;
			font-size: 15px;
			line-height: 24px;
			height: 24px;
			transition: color .3s;
			&:before {
				content: '';
				height: 24px;
				width: 40px;
				@include rounded(12px);
				background: #FFF;
				border: 1px solid #e0e0e0;
				position: absolute;
				top: 0;
				right: 0;
				cursor: pointer;
				transition: all .2s;
			}
			&:after {
				content: '';
				position: absolute;
				width: 22px;
				height: 22px;
				@include rounded(11px);
				top: 1px;
				right: 18px;
				background: #FFF;
				border: 1px solid #e0e0e0;
				cursor: pointer;
				box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
				border: solid 1 rgba(0, 0, 0, 0.1);
				transition: all .2s;
			}
		}
		&:checked + label {
			color: #212121;
			&:before {
				background: $link;
				border-color: $link_dark;
			}
			&:after {
				right: 0;
				box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
				border: solid 1 rgba(0, 0, 0, 0.1);
			}
		}
	}
}
.form_radio {
	height: 24px;
	line-height: 24px;
	position: relative;
	overflow: hidden;
	& + .form_radio {
		margin-top: 18px;
	}
	label {
		display: block;
		position: relative;
		cursor: pointer;
		min-height: 24px;
		line-height: 24px;
		font-size: 15px;
		color: #212121;
		font-weight: 400;
		padding-left: 40px;
	}
	input[type=radio] {
		display: none;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		width: 0;
		height: 0;
		top: -9999em;
		left: -9999em;
		& + label {
			&:before {
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				width: 24px;
				height: 24px;
				background: asset-url(icon-radio) no-repeat 50% 50%;
			}
			&:after {
				content: '';
				display: block;
				width: 0;
				height: 0;
				opacity: 0;
				background: asset-url(icon-radio-enable) no-repeat 50% 50%;
			}
		}
		&:checked + label{
			color: $link;
			&:before {
				background: asset-url(icon-radio-enable) no-repeat 50% 50%;
			}
		}
	}
	& + .form_item {
		margin-top: 16px;
	}
}
.user_checkbox {
	display: inline-block;
	margin-top: 8px;
	input[type=checkbox] {
		display: none;
		visibility: hidden;
		opacity: 0;
		width: 0;
		height: 0;
		& + label {
			display: block;
			cursor: default;
			text-indent: -9999em;
			overflow: hidden;
			position: relative;
			height: 24px;
			width: 24px;
			color: transparent;
			cursor: pointer;
			&:before {
				content: '';
				display: block;
				height: 24px;
				width: 24px;
				background: asset-url(icon-checkbox) no-repeat 50% 50%;
			}
			&:after {
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				visibility: hidden;
				display: block;
				opacity: 0;
				background: asset-url(icon-checkbox-enable) no-repeat 50% 50%;
			}
		}
		&:checked + label {
			&:before {
				background: asset-url(icon-checkbox-enable) no-repeat 50% 50%;
			}
		}
	}
}

.close_modal {
  margin-right: 20px;
}

.form_submit {
	margin-top: 32px;
	margin-left: 15px;
    margin-right: 20px;
	.btn {
		display: inline-block;
		height: $form_height;
		line-height: $form_height;
		margin-bottom: 16px;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		cursor: pointer;
		text-decoration: none;
		outline: none;
		font-size: 15px;
		font-weight: 400;
		background: #f0f0f0;
		color: $link;
		border: 0 none;
		@include rounded($form_height/2);
		padding: 0 $form_height/2;
		&.green {
			background: $link;
			color: #fff;
			&:hover {
				background: darken($link,3%);
			}
		}
		&.red {

		}
		&.full_width {
			display: block;
			width: 100%;
			max-width: 280px;
		}
		&.btn_link {
			background: transparent;
			padding: 0;
			font-size: 13px;
			color: #212121;
			text-decoration: underline;
			margin-right: 9px;
			&:hover {
				color: $link_dark;
			}
		}
		&::-moz-focus-inner {
		  border: 0;
		}
	}
}

#error_explanation {
	margin-bottom: 30px;
	h2 {
		display: none;
		visibility: hidden;
		opacity: 0;
		margin: 0;
		padding: 0;
	}
	ul {
		display: block;
		list-style: none;
		li {
			display: block;
			list-style: none;
			padding: 0 0 0 55px;
			margin: 0;
			font-size: 14px;
			font-weight: 400;
			color: #b61418;
			& + li {
				margin-top: 8px;
			}
		}
	}
}

