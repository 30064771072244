.rely-form {
  padding: 20px;
  background: #F5F5F5;
  border-radius: 4px; }

.rely-form__textarea {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  outline: none;
  resize: none;
  font-size: 15px;
  line-height: 25px;

  &::placeholder {
    opacity: 1;
    color: #A5A5A5; }

  &:focus::placeholder {
    opacity: 0.8; } }

.rely-form__attachments {
  margin-top: 20px; }

.rely-form__actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .button--link {
    text-decoration: none; } }

.rely-form__actions-left {}

.rely-form__actions-right {}
