@import "~common";

.c-banner-info {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background-color: #fff;
  display: flex;

  @include max-w(480) {
    border-radius: 0; } }

.c-banner-info__inner {
  display: flex;
  padding: 24px 22px;
  align-items: center;
  margin-right: 10%;

  @include max-w(800) {
    padding: 20px 15px;
    margin: 0; } }

.c-banner-info__logo {
  margin-right: 18px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;

  @include max-w(800) {
    width: 50px;
    height: 50px;
    margin-right: 15px; } }

.c-banner-info__logo-img {
  width: 10px;
  height: 20px; }

.c-banner-info__description {
  font-size: 15px;
  line-height: 22px;
  color: #000;

  @include max-w(800) {
    font-size: 13px;
    line-height: 19px; } }
