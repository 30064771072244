@import "~common";

.popup-main {
  position: absolute;
  z-index: 5;
  top: 65px;
  left: 50%;
  width: 510px;
  transform: translate(-41%, 0);

  @include max-w(1090) {
    transform: translate(-53%, 0); }

  @include max-w(576) {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    transform: none; } }


.popup-main__inner {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  line-height: normal;
  background-color: #ffffff;
  padding: 30px 50px 30px 40px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);

  @include max-w(576) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 30px; }

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 40%;
    transform: rotate(45deg);
    background-color: #ffffff;
    width: 20px;
    height: 20px;

    @include max-w(576) {
      display: none; } } }

.popup-main__title {
  color: #000000;
  font-weight: bold;
  padding-bottom: 9px;
  font-size: 18px;

  @include max-w(576) {
    font-size: 20px;
    line-height: 30px; } }

.popup-main__wrapper {
  text-align: left;
  margin-left: 30px;

  @include max-w(576) {
    width: 100%;
    margin-left: 0;
    text-align: center;
    align-items: center; } }

.popup-main__description {
  font-size: 12px;
  line-height: 21px;
  color: #000000;

  @include max-w(576) {
    font-size: 16px;
    line-height: 24px; } }

.popup-main__close {
  position: absolute;
  cursor: pointer;
  top: 20px;
  background-color: transparent;
  border: none;
  right: 18px;
  width: 30px;
  height: 30px; }

.popup-main__image {

  @include max-w(576) {
    width: 160px;
    margin-bottom: 32px; } }

.popup-main__create-image {
  margin-right: 13px; }

.popup-main__create {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 15px;
  right: 15px;
  bottom: 17px;
  padding: 15px 0;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  color: #ffffff;
  background-color: $link;

  @include min-w(576) {
    display: none; } }


