@import "~/common";

.ReactModalHtmlOpen {
  overflow: hidden;
  margin-right: 17px;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    margin-right: 10px; } }

.ReactModalBodyOpen {
 }  //overflow-y: scroll

.ReactModal__Overlay--Custom {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  overflow-y: scroll;
  z-index: 1000;

  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle; }

  &::-webkit-scrollbar {
    width: 10px; }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05); }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.18); } }

.ReactModal__Content--Custom {
  display: inline-block;
  width: 100%;
  max-width: 810px;
  padding: 30px 15px;
  text-align: left;
  white-space: normal;
  vertical-align: middle;
  outline: none;

  @include max-w(700) {
    padding: 0;
    display: inline-flex;
    min-height: 100%;
    flex-shrink: 0; } }
