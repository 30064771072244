@import "~common";

.left_block_ {
	& + .left_block_ {
		padding-top: 70px;
	}
	h4 {
		line-height: 1.2;
		margin: 0 0 8px;
		padding: 0;
		& + p {
			line-height: 1.5;
			margin: 0;
			padding: 0 0 23px;
			position: relative;
			&:after {
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: 0;
				border-bottom: 1px solid rgba(0,0,0,.08);
				left: 0;
				bottom: 13px;
			}
		}
	}
	.competiotion_index {
		font-size: 15px;
		color: #212121;
		font-weight: 400;
		line-height: 20px;
		padding-top: 4px;
		span.sbercoins {
			display: block;
			position: relative;
			margin-top: 6px;
			height: 24px;
			line-height: 24px;
			vertical-align: top;
			color: $link;
			font-size: 14px;
			font-weight: 400;
			.icon {
				display: inline-block;
				vertical-align: top;
				width: 24px;
				height: 24px;
				margin-right: 8px;
				background: asset-url(sbercoints-new) no-repeat 50% 50%;
			}
		}
		& + .competiotion_index {
			margin-top: 24px;
		}
	}
	.left_block_item {
		& + .left_block_item {
			padding-top: 32px;
		}
		img.responsive {
			max-width: 100px;
		}
		strong {
			display: block;
		}
		span.author {
			display: block;
			padding-top: 4px;
		}
		.title {
			margin-bottom: 4px;
			color: #212121;
			text-decoration: none;
			display: inline;
			line-height: 20px;
			vertical-align: top;
			&:hover {
				color: $link_dark;
			}
		}
		.ndash {
			height: 20px;
			width: 20px;
			display: inline-block;
			vertical-align: top;
			position: relative;
			&:before {
				content: '';
				width: 6px;
				height: 3px;
				left: 7px;
				top: 50%;
				background: #212121;
				position: absolute;
				display: block;
				margin-top: -1px;
			}
		}
		.category {
			margin-bottom: 4px;
			color: $link;
			text-decoration: none;
			display: inline;
			line-height: 20px;
			vertical-align: top;
			&:hover {
				color: $link_dark;
			}
		}
		form {
			display: inline;
			input[type=submit] {
				border: 0 none;
				background: transparent;
				outline: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				cursor: pointer;
				display: inline;
				padding: 0;
				margin: 0;
			}
			&.hide {
				display: none;
			}
		}
	}

	.title-wrap{
		word-break: break-word;
		overflow: hidden;
	}
}

