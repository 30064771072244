@import "~common";

.edit-profile {
  position: relative;
  padding: 25px;
  //margin-top: 20px
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);

  @include max-w(480) {
    padding: 25px 15px; } }

.edit-profile__user {
  display: flex;

  @include max-w(480) {
    display: block; } }

.edit-profile__info {
  display: flex;
  flex-grow: 1;
  min-width: 0;
  align-items: center; }

.edit-profile__info-content {
  flex-grow: 1;
  min-width: 0;

  @include max-w(480) {
    text-align: center; } }

.edit-profile__info-actions {
  margin-left: 16px;
  flex-shrink: 0;

  @include max-w(580) {
    display: none; }

  .btn_default {
    margin-left: 16px; } }

.edit-profile__name {
  font-size: 24px;
  font-weight: 700;

  @include max-w(480) {
    font-size: 20px; } }

.edit-profile__email {
  color: #A0A0A0;
  font-size: 18px;
  margin-top: 7px;

  @include max-w(480) {
    font-size: 16px;
    margin-top: 3px; } }

.edit-profile__upload-avatar {
  display: block;
  position: relative;
  width: 105px;
  height: 105px;
  margin-right: 22px;
  border-radius: 50%;
  overflow: hidden;
  text-decoration: none;

  @include max-w(480) {
    width: 120px;
    height: 120px;
    margin: 0 auto 17px; } }

.edit-profile__avatar,
.edit-profile__avatar.avatar {
  flex-shrink: 0;
  width: 105px;
  height: 105px;
  margin-right: 23px;
  line-height: 105px;
  font-size: 28px;
  letter-spacing: .6px;
  object-fit: cover;
  object-position: center;
  position: relative;
  overflow: hidden;

  @include max-w(480) {
    width: 120px;
    height: 120px;
    line-height: 120px;
    margin: 0 auto 16px; } }

.edit-profile__avatar-new-img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 0; }

.edit-profile__placeholder-upload {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: {
    color: rgba(#212121, 0.45);
    image: url("./photo-upload.svg");
    repeat: no-repeat;
    position: center; }
  cursor: pointer;
  transition: 0.3s background-color;

  &:hover {
    background-color: rgba(#212121, 0.6); } }

.edit-profile__input-avatar {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  top: 50%;
  left: 50%;
  overflow: hidden; }

.edit-profile__self-about {
  position: relative;
  margin-top: 28px;

  @include max-w(480) {
    margin-top: 24px; } }

.edit-profile__self-label {
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 20px;
  color: #A0A0A0;
  opacity: 1;
  transition: 0.3s all;

  .edit-profile__self-about--focus &,
  .edit-profile__self-about--not-empty & {
    font-size: 12px;
    color: #AAAAAA; } }

.edit-profile__text {
  padding: 38px 18px 15px 18px;
  width: 100%;
  min-height: 121px;
  color: #000;
  font-size: 15px;
  line-height: 22px;
  border: 1px solid #EDEDED;
  border-radius: 2px;
  resize: none;
  outline: none;

  @include max-w(580) {
    min-height: 209px;
    padding-bottom: 40px; }

  &::placeholder {
    color: #A0A0A0;
    opacity: 1; }

  &:focus::placeholder {
    opacity: 0.5; } }

.edit-profile__counter {
  font-size: 12px;
  color: #A0A0A0;

  @include max-w(580) {
    position: absolute;
    left: 18px;
    bottom: 12px; }

  span {
    color: #212121;
    font-weight: 600; } }

.edit-profile__actions {
  display: none;
  margin-top: 17px;

  @include max-w(580) {
    display: block; }

  .subscribe-form [type="submit"],
  .unsubscribe-form [type="submit"],
  .btn_edit,
  .btn_default {
    display: block;
    width: 100%;

    @include max-w(480) {
      font-size: 16px;
      height: 48px;
      line-height: 46px; } }

  .btn_default {
    margin-top: 11px; } }

