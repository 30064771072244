@import "~/common";

.competition-card {
  display: flex;
  position: relative;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  transition: 0.2s box-shadow;

  &:hover {
    position: relative;
    z-index: 1;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1); } }

.competition-card__wrap {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  width: 100%;
  padding: 30px 25px 25px;
  @include max-w(767) {
    padding: 30px 15px 25px; } }

.competition-card__menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 25px;

  @include max-w(767) {
    top: 15px;
    right: 15px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #EBEBEB; } }

.competition-card__main {
  padding-bottom: 20px;
  text-align: center;
  flex-shrink: 0;
  flex-grow: 1; }

.competition-card__preview {
  margin-bottom: 15px;

  img {
    display: block;
    width: 125px;
    height: 125px;
    margin: 0 auto;
    border-radius: 50%;

    @include max-w(767) {
      width: 120px;
      height: 120px; } } }

.competition-card__date {
  margin-bottom: 3px;
  font-size: 14px;
  line-height: 19px;
  color: #A8A8A8; }

.competition-card__title {
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #212121; }

.competition-card__author {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #000000; }


.competition-card__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding-top: 18px;
  border-top: 1px solid #EBEBEB; }

.competition-card__stats {
  padding-right: 15px; }

.competition-card__stat {
  font-size: 14px;
  line-height: 19px;
  color: #212121;

  & + & {
    margin-top: 5px; }

  img {
    margin-top: -0.1em;
    margin-right: 6px;
    vertical-align: middle; } }

.competition-card__btn {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  background: {
    color: #F5F5F5;
    image: url("./icon-arrow.svg");
    repeat: no-repeat;
    position: center; }
  border-radius: 50%; }
