@import "~common";

.Images {
  max-width: 740px;
  margin: 0 auto;
  user-select: none; }

.Images--FullWidth {
  max-width: 1140px; }

.Images--FullScreen {
  margin-right: calc(-100vw / 2 + 770px / 2);
  margin-left: calc(-100vw / 2 + 770px / 2);
  max-width: none; }

.Images__Container {

  .Images--Carousel.Images--FullWidth & {
    padding-right: 40px;
    padding-left: 40px; }

  .Images--Float & {
    float: left;
    width: 50%;
    margin-right: 20px;
    margin-bottom: 15px; } }

.Images__ImgWrap {
  position: relative;
  border-radius: 4px;
  min-height: 80px; }

.Images__ImgWrap--WithMenu {
  box-shadow: 0 0 0 2px $color-main; }

.Images__Img {
  position: relative;
  display: block;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 4px;
  z-index: -1;

  .Images--FullScreen & {
    width: 100%; } }

.Images__Caption {
  display: block;
  width: 100%;
  margin-top: 10px;
  font-size: 15px;
  line-height: 20px;
  color: #BBBBBB;
  text-align: center;
  border: none;
  outline: none;

  &::placeholder {
    color: #BBBBBB;
    outline: none; } }

.Images__Btn {
  position: absolute;
  top: 20px;
  right: 20px; }

.Images__CarouselCounter {
  position: absolute;
  bottom: 25px;
  left: 50%;
  padding: 7px 15px;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  background: rgba(#000, 0.7);
  border-radius: 4px;
  transform: translateX(-50%);

  @include max-w(830) {
    bottom: 10px;
    padding: 7px 10px;
    font-size: 13px;
    line-height: 18px; } }

.Images__Carousel {
  font-size: 0;
  line-height: 0;

  .slick-prev,
  .slick-next {
    width: 20px;
    height: 30px;
    background: {
      position: center;
      repeat: no-repeat; }

    @include max-w(830) {
      filter: drop-shadow(0 1px 2px rgba(#000, 0.2)); }

    &:before {
      display: none; } }

  .slick-prev {
    left: -35px;
    background-image: url("./icon-arrow-prev.svg");
    z-index: 1;

    @include max-w(830) {
      left: 15px;
      background-image: url("./icon-arrow-prev-mobile.svg"); } }

  .slick-next {
    right: -35px;
    background-image: url("./icon-arrow-next.svg");

    @include max-w(830) {
      right: 15px;
      background-image: url("./icon-arrow-next-mobile.svg"); } } }


.Images__CarouselImage {
  background: {
    color: #F5F5F5;
    repeat: no-repeat;
    position: center;
    size: contain; }
  border-radius: 4px;

  &:before {
    content: "";
    display: block;
    padding-top: (416 / 740 * 100%); } }

