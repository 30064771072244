@import "~common";

select.js-select {
  display: none;
}

.select-dropdown {
  display: none;

  &.selectize-control {
    display: block;
  }

  &.single .selectize-input.input-active {
    background: #fff;
    display: block;

    @media(max-width: 991px) {
      background-color: #f5f5f5;
    }
  }

  .selectize-input {
    display: block;
    padding: 9px 20px;
    padding-right: 40px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: none;
    white-space: nowrap;
    background-color: #fff;

    @media(max-width: 991px) {
      background-color: #f5f5f5;
    }

    &.dropdown-active {
      padding-left: 40px;
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;

      &:before {
        display: none;
      }
    }

    &.focus {
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    }

  }
  &.js-select-authors {
    .selectize-input {
      padding-right: 25px;

      &::after {
        display: none
      }
    }
  }

  .selectize-input > .item,
  .selectize-input > input,
  .selectize-input > input::placeholder {
    display: inline-block;
    font-size: 15px;
    color: #A5A5A5;
    line-height: 1 !important;
    vertical-align: middle !important;
  }

  .selectize-input > input[placeholder] {
    width: 100% !important;
  }

  .selectize-input > .item {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px !important;


    @media(max-width: 991px) {
      color: #212121;
    }
  }

  #authors-selectized {
    @media(max-width: 991px) {
      color: #212121 !important;
    }
  }

  .selectize-dropdown {
    margin: 0;
    border: none;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.15);

    .option {
      padding: 5px 20px;
      font-size: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #A5A5A5;
      cursor: pointer;

      &.active {
        background: #fff;
        color: $color-main;
      }
    }
  }

  .selectize-dropdown-content {
    padding: 10px 0;
  }

  .selectize-control {

    @media (max-width: 480px) {
      width: 480px;
    }
  }


  .selectize-input:after {
    border: none !important;
    width: 12px !important;
    height: 7px !important;
    background-image: url("./dropdown.svg");
  }

  .selectize-input.dropdown-active:after {
    transform: rotate(180deg);
  }

  .selectize-input.dropdown-active:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -7px;
    border: none !important;
    width: 15px !important;
    height: 15px !important;
    background-color: transparent;
    background-image: url("./search.svg");
  }

  &.select-dropdown--simple .selectize-input.dropdown-active {
    padding-left: 20px;

    &:before {
      display: none;
    }

    > input {
      display: none !important;
    }
  }

  .btn_clear {
    position: absolute;
    top: 50%;
    right: 35px;
    margin-top: -10px;
    border: none;
    background: none;
    color: #212121;
    cursor: pointer;
    opacity: 0.4;
    outline: none;
    appearance: none;
    z-index: 10;

    &:hover{
      opacity: 0.7;
    }
  }

  &.select-dropdown--community {
    margin-bottom: 20px;

    @include max-w(767) {
      margin-bottom: 25px;
    }

    .selectize-input {
      background: #fff;
      padding: 16px 27px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
      border-radius: 4px;

      &.dropdown-active {
        padding-left: 40px;
        background: #fff;
      }

      &.focus {
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
      }
    }

  }

}
