@import "~common";

.modal-community-wrapper {

  .mfp-content {

    @include max-w(585) {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } } }

.ModalCommunity {
  display: flex;
  flex-direction: column;
  max-width: 785px;
  margin: 0 auto;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 0;

  .ModalWindow {
    flex-grow: 1;
    flex-shrink: 0; }

  @include max-w(585) {
    width: 100%; }

  .ModalWindow__Main {
    display: flex;
    flex-direction: column; } }

.ModalCommunity__Content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%; }

.ModalCommunity__ContentWrapper {
  flex-grow: 1;
  flex-shrink: 0; }

.ModalCommunity__Settings {
  padding: 20px;
  background: #FAFAFA;
  border-radius: 4px; }

.ModalCommunity__SettingsItem {

  & + & {
    margin-top: 15px; } }

.ModalCommunity__Setting {
  display: flex; }

.ModalCommunity__SettingLabel {
  flex-grow: 1;
  min-width: 0;
  font-size: 15px;
  line-height: 22px;
  color: #A0A0A0; }

.ModalCommunity__SettingValue {
  flex: 0; }

.ModalCommunity__Actions {
  margin-top: 40px;
  text-align: center;
  flex-shrink: 0;

  @media (max-width: 700px) {
    margin-top: 30px; }

  > .button {
    margin: 0 5px;

    @include max-w(585) {
      width: 100%;
      margin: 5px 0; } } }

.ModalCommunity__Image {

  .ReactCrop__image {
    max-height: none; } }

.ModalCommunity__ImagePreviews {
  margin-top: 20px;
  padding: 20px 0 0 20px;
  background: #FAFAFA;
  border-radius: 4px;
  overflow: hidden;

  @include max-w(585) {
    padding: 19px 0 0 19px; }

  > div {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;

    @include max-w(585) {
      margin-right: 15px;
      margin-bottom: 15px; }

    &:last-child {
      margin-right: 0; } } }

.ModalCommunity__PreviewCircleSmall {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: {
    color: #ccc;
    size: cover;
    position: center; } }

.ModalCommunity__PreviewCircleMedium {
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background: {
    color: #ccc;
    size: cover;
    position: center; }

  @include max-w(585) {
    width: 120px;
    height: 120px; } }

.ModalCommunity__PreviewSquare {
  width: 115px;
  height: 115px;
  border-radius: 4px;
  background: {
    color: #ccc;
    size: cover;
    position: center; } }

.ModalCommunity__PreviewRect {
  width: 208px;
  height: 115px;
  border-radius: 4px;
  background: {
    color: #ccc;
    size: cover;
    position: center; }

  @include max-w(585) {
    width: 305px;
    height: 175px; } }

.ModalCommunity__ChangeImage {
  position: relative; }

.ModalCommunity__ChangeImageInput {
  width: 1px;
  height: 1px;
  overflow: hidden;
  appearance: none;
  opacity: 0.001; }

.ModalCommunity__Dropzone.Dropzone {

  @media (min-width: 701px) {
    height: 320px; } }

.ModalCommunity__ActionsDescription {
  margin-bottom: 25px;
  font-size: 13px;
  line-height: 18px;
  color: #A0A0A0;

  @media (max-width: 700px) {
    margin-bottom: 10px; } }
