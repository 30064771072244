@import "~common";

//HELP PAGE
#help_page {
	h2 {
		font-size: 24px;
		line-height: 30px;
		letter-spacing: -.2px;
		color: #000;
		padding: 0;
		margin: 0 0 32px;
		font-weight: 700;
	}
	.help_item {
		margin-bottom: 24px;
		h3 {
			color: $link;
			display: inline-block;
			font-size: 24px;
			font-weight: 600;
			line-height: 30px;
			padding: 0;
			margin: 0;
			cursor: pointer;
			border-bottom: 2px dashed $link;
			&:hover {
				color: $link_dark;
				border-color: $link_dark;
			}
		}
		.help_item_content {
			display: none;
			padding-top: 12px;
			h4 {
				color: #212121;
				font-size: 20px;
				font-weight: 600;
				margin: 12px 0;
			}
			p {
				font-size: 15px;
				font-weight: 400;
				color: #212121;
				line-height: 22px;
				& + p, & + ul, & + ol {
					margin-top: 14px;
				}
				a, .video-tip {
					color: $link;
					text-decoration: underline;
					cursor: pointer;
					&:hover {
						color: $link_dark;
					}
				}
				& + .player {
					margin-top: 24px;
				}
			}
			ul {
				li {
					list-style: outside disc;
					margin-left: 25px;
					font-size: 15px;
					font-weight: 400;
					color: #212121;
					line-height: 22px;
					& + li {
						margin-top: 14px;
					}
					a, .video-tip {
						color: $link;
						text-decoration: underline;
						cursor: pointer;
						&:hover {
							color: $link_dark;
						}
					}
				}
				& + p {
					margin-top: 14px;
				}
				& + .player {
					margin-top: 24px;
				}
			}
			ol {
				li {
					list-style: outside decimal;
					margin-left: 25px;
					font-size: 15px;
					font-weight: 400;
					color: #212121;
					line-height: 22px;
					& + li {
						margin-top: 14px;
					}
					a, .video-tip {
						color: $link;
						text-decoration: underline;
						cursor: pointer;
						&:hover {
							color: $link_dark;
						}
					}
				}
				& + p {
					margin-top: 14px;
				}
				& + .player {
					margin-top: 24px;
				}
			}
		}
		&.open {
			h3 {
				border-color: transparent !important;
			}
			.help_item_content {
				display: block;
			}
		}
	}
}
