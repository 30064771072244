@import "~common";

.profile-container {
  @include max-w(767) {
    padding: 0; } }

.user-profile {
  margin-bottom: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);

  @include max-w(580) {
    margin-bottom: 0; } }

.user-profile__upper-box {
  padding: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);

  @include max-w(480) {
    padding: 25px 15px 10px 15px;
    border-bottom: none; } }


.user-profile__top {
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  @include max-w(480) {
    display: block; } }

.user-profile__avatar,
.user-profile__avatar.avatar {
  display: block;
  flex-shrink: 0;
  width: 105px;
  height: 105px;
  margin-right: 22px;
  line-height: 105px;
  font-size: 28px;
  letter-spacing: .6px;

  @include max-w(480) {
    width: 120px;
    height: 120px;
    line-height: 120px;
    margin: 0 auto 14px; } }

.user-profile__info {
  flex-grow: 1;
  min-width: 0;

  @include max-w(480) {
    text-align: center; } }

.user-profile__info-top {}

.user-profile__name {
  display: inline-block;
  margin-right: 14px;
  color: #212121;
  font-size: 24px;
  font-weight: 700;
  vertical-align: middle;

  @include max-w(480) {
    font-size: 20px;
    display: block;
    margin-right: 0;
    margin-bottom: 2px; } }

.user-profile__roles {
  display: inline-block;
  vertical-align: middle;

  @include max-w(480) {
    display: block; } }

.user-profile__role {
  display: inline-block;
  margin-right: 10px;

  &:last-child {
    margin-right: 0; } }

.user-profile__info-bottom {
  margin-top: 7px; }

.user-profile__email {
  display: inline-block;
  color: #A0A0A0;
  font-size: 15px;
  margin-right: 18px;

  @include max-w(480) {
    margin-right: 0; } }

.user-profile__stats {
  display: flex;
  margin-top: 10px;

  @include max-w(480) {
    flex-wrap: wrap;
    margin-top: 16px; } }

.user-profile__stat {
  display: inline-block;
  margin-right: 18px;
  text-decoration: none;
  font-size: 15px;
  color: #A0A0A0;

  @include max-w(700) {
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-right: 0;
    border: 1px solid rgba(0, 0, 0, 0.06);

    &:last-child {
      width: 100%; } }

  .count {
    display: inline-block;
    margin-right: 8px;
    color: $color2;
    text-decoration: inherit;

    @include max-w(480) {
      margin-right: 0;
      display: block; } }

  .descr {
    @include max-w(700) {
      margin-left: 5px; } } }


a.user-profile__stat:hover {
  text-decoration: underline; }

.user-profile__top-actions {

  @include max-w(580) {
    //display: none
    display: flex;
    justify-content: center;
    margin-top: 20px; }

  @include max-w(480) {

    .btn_edit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50px;
      line-height: 1;
      font-size: 15px; } } }

.user-profile__bottom-actions {
  display: none;
  margin-top: 20px;

  @include max-w(580) {
    display: none; }

  .subscribe-form,
  .unsubscribe-form {
    display: block; }

  .btn_edit,
  .subscribe-form [type="submit"],
  .unsubscribe-form [type="submit"] {
    display: block;
    width: 100%;

    @include max-w(480) {
      font-size: 16px;
      height: 48px;
      line-height: 46px; } } }

.user-profile__bottom {}

.user-profile__self-about {
  position: relative;
  padding: 15px 20px;
  color: #212121;
  font-size: 18px;
  line-height: (26 / 18);
  border-radius: 4px;
  background: #FAFAFA;
  border: 1px solid rgba(0, 0, 0, 0.06);

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 40px;
    top: -8px;
    width: 15px;
    height: 15px;
    background: #fafafa;
    transform: rotate(45deg);
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-left: 1px solid rgba(0, 0, 0, 0.06); }

  @include max-w(480) {
    text-align: center;
    font-size: 16px;
    line-height: (24 / 16);
    background: transparent;
    border: none;

    &:before {
      display: none; } } }

.user-profile__job {
  padding: 25px;

  @include max-w(700) {
    padding: 15px; } }

.user-profile__job-wrap {
  display: block;

  @include max-w(700) {
    display: none; } }

.user-profile__job-box {
  display: flex;
  position: relative;

  @include max-w(700) {
    flex-direction: column;
    align-items: center; } }

.user-profile__job-info {
  position: relative;
  margin-right: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  color: #A0A0A0;
  padding-left: 24px; }

.user-profile__job-info--place {
  &::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 16px;
    left: 0;
    top: 7px;
    background-image: url("./environment.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center; } }

.user-profile__job-info--position {
  &::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 16px;
    left: 0;
    top: 7px;
    background-image: url("./user.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center; } }

.user-profile__job-descr {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  color: #000000;

  @include max-w(700) {
    text-align: center;
    margin: 3px 0; } }

.user-profile__job-show {
  position: relative;
  display: none;
  padding-bottom: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #A0A0A0;
  text-align: center;

  &::before {
    position: absolute;
    content: '';
    top: 7px;
    right: 30px;
    width: 14px;
    height: 9px;
    background-image: url("./arrow-down.svg");
    background-position: center;
    background-repeat: no-repeat; }

  @include max-w(700) {
    display: block; } }

.user-profile__job-hide {
  position: relative;
  display: none;
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #A0A0A0;
  text-align: center;

  &::before {
    position: absolute;
    content: '';
    top: 7px;
    right: 30px;
    width: 14px;
    height: 9px;
    background-image: url("./arrow-up.svg");
    background-position: center;
    background-repeat: no-repeat; } }

.user-profile__display {
  @include max-w(700) {
    display: none !important; } }

.user-profile__edit {
  display: none;

  @include max-w(700) {
    display: block;
    width: 100%;
    margin: 0 0 30px 0;
    padding: 0 15px; } }

.user-profile__edit-link {
  width: 100%; }

