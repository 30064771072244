@import '~common';

.c-banner-inform {
  border-radius: 4px;
  background-color: #F8F8F8;
  display: flex; }

.c-banner-inform__inner {
  display: flex;
  padding: 15px 20px;
  align-items: center;

  @include max-w(767) {
    padding: 20px; } }

.c-banner-inform__logo {
  margin-right: 18px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  @include max-w(767) {
    width: 50px;
    height: 50px; } }

.c-banner-inform__logo-img {
  width: 7px;
  height: 14px; }

.c-banner-inform__description {
  font-size: 15px;
  line-height: 22px;
  color: #000;

  @include max-w(767) {
    font-size: 13px;
    line-height: 19px; } }
