@import "~common";
//mini
.about-top__what-mini {
  display: none;

  @include max-w(992) {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
    margin-top: 10px;
    border-radius: 4px;

    @include max-w(480) {
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 20px; } } }

.about-top__mini-block {
  display: flex;
  justify-content: center; }
.about-top__mini-text {
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 17px;
  color: #000000;
  &:after {
    position: absolute;
    content: '';
    background-image: url("./sou.svg");
    left: -24px;
    top: -1px;
    width: 20px;
    height: 19px; }
  &:before {
    position: absolute;
    content: '';
    background-image: url("./point.svg");
    left: -20px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    top: 6px;
    width: 13px;
    height: 3px; } }

//.user-position
//  border-bottom: 1px solid rgba(0, 0, 0, 0.06)
//  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06)
//  border-radius: 4px
//  overflow: hidden

.user-position__user-title {
  background: #fff;
  padding: 0 25px;
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px; }

.user-position__user-title-img {
  width: 40px;
  height: 40px;
  z-index: 4;
  position: relative;
  & img {
    width: 100%; } }
.user-position__user-title-box {
  margin-left: 10px; }
.user-position__user-title-login {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #000000; }
.user-position__user-title-text {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #7F7F7F; }
