@import "~common";
.article {
  &__share {
    display: block;
    position: relative;
    height: 40px;
    line-height: 40px;
    border: 0 none;
    outline: none;
    background: transparent;
    padding: 0 0 0 32px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: #a8a8a8;
    text-decoration: none;
    &:before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;
      left: 0;
      margin-top: -12px;
      background: url('~img/icon-share') no-repeat 50% 50%; }
    &:hover {
      color: $link;
      &:before {
        background: url('~img/icon-share-hover') no-repeat 50% 50%; } } } }
