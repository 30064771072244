@import "~common";

// DATEPICKER
#datepicker_div {
	padding: 0px;
	background: #fff;
	border: 1px solid rgba(0,0,0,.2);
	-webkit-box-shadow: 0 0 6px 1px rgba(0,0,0,.2);
	box-shadow: 0 0 6px 1px rgba(0,0,0,.2);
	font-size: 13px;
	margin-top: 2px;
	position: absolute;
	z-index: 1110;
	min-width: 266px;
	max-width: 266px;
	display: none;
	.datepicker_links {
		margin: 0!important;
		border-width: 1px 0;
		height: 46px !important;
		line-height: 46px !important;
		position: relative;
		background: $link;
		color: #fff;
		text-align: center;
		z-index: 1;
		&:before, &:after {
			@include clear();
		}
		.datepicker_current {
			display: none;
			visibility: hidden;
			opacity: 0;
			position: absolute;
			top: -9999em;
			left: -9999em;
		}
		.datepicker_prev, .datepicker_next {
			height: 46px;
			width: 17%;
			float: left;
			a {
				display: block;
				line-height: 46px;
				text-decoration: none;
				font-size: 18px;
				cursor: pointer;
				text-decoration: none;
				text-align: center;
				color: #fff;
			}
		}
		.datepicker_next {
			float: right !important;
		}
	}
	.datepicker_header {
		margin: -46px 17% 8px;
		text-align: center;
		font-size: 15px;
		font-weight: 400;
		color: #fff;
		line-height: 46px;
		height: 46px;
		background: transparent;
		position: relative;
		z-index: 2;
	}
	table.datepicker {
		width: 100%;
		table-layout: fixed;
		border-spacing: 0;
		border-collapse: collapse;
		font-size: .9em;
		margin: 0 0 .4em;
		height: 14.167em;
		thead {
			td {
				color: #282828;
				text-align: right;
				font-size: 14px;
				font-weight: 400;
				border: 0;
				padding: .714em .45em .714em 0;
				a {
					text-decoration: none;
					color: #282828;
					font-weight: 400;
				}
			}
		}
		tbody {
			td {
				border: 0 none;
				text-align: center;
				color: #b0b0b0;
				vertical-align: middle;
				font-size: 15px;
				font-weight: 400;
				min-height: 2.571em;
				line-height: 2.571em;
				a {
					min-height: 2.571em;
					line-height: 2.571em;
					padding: 0;
					text-align: center;
					display: block;
					overflow: hidden;
					outline:  none !important;
					cursor: pointer;
					@include rounded(50%);
					color: #282828;
					text-decoration: none;
					font-size: 15px;
					font-weight: 400;
					&:hover {
						background: lighten($link, 35%);
					}
				}
				&.datepicker_today{
					a {
						background: lighten($link, 20%);

					}
				}
				&.datepicker_currentDay {
					a {
						background: $link;
						color: #fff;
					}
				}
			}
		}
	}
	.datepicker_close {
		display: none;
	}
}
