.QuizOption {
  border-radius: 4px;
  color: inherit;
  display: flex;
  align-items: center;

  &__label {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    transition: background-color 0.3s;
    font-size: 14px;
    line-height: 21px;
    padding: 11px 20px;
    border: 1px solid transparent;
    .QuizShow--ThemeLight & {
      background-color: #F6F6F6; }
    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
      cursor: pointer;
      .QuizShow--ThemeLight & {
        background-color: #EBEBEB; }

      .QuizOption--Readonly &,
      .QuizOption--Disabled & {
        cursor: default; } } }

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    appearance: none;
    + .QuizOption__label {
      display: inline-flex;
      align-items: center;
      user-select: none; }
    + .QuizOption__label--type--radio {
      justify-content: space-between;
      flex-direction: row-reverse; }
    + .QuizOption__label::before {
      content: '';
      display: inline-block;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%; }
    + .QuizOption__label--type--checkbox::before {
      border: 1px solid #fff;
      border-radius: 4px;
      width: 21px;
      height: 21px;
      .QuizShow--ThemeLight & {
        background-color: #ffffff;
        border: 1px solid #B3B3b3; } }

    + .QuizOption__label--type--radio::before {
      width: 30px;
      height: 21px; }
    &:checked + .QuizOption__label {
      background-color: transparent;
      border: 1px solid #83ab82;
      .QuizShow--ThemeLight & {
        border: 1px solid #E5E5E5; } }
    &:checked + .QuizOption__label--type--checkbox::before {
      border-color: #ffffff;
      background-color: #ffffff;
      background-image: url("./svg/label-type-checkbox.svg");
      .QuizShow--ThemeLight & {
        border-color: #389A52; } }
    &:checked + .QuizOption__label--type--radio::before {
      background-image: url("./svg/label-type-radio.svg");
      .QuizShow--ThemeLight & {
        background-image: url("./svg/label-type-radio-theme-light.svg"); } } } }
