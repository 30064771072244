@import '~common';

.community-recommendation {
  width: 100%;
  margin: 30px 0 35px;
  padding: 0 15px; }

.community-recommendation__info {

  @include max-w(480) {
    padding: 0 15px; } }

.community-recommendation__title {
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 18px;
  line-height: (28 / 18);
  color: #212121; }

.community-recommendation__description {
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 1.2;
  color: #212121; }


.community-recommendation__list-wrap-shadow {

  @include max-w(480) {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 90px;
      height: 100%;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
      pointer-events: none;
      opacity: 0;
      transition: 0.3s opacity; }

    &.has-sh-right:after {
      opacity: 1; } } }

.community-recommendation__list-wrap {

  @include max-w(1199) {
    padding: 0 30px; }

  @include max-w(480) {
    padding: 0;
    padding-left: 15px;
    overflow: auto;
    -webkit-overflow-scrolling: touch; } }

.community-recommendation__list {
  display: flex;
  margin: 0 -10px;

  &:not(.slick-slider) {
    @include min-w(481) {
      overflow: hidden; } }

  @include max-w(480) {
    margin: 0;
    width: 75%;
    overflow: visible; }


  .slick-arrow {
    width: 13px;
    height: 22px;

    &:before {
      display: block;
      font-size: 0;
      width: 13px;
      height: 22px;
      background-image: url("./arrow.svg");
      background-repeat: no-repeat; }

    &.slick-next {
      right: -15px; }

    &.slick-prev {
      left: -15px;

      &:before {
        transform: scaleX(-1); } }

    &.slick-disabled {
      cursor: default; } }

  .slick-list {
    width: 100%;
    min-width: 0; }

  .slick-track {
    display: flex; }

  .slick-slide {
    height: auto;
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    margin: 0 10px;

    > div {
      display: flex;
      flex-direction: column;
      flex-grow: 1; } }

  > .community-recommendation__list-item {
    padding: 0 10px;

    @include max-w(480) {
      flex-basis: auto;
      flex-shrink: 0;
      width: 100%;
      padding: 0;
      padding-right: 15px; } } }

.community-recommendation__list-item {
  display: flex !important;
  flex-grow: 1; }
