@import "~/common";

.QuizEditDateTimeInput {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 4px;

  .react-datepicker-wrapper {
    border: 1px solid #EBEBEB;
    background-color: #fff;
    border-radius: 4px;

    .QuizEditDateTimeInput__Input {
      min-width: auto; } }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: auto;
    line-height: 1;
    font-size: 14px; }

  .react-datepicker__triangle {
    left: 15px !important; }

  .react-datepicker__current-month:first-letter {
    text-transform: uppercase; } }

.QuizEditDateTimeInput__Input {
  flex-grow: 1;
  height: 55px;
  min-width: 50%;
  padding: 0 20px;
  color: #212121;
  font-size: 15px;
  line-height: 22px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  max-width: 170px;

  &--Date,
  &--Time {}

  @include max-w(480) {
    max-width: 155px; } }

.QuizEditDateTimeInput__Devider {
  display: inline-block;
  padding: 0 10px;
  font-size: 15px;
  color: #A1A1A1; }

