.ModalDonePublication {}

.ModalDonePublication__Img {
  background-image: url("modal-done.png");
  width: 68px;
  height: 68px;
  margin: 0 auto; }

.ModalDonePublication__Body {
  text-align: center; }

.ModalDonePublication__Body-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 22px;
  color: #000000;
  margin-top: 30px;
  margin-bottom: 15px; }

.ModalDonePublication__Body-descr {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: (22 / 15);
  color: #000000;
  margin-bottom: 25px;

  a {
    text-decoration: none; } }
