.QuizChangeVote {
  border-radius: 4px;
  border: 1px solid #ffffff;
  font-size: 15px;
  color: #fff;
  padding: 14px 27px;
  display: inline-flex;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;

  .QuizShow--ThemeLight & {
    border: 1px solid #389A52;
    color: #389A52; }

  &:hover {
    color: #fff;

    .QuizShow--ThemeLight & {
      color: #389A52; } }

  @media (max-width: 420px) {
    margin-top: 0.5rem; } }
