@import '~common';

.PopoverError {

  .Popover-body {
    padding: 20px 30px;
    background: #fff;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15); }

  .Popover-tip {
    fill: #fff; } }
