@import "~common";

#header {
  position: relative;
  padding: 16px 0;
  background: #fff;
  width: 100%;
  z-index: 6;
  box-shadow: 0 -2px 2px 2px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 -2px 2px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 -2px 2px 2px rgba(0, 0, 0, 0.15);

  #logo_vs {
    flex-shrink: 0;
    margin: -4px 10px 0 0;
    display: block;
    height: 27px;
    width: 63px;
    text-decoration: none;
    text-indent: -9999em;
    color: transparent;
    overflow: hidden;
    float: left;
    background: url("Vsh_insberra_logo.svg") no-repeat 0% 50%;
    position: relative;
    //&:before {
    //	position: absolute;
    //	width: 32px;
    //	height: 40px;
    //	content: '';
    //	top: 0;
    //	left: 40px;
    //	background: asset-url(logo-arrow) no-repeat 50% 50%;
    //}
  }

  #logo {
    margin: 0;
    display: block;
    height: 40px;
    width: 110px;
    flex-shrink: 0;
    text-decoration: none;
    text-indent: -9999em;
    color: transparent;
    overflow: hidden;
    float: left;
    background: asset-url(logo-insberra) no-repeat 50% 50%;
    background-size: contain;
  }

  ul#after_header_menu {
    display: flex;
    list-style: none;
    float: left;
    transition: opacity .3s;

    @media (max-width: 991px) {
      margin-right: 0;
    }


    li {
      float: left;
      padding: 0;
      margin: 0;
      list-style: none;

      a {
        color: #909090;
        color: rgba(0, 0, 0, .38);
        text-decoration: none;
        display: block;
        line-height: 40px;
        font-size: 15px;
        font-weight: 400;

        &.active {
          color: #389A52 !important;
        }

        &:hover {
          color: #909090;
          color: rgba(0, 0, 0, .38);
        }

        .main-search-suggests--mobile {
          display: none;

          @media (max-width: 991px) {
            display: block;
          }
        }
      }

      & + li {
        margin-left: 32px;

        @media (max-width: 1090px) {
          margin-left: 15px;
        }
      }
    }
  }

  &.search_open {
    ul#after_header_menu {
      opacity: .3;
    }
  }

  ul#after_header_links {
    float: right;
    height: 40px;

    &:before, &:after {
      @include clear();
    }

    li {
      float: left;
      list-style: none;
      margin: 0;
      padding: 0;
      line-height: 40px;
      position: relative;

      & + li {
        margin-left: 40px;

        @media (max-width: 1090px) {
          margin-left: 20px;
        }
      }

      .notification_onboard {
        cursor: pointer;
      }

      //span, a {
      //	display: block;
      //	text-decoration: none;
      //	color: $link;
      //	font-size: 15px;
      //	font-weight: 400;
      //	line-height: 40px;
      //}
      span {
        .icon {
          margin-right: 8px
        }
      }

      .icon {
        display: inline-block;
        vertical-align: middle;
        height: 24px;
        width: 24px;
        background-repeat: no-repeat;

        &.icon-sbercoins-white {
          background-image: asset-url(sbercoints-white-new);
        }

        &.icon-notification {
          background-image: asset-url(notifications);
        }

        &.icon-search {
          background-image: asset-url(search);
        }
      }

      #new_notifications_count {
        em {
          font-style: normal;
          font-weight: 700;
          display: block;
          position: absolute;
          min-width: 20px;
          height: 20px;
          @include rounded(10px);
          background: #F34236;
          line-height: 20px;
          font-size: 10px;
          font-weight: 600;
          color: #fff;
          padding: 0;
          text-align: center;
          top: 50%;
          left: 10px;
          margin: -16px 0 0;
        }
      }

      #notification {
        #notification_inner {
          display: flex;
          flex-direction: column;
          position: absolute;
          z-index: 100;
          right: -148px;
          top: 50px;
          padding: 0;
          width: 458px;
          background: #FFF;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
          border: solid 1px rgba(0, 0, 0, 0.09);
          @include rounded(3px);

          &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent rgba(0, 0, 0, 0.09) transparent;
            top: -8px;
            right: 151px;
          }

          &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent #fff transparent;
            top: -7px;
            right: 151px;
          }

          @media (max-width: 1090px) {
            right: -120px;

            &:before, &:after {
              right: 123px;
            }
          }

          @media (max-width: 960px) {
            right: -165px;

            &:before, &:after {
              right: 168px;
            }
          }

          .pagination {
            padding: 12px 0;
          }
        }

        ul {
          flex-grow: 1;
          display: block;
          width: 100%;
          line-height: 1;
          max-height: 400px;
          overflow: hidden;
          overflow-y: scroll;
          padding: 8px 0;

          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, .1);
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: $link;
            z-index: 1000;
          }

          li {
            display: block;
            padding: 16px 16px 16px 72px;
            background: transparent;
            line-height: 21px;
            float: none;
            width: 99.9%;
            height: auto;
            position: relative;
            min-height: 72px;
            margin: 0;

            &:before, &:after {
              content: '';
              display: table;
              width: 100%;
              clear: both;
              float: none;
            }

            &.notification_clickable {
              background: #F3F9F5;
            }

            & + li {
              border-top: 1px solid #f0f0f0;
            }

            .avatar {
              position: absolute;
              top: 16px;
              left: 16px;
              font-weight: 600;
            }

            .notification_text {
              height: auto;
              display: block;
              line-height: 21px;
              margin: 0;
              padding: 0;
              font-size: 15px;
              color: #555;
              font-weight: 400;
              text-align: left;

              strong, a {
                display: inline;
                line-height: inherit;
              }

              strong {
                font-weight: 600;
                color: #313131;
              }

              a {
                color: $link;
                font-weight: 600;
                text-decoration: none;

                &:hover {
                  color: $link_dark;
                }
              }

              span.time {
                display: block;
                font-size: 12px;
                padding-top: 6px;
                color: #969696;
                line-height: 1;
                height: auto;
              }
            }
          }
        }
      }

      .user_block {
        display: block;
        width: 40px;
        height: 40px;
        position: relative;
        z-index: 4;

        @include max-w(960) {
          width: 35px;
          height: 35px;
        }

        .avatar {
          cursor: pointer;

          @include max-w(960) {
            width: 35px;
            height: 35px;
          }
        }

        ul {
          display: none;
          z-index: 10;
          width: 236px;
          background: #FFF;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
          border: solid 1px rgba(0, 0, 0, 0.09);
          position: absolute;
          left: 50%;
          top: 50px;
          margin-left: -120px;
          padding: 8px 0;
          @include rounded(3px);
          transition: opacity .3s;

          &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent rgba(0, 0, 0, 0.09) transparent;
            top: -8px;
            left: 50%;
            margin-left: -8px;
          }

          &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent #fff transparent;
            top: -7px;
            left: 50%;
            margin-left: -8px;
          }

          li {
            display: block;
            margin: 0;
            padding: 0;
            float: none;
            width: 100%;
            text-align: left;
            line-height: 20px;

            &.coins {
              display: none;
              padding: 12px 24px 12px;
              overflow: hidden;
              font-size: 14px;
              font-weight: 600;
              color: #a5a5a5;
              line-height: 20px;
              border-bottom: 1px solid #efefef;
              margin-bottom: 4px;

              span {
                float: right;
                display: block;
                max-width: 40%;
                text-align: right;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                color: $link;

                .icon-sbercoins {
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  margin-left: 4px;
                  vertical-align: top;
                  background: asset-url(sbercoints-new) no-repeat 50% 50%;
                  background-size: 20px 20px;
                }
              }
            }

            a {
              display: block;
              float: none;
              text-align: left;
              padding: 8px 24px;
              font-size: 15px;
              font-weight: 400;
              color: #a5a5a5;
              line-height: 20px;
              text-decoration: none;

              &:hover {
                color: $link;
              }
            }
          }
        }

        &.open {
          ul {
            display: block;
          }
        }
      }
    }

    .user_block__name {
      .user_block__name-user {
        line-height: 23px;
      }
      .user_block__name-title {
        font-size: 13px;
        line-height: 23px;
      }
    }

    .user_block__link {
      border-bottom: 1px solid #efefef;

      a {
        span {
          color: #a5a5a5;
          line-height: 23px;

          &:last-child {
            font-size: 13px;
          }
        }
      }

      &:hover {
        span {
          color: $link;
        }
      }
    }
  }

  #header_search {
    position: absolute;
    background: #fff;
    z-index: 10;
    height: 40px;
    width: 100%;
    left: 0;
    top: 50%;
    display: none;
    transform: translate(0, -50%);

    .form_item {
      background: transparent;

      &:before {
        width: 40px;
        height: 40px;
        position: absolute;
        border: 0 none;
        content: '';
        top: 0;
        left: 0;
        background: asset-url(search) no-repeat 0% 50%;
        z-index: 7;
      }

      input {
        padding: 5px 40px;
        line-height: 30px;
        color: #000;
        font-size: 15px;
        font-weight: 400;
        z-index: 9;

        &::placeholder {
          color: #c1c1c1;
          font-size: 15px;
          font-weight: 400;
        }

        &:focus::placeholder {
          opacity: 0.5;
        }
      }

      label {
        display: none;
        padding: 0 40px;
        color: #c1c1c1;
        font-size: 15px;
        font-weight: 400;
        z-index: 7;
      }

      .hide_search {
        display: block;
        width: 40px;
        height: 40px;
        text-decoration: none;
        position: absolute;
        text-indent: -9999em;
        top: 0;
        right: 0;
        background: {
          image: asset-url(close-black);
          repeat: no-repeat;
          position: center;
        }
        z-index: 10;
        opacity: 0.38;
        cursor: pointer;
      }
    }
  }

}

@media (max-width: 872px) {
  #header {
    padding-top: 12px;
    padding-bottom: 40px;
    //height: 106px;

    #logo {
      width: 100px;
      background-size: contain;
    }

    ul#after_header_links {
      li + li {
        margin-left: 18px;
      }

      li[data-phone-hidden] + li {
        margin-left: 0;
      }

      li {
        .user_block {
          ul {
            width: 100vw;
            position: absolute;
            left: auto;
            bottom: 0;
            height: 100vh;
            right: -15px;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
              width: 4px;
            }

            &::-webkit-scrollbar-track {
              background: rgba(0, 0, 0, .1)
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 2px;
              background: rgba(0, 0, 0, .3);
            }

            &:before, &:after {
              left: auto;
              margin-left: 0;
              right: 24px
            }
          }

          &:hover ul {
            display: none;
          }

          &.open ul {
            display: block;
          }
        }

        #notification {
          #notification_inner {
            width: 100vw;
            position: fixed;
            left: auto;
            bottom: 0;
            height: auto;
            right: 0;
            top: 64px;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
              width: 4px;
            }

            &::-webkit-scrollbar-track {
              background: rgba(0, 0, 0, .1)
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 2px;
              background: rgba(0, 0, 0, .3);
            }

            &:before, &:after {
              left: auto;
              margin-left: 0;
              right: 124px
            }

            ul {
              padding: 4px 0;
              max-height: none;

              li {
                padding: 16px 5px 16px 52px;

                .avatar {
                  top: 16px;
                  left: 10px;
                  width: 32px;
                  height: 32px;
                  line-height: 32px;
                }

                .notification_text {
                  font-size: 12px;
                  line-height: 18px;

                  a {
                    font-size: 12px;
                    line-height: 18px;
                  }

                  span.time {
                    font-size: 10px;
                    padding-top: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }

    //ul#after_header_menu {
    //	margin: 0;
    //	position: absolute;
    //	width: 100vw;
    //	left: 0;
    //	top: 52px;
    //	overflow: hidden;
    //	overflow-x: auto;
    //	text-align: left;
    //	white-space: nowrap;
    //	padding-left: 15px;
    //	padding-right: 15px;
    //	border-top: 1px solid #f4f4f4;
    //	-webkit-overflow-scrolling: touch;
    //	&::-webkit-scrollbar {
    //		height: 4px;
    //	}
    //	&::-webkit-scrollbar-track {
    //		background: rgba(0,0,0,.1)
    //	}
    //	&::-webkit-scrollbar-thumb {
    //		border-radius: 2px;
    //		background: rgba(0,0,0,.3);
    //	}
    //	li {
    //		display: inline-block;
    //		& + li {
    //			margin-left: 10px;
    //		}
    //		a {
    //			font-size: 13px;
    //			line-height: 40px;
    //		}
    //
    //	}
    //}
    #header_search {
      top: 12px;
      left: 0;
      margin: 0;

      .form_item .hide_search {
        background-size: 18px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1345px) {
  #header ul#after_header_links li .user_block ul {
    margin-left: -210px
  }

  #header ul#after_header_links li .user_block ul:before,
  #header ul#after_header_links li .user_block ul:after {
    left: auto;
    right: 18px
  }
}

@media (max-width: 872px) {
  #header {
    padding-bottom: 54px;

    ul#after_header_menu {
      display: none;
      flex-direction: column;
      align-items: center;
      position: absolute;
      height: calc(100% - 64px);
      z-index: 22222222 !important;
      background-color: #ffffff;
      top: 64px;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        height: 4px;
      }

      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, .1)
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: rgba(0, 0, 0, .3);
      }

      li {
        display: flex;

        & + li {
          margin-left: 10px;
        }

        a:not(.suggest__item) {
          font-size: 13px;
          line-height: 18px;

          @media (max-width: 872px) {
            color: #000000;
            margin-top: 10px;
            font-size: 24px;
            line-height: 33px;
          }
        }
        .suggest__item {
          font-size: 13px;
        }
      }

      @media (max-width: 872px) {
        display: none;
        flex-direction: column;
        align-items: center;
        position: absolute;
        z-index: 5;
        background-color: #ffffff;
        top: 64px;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }

  }
}

.search__mobile {
  margin-left: 15px;
  margin-right: 15px;

  @media (min-width: 873px) {
    display: none;
  }

  &:nth-child(2) {
    display: none;
  }
}

@media (max-width: 872px) {
  #header {
    position: static;
    padding-bottom: 15px;

    ul#after_header_menu {
      border: none;
    }
  }
}

@media (max-width: 330px) {
  #header {
    ul#after_header_menu {
      padding-left: 13px;
      padding-right: 13px;
    }
  }
}

.top-panel__fixed {
  position: fixed;
  top: 0;
  z-index: 10;
}

@media (max-width: 767px) {
  .no--scroll {
    overflow: hidden;
  }
}

.main-search-suggests {
  background-color: #fff;
}

.suggest__item {
  cursor: pointer;

  .highlight {
    background: rgba(125, 168, 208, 0.2);
    color: inherit;
  }
}


.add-material-btn {
  display: inline-block;
  position: relative;
  height: 40px;
  line-height: 36px;
  padding-right: 25px;
  padding-left: 25px;
  font-size: 15px;
  color: #fff;
  background: $color-main;
  border-radius: 4px;
  border: 1px solid $color-main;
  text-decoration: none;
  transition: 0.15s background;
  vertical-align: top;

  &:hover {
    background-color: transparent;
  }
}

.add-material-btn-icon {
  vertical-align: middle;
  margin-right: 5px;
}

.header__inner {
  display: flex;
  justify-content: space-between;
}

.header__left {
  position: static;
  display: flex;
  align-items: center;
}

.header__right {
  flex-shrink: 0;
}

.header__burger {
  display: inline-block;
  outline: 0;
  float: left;
  margin-right: 13px;
  cursor: pointer;
  border: none;
  background-color: transparent;

  span {
    display: block;
    margin-bottom: 5px;
    height: 2px;
    transition: 300ms;
    background-color: #C1C1C1;

    &:nth-child(1) {
      width: 20px;
    }

    &:nth-child(2) {
      width: 14px;
    }

    &:nth-child(3) {
      width: 20px;
      margin-bottom: 0;
    }
  }


  @media (min-width: 873px) {
    display: none;
  }
}

.header__burger--active {
  span {
    &:nth-child(1) {
      transform: rotate(45deg) translate(4px, 6px);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      transform: rotate(-45deg) translate(4px, -5px);
    }
  }
}

.header_links-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 576px) {
    position: static;
  }
}

.check_search_height {
  position: static;
}

.header__btn {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 15px;
  right: 15px;
  bottom: 17px;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  color: #ffffff !important;
  font-size: 16px !important;
  background-color: $link;

  @media (min-width: 872px) {
    display: none !important;
  }
}

.add-material-mobile {
  display: none;
  @include max-w(960) {
    display: block;
  }
}

.add-material-btn-desktop {
  @include max-w(960) {
    display: none;
  }
}

.add-material-mobile-btn {
  display: block;
  width: 35px;
  height: 35px;
  font-size: 0;
  text-decoration: none;
  border-radius: 50%;
  background: {
    color: #E1F0E5;
    image: url("./icon-add-mobile.svg");
    repeat: no-repeat;
    position: center;
  }
}

.main-search-suggests {
  @include max-w(872) {
    display: none !important;
  }
}

.main-search-suggests--mobile {
  display: none;
  @include max-w(872) {
    height: auto;
    max-height: 45vh;

    #search_popup_inner {
      max-height: 45vh;
      overflow-y: auto;
    }
  }
}

.notifications__check_all {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 4px 20px;
}

.notifications__btn {
  font-size: 13px;
  line-height: 24px;
  text-decoration: none;
}
