@import "~common";

.empty-filtered-data {
  margin-top: 17px;
  padding: 80px 20px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.06));
  border-radius: 4px; }


.empty-filtered-data__icon {
  display: block;
  margin: 0 auto;

  @include max-w(767) {
    max-width: 90px; } }

.empty-filtered-data__content {
  max-width: 430px;
  margin: 0 auto; }

.empty-filtered-data__title {
  margin-top: 25px;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #212121;

  @include max-w(767) {
    font-size: 20px;
    line-height: 30px; } }

.empty-filtered-data__descr {
  margin-top: 15px;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #212121;

  @include max-w(767) {
    margin-top: 10px; } }
