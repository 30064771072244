@import "~common";

//AUTH
body#auth {
	height: 100%;
	padding: 0;
	margin: 0;
	background: #fff;
	color: #3f3e37;
	font-size: 15px;
	line-height: 22px;
	top: 0;
	left: 0;
	min-height: 800px;
	#page {
		min-height: 600px;
		width: auto;
		//min-width: 990px;
		padding: 0;
		height: 100%;
		position: relative;
		overflow: hidden;
		.logo_bg {
			position: relative;
			background-image: asset-url(auth_bg);
			background-position: left bottom;
			background-repeat: no-repeat;
			background-size: cover;
			float: left;
			height: 100%;
			min-height: 600px;
			overflow: hidden;
			width: 50%;
			.logo {
				width: 100%;
				height: 100px;
				line-height: 100px;
				text-align: center;
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -50px;
				color: #FFF;
				font-weight: 700;
				font-size: 86px;
				letter-spacing: -.7px;
			}
		}
		.auth_content {
			margin: 0;
			width: 50%;
			height: 100%;
			overflow: hidden;
			position: relative;
			padding: 0;
			.wrapper_auth {
				display: table;
				height: 100%;
				padding-left: 43px;
				padding-right: 20px;
				width: 90%;
				min-height: 600px;
				&:before, &:after {
					display: table;
					clear: both;
					width: 100%;
					content: '';
				}
				.inner_auth {
					display: table-cell;
					margin: 0;
					padding: 0;
					vertical-align: middle;
					position: relative;
					&.v_top {
						padding-top: 50px;
						vertical-align: top;
					}
					.form_item, .form_submit, .form_checkbox, form {
						max-width: 420px;
					}
					h2 {
						margin: 0 0 36px;
						padding-left: 51px;
						font-weight: 600;
						font-size: 32px;
						line-height: 1.2;
					}
					a.btn-school {
						@include btn();
						margin-left: 51px;
					}
					a.just_link {
						display: inline-block;
						font-size: 14px;
						& + a.just_link {
							margin-left: 8px;
						}
					}
					#user_email_domain {
						display: none;
					}
					.ui-selectmenu-button {
						@include rounded(0);
						width: 100% !important;
						padding: 0;
						background: #fff !important;
						border: 0 none;
						position: relative;
						overflow: hidden;
						height: $form_height + 1;
						cursor: pointer;
						z-index: 1;
						&:before {
							content: '';
							position: absolute;
							width: 100%;
							bottom: 0;
							left: 0;
							height: 0;
							border-bottom: 1px solid #dcdcdc;
						}
						.ui-selectmenu-text {
							display: block;
							padding: 0 30px 0 5px !important;
							margin: 0;
							height: $form_height;
							line-height: $form_height;
							font-size: 15px;
							font-weight: 400;
							color: #212121;
							width: 100%;
							position: absolute;
							top: 0;
							left: 0;
							z-index: 1;
						}
						.ui-selectmenu-icon {
							position: absolute;
							width: 30px;
							height: $form_height;
							top: 0;
							right: 0;
							background: #fff asset-url(arrow-down) no-repeat 50% 50%;
							background-size: 24px 24px;
							z-index: 2;
						}
					}
				}
			}
		}
	}
	.auth_footer_links {
		padding: 12px 0 0 18px;
		position: relative;
		a {
			font-size: 14px;
			font-weight: 400;
			text-decoration: underline;
			display: inline-block;
			vertical-align: top;
			& + a {
				margin-left: 12px;
			}
		}
	}
}

@media (max-width: 767px) {
  body {
    &#auth {
			background: #fafafa;
			min-width: 0;
			width: 100%;
			min-height: 0;
			#page {
				min-height: 0;
				min-width: 0;
				height: auto;
				.logo_bg {
					background: transparent;
					float: none;
					width: 100%;
					height: auto;
					min-height: 0;
					min-width: 0;
					padding: 40px 0;
					.logo {
						width: 110px;
						height: 40px;
						margin: 0 auto;
						background: asset-url(logo-insberra-mobile) no-repeat 50% 50%;
						background-size: 110px 40px;
						color: transparent;
						text-indent: -9999em;
						position: relative;
						left: auto;
						top: auto;
						font-size: 10px;
						line-height: 1;
					}
				}
				.auth_content {
					width: 100%;
					min-width: 0;
					min-height: 0;
					height: auto;
					float: none;
					padding-bottom: 50px;
					.wrapper_auth {
						display: block;
						width: 100%;
						min-height: 0;
						padding: 0 15px;
						.inner_auth {
							display: block;
							vertical-align: baseline;
							h2 {
								font-size: 20px;
								line-height: 28px;
								text-align: center;
								margin:0 0 16px;
								padding: 0;
							}
							form {
								max-width: 100%;
							}
							.form_item {
								background: transparent;
								max-width: 100%;
								&:before {
									left: 0;
								}
								&.active:before {
									border-color: $link;
								}
								.fa {
									display: none;
								}
								label, input {
									padding-left: 5px;
								}
								label {
									max-width: 100%;
									overflow: hidden;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
								}
							}
							.field_with_errors_text {
								padding-left: 5px;
							}
							.form_checkbox {
								padding-left: 5px;
								max-width: 100%;
							}
							.ui-selectmenu-button {
								background: transparent !important;
								.ui-selectmenu-icon {
									background-color: transparent !important;
								}
							}
							.form_desc {
								padding-left: 5px;
							}
							.form_submit {
								max-width: 100%;
								margin-left: 0;
								.btn {
									@include rounded(4px);
									border: 1px solid rgba(0, 0, 0, 0.16);
									width: 100%;
									background: transparent;
									color: #c1c1c1;
									max-width: 100%;
									line-height: 38px;
									&.active {
										background: $link;
										border-color: $link;
										color: #fff;
									}
								}
								.alignright {
									position: fixed;
									left: 0;
									bottom: 0;
									z-index: 5;
									background: #fafafa;
									padding-top: 0px;
									padding-bottom: 0px;
									.btn {
										&.btn_link {
											border: 0 none;
											color: $link;
											text-decoration: none;
											font-size: 15px;
											width: auto;
											max-width: 50%;
											margin: 0;
											&:first-child {
												float: left;
											}
											&:last-child {
												float: right;
											}
											&:hover {
												color: $link_dark;
											}
										}
									}
								}
							}
							.auth_footer_links {
								padding: 8px 0 0;
								text-align: center;
								a {
									color: #c1c1c1;
									text-decoration: none;
								}
							}
						}
					}
				}
			}
		}
	}
}
