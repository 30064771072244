@mixin clear() {
  content: '';
  display: table;
  clear: both;
  float: none;
  width: 100%; }

@mixin center() {
  text-align: center; }


@mixin inline() {
  display: inline-block;
  zoom: 1; }


// TODO выпилить
@function asset-url($file) {
  @return url('~img/' + $file); }


@mixin rounded($amount: "2px",$position: null) {
  @if $position != null {
    @if $position == "top" or $position == "bottom" {
      border-#{$position}-left-radius: $amount;
      border-#{$position}-right-radius: $amount; }

    @else {
      border-#{$position}-radius: $amount; } }

  @else {
    border-radius: $amount; } }

@mixin select($argument: none) {
  user-select: $argument; }


@mixin rotate($deg: 0) {
  transform: rotate($deg); }


@mixin btn() {
  display: inline-block;
  zoom: 1;
  height: $form_height;
  @include rounded(4px);
  padding: 0 12px;
  line-height: $form_height - 2;
  cursor: pointer;
  background: transparent;
  border: 1px solid $link;
  color: $link;
  text-decoration: none;
  font-size: 13px;
  font-weight: 400;
  outline: none;
  &:hover {
    background: $link;
    color: #fff; }

  &:disabled, &.disabled {
    border-color: #a8a8a8 !important;
    color: #a8a8a8 !important;
    background: #FFF !important;
    cursor: not-allowed; }

  &.red {
    border-color: $red;
    color: $red;
    &:hover {
      background: $red;
      color: #FFF; }
    @media (max-width: 767px) {
      background: $red;
      color: #FFF;
      width: 100%;
      &:hover {
        border-color: #FFF;
        color: $red;
        background: #FFF; } } }


  &.green {
    background: $link;
    color: #fff;
    &:hover {
      background: #FFF;
      color: $link; } } }

@mixin max-w($width) {
  @media (max-width: #{$width}px) {
    @content; } }

@mixin max-h($height) {
  @media (max-height: #{$height}px) {
    @content; } }

@mixin min-w($width) {
  @media (min-width: #{$width}px) {
    @content; } }


@mixin line-clamp($count-line, $line-height) {
  display: block;
  display: -webkit-box;
  /*! autoprefixer: off */
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  /*! autoprefixer: on */
  position: relative;
  -webkit-line-clamp: $count-line;
  max-height: calc(1em * #{$line-height} * #{$count-line});
  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 !important;

  // &:after
  //display: inline-block
  //content: '...'
  ////text-align: right
  //bottom: 0
  //right: 0
  ////width: 25%
  ////display: block
  //position: absolute
  //height: calc(1em * #{$line-height})

  @supports (-webkit-line-clamp: 1) {
    &:after {
      display : none !important; } } }

@mixin placeholder($color) {

  &::-webkit-input-placeholder {
    color: $color; }

  &:-moz-placeholder {
    color: $color; }

  &::-moz-placeholder {
    color: $color; }

  &::-ms-input-placholder {
    color: $color; }

  &::-ms-input-placeholder {
    color: $color; } }

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace); }
  @return $string; }
