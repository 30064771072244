//убираем лишний отступ перед слайдером
.photogrid {
  margin-top: 0 !important; }



.longread-slider {
  max-width: 750px;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 870px) {
    max-width: 600px; }

  @media screen and (max-width: 750px) {
    max-width: 500px; }

  @media screen and (max-width: 630px) {
    width: calc(100% - 30px); }

  .slick-list {
    margin: -15px;
    overflow: visible !important; }

  .slick-slide {
    margin: 15px; }

  //for dots

  .slick-dots {
    position: absolute;
    bottom: -70px;
    transition: 0.3s; }

  .slick-dots li {
    width: 12px;
    height: 12px; }

  .slick-dots li button:before {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #c1c1c1;
    cursor: pointer;
    font-size: 0;
    transition: 0.3s; }


  .slick-dots .slick-active button:before {
    background: #389a52; }


  //for not active
  .longread-slider__slider {
    position: relative;
    opacity: 0.4;
    transition: .2s; }

  .slick-current .longread-slider__slider {
    opacity: 1; }


  //arrow
  .slick-next {
    right: -50px;
    z-index: 1000;

    @media  screen and (max-width: 630px) {
      top: auto;
      bottom: -50px;
      right: 30px; } }

  .slick-next:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../../../img/next-arrow.svg");
    width: 30px;
    height: 30px; }

  .slick-arrow.slick-disabled {
    display: none;

    &:before {
      display: none; } }

  .slick-prev {
    left: -50px;
    z-index: 1000;

    @media  screen and (max-width: 630px) {
      top: auto;
      bottom: -50px;
      left: 20px; } }

  .slick-prev:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../../../img/prev-arrow.svg");
    width: 30px;
    height: 30px; } }


.longread-slider__slide {}
.longread-slider__img {
  @media screen and (max-width: 870px) {
    max-width: 600px; }

  @media screen and (max-width: 750px) {
    max-width: 500px;
    width: 500px; }

  @media screen and (max-width: 630px) {
    max-width: 100%; } }



.longread-slider__descr span {
  display: block;
  text-align: center;
  font-weight: 600;
  color: #212121 !important;
  font-size: 15px;
  margin:  0 auto;
  position: relative;
  top: 10px; }


.longread-slider.slick-dotted.slick-slider {
  margin-bottom: 90px; }
