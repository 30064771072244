@import "~common";

blockquote {
  display: block;
  padding: 17px 5px 0 97px;
  position: relative;
  margin-top: 15px;
  margin-bottom: 35px;

  &.editable {
    padding-right: 40px;
    .remove_block {
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 23px;
      right: 5px;
      text-indent: -9999em;
      color: transparent;
      text-decoration: none;
      background: asset-url(close-black) no-repeat 50% 50%; } }
  &:before {
    position: absolute;
    width: 68px;
    height: 68px;
    top: 0;
    left: 0;
    background: asset-url(icon-blockquote) no-repeat 50% 50%;
    content: ''; }
  .text-blockquote,
  .text-blockquote p {
    font-size: 24px !important;
    font-weight: 300 !important;
    font-style: italic;
    line-height: 34px !important;
    color: #212121;
    display: block;
    margin: 0 !important;
    padding: 0; }
  small {
    display: block;
    padding: 16px 0 12px;
    line-height: 1;
    font-weight: 600;
    font-size: 15px;
    color: #c1c1c1; }
  .blockquoteeditor {
    position: relative;
    padding: 0;
    z-index: 3;
    position: relative;
    min-height: $form_height;
    line-height: $form_height - 10;
    font-weight: 300;
    color: #212121;
    cursor: text;
    font-style: italic;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 10px;
    &.placeholder:after {
      content: attr(data-placeholder) !important;
      font-style: italic;
      position: absolute;
      left: 0;
      top: 0;
      white-space: pre;
      padding: inherit;
      margin: 0;
      height: $form_height;
      line-height: $form_height;
      padding: 0;
      color: #c1c1c1;
      font-weight: 300;
      font-size: 24px;
      cursor: text; } }
  .form_item {
    &:before {
      display: none; }
    label {
      font-weight: 600; }
    input {
      font-weight: 600;
      color: #9f9f9f; } } }
