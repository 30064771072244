@import "~common";

//STAT
.stat_info_ {
	width: 100%;
	background: #fff;
	background-color: #ffffff;
  	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
  	border: solid 1px rgba(0, 0, 0, 0.09);
  	@include rounded(2px);
  	margin-top: 43px;
  	.stat_info_head {
  		padding: 24px;
  		line-height: 24px;
  		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  		h4 {
  			padding: 0 0 0 32px;
			white-space: nowrap;
			color: #000;
			font-size: 15px;
			font-weight: 600;
			line-height: 24px;
			height: 24px;
			background: asset-url(icon_materials) no-repeat 0 50%;
  		}
  	}
  	.stat_info_item {
  		padding: 26px 24px;
  		position: relative;
  		overflow: hidden;
  		& + .stat_info_item {
  			&:before {
  				content: '';
  				width: auto;
  				right: 0;
  				left: 24px;
  				height: 0;
  				top: 0;
  				border-top: 1px solid rgba(0, 0, 0, 0.08);
  				position: absolute;
  			}
  		}
  		span, strong {
  			display: block;
  			font-size: 15px;
  			font-weight: 600;
  			white-space: nowrap;
  		}
  		span {
  			float: left;
  			color: rgba(0,0,0,.24);
  		}
  		strong {
  			float: right;
  			color: #212121;
  		}
  	}
}

.table-content {
  margin-top: 43px;

  @media (max-width: 1024px) {
    margin-top: 20px;
  }

  .fixed-table-body {
    @media (max-width: 700px) {
      overflow: hidden;
      overflow-x: scroll;
    }
  }

  .stat_table table#table, .stat_table table.table {
    @media (max-width: 700px) {
      table-layout: auto;
    }
  }
}

.table-scroll {
  .fixed-table-body {
    overflow: auto;
  }

  .stat_table table#table, .stat_table table.table {
    @media (max-width: 700px) {
      table-layout: auto;
    }
  }

  &--user {
    table#table {
      min-width: 700px;
    }
  }
}

.table-break-word {
  word-break: break-all;
}

.stat-popular-list-title {
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
}

.stat_table {

	.stat_table_head {

		.avatar {
			@include inline();
		}
		span.subbold {
			line-height: 40px;
			margin-left: 16px;
			@include inline();
		}

		span#stat_els_per_page-button, span#filter_action_id-button, span#month_visit-button, span#month_time-button {
			@include rounded(0);
			border: 0 none;
			padding: 0 20px 0 0;
			height: 24px;
			line-height: 24px;
			background: transparent;
			width: auto;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 4px 4px 0 4px;
				border-color: rgba(0, 0, 0, 0.38) transparent transparent transparent;
				right: 6px;
				top: 50%;
				margin-top: -1px;
			}
			.ui-selectmenu-text {
				margin: 0;
				display: inline-block;
				margin-top: 1px;
			}
			.ui-selectmenu-icon {
				display: none !important;
			}
		}
	}
  $colorsvg: str-replace(''+$link, '#', '%23');
	.fixed-table-loading {
    position: relative;
		width: 100%;
		height: 40px;
    margin-bottom: -40px;
		&:before {
			content: '';
			width: 40px;
			height: 40px;
			display: block;
			margin: 0 auto;
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 500 500' style='enable-background:new 0 0 40 40;' xml:space='preserve'%3E%3Cpath fill='#{$colorsvg}' d='M250,0c14,0,24,10,24,24v94c0,14-10,25-24,25s-25-11-25-25V24C225,10,236,0,250,0z'/%3E%3Cpath fill='#{$colorsvg}' d='M137,53l55,76c12,16,0,39-20,39c-8,0-14-3-19-10L98,82c-8-11-6-26,5-34S129,42,137,53z'/%3E%3Cpath fill='#{$colorsvg}' d='M28,204c-13-4-20-18-16-31s18-20,31-16l89,29c13,4,20,18,16,31c-3,10-14,17-24,17c-3,0-4-1-7-2 L28,204z'/%3E%3Cpath fill='#{$colorsvg}' d='M148,283c4,13-3,27-16,31l-89,29c-3,1-5,1-8,1c-10,0-20-7-23-17c-4-13,3-27,16-31l89-29 C130,263,144,270,148,283z'/%3E%3Cpath fill='#{$colorsvg}' d='M187,337c11,8,13,23,5,34l-55,76c-5,7-12,10-20,10c-20,0-31-23-19-39l55-76C161,331,176,329,187,337	z'/%3E%3Cpath fill='#{$colorsvg}' d='M250,357c14,0,24,11,24,25v93c0,14-10,25-24,25s-25-11-25-25v-93C225,368,236,357,250,357z'/%3E%3Cpath fill='#{$colorsvg}' d='M347,342l55,76c12,16,0,39-20,39c-8,0-14-3-19-10l-55-76c-8-11-6-26,5-34S339,331,347,342z'/%3E%3Cpath fill='#{$colorsvg}' d='M472,296c13,4,20,18,16,31c-3,10-14,17-24,17c-3,0-4,0-7-1l-89-29c-13-4-20-18-16-31s18-20,31-16 L472,296z'/%3E%3Cpath fill='#{$colorsvg}' d='M352,217c-4-13,3-27,16-31l89-29c13-4,27,3,31,16s-3,27-16,31l-89,28c-3,1-5,2-8,2 C365,234,355,227,352,217z'/%3E%3Cpath fill='#{$colorsvg}' d='M327,168c-20,0-31-23-19-39l55-76c8-11,23-13,34-5s13,23,5,34l-55,76C342,165,335,168,327,168z'/%3E%3C/svg%3E");
			background-size: 40px 40px;
			-webkit-animation: spin-loader 1.6s infinite linear;
  			animation: spin-loader 1.6s infinite linear;
		}
	}
	table#table, table#table_session_time, table.table, table#table {
		width: 100%;
		margin: 0;
		border-collapse: collapse;
		border-spacing: 0;
		table-layout: fixed;
		border: 0 none !important;
		thead {
			th {
				padding: 16px 24px;
				text-align: left;
				border: 0 none;
				vertical-align: middle;
				font-size: 12px;
				line-height: 18px;
				font-weight: 500;
				color: rgba(0, 0, 0, 0.54);
				border-bottom: 1px solid rgba(0, 0, 0, 0.08);
				.icon {
					display: block;
					width: 24px;
					height: 24px;
					&.icon_bookmark {
						background: asset-url(icon-bookmark) no-repeat 50% 50%;
					}
          &.icon_like {
            background: asset-url(like) no-repeat 50% 50%;
          }
          &.icon_dislike {
            background: asset-url(dislike) no-repeat 50% 50%;
          }
					&.icon_comment {
						background: asset-url(comments-disable) no-repeat 50% 50%;
					}
					&.icon_raiting {
						background: asset-url(star-default) no-repeat 50% 50%;
					}
					&.icon_eye {
						background: asset-url(eye-disable) no-repeat 50% 50%;
					}
				}
				.th-inner.sortable {
					position: relative;
					cursor: pointer;
					padding-right: 24px;
					display: inline-block;
					&.sorted {
						color: $link;
						.icon {
							&.icon_bookmark {
								background: asset-url(icon-bookmark-enable) no-repeat 50% 50%;
							}
							&.icon_comment {
								background: asset-url(icon-comments) no-repeat 50% 50%;
							}
							&.icon_raiting {
								background: asset-url(star-over) no-repeat 50% 50%;
							}
							&.icon_eye {
								background: asset-url(eye-enable) no-repeat 50% 50%;
							}
						}
						.order {
							position: absolute;
							right: 0;
							top: 50%;
							margin-top: -8px;
							width: 16px;
							height: 16px;
							&:before {
								position: absolute;
								width: 0;
								height: 0;
								content: '';
								top: 0;
								left: 0;
								border-style: solid;
								border-width: 0 4px 4px 4px;
								border-color: transparent transparent rgba(0, 0, 0, 0.24) transparent;
							}
							&:after {
								position: absolute;
								width: 0;
								height: 0;
								content: '';
								bottom: 0;
								left: 0;
								border-style: solid;
								border-width: 4px 4px 0 4px;
								border-color: rgba(0, 0, 0, 0.54) transparent transparent transparent;
							}
							&.dropup {
								&:before {
									border-color: transparent transparent rgba(0, 0, 0, 0.54) transparent;
								}
								&:after {
									border-color: rgba(0, 0, 0, 0.24) transparent transparent transparent;
								}
							}
						}
					}
				}
			}
		}
		tbody {
			tr {
				td {
					padding: 14px 24px;
					text-align: left;
					font-weight: 600;
					font-size: 14px;
					color: #212121;
					border-bottom: 1px solid rgba(0, 0, 0, 0.08);
					white-space: pre-wrap;
					white-space: -moz-pre-wrap;
					white-space: -pre-wrap;
					white-space: -o-pre-wrap;
					word-wrap: break-word;
					a {
						text-decoration: none;
						font-weight: 600;
					}
					&.normal {
						font-weight: 500;
					}
					&.gray {
						color: rgba(0, 0, 0, 0.24);
					}
				}
				&:hover {
					td {
						background: #fafafa;
					}
				}
			}
		}
	}
}
