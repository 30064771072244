@import "~common";

*,
*:before,
*:after {
  box-sizing: border-box; }

html {
  & .turbolinks-progress-bar {
    background-color: red !important; } }

pre {
  height: 200px;
  overflow: hidden;
  overflow-y: scroll;
  color: #000;
  margin: 25px 0; }

.hide {
  display: none; }

.hidden, #dropzone_prw {
  display: none !important; }

.clear {
  clear: both; }

@media (min-width: 992px) {
  .pull-md-right {
    float: right !important; } }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, acronym, address, big, cite, code, figure, del,
dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup,
tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label,
legend, table, caption, tbody, tfoot, thead, tr, th, td {
  outline: none !important;
  outline-color: transparent !important;
  padding: 0;
  margin: 0; }

html, body {
  height: 100%;
  // не помогает, когда открывается клавиатура, то всеравно пояляется скрол и fixed элементы перекрываются клавиатурой
  //-webkit-overflow-scrolling: touch !important
  //overflow: hidden !important
  //overflow-y: auto !important
  -ms-overflow-style: scrollbar;

  &::-webkit-scrollbar {
    width: 10px; }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05); }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.18); } }


html {

  &.auth {
    height: 100%; }

  &.no_scroll_desktop, &.tooltip_open {
    overflow: hidden !important;
    overflow-y: hidden !important; } }

body {
  background: $body_bg;
  margin: 0;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -ms-content-zooming: none;
  font-family: $font;
  font-weight: 400;
  font-style: normal;
  color: #111;
  color: rgba(0, 0, 0, 0.88);
  letter-spacing: -.2px; }

//FONTS

.alignright {
  text-align: right;
  vertical-align: middle; }

.aligncenter {
  text-align: center; }

.light {
  font-weight: 300; }

.normal {
  font-weight: 400; }

.subbold {
  font-weight: 600; }

.bold {
  font-weight: 700; }

.green {
  color: $link; }

.red {
  color: red; }

.black {
  color: #212121;
  color: rgba(0, 0, 0, 0.88);
  &.green {
    color: $link !important; } }

.gray {
  color: #717171;
  color: rgba(0, 0, 0, 0.54); }

.lightgray {
  color: #c1c1c1;
  color: rgba(0, 0, 0, 0.24); }

.lightgray2 {
  color: #A8A8A8; }

.size24 {
  font-size: 24px; }

.size15 {
  font-size: 15px; }

.size13 {
  font-size: 13px; }

.size12 {
  font-size: 12px; }

.responsive {
  width: 100%;
  height: auto; }

.rounded {
  @include rounded(50%); }

.top_32 {
  margin-top: 32px; }

.top_24 {
  margin-top: 24px; }

input, button, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: $font; }

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 25px 0;
  width: 100%; }

a {
  color: $link;
  text-decoration: underline;
  font-size: inherit;
  line-height: inherit;
  transition: color .2s;
  -webkit-transition: color .2s;
  -moz-transition: color .2s;
  img {
    border: 0 none; }
  &:hover {
    color: darken($link, 6%); } }

.btn_delete {
  display: inline-block;
  height: 40px;
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 4px;
  padding: 0 12px;
  cursor: pointer;
  line-height: 38px;
  font-size: 13px;
  font-weight: 400;
  outline: none;
  text-decoration: none;
  &:hover {
    background: red;
    color: white; } }

.btn_default {
  display: inline-block;
  height: 40px;
  background: white;
  color: #000;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 0 12px;
  cursor: pointer;
  line-height: 38px;
  font-size: 13px;
  font-weight: 400;
  outline: none;
  text-align: center;
  text-decoration: none;

  &:hover {
    color: #000;
    border-color: #000; } }

.btn_edit {
  display: inline-block;
  cursor: pointer;
  height: 40px;
  background: white;
  color: $link;
  border: 1px solid $link;
  border-radius: 4px;
  padding: 0 12px;
  line-height: 38px;
  font-size: 13px;
  font-weight: 400;
  outline: none;
  text-decoration: none;
  text-align: center;
  &:hover {
    background: $link;
    color: white; } }

.btn_answer,
.bl_ .bl_submit button.btn_answer {
  font-size: 15px;
  margin-left: 24px; }

@media (max-width: 872px) {
  *[data-phone-hidden] {
    display: none !important;
    visibility: hidden;
    opacity: 0; }

  html {
    min-width: 0;
    width: 100%;

    &.no_scroll {
      overflow: hidden !important;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      overflow-y: hidden !important;
      body {
        overflow: hidden !important; } } }

  body {
    background: $body_bg_mobile;
    .alert {
      padding: 15px 40px 15px 0;
      text-align: left; }
    &#errors {
      #page_error {
        #page_error_wrap {
          #page_error_left {
            width: 100%;
            #page_error_left_inner {
              h1 {
                font-size: 32px;
                line-height: 38px;
                text-indent: 0; }
              p {
                font-size: 15px;
                line-height: 21px;
                margin-top: 5px;
                font-weight: 600; } } } } } }
    &#auth {
      background: #fafafa;
      min-width: 0;
      width: 100%;
      min-height: 0;
      #page {
        min-height: 0;
        min-width: 0;
        height: auto;
        .logo_bg {
          background: transparent;
          float: none;
          width: 100%;
          height: auto;
          min-height: 0;
          min-width: 0;
          padding: 40px 0;
          .logo {
            width: 110px;
            height: 40px;
            margin: 0 auto;
            background: asset-url(logo-insberra-mobile) no-repeat 50% 50%;
            background-size: 110px 40px;
            color: transparent;
            text-indent: -9999em;
            position: relative;
            left: auto;
            top: auto;
            font-size: 10px;
            line-height: 1; } }
        .auth_content {
          width: 100%;
          min-width: 0;
          min-height: 0;
          height: auto;
          float: none;
          padding-bottom: 50px;
          .wrapper_auth {
            display: block;
            width: 100%;
            min-height: 0;
            padding: 0 15px;
            .inner_auth {
              display: block;
              vertical-align: baseline;
              h2 {
                font-size: 20px;
                line-height: 28px;
                text-align: center;
                margin: 0 0 16px;
                padding: 0; }
              form {
                max-width: 100%; }
              .form_item {
                background: transparent;
                max-width: 100%;
                &:before {
                  left: 0; }
                &.active:before {
                  border-color: $link; }
                .fa {
                  display: none; }
                label, input {
                  padding-left: 5px; }
                label {
                  max-width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis; } }
              .field_with_errors_text {
                padding-left: 5px; }
              .form_checkbox {
                padding-left: 5px;
                max-width: 100%; }
              .ui-selectmenu-button {
                background: transparent !important;
                .ui-selectmenu-icon {
                  background-color: transparent !important; } }
              .form_desc {
                padding-left: 5px; }
              .form_submit {
                max-width: 100%;
                margin-left: 0;
                .btn {
                  @include rounded(4px);
                  border: 1px solid rgba(0, 0, 0, 0.16);
                  width: 100%;
                  background: transparent;
                  color: #c1c1c1;
                  max-width: 100%;
                  line-height: 38px;
                  &.active {
                    background: $link;
                    border-color: $link;
                    color: #fff; } }
                .alignright {
                  position: fixed;
                  left: 0;
                  bottom: 0;
                  z-index: 5;
                  background: #fafafa;
                  padding-top: 0px;
                  padding-bottom: 0px;
                  .btn {
                    &.btn_link {
                      border: 0 none;
                      color: $link;
                      text-decoration: none;
                      font-size: 15px;
                      width: auto;
                      max-width: 50%;
                      margin: 0;
                      &:first-child {
                        float: left; }
                      &:last-child {
                        float: right; }
                      &:hover {
                        color: $link_dark; } } } } }
              .auth_footer_links {
                padding: 8px 0 0;
                text-align: center;
                a {
                  color: #c1c1c1;
                  text-decoration: none; } } } } } } } } }

.label {
  display: inline-block;
  text-decoration: none;
  border-radius: 100px;
  height: 22px;
  line-height: 22px;
  padding: 0 15px;
  font-size: 12px;
  position: relative;

  &--green {
    background-color: #EBF5EE;
    color: #389A52; }

  &--red {
    color: #DC4545;
    background-color: rgba(255, 123, 123, .1); }

  &--yellow {
    color: #F4A512;
    background-color: rgba(250, 180, 50, 0.1); }

  &--grey {
    color: #A0A0A0;
    background-color: #f5f5f5; } }
