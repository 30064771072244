@import "~common";

.mce-window[aria-label="Insert link"] {

  @include max-w(560) {
    width: auto !important;
    left: 15px !important;
    right: 15px !important; }

  > .mce-reset > .mce-foot,
  > .mce-reset > .mce-foot > .mce-container-body {
    width: auto !important; }

  > .mce-reset >  .mce-window-body,
  > .mce-reset >  .mce-window-body > .mce-form,
  > .mce-reset >  .mce-window-body > .mce-form > .mce-container-body {
    width: 100% !important; }

  > .mce-reset >  .mce-window-body > .mce-form > .mce-container-body > .mce-formitem {
    left: 0 !important;
    width: 100% !important;
    padding: 0 15px;
    box-sizing: border-box; }

  > .mce-reset >  .mce-window-body > .mce-form > .mce-container-body > .mce-formitem > .mce-container-body {
    width: auto !important; }

  > .mce-reset >  .mce-window-body > .mce-form > .mce-container-body > .mce-formitem > .mce-container-body > .mce-combobox,
  > .mce-reset >  .mce-window-body > .mce-form > .mce-container-body > .mce-formitem > .mce-container-body > .mce-textbox,
  > .mce-reset >  .mce-window-body > .mce-form > .mce-container-body > .mce-formitem > .mce-container-body > .mce-listbox {
    width: auto !important;
    right: 0;
    display: block;
    box-sizing: border-box; }

  .mce-reset >  .mce-window-body > .mce-form > .mce-container-body > .mce-formitem > .mce-container-body > .mce-textbox {
    width: calc(100% - 165px) !important; }

  > .mce-reset >  .mce-window-body > .mce-form > .mce-container-body > .mce-formitem > .mce-container-body > .mce-combobox > .mce-textbox {
    width: 100% !important;
    box-sizing: border-box; }

  > .mce-reset > .mce-foot > .mce-container-body > .mce-btn {

    &:nth-last-child(1) {
      left: auto !important;
      right: 15px !important; }

    &:nth-last-child(2) {
      left: auto !important;
      right: 100px !important; } } }

body > div.mce-tinymce-inline.mce-floatpanel,
body > .mce-tinymce-inline.mce-floatpanel {
  padding: 5px;
  background-color: #212121;
  border: none;
  border-radius: 4px;

  .is-mobile & {
    background-color: #fff;
    position: fixed !important;
    position: sticky !important;
    width: 100% !important;
    top: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    border: 1px solid rgba(0, 0, 0, 0.06);
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    box-sizing: border-box;
    height: auto !important; }

  .is-mobile.is-safari & {
    position: fixed !important;
    will-change: transform, top, bottom; }

  &.mce-arrow.mce-arrow-up:before,
  &.mce-arrow.mce-arrow-up:after {
    border-bottom-color: #212121; }

  .mce-toolbar {

    .mce-btn-group {
      padding: 0 10px; }

    .mce-btn-group:first-child {
      padding-left: 0; }

    .mce-btn-group:last-child {
      padding-right: 0; }

    .mce-btn-group:not(:first-child) {
      position: relative;
      border: none;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: 0;
        bottom: 5px;
        width: 1px;
        background: #f2f2f2;
        opacity: 0.3;

        .is-mobile & {
          background-color: #A5A5A5; } } }

    .mce-btn.mce-active,
    .mce-btn.mce-active:hover,
    .mce-btn.mce-active:focus,
    .mce-btn.mce-active:active {
      background: {
        color: transparent;
        position: center;
        repeat: no-repeat; } } }

  .mce-btn {
    background: {
      color: transparent;
      position: center;
      repeat: no-repeat; }

    &[aria-label="Bold"] {
      background-image: url("./icons/bold.svg");

      .is-mobile & {
        background-image: url("./icons/bold2.svg"); }

      &.mce-active,
      .is-mobile &.mce-active {
        background-image: url("./icons/bold-active.svg"); } }

    &[aria-label="Italic"] {
      background-image: url("./icons/italic.svg");

      .is-mobile & {
        background-image: url("./icons/italic2.svg"); }

      &.mce-active,
      .is-mobile &.mce-active {
        background-image: url("./icons/italic-active.svg"); } }

    &[aria-label="Underline"] {
      background-image: url("./icons/under.svg");

      .is-mobile & {
        background-image: url("./icons/under2.svg"); }

      &.mce-active,
      .is-mobile &.mce-active {
        background-image: url("./icons/under-active.svg"); } }

    &[aria-label="Bullet list"] {
      background-image: url("./icons/ul.svg");

      .is-mobile & {
        background-image: url("./icons/ul2.svg"); }

      &.mce-active,
      .is-mobile &.mce-active {
        background-image: url("./icons/ul-active.svg"); } }

    &[aria-label="Numbered list"] {
      background-image: url("./icons/ol.svg");

      .is-mobile & {
        background-image: url("./icons/ol2.svg"); }

      &.mce-active,
      .is-mobile &.mce-active {
        background-image: url("./icons/ol-active.svg"); } }

    &[aria-label="Insert/Edit link"],
    &[aria-label="Insert/edit link"] {
      background-image: url("./icons/link.svg");

      .is-mobile & {
        background-image: url("./icons/link2.svg"); }

      &.mce-active,
      .is-mobile &.mce-active {
        background-image: url("./icons/link-active.svg"); } }

    &[aria-label="Strikethrough"] {
      background-image: url("./icons/strike.svg");

      .is-mobile & {
        background-image: url("./icons/strike2.svg"); }

      &.mce-active,
      .is-mobile &.mce-active {
        background-image: url("./icons/strike-active.svg"); } }

    &:hover {
      border-color: transparent; }

    .mce-ico {
      color: transparent; }

    &.mce-active .mce-ico {
      color: transparent; }

    &[aria-label="Ok"] .mce-ico {
      color: #fff; } } }
