@import "~common";

.filter_ {
	position: relative;
	width: 100%;
	background: #FFF;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
	border: solid 1px rgba(0, 0, 0, .1);
	padding: 16px 24px;
	margin: 0 0 16px;

  &.filter_hide {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }

  }

	&:before, &:after {
		content: '';
		display: table;
		width: 100%;
		clear: both;
		float: none;
	}
	a {
		display: block;
		float: left;
		font-size: 15px;
		line-height: 22px;
		cursor: pointer;
		text-decoration: none;
		color: #a8a8a8;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
		&.active {
			color: $link;
		}
		&:hover {
			color: $link_dark;
		}
		& + a {
			margin-left: 0;
		}
		&.back_url {
			position: relative;
			padding-left: 40px;
			line-height: 24px;
			color: #c1c1c1;
			font-size: 15px;
			font-weight: 600;
			text-decoration: none;
			&:before {
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				width: 24px;
				height: 24px;
				background: asset-url(icon-back) no-repeat 50% 50%;
			}
		}
		&.export_act {
			width: 22px;
			text-indent: -9999em;
			color: transparent;
			background: asset-url(icon-share) no-repeat 50% 50%;
			background-size: 20px;
			overflow: hidden;
			white-space: nowrap;
			font-size: 0;
		}
		&.stat_link {
			float: right;
			margin-left: 0 !important;
			margin-right: 32px;
		}
	}
	#search_product_form {
		display: block;
		position: relative;
		.form_item {
			height: 24px;
			line-height: 24px;
			&:before {
				width: 24px;
				height: 24px;
				content: '';
				position: absolute;
				z-index: 7;
				left: 0;
				top: 0;
				border: 0 none;
				background: asset-url(search) no-repeat 50% 50%;
			}
			label {
				padding: 0 40px;
			    color: #c1c1c1;
			    font-size: 15px;
			    font-weight: 400;
			    z-index: 7;
			    height: 24px;
			    line-height: 24px;
			}
			input[type=text] {
				height: 24px;
				line-height: 22px;
				padding: 0px 40px;
				font-size: 15px;
				color: #212121;
				position: relative;
				z-index: 8;
			}
			a.hide_product_search {
				display: block;
				width: 24px;
				height: 24px;
				text-decoration: none;
				position: absolute;
				top: 0;
				right: 0;
				background: asset-url(close-black) no-repeat 50% 50%;
				background-size: 20px 20px;
				z-index: 9;
				&.hide {
					display: none;
				}
			}
		}
	}
	#admin_search_users {
		float: right;
		width: 24px;
		height: 24px;
		margin-top: -2px;
		#find_user {
			display: block;
			width: 24px;
			height: 24px;
			cursor: pointer;
			background: asset-url(search) no-repeat 50% 50%;
		}
		form {
			display: none;
		}
		&.show_search_ {
			position: absolute;
			float: none;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #fff;
			padding: 16px 24px;
			margin: 0;
			#find_user {
				display: none;
			}
			form {
				display: block;
				position: relative;
				height: 22px;
				&:before {
					content: '';
					position: absolute;
					width: 24px;
					height: 24px;
					background: asset-url(search) no-repeat 0 0;
					top: 0;
					left: 0;
					z-index: 2;
				}
				input {
					-webkit-appearance: none;
  					-moz-appearance: none;
  					appearance: none;
  					display: block;
  					outline: none;
  					border: 0 none;
  					background: #fff;
  					padding: 0 40px;
  					height: 22px;
  					line-height: 22px;
  					z-index: 1;
  					font-size: 15px;
  					line-height: 24px;
  					color: #212121;
  					width: 100%;
				}
				#find_user_close {
					position: absolute;
					z-index: 2;
					width: 24px;
					height: 24px;
					top: 0;
					right: 0;
					background: asset-url(close-gray) no-repeat 0 0;
					cursor: pointer;
				}
			}
		}
	}
}
.user-filter__notification{
  display: inline-block;
  font-size: 15px;
  color: rgba(0,0,0,.6);
  padding: 0 24px;
}

.filter_search_toggle {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  background: {
    color: transparent;
    image: asset-url(search);
    repeat: no-repeat;
    position: center;
  }
  border: none;
  appearance: none;
  outline: none;
  cursor: pointer;

  @media (max-width: 767px) {
    display: none;
  }

  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    opacity: 0;
    background-image: asset-url(search2);
  }

  &:hover {
    background-image: asset-url(search2);
  }
}
