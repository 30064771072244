
@media (max-width: 1300px){
  .filter-content.ui-selectmenu-menu ul.ui-widget.ui-widget-content:before,
  .filter-content.ui-selectmenu-menu ul.ui-widget.ui-widget-content:after{
    left: auto;
    right: 8px;
  }
}

.ui-selectmenu-menu {
	display: none;
	&.ui-selectmenu-open {
		display: block;
		z-index: 1300;
	}
	ul.ui-widget.ui-widget-content {
		display: block;
		list-style: none;
		width: auto !important;
		min-width: 180px;
		max-width: 400px;
		margin: 0 auto;
		background: #fff;
		@include rounded(2px);
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .06);
		border: solid 1px rgba(0, 0, 0, .1);
		border-width: 1px !important;
		overflow: auto;
		overflow-x: hidden;
		padding: 16px 0;
		&:before, &:after  {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 7px 8px 7px;
			left: 50%;
			margin-left: -7px;
		}
		&:before {
			top: -8px;
			border-color: transparent transparent rgba(0, 0, 0, .15) transparent;
		}
		&:after {
			top: -7px;
			border-color: transparent transparent #fff transparent;
		}
		li {
			list-style: none;
			display: block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			line-height: 1.2;
			font-size: 15px;
			font-weight: 400;
			padding: 5px 24px;
			background: transparent !important;
			.ui-menu-item-wrapper {
				background: transparent;
				border: 0 none;
				padding: 0;
				margin: 0;
				color: #a6a6a6;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				&.ui-state-active {
					color: #1E1E1E;
					border: 0 none;
					background: transparent;
				}
				&:hover {
					color: #1E1E1E;
				}
			}
			&.ui-select, .ui-select {
				color: $link !important;
				&:hover {
					color: #1E1E1E !important;
				}
				.ui-menu-item-wrapper {
					color: $link;
					&:hover {
						color: #1E1E1E;
					}
				}
			}
		}
	}
}

//LEFT COL
#fixed_scroll_bottom {
	&.fixed {
		position: fixed;
		bottom: 0;
		left: 0;
	}
}

.left_offer {
	position: relative;
	width: 100%;
	background: #FFF;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
	border: solid 1px rgba(0, 0, 0, .1);
	margin-top: 32px;
	padding: 0;
	.category_offer {
		padding: 16px 40px 16px 80px;
		position: relative;
		& + .category_offer {
			border-top: 1px solid #efefef;
		}
		.avatar {
			position: absolute;
			top: 16px;
			left: 24px;
		}
		.remove_offer {
			position: absolute;
			width: 20px;
			height: 20px;
			top: 16px;
			right: 16px;
			color: transparent;
			text-indent: -9999em;
			overflow: hidden;
			display: block;
			text-decoration: none;
			background: asset-url(close-gray) no-repeat 50% 50%;
		}
	}
}

.bl_ {
	position: relative;
	//width: calc(50% - 15px);
  margin-bottom: 30px;
	background: #FFF;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
	border: solid 1px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
	padding: 16px 24px;
	@include rounded(2px);

  @media (max-width: 768px){
    width: 100%;
  }

  &moder{
    display: inline-block;
  }

	&.bl_comments {
		padding: 24px;
		margin: 0 0 16px;
    background: #fafafa;
		&.lev_2 {
			width: auto !important;
			margin-left: 48px;
		}
	}
	.in-competition {
		float: right;
		font-size: 14px;
		line-height: 20px;
		font-weight: 600;
		color: rgba(0,0,0,.38);
		padding-top: 10px;
		a {
			color: inherit;
			text-decoration: none;
		}
		& + .bl_header.author_onboard {
			max-width: 70%;
		}
	}
	.bl_header {
		position: relative;
		padding: 0 0 0 56px;
		min-height: 40px;
		&.author_onboard {
			display: inline-block;
			zoom: 1;
			*display: inline;
			max-width: 100%;
		}
		.avatar {
			position: absolute;
			display: block;
			top: 2px;
			left: 0;
			& + div {
				padding-top: 0 !important;
				margin-top: -1px;
			}
		}
		div {
			.badge {
				margin-left: 11px;
				& + .badge {
					margin-left: 7px;
				}
			}
			& + div {
				padding-top: 1px;
			}
		}
		form {
			display: inline;
			input[type=submit] {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				border: 0 none;
				background: transparent;
				outline: none;
				cursor: pointer;
				padding: 0;
				margin: 0;
				font-size: inherit;
				line-height: inherit;
				color: inherit;
				font-weight: inherit;
			}
			&.hide {
				display: none;
			}
		}
	}
	.bl_comment {
		padding: 16px 0 0;
		color: #212121;
		font-size: 15px;
		font-weight: 400;
		line-height: 24px;
		.normal {
			word-wrap: break-word;
		}
	}
	.bl_cover {
		margin-top: 16px;
		text-decoration: none;
		display: block;
		width: 100%;
		max-height: 400px;
		overflow: hidden;
		border: 0 none;
		img {
			border: 0 none;
		}
	}
	.bl_title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
		word-break: break-word;
		overflow: hidden;
    text-align: center;

		> span {
      margin-bottom: 10px;
			vertical-align: top;
			display: inline;

      a {
        font-size: 15px;
        font-weight: 400;
        line-height: 42px;
        color: rgba(255, 255, 255, 0.85);
        text-decoration: none;
      }

			&.ndash {
				display: inline-block;
				width: 16px;
				height: 34px;
				margin: 0 15px;
				position: relative;
        color: #fff;

				&:before {
					content: '';
					position: absolute;
					top: 50%;
					height: 3px;
					width: 100%;
					margin-top: -1px;
					background: #212121;
				}
			}
		}

		.bl_title-link {
      padding: 0 40px;
			text-decoration: none;
			display: inline;
      font-size: 18px;
      line-height: 28px;
      color: #fff;

			&.dark {
				color: #212121;
			}
			&.lightgray {
				color: $link;
			}
		}
		form {
			display: inline;
			input[type=submit] {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				border: 0 none;
				background: transparent;
				outline: none;
				cursor: pointer;
				padding: 0;
				margin: 0;
				font-size: inherit;
				line-height: inherit;
				color: inherit;
				font-weight: inherit;
			}
		}
	}
	.bl_desc {
		margin-top: 24px;
		line-height: 24px;
		.article_or_comments_description {
      min-height: 80px;
			max-height: 80px;
			p {
				font-size: 13px;
				font-weight: 400;
				color: #212121;
				line-height: 19px;
				word-break: break-word;
				& + p, & + ul, & + ol {
					margin-top: 14px;
				}
				a {
					color: $link_dark;
					text-decoration: underline;
					cursor: pointer;
					&:hover {
						color: $link;
					}
				}
			}
			ul {
				li {
					list-style: outside disc;
					margin-left: 25px;
					font-size: 15px;
					font-weight: 400;
					color: #212121;
					line-height: 22px;
					& + li {
						margin-top: 14px;
					}
				}
				& + p {
					margin-top: 14px;
				}
			}
			ol {
				li {
					list-style: outside decimal;
					margin-left: 25px;
					font-size: 15px;
					font-weight: 400;
					color: #212121;
					line-height: 22px;
					& + li {
						margin-top: 14px;
					}
				}
				& + p {
					margin-top: 14px;
				}
			}
		}
	}
	.bl_more {
		margin-top: 16px;
		margin-left: -6px;
		margin-right: -6px;
		a {
			display: inline-block;
			zoom: 1;
			*display: inline;
			padding: 8px;
			font-size: 15px;
			font-weight: 400;
			color: #a0a0a0;
			text-decoration: none;

      @media (max-width: 480px) {
        font-size: 13px;
      }

			&:hover {
				color: $link_dark;
			}
			&.gray {
				color: #717171;
			}
			&.lightgray {
				color: #bbb;
				&:hover {
					color: #8f8f8f;
				}
			}
		}
	}
	.bl_tags {
		margin-top: 24px;
		padding: 0;
		form {
			display: inline;
			input[type=submit] {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				background: transparent;
				outline: none;
				cursor: pointer;
			}
		}
		.tag, form {
			margin-right: 14px;
			margin-top: 8px;
			margin-bottom: 0;
			display: inline-block;
			.tag {
				margin: 0;
			}
		}
	}

	.bl_footer {
		padding-top: 16px;
		span:not(.article-stat) {
			display: inline-block;
			vertical-align: middle;
			line-height: 24px;
			font-size: 14px;
			font-weight: 600;
			color: #acacac;
			& + span:not(.article-stat) {
				margin-left: 40px;
			}
			&.green {
				color: $link;
			}
			.icon {
				vertical-align: middle;
				display: inline-block;
				width: 24px;
				height: 24px;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				margin-right: 8px;
				&.icon-eye-disable {
					background-image: asset-url(eye-disable);
				}
				&.icon-eye {
					background-image: asset-url(eye-enable);
				}
				//&.icon-comments-disable {
				//	background-image: asset-url(comments-disable);
				//}
				//&.icon-comments {
				//	background-image: asset-url(comments-enable);
				//}
				&.icon-likes-disable {
					background-image: asset-url(likes-disable);
				}
				&.icon-likes {
					background-image: asset-url(likes-enable);
				}
				&.icon-sbercoins-disable {
					background-image: asset-url(sbercoints-gray);
				}
			}
			&#exist_rating {
				color: #acacac;
				line-height: 22px;

        &:after {
					content: '';
					display: inline-block;
          width: 22px;
          height: 21px;
          margin-top: -0.4em;
					margin-left: 8px;
					background: {
            image: url("./img/icon-star.svg");
            repeat: no-repeat;
            position: center;
          }
          vertical-align: middle;
				}

				&.voted {
					color: $link;

          &:after {
						background: {
              image: url("./img/icon-star-enabled.svg");
            };
					}
				}
			}
		}
	}
	.bl_submit {
		position: relative;
		padding-top: 32px;
		display: block;
		&:before {
			content: '';
			position: absolute;
			top: 16px;
			left: 0;
			width: 100%;
			height: 0;
			border-top: 1px solid rgba(0,0,0,.1);
		}
		form {
			display: inline;
		}
		button, input[type=submit] {
			border: 0 none;
			background: none;
			outline: none;
			display: inline-block;
			height: 24px;
			padding: 0;
			cursor: pointer;
			color: #b3b3b3;
			font-size: 15px;
			font-weight: 400;
			cursor: pointer;
			transition: color .2s;
			margin-left: 24px;
			line-height: 24px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			&:hover {
				color: $link_dark;
			}
		}

		.best_answer {
			display: inline-block;
			padding-left: 28px;
			font-size: 15px;
			line-height: 24px;
			color: $link;
			position: relative;
			margin-left: 24px;
			&:before {
				content: '';
				position: absolute;
				width: 24px;
				height: 24px;
				top: 0;
				left: 0;
				background: asset-url(icons-subscription-green) no-repeat 50% 50%;
				background-size: 20px 20px;
			}
		}
	}
}

.bl__menu {
  position: relative;
  z-index: 2;
  padding: 10px 0 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: right;

  &:hover {
    .bl__list {
      display: block;
    }

    svg path {
      fill: #212121;
    }
  }

  svg path {
    transition: 300ms;
  }
}

.bl__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bl__list {
  display: none;
  position: absolute;
  padding-top: 10px;
  list-style: none;
  text-align: center;
  background: #ffffff;
  top:50px;
  right: -50%;
  transform: translateX(38%);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);

  &:before {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    top: -12px;
    right: 41%;
    background-color: #ffffff;
    transform: rotate(45deg);
  }
}


.bl__item {
  display: block;
  text-decoration: none;
  padding: 10px 35px;
  color: #a5a5a5;
  font-size: 15px;
  line-height: 21px;
}

.bl__item--delete {
  display: block;
  text-decoration: none;
  padding: 10px 35px;
  color: #F0443D;
  font-size: 15px;
  line-height: 21px;

  &:hover {
    color: #F0443D;
  }
}

.bl_icon {
  &:hover {

    fill: #000000;
  }
}


.bl_form-subcoment{
	margin-top: 15px;
}

.detail_material_action {
	#complaint {
		button {
			@include btn();
		}
	}
	#share {
		display: inline-block;
		height: $form_height;
		line-height: $form_height;
		vertical-align: middle;
		button {
			display: block;
			position: relative;
			height: $form_height;
			line-height: $form_height;
			border: 0 none;
			outline: none;
			background: transparent;
			padding: 0 0 0 32px;
			cursor: pointer;
			font-size: 14px;
			font-weight: 600;
			color: #a8a8a8;
			&:before {
				content: '';
				position: absolute;
				width: 24px;
				height: 24px;
				top: 50%;
				left: 0;
				margin-top: -12px;
			}
		}
	}
	#share {
		button {
			&:before {
				background: asset-url(icon-share) no-repeat 50% 50%;
			}
			&:after {
				content: '';
				display: block;
				width: 0;
				height: 0;
				visibility: hidden;
				background: asset-url(icon-share-hover) no-repeat 50% 50%;
			}
			&:hover {
				color: $link;
				&:before {
					background: asset-url(icon-share-hover) no-repeat 50% 50%;
				}
			}
		}
	}
}

.fixed_edit_block_pseudo {
	height: 92px;
	width: 100%;

}

.fixed_edit_block {
	width: 100%;
	background-color: #ffffff;
	//transition: box-shadow .2s;
	border-bottom: 1px solid transparent;
	padding: 16px 0;
	height: 92px;
	margin-top: -92px;
	&.fixed {
		position: fixed;
		z-index: 999;
		top: 0;
		left: 0;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
		border-bottom-color:rgba(0, 0, 0, 0.09);
		margin-top: 0;
	}
}

.detail_comments {
  background: #fff;
	padding: 32px 0;
	h4 {
		padding: 0;
		margin: 0 0 24px;
	}
	.bl_ + h4 {
		margin-top: 32px;
	}
}

.badge {
	display: inline-block;
	zoom:1;
	*display: inline;
	vertical-align: middle;
	background-color: #acacac;
	@include rounded(2px);
	height: 20px;
	line-height: 20px;
	padding: 0 5px;
	font-size: 10px;
	font-weight: 600;
	text-transform: uppercase;
	color: #fff;
	&.default {
		color: #acacac;
		background: #FFF;
		border: 1px solid #dedede;
		line-height: 18px;
	}
}

.tag {
	display: inline-block;
	zoom:1;
	*display: inline;
	height: 28px;
	border: 2px solid #ededed;
	@include rounded(4px);
	padding: 0 7px;
	line-height: 25px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 12px;
	text-decoration: none;
	color: #a6a6a6;
	cursor: default;
}
a.tag {
	cursor: pointer;
	&:hover {
		color: #fff;
		background: $link;
		border-color: $link;
	}
}

.nowrap {
	white-space: nowrap;
}
.author_tooltip {
	cursor: pointer;
	&:hover {
		color: $link_dark;
	}
	a {
		text-decoration: none;
		&:hover {
			color: $link_dark;
		}
	}
}

.article_operations {
	padding: 17px 0;
	background: #fff;
	box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.1);
	.accept_btn {
		@include btn();
		em {
			color: inherit;
			font-size: inherit;
			font-style: normal;
			padding-left: 10px;
		}
	}

	.cancel_btn {
		@include btn();
	}
	form {
		display: inline-block;
		zoom: 1;
		*display: inline;
		& + form {
			margin-left: 16px;
		}
	}
	.thank_you {
		display: inline-block;
		zoom: 1;
		*display: inline;
		line-height: 40px;
		font-weight: 600;
		font-size: 15px;
		color: #000;
	}
}

.add_comment_form {
	position: relative;
	padding: 0  0 0 56px;
	min-height: 40px;
	.avatar {
		position: absolute;
		top: 0;
		left: 0;
	}
	.contenteditable_comment {
		position: relative;
		display: block;
		padding: 8px 0;
		font-size: 15px;
		line-height: 24px;
		width: 100%;
		font-weight: 400;
		color: #212121;
		cursor: text;
		&:before {
			content: attr(data-placeholder);
			color: #c1c1c1;
			display: none;
		}
		&.not_value:before {
			display: block;
		}
	}

	textarea{
		position: relative;
		display: block;
		padding: 8px 0;
		font-size: 15px;
		line-height: 24px;
		width: 100%;
		font-weight: 400;
		color: #212121;
		cursor: text;
	}
}

.add_subcomment_form {
	position: relative;
	padding: 0  0 0 56px;
	min-height: 40px;
	&.add_subcomment_form_question {
		margin-top: 24px;
	}
	.avatar {
		position: absolute;
		top: 0;
		left: 0;
	}
	.contenteditable_comment {
		position: relative;
		display: block;
		padding: 8px 0;
		font-size: 15px;
		line-height: 24px;
		width: 100%;
		font-weight: 400;
		color: #212121;
		cursor: text;
		&:before {
			content: attr(data-placeholder);
			color: #c1c1c1;
			display: none;
		}
		&.not_value:before {
			display: block;
		}
	}
}

//USER CATEGORIES

.user_category_item {
	position: relative;
	width: 100%;
	margin-bottom: 30px;
	height: 200px;
	overflow: hidden;
	span {
		display: block;
		position: absolute;
		z-index: 5;
		&.img {
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 3;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-image: asset-url(placeholders-image);
			@for $i from 1 through 9 {
				&.img_#{$i} {
					background-image: asset-url(theme_#{$i});
				}
			}
			img {
				width: 100%;
				height: auto;
				position: absolute;
				display: block;
				left: 0;
				top: 0;
				z-index: 4;
			}
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.48);
				transition: all .2s;
				-webkit-transition: all .2s;
				-moz-transition: all .2s;
				z-index: 5;
			}
		}
		&.name {
			position: absolute;
			width: 100%;
			padding: 0 24px;
			font-size: 18px;
			font-weight: 700;
			color: #e0e0e0;
			color: rgba(255,255,255,.7);
			letter-spacing: -.1px;
			line-height: 24px;
			left: 0;
			bottom: 24px;
			z-index: 6;
		}
		&.badge {
			z-index: 7;
			position: absolute;
			margin: 0;
			top: 24px;
			left: 24px;
		}
	}
}

//SUBSCRIBE

.recommend_article_item {
	position: relative;
	width: 100%;
	padding: 16px 24px 16px 108px;
	position: relative;
	min-height: 60px;
	& + .recommend_article_item {
		&:before {
			height: 0;
			width: auto;
			left: 108px;
			right: 0;
			top: 0;
			content: '';
			position: absolute;
			border-top: 1px solid #efefef;
		}
	}
	.img {
		width: 60px;
		height: 40px;
		position: absolute;
		top: 10px;
		left: 24px;
		overflow: hidden;
	}
	.row_1 {
		span, font, strong {
			font-weight: 400;
			font-size: 15px;
			line-height: 22px;
		}
		span {
			color: $link;
		}
		font {
			padding: 0 2px;
			color: #a0a0a0;
		}
		strong {
			color: #000;
		}
	}
	.form_radio {
		height: 24px;
		line-height: 24px;
		position: relative;
		overflow: hidden;
		label {
			display: inline-block;
			zoom: 1;
			*display: inline;
			position: relative;
			cursor: pointer;
			width: 24px;
			height: 24px;
			text-indent: -9999em;
			color: transparent;
			padding: 0;
		}
		input[type=radio] {
			display: none;
			opacity: 0;
			visibility: hidden;
			position: absolute;
			width: 0;
			height: 0;
			top: -9999em;
			left: -9999em;
			& + label {
				&:before {
					position: absolute;
					content: '';
					top: 0;
					left: 0;
					width: 24px;
					height: 24px;
					background: asset-url(icon-radio) no-repeat 50% 50%;
				}
				&:after {
					content: '';
					display: block;
					width: 0;
					height: 0;
					opacity: 0;
					background: asset-url(icon-radio-enable) no-repeat 50% 50%;
				}
			}
			&:checked + label{
				color: $link;
				&:before {
					background: asset-url(icon-radio-enable) no-repeat 50% 50%;
				}
			}
		}
		& + .form_item {
			margin-top: 16px;
		}
	}
}

.subscribe_author_list {
	padding: 8px 0px;
	position: relative;
	width: 100%;
	background: #FFF;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
	border: solid 1px rgba(0, 0, 0, 0.1);
	&.for_modal {
		box-shadow: none;
		border: 0 none;
	}
	.subscribe_author_item {
		width: 100%;
		padding: 15px 24px 15px 80px;
		position: relative;
		& + .subscribe_author_item {
			&:before {
				height: 0;
				width: auto;
				left: 80px;
				right: 0;
				top: 0;
				content: '';
				position: absolute;
				border-top: 1px solid #efefef;
			}
		}
		.avatar {
			left: 24px;
			top: 16px;
			position: absolute;
		}
		.user_invited_placehodler {
			left: 24px;
			top: 16px;
			position: absolute;
			width: 40px;
			height: 40px;
			background: #cdd8b7 asset-url(noavatar) no-repeat 50% 50%;
			@include rounded(50%);
		}
		.row_1 {
			margin-top: -2px;
			.badge {
				margin-left: 0;
				margin-right: 8px;
			}
		}
		.row_2 {
			padding-top: 2px;
		}
		.row_1_2 {
			line-height: 40px;
			margin: 0;
		}
		.row_text_dark {
			font-size: 15px;
			font-weight: 400;
			color: #212121;
			line-height: 22px;
			padding-top: 16px;
		}
		.row_text {
			padding-top: 16px;
			font-size: 15px;
			font-weight: 400;
			color: #a8a8a8;
			line-height: 22px;
      word-break: break-all;
		}
		.user_more_action {
			position: absolute;
			width: 0;
			height: 24px;
			background: asset-url(more-default) no-repeat 50% 50%;
			cursor: pointer;
			z-index: 5;
			display: block;
			top: 0;
			right: 0;
      user-select: none;
			&:before {
				width: 0;
				height: 0;
				position: absolute;
				content: '';
				opacity: 0;
				visibility: hidden;
				z-index: -1;
				background-image: asset-url(more-hover);
			}

      &:active,
      &:focus {
        z-index: 1000;
      }

      @media (max-width: 991px) {
        width: 24px;
      }

			ul {
				position: absolute;
				display: none;
				list-style: none;
				@include rounded(2px);
				background: #FFF;
				width: 250px;
				left: 50%;
				top: 33px;
				box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
				border: solid 1px rgba(0, 0, 0, 0.09);
				margin-left: -125px;
				z-index: 20;
				padding: 8px 0;
        @media (max-width: 767px) {
          right: -23px;
          left: auto;
        }
				&:after {
					content: '';
					position: absolute;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 6px 8px 6px;
					border-color: transparent transparent #ffffff transparent;
					top: -7px;
					left: 50%;
					margin-left: -6px;
          @media (max-width: 767px) {
            right: 27px;
            left: auto;
          }
				}
				&:before {
					content: '';
					position: absolute;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 6px 8px 6px;
					border-color: transparent transparent #e5e5e5 transparent;
					top: -8px;
					left: 50%;
					margin-left: -6px;
          @media (max-width: 767px) {
            right: 27px;
            left: auto;
          }
				}
				li {
					display: block;
					list-style: none;
					padding: 0;
					margin: 0;
					a, button {
						display: block;
						list-style: none;
						padding: 8px 24px;
						line-height: 1;
						color: #a5a5a5;
						font-size: 15px;
						font-weight: 400;
						text-decoration: none;
						text-align: left;
						outline: none;
						background: transparent;
						border: 0 none;
						cursor: pointer;
						&:hover {
							color: $link;
						}
						&.last {
							margin-top: 8px;
							border-top: 1px solid #efefef;
							padding: 16px 24px 8px;
						}
					}
				}
			}
			&:hover {
				background-image: asset-url(more-hover);
			}
		}
		.user_more_last_login {
			display: inline-block;
			position: relative;
			line-height: 24px;
			height: 24px;
			min-width: 1px;
			color: #c1c1c1;
			color: rgba(0, 0, 0, 0.24);
			font-size: 15px;
			text-align: right;
		}
		.user_name {
			padding-right: 8px;
      text-decoration: none;
		}
		.unsubscribe_user {
			display: inline-block;
			position: relative;
			line-height: 40px;
			height: 40px;
			min-width: 1px;
			color: #c1c1c1;
			color: rgba(0, 0, 0, 0.24);
			font-size: 15px;
			text-align: right;
			border: 0 none;
			background: transparent;
			outline: none;
			cursor: pointer;
		}
		&:hover,
    &.is-active {
			.user_name, .unsubscribe_user {
				color: $link;
			}
			.user_more_last_login {
				color: $link;

        > span {
          display: inline-block;
          padding-right: 30px;
        }
				.user_more_action {
					width: 24px;
				}
			}
		}

    &.is-active {
      .user_more_last_login {
        .user_more_action {
          z-index: 10;
          background-image: asset-url(more-hover);
          > ul {
            display: block;
          }
        }
      }
    }

		.user_roles {
			padding-top: 24px;
			.role_item {
				&:before, &:after {
					@include clear();
				}
				& + .role_item {
					padding-top: 8px;
				}
				.badge {
					margin-bottom: 8px;
					margin-right: 8px;
					margin-left: 0 !important;
					float: left;
					cursor: default;
				}
				.add_cat {
					width: 20px;
					height: 20px;
					float: left;
					border: 1px solid #dedede;
					color: #acacac;
					cursor: pointer;
					position: relative;
					background: asset-url(add-block-min) no-repeat 50% 50%;
					@include rounded(2px);
					&:before {
						content: '';
						position: absolute;
						width: 40px;
						height: 20px;
						z-index: 10;
						top: 17px;
						left: 50%;
						margin-left: -20px;
					}
					ul {
						position: absolute;
						display: none;
						list-style: none;
						@include rounded(2px);
						background: #FFF;
						width: 300px;
						left: 50%;
						top: 28px;
						box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
						border: solid 1px rgba(0, 0, 0, 0.09);
						margin-left: -150px;
						z-index: 20;
						padding: 8px 0;
						&:after {
							content: '';
							position: absolute;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0 6px 8px 6px;
							border-color: transparent transparent #ffffff transparent;
							top: -7px;
							left: 50%;
							margin-left: -6px;
						}
						&:before {
							content: '';
							position: absolute;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0 6px 8px 6px;
							border-color: transparent transparent #e5e5e5 transparent;
							top: -8px;
							left: 50%;
							margin-left: -6px;
						}
						li {
							display: block;
							list-style: none;
							padding: 0;
							margin: 0;
							a, button {
								display: block;
								list-style: none;
								padding: 8px 24px;
								line-height: 1;
								color: #a5a5a5;
								font-size: 15px;
								font-weight: 400;
								text-decoration: none;
								text-align: left;
								border: 0 none;
								background: transparent;
								cursor: pointer;
								outline: none;
								&:hover {
									color: $link;
								}
							}
						}
					}
					&:hover {
						ul {
							display: block;
						}
					}

				}
			}
		}
		select#user_product_status {
			display: none;
		}
		.ui-selectmenu-button {
			width: auto !important;
			padding: 0 19px 0 0;
			position: relative;
			background: transparent;
			border: 0 none;
			@include rounded(0);
			.ui-selectmenu-text {
				display: inline-block;
				width: auto;
				margin: 0;
				line-height: 20px;
				height: 20px;
				font-size: 12px;
				color: $link;
			}
			.ui-selectmenu-icon {
				display: block;
				position: absolute;
				width: 18px;
				height: 20px;
				background: asset-url(icon-selector-mini) no-repeat 50% 50%;
				top: 0;
				right: 0;
			}
		}
	}
	&#category_users {
		.user_name {
			color: #212121 !important;
		}
	}
}

.bl_no {
	padding: 100px 24px;
	position: relative;
	width: 100%;
	background: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
	text-align: center;

  .icon-img {
    display: block;
    margin: 0 auto 15px;
  }

  .icon {
		display: block;
		width: 60px;
		height: 60px;
		margin: 0 auto 16px;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		&.icon-contest {
			background-image: asset-url(create-placeholders-contest);
		}
		&.icon-user {
			background-image: asset-url(create-placeholders-users);
		}
		&.icon-liked {
			background-image: asset-url(placeholders-favorite);
		}
		&.icon-modarated {
			background-image: asset-url(placeholders-moderation);
		}
		&.icon-dasboard {
			background-image: asset-url(placeholder-dasboard);
		}
		&.icon-draft {
			background-image: asset-url(placeholders-draft);
		}
		&.icon-article {
			background-image: asset-url(create-longread-disable);
			background-size: 60px 60px;
		}
		&.icon-comments {
			background-image: asset-url(placeholders-comments);
		}
		&.icon-bookmarks {
			background-image: asset-url(placeholders-bookmarks);
		}
		&.icon-leaderboard {
			background-image: asset-url(placeholders-leaderboard);
		}
	}
	span {
		display: block;
		font-weight: 600;
		font-size: 15px;
		color: #c1c1c1;
		text-align: center;
		line-height: 20px;
		margin-bottom: 4px;
	}
	small {
		display: block;
		font-weight: 400;
		font-size: 13px;
		line-height: (18 / 13);
		color: #c1c1c1;
		margin:0 auto 20px;
		max-width: 555px;
	}
  a.button {
    display: inline-block;
    padding-right: 30px;
    padding-left: 30px;
  }
	a {
		text-decoration: none;
		display: block;
		text-align: center;
		font-size: 15px;
		font-weight: 400;
		color: $link;
	}
}

.bl_tags {
	padding: 8px 0;
	.tag_admin_item {
		padding: 16px 24px;
		& + .tag_admin_item {
			border-top: 1px solid #efefef;
		}
		form {
			display: block;
			position: relative;
			width: 200px;
			padding: 0;
			z-index: 1;
			input[type=text] {
				position: relative;
				z-index: 1;
				line-height: 20px;
				padding: 4px 25px 4px 7px;
				height: 28px;
				border: 2px solid #ededed;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				border-radius: 4px;
				font-weight: 600;
				font-size: 12px;
				text-decoration: none;
				color: #a6a6a6;
				text-transform: uppercase;
				outline: none;
				background: #fff;
				width: 100%;
				z-index: 2;
				&:focus {
					border-color: $link;
					color: $link;
				}
			}
			a {
				text-decoration: none;
				color: transparent;
				display: block;
				position: absolute;
				height: 28px;
				width: 25px;
				top: 0;
				right: 0;
				text-align: center;
				text-indent: -9999em;
				z-index: 3;
				background: asset-url(close-black) no-repeat 50% 50%;
				background-size: 11px 11px;
			}
		}
		a {
			display: inline-block;
			line-height: 20px;
			text-decoration: none;
			font-size: 15px;
			font-weight: 400;
			& + a {
				margin-left: 12px;
			}
		}
	}
}

.bl_stats {
	padding: 8px 0;
	& + .bl_stats {
		margin-top: 32px;
	}
	.bl_stat_el {
		padding: 16px 24px;
		position: relative;
		line-height: 24px;
		& + .bl_stat_el {
			border-top: 1px solid #efefef;
		}
		&.bl_stat_header {
			font-weight: 600;
			color: #212121;
			.icon {
				display: inline-block;
				width: 24px;
				height: 24px;
				vertical-align: top;
				margin-right: 8px;
				background-repeat: no-repeat;
				background-repeat: no-repeat;
				&.icon-users {
					background-image: asset-url(stat-users);
				}
				&.icon-materials {
					background-image: asset-url(stat-materials);
				}
			}
		}
	}
}

//PROFILE
.user_profile_right {
	position: relative;
	width: 100%;
	background: #FFF;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
	border: solid 1px rgba(0, 0, 0, .1);
	padding: 24px 24px 16px;
	margin-bottom: 32px;
	@include rounded(2px);
	.avatar {
		margin-bottom: 24px;
	}
	&_name {
		text-align: center;
		font-size: 15px;
		font-weight: 600;
		color: #000;
	}
	&_email {
		text-align: center;
		color: #a0a0a0;
		font-size: 12px;
		line-height: 18px;
		padding-top: 2px;
	}
	&_data {
		display: block;
		list-style: none;
		padding-top: 16px;
		padding-bottom: 8px;
		li {
			display: block;
			overflow: hidden;
			padding: 10px 0;
			span, strong {
				display: block;
				height: 24px;
				color: #a0a0a0;
				line-height: 24px;
				font-size: 15px;
				font-weight: 500;
			}
			span {
				float: left;
			}
			strong {
				position: relative;
				float: right;
				padding-right: 32px;
				&:before {
					content: '';
					position: absolute;
					width: 24px;
					height: 24px;
					right: 0;
					top: 0;
					background: asset-url(sbercoints-gray) no-repeat 50% 50%;
				}
				&.green {
					color: $link;
					&:before {
						background: asset-url(sbercoints-new) no-repeat 50% 50%;
					}
				}
			}
		}
	}
	&_roles {
		border-top: 1px solid #efefef;
		padding-top: 24px;
		&:before, &:after {
			@include clear();
		}
		& + .role_item {
			padding-top: 16px;
		}
		.badge {
			margin-bottom: 8px;
			margin-right: 8px;
			margin-left: 0 !important;
			float: left;
			cursor: default;
		}
	}
}

#support_complaint_type_id-button {
	@include rounded(0);
	max-width: 100%;
	display: inline-block;
	position: relative;
	border: 0 none !important;
	background: transparent !important;
	padding: 0 28px 0 0 !important;
	width: auto;
	.ui-selectmenu-icon {
		width: 20px;
		height: 20px;
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -10px;
		background: asset-url(arrow-down) no-repeat 50% 50%;
		transition: all .2s;
		-webkit-transition: all .2s;
		-moz-transition: all .2s;
	}
	.ui-selectmenu-text {
		white-space: nowrap;
		display: inline-block;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #a8a8a8;
		margin: 0;
		height: 30px;
		line-height: 30px;
		font-size: 15px;
		font-family: $font;
	}
	&.ui-selectmenu-button-open {
		.ui-selectmenu-icon {
			transform: rotate(180deg);
			-webkit-transform: rotate(180deg);
			-moz-transform: rotate(180deg);
		}
	}
	&.select {
		.ui-selectmenu-text {
			color: #212121;
		}
	}
}

#support_complaint_type_id-menu{
	li:first-child {
		display: none !important;
	}
}

.player {
	width: 100%;
	height: 0;
	position: relative;
	overflow: hidden;
	padding-bottom: 56.25%;
	iframe, object, embed, video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100%;
		background-color: #ebebeb;
	}
	& + .help_item {
		margin-top: 24px;
	}
}

@media (max-width: 767px) {

	.bl_ {
		padding: 10px 15px;
		.bl_header {
			padding: 0 0 0 40px;
			display: block;
			width: 100%;
			.avatar {
				width: 32px;
				height: 32px;
				line-height: 32px;
				font-size: 13px;
			}
			.size15 {
				font-size: 12px;
				color: #000 !important;
				a {
					color: #000;
				}
			}
			div {
				.badge {
					margin-left: 8px;
				}
			}
			.gray.size12 {
				font-size: 10px;
			}
		}
		.in-competition {
			float: none;
			width: 100%;
			text-align: left;
		}
		.bl_title {
			margin-top: 15px;
			line-height: 28px;
			.size24 {
				font-size: 20px !important;
			}
			span.ndash {
				height: 28px;
				line-height: 28px;
				margin: 0 4px;
			}
			span, a {
				line-height: 28px;
			}
		}
		.bl_desc, .bl_tags {
			margin-top: 16px;
		}
		.bl_tags {
			margin-top: 8px;
			.tag, form {
				margin-top: 8px;
				margin-right: 8px;
				.tag {
					margin: 0;
				}
			}
		}
		.bl_footer {
			margin-top: 16px;
			padding-top: 12px;
			padding-bottom: 2px;
			span:not(.article-stat) {
				line-height: 20px;
				font-size: 12px;
				& + span:not(.article-stat) {
					margin-left: 9px;
				}
				.icon {
					background-size: 20px 20px;
					margin-right: 3px;
				}
				&#exist_rating {
					line-height: 20px;
					&:after {
						width: 20px;
						height: 20px;
						background-size: 20px 20px;
					}
				}
			}
		}
	}
	.bl_ {
		.bl_submit {
			padding-top: 28px;
			.alignright {
				//text-align: left !important;
			}
			.col-xs-3 {
				text-align: right;
			}
			button, input[type=text], .best_answer {
				margin-right: 10px;
				margin-left: 0;
				font-size: 12px;
				&.set_the_best {
					color: $link;
				}
			}
		}
	}
	.bl_no {
		padding: 70px 15px;
		span {
			font-size: 12px;
			font-weight: 600;
			color: #000;
			line-height: 18px;
		}
		small {
			font-size: 12px;
			line-height: 18px;
		}
	}
	.bl_.bl_comments {
		padding: 16px;
		&.lev_2 {
			margin-left: 24px;
		}
	}
	.bl_.competition_ {
		padding-top: 16px;
		padding-bottom: 16px;
		.competition_title {
			font-size: 20px;
			line-height: 30px;
			margin-bottom: 16px;
			a {
				text-decoration: none;
				font-size: inherit;
				line-height: inherit;
				font-weight: inherit;
			}
		}
		.competition_cover {
			height: 100px;
			margin-bottom: 16px;
			span {
				width: 58px;
				height: 58px;
				margin: -29px 0 0 -29px;
				background-size: 58px 58px;
			}
			&:before {
				width: 300px;
				height: 300px;
				margin: -150px 0 0 -150px;
			}
			&:after {
				width: 150px;
				height: 150px;
				margin: -75px 0 0 -75px;
			}
		}
		.competition_text {
			p {
				font-size: 16px;
				line-height: 21px;
			}
			a {
				cursor: default;
				pointer-events: none;
				color: #000 !important;
			}
		}
		.competition_footer {
			padding-top: 40px;
			.col-md-7, .col-md-5 {
				overflow: hidden;
				position: relative;
				line-height: 20px;
				text-align: right;
				em {
					height: 20px;
					display: block;
					position: absolute;
					top: 0;
					left: 15px;
					font-size: 12px;
					line-height: 20px;
				}
				span {
					line-height: 20px;
					height: 20px;
					font-size: 12px;
					i {
						width: 20px;
						height: 20px;
						background-size: 20px 20px;
					}
				}
			}
			.col-md-5 {
				margin-top: 24px;
			}
		}
	}
	.avatar {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.badge {
		height: 16px;
		line-height: 16px;
		font-size: 8px;
		padding: 0 5px;
		&.default {
			line-height: 14px;
		}
	}
	.tag {
		height: 24px;
		line-height: 20px;
		padding: 0 8px;
		font-size: 8px;
		margin-left: 0 !important;
		margin-right: 8px;
		margin-bottom: 8px;
	}
	#search_popup {
		position: fixed;
		width: 100% !important;
		left: 0 !important;
		top: 64px !important;
		bottom: 0;
		height: auto;
		box-shadow: none;
		padding-top: 2px;
		&:before, &:after {
			margin: 0;
			left: 19px;
		}
		#search_popup_inner {
			-webkit-overflow-scrolling: touch;
		}
		.search_popup_sec {
			.search_popup_sec_head {
				padding: 15px;
				strong, a {
					font-size: 12px;
					line-height: 18px;
				}
			}
			.search_popup_sec_body {
				padding: 15px;
				&.search_popup_materials {
					a {
						font-size: 12px;
						line-height: 18px;
						a {
							font-size: 12px;
							line-height: 18px;
						}
					}
				}
				&.search_popup_authors {
					a {
						height: 32px;
						line-height: 32px;
						padding-left: 40px;
						font-size: 12px;
						.avatar {
							top: 0;
							left: 0;
							width: 32px;
							height: 32px;
							line-height: 32px;
							font-size: 13px;
						}
					}
				}
				&.search_popup_tags {
					padding: 15px 15px 0;
				}
			}
		}
	}

	.left_block_ {
		margin-top: 32px !important;
		padding-top: 0 !important;
		width: 100%;
		height: auto;
		padding: 0;
		h4 {
			padding: 0 15px;
		}
		h4 + p {
			margin-bottom: 16px;
			white-space: normal;
			padding: 0 15px;
			&:after {
				display: none;
			}
		}
		.left_block_item_wrap {
			overflow: hidden;
			overflow-x :auto;
			white-space: nowrap;
			padding: 0 15px 15px;
			vertical-align: top;
			-webkit-overflow-scrolling: touch;
			&::-webkit-scrollbar {
			    height: 4px;
			}
			&::-webkit-scrollbar-track {
			    background: rgba(0,0,0,.1)
			}
			&::-webkit-scrollbar-thumb {
			    border-radius: 2px;
			    background: rgba(0,0,0,.3);
			}
			.left_block_item {
				background: #FFF;
				display: inline-block;
				padding: 10px 15px 15px;
				box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
  				border: solid 1px rgba(0, 0, 0, 0.09);
				vertical-align: top;
				width: 275px;
				height: 222px;
				position: relative;
				overflow: hidden;
				& + .left_block_item {
					margin-left: 13px;
				}
				.row > [class^='col-'] {
					position: static !important;
				}
				span.author {
					position: absolute;
					left: 0;
					bottom: 16px;
					line-height: 32px;
					padding: 0 15px;
					font-size: 12px;
					color: #000;
					background: #FFF;
					z-index: 2;
					width: 100%;
					&:before {
						height: 30px;
						width: 100%;
						position: absolute;
						content: '';
						left: 0;
						top: -30px;
						background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%);
					}
					&:after {
						height: 16px;
						width: 100%;
						position: absolute;
						content: '';
						left: 0;
						bottom: -16px;
						background: #fff;
					}
					a {
						color: #000;
						font-weight: 600;
					}
					.avatar_pseudo {
						display: inline-block;
						width: 32px;
						height: 32px;
						line-height: 32px;
						margin-right: 10px;
						vertical-align: middle;
						background: #CDD8B7;
					    color: #FFF !important;
					    text-transform: uppercase;
					    font-weight: 700;
					    font-size: 13px;
					    text-align: center;
					    @include rounded(50%);
					    &:before {
					    	content: attr(data-avatar);
					    }
					}
				}
				.category, .ndash, .title {
					font-size: 20px !important;
					line-height: 28px !important;
					font-weight: 700 !important;
					white-space: normal !important;
				}
				span.ndash {
					height: 28px;
				}

			}
		}
	}
	.left_menu_mobile_fixed_overlay {
		display: none;
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 8;
		top: 0;
		left: 0;
		background-color: rgba(46, 126, 67, 0.9);
		&.open {
			display: block;
			z-index: 40;
			& + .left_menu_mobile_fixed {
				z-index: 50;
				a {
					display: block !important;
				}
			}
		}
	}
	.left_menu_mobile_fixed {
		position: fixed;
		z-index: 9;
		bottom: 0;
		left: 0;
		width: 100%;
		margin: 0;
		left: 0;
		padding: 8px 15px;
		border-width: 1px 0 0;
	}
	.left_menu.left_menu_coins {
		padding: 16px 15px;
		margin-bottom: 15px;
		strong, span {
			font-size: 12px;
		}
	}
	.left_menu.visible-xs, .subscribe_theme_item {
		margin-bottom: 15px;
	}
	.subscribe_author_list {
		padding: 0;
		.subscribe_author_item {
			padding: 15px 15px 15px 55px;
			& + .subscribe_author_item:before {
				left: 55px;
			}
			.avatar {
				width: 32px;
				height: 32px;
				line-height: 32px;
				font-size: 13px;
				left: 15px;
				top: 15px;
			}
			.unsubscribe_user {
				width: 24px;
				height: 24px;
				line-height: 24px;
				color: transparent;
				text-indent: -9999em;
				background: asset-url(icon-unsubscribe) no-repeat 50% 50%;
				margin-top: 2px;
			}
		}
	}

	.leaderboard_competition {
		padding: 15px;
		margin: 0 0 15px;
		span {
			font-size: 13px;
			line-height: 18px;
			margin-bottom: 4px;
		}
		strong {
			font-size: 20px;
			line-height: 28px;
		}
	}

	.product_item {
		margin-bottom: 15px;
		.bl_ {
			.product_text_equal {
				height: auto !important;
				padding-left: 15px;
				padding-right: 15px;
			}
			.product_footer {
				margin: 0 15px;
				padding-top: 15px;
				padding-bottom: 15px;
			}
		}
	}

	.user_profile_right {
		padding: 24px 15px;
	}
	#comments_list {
		#comments{
			.bl_ {
				&.bl_comments {
					padding: 16px 15px;
					.bl_header {
						height: 32px;
						min-height: 0;
						div {
							line-height: 32px;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
					.bl_footer {
						padding-top: 0;
						border-top: 0 none;
					}
				}
			}
		}
	}

	.article_operations {
		margin: 0;
		padding: 0;
		width: 100%;
		background: transparent;
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		.mobile_article_operations {
			position: fixed;
			width: 100%;
			z-index: 8;
			left: 0;
			bottom: 0;
			background: #FFF;
			box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.16);
			padding: 0;
			form {
				display: inline !important;
				margin: 0 !important;
				button {
					@include rounded(0);
					width: 50%;
					border: 0 none;
					font-size: 12px;
					font-weight: 600;
					text-align: center;
					padding: 0;
					height: 45px;
					line-height: 45px;
					margin: 0;
					&.cancel_btn {
						float: left;
						background: #FFF;
					}
					&.accept_btn {
						float: right;
						width: 100%;
						#accept_count {
							display: none;
						}
					}
				}
				& + form {
					button.accept_btn {
						width: 50% !important;
					}
				}
			}
		}
	}

	.modal {
		.modal_wrap {
			.modal_inner {
				padding: 15px;
				.modal_content {
					width: 100%;
					padding: 18px 15px;
					h3 {
						font-size: 20px;
						line-height: 28px;
					}
					.simpleeditor {
					    margin: 16px 0 0;
					}
					#search_in_modal {
						margin: -18px -15px 0;
						form {
							&:before {
								left: 15px;
							}
							.form_item {
								label, input {
									padding-left: 54px;
								}
							}
						}
					}
					#list_users, #list_articles {
						margin: 0 -15px;
						.subscribe_author_list {
							.subscribe_author_item {
								overflow: hidden;
								.row_1 {
									.user_name {
										font-size: 12px;
									}
								}
							}
						}
					}
					#share_users_action {
						margin: 0 -15px;
						padding: 0 15px;
					}
				}
			}
		}
		&.modal_img {
			overflow: hidden;
			overflow-y: auto;
			.modal_wrap {
				.modal_inner {
					padding: 5px;
					.modal_content {
						width: 100%;
						padding: 40px 5px;
						position: relative;
						img {
							width: 100%;
							height: auto;
						}
						.close_modal {
							display: block;
							position: absolute;
							width: 20px;
							height: 20px;
							top: 10px;
							right: 5px;
							background: asset-url(close-black) no-repeat 50% 50%;
						}
					}
				}
			}
		}
	}
	.detail_comments{
		h4
		{
			padding: 0 15px
		}
	}
	#tooltip_onboard {
		#tooltip_onboard_el {
			.mobile_post_action {
				position: fixed;
				z-index: 8;
				background: #FFF;
				box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.16);
				width: 100%;
				height: 48px;
				left: 0;
				bottom: 0;
				overflow: hidden;
				#share, #change_bookmark {
					line-height: 48px;
					height: 48px;
					margin: 0;
					button {
						line-height: 48px;
						height: 48px;
						font-size: 12px;
						padding-left: 28px;
						&:before {
							width: 20px;
							height: 20px;
							background-size: 20px 20px;
							margin-top: -10px;
						}
					}
				}
				#share {
					float: left;
				}
				#change_bookmark {
					float: right;
				}
			}
			#complaint {
				text-align: center;
				form {
					button {
						border: 0 none;
						display: block;
						height: 50px;
						@include rounded(0);
						border: 0 none;
						border-top: 1px solid #f4f4f4;
						background: #FFF;
						width: 100%;
						line-height: 50px;
						&:hover {
							background: #FFF;
							color: $link_dark;
						}
					}
				}
			}
			.author_onboard.bl_header {
				display: block;
				width: 100%;
				padding: 0 0 0 40px;
				.avatar {
					width: 32px;
				    height: 32px;
				    line-height: 32px;
				    font-size: 13px;
				}
				.size15 {
					font-size: 12px;
    				color: #000 !important;
				}
				.badge {
					margin-left: 8px;
				}
			}
			.comment_onboard, .rating_onboard, .coin_onboard {
				line-height: 20px;
    			font-size: 12px;
    			.icon {
    				background-size: 20px 20px;
    				margin-right: 3px;
    			}
			}
			#article_rating {
				padding: 16px 0 0;
				text-align: left;
				height: auto;
				line-height: 1;
				word-spacing: normal;
				span {
					display: block;
					font-size: 12px;
					line-height: 18px;
					font-weight: 600;

				}
				.br-wrapper {
					display: block;
					width: 100%;
					padding-top: 16px;
					text-align: center;
					max-width: 380px;
					margin: 0 auto;
					.br-widget {
						display: block;
						width: 100%;
						overflow: hidden;
						a {
							float: left;
							width: 20%;
							height: 32px;
							margin: 0;
							&:before, &:after {
								background-size: 32px 32px;
								background-position: 50% 50%;
							}
						}
						.br-current-rating {
							display: none !important;
						}
					}
				}
			}
		}
	}
	#tooltip_onboard_content {
		h4 {
			font-size: 16px;
		}
		p {
			font-size: 14px;
			line-height: 1.4;
			margin-bottom: 18px;
		}
	}
	.filter_ {
		a {
			font-size: 14px;
			& + a {
				margin-left: 16px;
			}
		}
		#search_product_form {
			width: 100%;
			margin-top: 16px;
			label, input {
				font-size: 14px !important;
			}
		}
	}
}


@media (max-width: 767px) {
  .title-wrap {
    height: 145px;
    overflow-wrap: break-word;
    white-space: normal;
  }
}

.ellip {
  display: inline;
  height: 100%;
}

.ellip-line {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  max-width: 100%;
}

.ellip,
.ellip-line {
  position: relative;
  overflow: hidden;
}


.embed-responsive{
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &:before{
    display: block;
    content: "";
    padding-top: 56.25%;
  }
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}


.toggle_video_size {
	position: relative;
	height: auto;
	padding: 0;
	width: 750px;
	margin: 0 auto 24px;
	.toggle_video_size_inner {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 0;
		padding: 0 0 56.25%;
	}
	&.middle {
		width: 1140px !important;
		.toggle_video_size_inner {
			//padding: 0 0 44%;
		}
	}
	&.big {
		width: 100% !important;
		.toggle_video_size_inner {
			//padding: 0 0 36%;
		}
	}

	iframe, object, embed, video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
	}
	.dropzone {
		width: 100% !important;
		height: 100% !important;
		position: absolute;
		top: 0;
		left: 0;
		padding-top: 58px !important;
		padding-bottom: 0 !important;
		z-index: 1;
		.dz-message {
			&:before {
				background-image: asset-url(placeholders-video) !important;
			}
		}
		a.remove_video {
			text-indent: -9999em;
			color: transparent;
			display: block;
			text-decoration: none;
			position: absolute;
			z-index: 90;
			width: 40px;
			height: 40px;
			@include rounded(50%);
			background: rgba(0, 0, 0, 0.54) asset-url(close-white) no-repeat 50% 50%;
			box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
			cursor: pointer;
			position: absolute;
			top: 16px;
			right: 16px;
			z-index: 100;
			&:hover {
				background-color: $link;
			}
		}
	}
	.video_insert_link {
		position: absolute;
		width: auto;
		left: 2px;
		right: 2px;
		top: 230px;
		text-align: center;
		z-index: 10;
		.form_item {
			width: 100%;
			padding: 0;
			&:before {
				display: none;
			}
			label, input {
				text-align: center;
				font-size: 15px;
				width: 100%;
			}
			label {
				top: 0;
				left: 0;
				color: #c1c1c1;
			}
			input {
				color: #212121;
			}
		}
	}
	.remove_exist_video {
		a.remove_video{
			text-indent: -9999em;
			color: transparent;
			display: block;
			text-decoration: none;
			position: absolute;
			z-index: 90;
			width: 40px;
			height: 40px;
			@include rounded(50%);
			background: rgba(0, 0, 0, 0.54) asset-url(close-white) no-repeat 50% 50%;
			box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
			cursor: pointer;
			position: absolute;
			top: 16px;
			right: 16px;
			z-index: 100;
			&:hover {
				background-color: $link;
			}
		}
	}
}


.detail_edit_question_data {
  &_item {
    @include rounded(4px);
    background: #f5f5f5;
    padding: 24px;
    width: 100%;
    overflow: hidden;
    span {
      display: block;
      float: left;
      line-height: 24px;
      color: #000;
      font-size: 15px;
      width: 60%;
    }
    & > div {
      float: right;
      width: 40%;
      line-height: 24px;
      text-align: right;
      padding-right: 30px;
      position: relative;
      color: $link;
      font-size: 15px;
      &:before {
        position: absolute;
        content: '';
        width: 24px;
        height: 24px;
        top: 0;
        right: 0;
        background: asset-url(sbercoints-new) no-repeat 50% 50%;
        background-size: 24px 24px;
      }
    }
  }
}

@media(max-width: 991px){
	.comment__align--left {
		left: 0;
		width: 27%;
		padding-right: 0;
		text-align: left !important;
	}

	.comment__align--right{
		width: 73%;
		padding-left: 0;
	}
}

.profile-comment {

  .bl_header {
    margin-bottom:  18px;
  }

  .bl_comments {
    margin-top: 15px;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
  }

  .bl_footer {
    margin-top: 21px;
  }
}

.bl_comments_user_name{
  text-decoration: none;
  color: inherit;
}

#articles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bl_img {
  position: relative;
  height: 285px;
  overflow: hidden;
  border-radius: 4px;
}

.bl_img:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.6);
}

.bl_more-link {
  padding: 10px 21px;
  color: #212121;
  font-size: 15px;
  background-color: #fff;
  border-radius: 4px;
  text-decoration: none;
}

.s-button {
  display: inline-flex;
  margin-left: 10px;
  padding: 7px 15px;
  color: #C1C1C1;
  font-size: 12px;
  font-weight: 600;
  background-repeat: no-repeat;
  background-position: center left 16px;
  background-size: 19px;
  border: 1px solid #EFEFEF;
  border-radius: 4px;
  text-decoration: none;
}

.s-button svg {
  width: 18px;
  margin-right: 12px;

  path {
    transition: fill 0.3s;
  }
}

.s-button--dislike:hover {
  color: #212121;

  svg path {
    fill: #212121;
  }
}

.s-button--like:hover {
  color: $link;

  svg path {
    fill: $link;
  }
}

.icon-repost {
  background-image: url('img/share.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.ptop {
  padding-top: 5px;
}

.comments-link svg path {
  transition: stroke 0.3s;
}

.comments-link {
  display: inline-flex;
  align-items: center;

  &:hover {
    color: #212121;

    svg path {
      stroke: #212121;
    }
  }
}

.icon-comments {
  width: 20px;
  margin-right: 10px;
}

.bl_favorite {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  width: 13px;
  height: 19px;
  padding: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  box-sizing: content-box;

  svg {
    width: 100%;
  }

  svg path {
    transition: opacity 0.3s;
  }

  &:hover svg path {
    opacity: 1;
  }
}

.bl_favorite--top {
  position: static;
  margin-right: 8px;
  margin-left: auto;
  svg {
    width: 12px;
    height: 17px;
    path {
      stroke: #C1C1C1;
      opacity: 1;
    }
  }
  &:hover {
    stroke: #212121;

    svg path {
      stroke: #212121;
    }
  }
}

.emotions {
  display: flex;
  justify-content: flex-end;
}
