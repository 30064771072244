@import "~common";

.dropzone {
	width: 100% !important;
	padding: 148px 30px !important;
	border: 2px dotted #d0d0d0 !important;
	background-color: #FFF !important;
	position: relative;
	@include rounded(3px);
	z-index: 1;
	min-height: 100px;
	input[type=file] {
		display: none;
		opacity: 0;
		visibility: hidden;
	}
	.dz-message {
		text-align: center;
		padding-top: 76px;
		position: relative;
		margin: 0 auto !important;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			margin-left: -30px;
			width: 60px;
			height: 60px;
			background: asset-url(placeholders-image) no-repeat 50% 50%;
		}
		span {
			display: block;
			text-align: center;
			font-size: 15px;
			color: #c1c1c1;
			font-weight: 600;
			font {
				display: block;
				text-align: center;
				font-size: 15px;
				font-weight: 600;
				color: #c1c1c1;
				line-height: 20px;
				margin-bottom: 8px;
			}
			em {
				font-style: normal;
				display: block;
				text-align: center;
				font-size: 12px;
				line-height: 1.2;
				font-weight: 400;
				color: #c1c1c1;
				& + em {
					margin-top: 8px;
				}
			}
			button {
				height: 20px;
				line-height: 20px;
				outline: none;
				border: 0 none;
				background: transparent;
				color: transparent;
				font-size: 15px;
				font-weight: 400;
				cursor: pointer;
				position: absolute;
				width: 100%;
				display: block;
				text-align: center;
				left: 0;
				bottom: -56px;
				transition: all .2s;
			}
		}
	}
	&:hover {
		border: 2px solid $link !important;
		z-index: 5;
		.dz-message {
			button {
				color: $link;
			}
		}
	}
	&.dz-started {
		padding: 0 !important;
		border: 0 none !important;
    min-height: 200px !important;
		.dz-message{
			display: none;
		}
		.dz-preview-custom {
			display: block !important;
			width: 100%;
			margin: 0 !important;
			position: relative;
			z-index: 1;
			min-height: 100px;
			max-height: 810px;
			.dz-image {
				display: block !important;
				width: 100% !important;
				height: auto !important;
				@include rounded(0);
				img {
					width: 100%;
					height: auto;
				}
			}
			.dz-progress {
				height: 126px;
				width: 100%;
				left: 0;
				top: 50%;
				background: transparent !important;
				margin-top: -63px;
				transition: all .3s;
				-webkit-transition: all .3s;
				-moz-transition: all .3s;
				position: absolute;
				span.dz-upload {
					position: absolute;
					width: 60px !important;
					height: 60px;
					border: 2px solid $link;
					z-index: 1;
					background-color: $link;
					background-image: none;
					top: 50%;
					left: 50%;
					@include rounded(50%);
					margin: -30px 0 0 -30px;
					display: block;
					&:before {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						display: block;
						@include rounded(50%);
						background: #FFF;
						z-index: 2;
						width: 48px;
						height: 48px;
						margin: -24px 0 0 -24px;

					}
				}
				.dz-filename {
					position: absolute;
					width: 100%;
					top: 50%;
					left: 0;
					top: 50%;
					margin-top: 46px;
					line-height: 20px;
					text-align: center;
					span {
						display: block;
						text-align: center;
						font-size: 14px;
						line-height: 20px;
						color: $link;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
				.dz-upload-progress {
					width: 100%;
					position: absolute;
					left: 0;
					top: 50%;
					margin-top: 68px;
					line-height: 18px;
					font-size: 12px;
					color: #a0a0a0;
					text-align: center;
					display: block;
				}
			}
			.dz-success-mark, .dz-error-mark {
				width: 50px;
				height: 50px;
				position: absolute;
				left: 50%;
				top: 50%;
				margin: -25px 0 0 -25px;
				background-size: 38px 38px;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				display: none;
				z-index: 7;
				transition: all .3s;
				-webkit-transition: all .3s;
				-moz-transition: all .3s;
				span {
					display: none;
				}
			}
			.dz-success-mark {
				background-image: asset-url(icons-subscription-green);
			}
			.dz-error-mark {
				background-image: asset-url(close-red);
			}
			&.dz-error {
				.dz-error-mark {
					display: block;
					&:hover {
						& + .dz-error-message {
							display: block !important;
						}
					}
				}
				.dz-error-message {
					position: fixed;
					left: 0;
					top: 0;
					width: 100%;
					padding: 12px 24px;
					background: #b60000;
					color: #fff;
					font-size: 13px;
					line-height: 17px;
					display: none;
					z-index: 1000;
					max-height: 17px * 4 + 24;
					overflow: hidden;
				}
				.dz-progress {
					span.dz-upload {
						border-color: #b60000;
						background-color: #b60000;
						background-image: none !important;
					}
					.dz-filename span {
						color: #b60000;
					}
				}
			}
			&.dz-success {
				.dz-success-mark {
					display: block;
				}
			}
			&.dz-complete.dz-success {
				.dz-progress, .dz-success-mark, .dz-error-mark {
					-moz-animation: cssAnimation 0s ease-in 2s forwards;
			    	-webkit-animation: cssAnimation 0s ease-in 2s forwards;
			    	-o-animation: cssAnimation 0s ease-in 2s forwards;
			    	animation: cssAnimation 0s ease-in 2s forwards;
			    	-webkit-animation-fill-mode: forwards;
			    	animation-fill-mode: forwards;
			    	animation-duration:0.7s;
					-o-animation-duration:0.7s;
					-ms-animation-duration:0.7s;
					-webkit-animation-duration:0.7s;
					-moz-animation-duration:0.7s;
				}
			}
		}
	}
	&.grid_dropzone {
		border-bottom-color: transparent;
	}
	&.grid_dropzone_third {
		float: left;
		padding: 0 !important;
		width: 33.333333% !important;
		height: 188px;
		margin-top: -2px !important;
		margin-bottom: 24px;
		border-right-color: transparent !important;
		position: relative;
		@include rounded(0);
		&.dz-started {
			padding: 0 !important;
			border: 0 none !important;
      min-height: 200px !important;
		}
		&.last {
			border-right-color: #d0d0d0 !important;
		}
		&:hover {
			border-style: solid;
			border-color: $link !important;
		}
		.dz-image-preview {
			overflow: hidden;
			height: 188px;
		}
		.dz-message {
			padding-top: 36px;
			&:before {
				display: none;
			}
			span {
				position: relative;
				width: 100%;
				overflow: hidden;
				height: 92px;
				&:before {
					content: '';
					display: block;
					width: 60px;
					height: 60px;
					background: asset-url(placeholders-image) no-repeat 50% 50%;
					margin: 20px auto 12px;
					transition: all .2s;
					-webkit-transition: all .2s;
					-moz-transition: all .2s;
				}
				font, em {
					display: none
				}
				button {
					position: relative;
					bottom: auto;
					height: 20px;
					line-height: 20px;
					color: $link;
				}
			}
		}
		a.remove_img {
			display: none;
			width: 24px;
			height: 24px;
			visibility: hidden;
			opacity: 0;
			position: absolute;
			background: asset-url(delete-little) no-repeat 50% 50%;
		}
		&:hover {
			a.remove_img {
				opacity: 1;
				visibility: visible;
			}
			.dz-message {
				span:before {
					margin-top: 0;
				}
			}
		}
	}
	.dz-fallback {
		text-align: center;
		input[type=file] {
			display: inline-block;
			opacity: 1;
			visibility: visible;
			margin-top: 16px;
		}
		input[type=submit] {
			display: none;
		}
	}
}
