@import "~common";

.edit-settings {
  margin-top: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06); }

.edit-settings__head {
  padding: 22px 25px;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid #F0F0F0;

  @include max-w(480) {
    padding: 16px 15px; } }

.edit-settings__main {
  padding: 25px;

  @include max-w(480) {
    padding: 19px 15px; } }

.edit-settings__group {

  & + & {
    margin-top: 25px;

    @include max-w(480) {
      margin-top: 20px; } } }

.edit-settings__group-title {
  margin-bottom: 18px;
  font-size: 15px;
  font-weight: 700;
  color: #000; }

.edit-settings__group-items {

  & + & {
    margin-top: 15px; } }
