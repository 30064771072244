@import '~common';

.dashboard {
  padding-bottom: 100px; }

.dashboard__container {
  margin-top: -15px; }

.dashboard__container {

  @include max-w(480) {
    padding: 0;
    margin: 0; } }

.dashboard__competition {
  margin: 30px 0;

  @media (max-width: 768px) {
    margin: 14px 0; } }

.create-popup {
  position: relative;
  top: 15%;
  max-width: 780px;
  margin: 0 auto;
  background-color: #FFFFFF;

  @media (max-width: 700px) {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center; } }

.create-popup__close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  font-family: $font;
  font-size: 50px;
  font-weight: 300;
  line-height: 0.55;
  color: #C2C2C2;
  cursor: pointer;


  &:active {
    top: 30px; }

  @include max-w(520) {
    top: 10px;
    right: 10px;

    &:active {
      top: 10px; } } }

.create-popup__body {
  position: relative;
  max-width: 510px;
  margin: 0 auto;
  padding: 0 15px;
  padding-top: 55px;
  padding-bottom: 55px; }

.create-popup__title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin-bottom: 15px;

  @include max-w(520) {
    font-size: 20px;
    line-height: 27px; } }

.create-popup__descr {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  text-align: center;
  margin-bottom: 20px;

  @include max-w(520) {
    margin-bottom: 15px; } }

.create-popup__box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.create-popup__box-elem {
  position: relative;
  width: 50%;
  padding: 40px 0;
  text-align: center;
  text-decoration: none;
  transition: 0.3s background-color;

  @include max-w(520) {
    padding: 15px 0; }

  &:hover {
    background-color: #F3F3F3; }

  &:last-child:after {
    display: none; }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 40px;
    right: -1px;
    bottom: 40px;
    width: 1px;
    background-color: rgba(#000, 0.06);

    .create-popup__box:hover & {
      opacity: 0; }

    @include max-w(520) {
      display: none; } } }

.create-popup__img {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto;

  @include max-w(520) {
    width: 90px;
    height: 90px; } }

.create-popup__img img {
  width: 100%;
  height: 100%; }

.create-popup__img-descr {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 15px;

  @include max-w(520) {
    font-size: 13px;
    line-height: 20px; } }

