.ModalQuizPub {

  .ModalWindow__Main {
    padding: 50px;

    @media (max-width: 480px) {
      padding: 50px 20px; } }

  .ModalWindow__Title {
    margin-bottom: 10px;
    text-align: center; }

  .ReactModal__Content--Custom {
    max-width: 620px;
    padding-left: 0;
    padding-right: 0; }

  .ModalWindow__Close {
    top: 20px;
    right: 20px; } }

.ModalQuizPub__Actions {
  display: flex;
  margin: 25px -5px 0;
  flex-wrap: wrap; }

.ModalQuizPub__Action {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 5px; }

.ModalQuizPub__Content {
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #000000; }



