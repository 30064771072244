@import "~common";

.DocsUploader {}

.DocsUploader__ProgressText {
  font-size: 12px;
  line-height: 16px;
  color: #A0A0A0; }

.DocsUploader__Progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.DocsUploader__Requirements {
  position: relative;
  background-color: #FAFAFA;
  padding: 25px 30px;
  font-size: 15px;
  line-height: (24 / 15);
  color: #212121;
  border: 1px dashed #D0D0D0;
  border-radius: 4px; }

.DocsUploader__RequirementsInner {

  > ul {
    padding: 0;
    margin: 0;
    margin-top: 15px;
    list-style: none;

    > li {
      position: relative;
      padding-left: 30px;

      + li {
        margin-top: 10px; }

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 10px;
        top: 10px;
        width: 6px;
        height: 6px;
        background-color: $color-main;
        border-radius: 50%; } } } }

.DocsUploader__Btn {
  position: relative;
  margin-top: 25px;

  > input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0.01; } }

.Docs {
  position: relative;
  max-width: 740px;
  margin: 0 auto;
  user-select: none; }

.Docs__BtnChange {
  position: absolute;
  top: 20px;
  right: 20px; }
