@import "~common";

//PAGINATION
.pagination {
	padding: 48px 0;
	width: 100%;
	text-align: center;
	white-space: nowrap;
	vertical-align: top;
	span, em, a {
		display: inline-block;
		zoom: 1;
		*display: inline;
		font-style: normal;
		height: 36px;
		line-height: 36px;
		font-size: 15px;
		text-align: center;
		text-decoration: none;
		padding: 0 7px;
		vertical-align: top;
		&.current {
			color: #000;
			pointer-events: none;
			cursor: default;
		}
	}
	em {
		color: #000;
	}
	a {
		color: #a0a0a0;
		&:hover {
			color: $link_dark;
		}
	}
	.previous_page, .next_page {
		width: 36px;
		height: 36px;
		color: transparent;
		text-indent: -9999em;
		background-color: #ffffff;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
		-webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
		-moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
		border: solid 1px rgba(0, 0, 0, 0.09);
		@include rounded(2px);
		overflow: hidden;
		padding: 0;
		&.disabled {
			opacity: .5;
			cursor: default;
		}
	}
	.previous_page {
		background-image: asset-url(paginator-previous);
		margin-right: 7px;
		margin-left: 0;

	}
	.next_page {
		background-image: asset-url(paginator-next);
		margin-right: 0;
		margin-left: 7px;
	}
}

