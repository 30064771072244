@import "~common";

.articles {
  position: relative;
}

.articles__head {
  display: flex;
}

.articles__info-wrap {
  display: flex;
  flex-grow: 1;
  min-width: 0;

  @include max-w(585) {
    flex-direction: column-reverse;
  }
}

.articles__elem {
  position: relative;
  width: 100%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.articles__elem-main {
  padding: 24px;
}

.articles__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  min-width: 0;
}


.articles__img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  background: #f2f2f2;

  @include max-w(585) {
    margin-right: 10px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
}


.articles__info-title {
  margin-bottom: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #212121;
  text-decoration: none;

  @include max-w(585) {
    margin-bottom: 0;
  }
}

.articles__author-wrap {
  display: flex;
}

.articles__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.articles__author {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #212121;
  margin-right: 0.5em;
  text-decoration: none;
}

.articles__date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #909090;

  &:before {
    content: "•";
    display: inline-block;
    margin-right: 0.5em;
  }
}

.articles__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include max-w(585) {
    margin-bottom: 4px;
  }
}

.articles__states {
  display: flex;
}

.articles__label {
  height: 20px;
  padding: 3px 6px;
  margin-right: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: (14 / 10);
  text-transform: uppercase;
  color: #212121;
  background-color: rgba(193, 193, 193, 0.2);
  border-radius: 2px;

  @include max-w(585) {
    height: 16px;
    padding: 2px 5px;
    font-size: 8px;
  }
}

.articles__label--cancel {
  color: #ff2b00;
  background-color: rgba(242, 38, 19, 0.2);
}
.articles__label--progress {
  color: #212121;
  background-color: #C1C1C1;;
}

.articles__body {
  width: 100%;
  margin-top: 15px;
}

.articles__title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #212121;
  text-decoration: none;
}

.articles__title--size {
  font-size: 24px;
}

.articles__tooltip {
  position: absolute;
  top: 60px;
  left: 615px;
  width: 175px;
  display: none;
  background: #FFFFFF;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
  z-index: 2;
  padding: 10px 0 20px 0;

  @media (max-width: 500px) {
    left: 53%;
  }

  @media (max-width: 320px) {
    left: 45%;
  }

  &::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    transform: rotate(45deg);
    top: -10px;
    left: 75px;

    @media (max-width: 500px) {
      left: 125px;
    }
  }
}

.articles__show {
  display: block !important;
}

.articles__success {
  float: left;
  width: 100%;
  text-decoration: none;
  margin-left: 20px;
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #A5A5A5;

  &:hover {
    color: #389A52;
  }
}

.articles__fail {
  float: left;
  width: 100%;
  text-decoration: none;
  margin-left: 20px;
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #A5A5A5;

  &:hover {
    color: #F0443D;
  }
}

.articles__comment {
  position: relative;
  display: none;
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #E5E5E5;
}

.articles__area {
  width: 100%;
  margin-top: 20px;
  resize: none;
  border: none;
  height: 70px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  color: #C1C1C1;
}

.articles__submit {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  background: #F0443D;
  border-radius: 4px;
  border: none;
  padding: 8px 12px 8px 12px;

  @media (max-width: 500px) {
    width: 100%;
  }
}

.articles__refused {
  border: none;
  background: transparent;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #C1C1C1;
  margin-left: 15px;

  @media (max-width: 500px) {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }
}

.articles__answer {
  width: 100%;
  padding: 24px;
  border-top: 1px solid #E5E5E5;
}

.articles__moderate {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #A8A8A8;
}

.articles__answer-descr {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: rgba(33, 33, 33, 0.85);
  margin-top: 7px;
}

.articles__waiting {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  color: #C1C1C1;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

.articles__waiting-descr {
  margin-bottom: 120px;

  @media (max-width: 767px) {
    margin-bottom: 60px;
  }
}

.articles__waiting-title {
  margin-bottom: 5px;
}

.articles__waiting-img {
  margin-top: 160px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-top: 60px;
  }
}

.row--flex {
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}

.col--flex {
  position: relative;
  @media (max-width: 767px) {
    order: -1;
  }
}

.left_menu {
  display: block;
  @media (max-width: 767px) {
    margin-top: -20px !important;
    display: none;
    position: absolute;
    width: calc(100% - 30px);
  }
}

.rotate {
  transform: rotate(180deg);
}

//.filter--menu {
//  @media (max-width: 767px) {
//    margin-top: 20px;
//  }
//
//}

.user_status {
  display: inline-block;
  color: #389A52;
  font-size: 12px;
}


