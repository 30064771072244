.OrDivider {
  display: flex;
  align-items: center;
  margin: 20px 0 10px;

  &:before,
  &:after {
    content: "";
    display: block;
    flex-grow: 1;
    height: 1px;
    min-width: 0;
    background: rgba(#000000, 0.06); } }

.OrDivider__Title {
  padding: 0 8px 4px;
  font-size: 15px;
  color: #A1A1A1;
  text-transform: lowercase;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 4px; }
