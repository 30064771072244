@import "~common";

//ERRORS
body#errors {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	#page_error {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		&_wrap {
			display: table;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
		&_left, &_right {
			display: table-cell;
			height: 100%;
			padding: 0 15px;
			vertical-align: middle;
			overflow: hidden;
		}
		&_left {
			width: 40%;
			#page_error_left_inner {
				width: 100%;
				max-width: 458px;
				float: right;
				text-align: left;
				#logo_gray {
					display: block;
					color: transparent;
					text-indent: -9999em;
					overflow: hidden;
					width: 111px;
					height: 35px;
					margin: 0 0 16px;
					background: url("/logo-insberra-mobile-gray.svg") no-repeat 0% 50%;
					background-size: contain;
				}
				h1 {
					font-size: 72px;
					line-height: 80px;
					white-space: nowrap;
					color: #000;
					font-weight: 600;
					padding: 0;
					margin: 0;
					text-indent: -5px;
				}
				p {
					font-size: 18px;
					font-weight: 700;
					color: #000;
					margin: 2px 0 0;
					padding: 0;
				}
				span {
					display: block;
					margin-top: 32px;
					font-size: 15px;
					a {
						color: $link;
						text-decoration: none;
						&:hover {
							color: $link_dark;
						}
					}
				}
			}
		}
		&_right {
			text-align: left;
			width: 60%;
			background: url("/placeholder-error.svg") no-repeat 100% 50%;
			background-size: cover;
		}
	}
}
