.infinity-scroll {
  height: 100px;
  position: relative;

  > .pagination {
    display: none; } }

.infinity-preloader {
  display: block;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  transform: rotate(-90deg);

  circle {
    stroke-dasharray: 170;
    stroke-dashoffset: 170;
    animation: {
      name: circle-preload;
      duration: 2s;
      iteration-count: infinite; } } }

@keyframes circle-preload {
  0% {
    stroke-dashoffset: 170; }
  100% {
    stroke-dashoffset: -170; } }
