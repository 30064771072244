@import "~common";
.about-leaderboard {
  background: #fff;
  padding: 25px;
  padding-top: 25px;
  //border: 1px solid rgba(0, 0, 0, 0.06)
  border-radius: 4px;
  @include max-w(992) {
    display: none;
    //display: flex
    //justify-content: center
 }    //text-align: center
  //+max-w(700)


  .user-position & {
    border: none;
    border-radius: 0; } }

.about-leaderboard__title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 17px; }
.about-leaderboard__description {
  max-width: 300px;
  margin-top: 17px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  color: #A0A0A0;
  @include max-w(992) {
    max-width: 500px; } }
.about-leaderboard__how-take {
  margin-top: 18px;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  //+max-w(992)
 }  //  flex-basis: 50%
.about-leaderboard__how-take p {
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #A0A0A0;
  text-decoration: none;
  display: flex;
  flex-direction: column; }
.about-leaderboard__how-take a {
  &:hover {
    color: #389a52; } }

.about-leaderboard__how-take-links {
  margin-top: 22px; }
.about-leaderboard__how-take-links a {
  text-align: center;
  width: 100%;
  height: 50px;
  line-height: 44px;
  @include max-w(992) {
    width: 37%; } }

