
@font-face {
  font-family: 'icomoon';
  src: url('icomoon.eot');
  src: url('icomoon.eot?#iefix') format('embedded-opentype'), url('icomoon.woff') format('woff'), url('icomoon.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.icon-ex {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: inherit;
	color: inherit;
	display: inline-block;
	font-size: inherit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:before {
		font-family: 'icomoon';
		content: "\e609";
	}
	&.icon-faq {
		&:before {
			content: '\e603';
		}
	}
	&.icon-admin {
		&:before {
			content: '\e604';
		}
	}
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./opensans/OpenSans-Light.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./opensans/OpenSansRegular.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./opensans/OpenSansSemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./opensans/OpenSansBold.woff') format('woff');
}


// Зачем это все, да еще и woff2 который в IE11 не работает

///* cyrillic-ext */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 300;
//  src: local('Open Sans Light'), local('OpenSans-Light'), url('/fonts_folder/googleapis/DXI1ORHCpsQm3Vp6mXoaTa-j2U0lmluP9RWlSytm3ho.woff2') format('woff2');
//  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
//}
///* cyrillic */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 300;
//  src: local('Open Sans Light'), local('OpenSans-Light'), url('/fonts_folder/googleapis/DXI1ORHCpsQm3Vp6mXoaTZX5f-9o1vgP2EXwfjgl7AY.woff2') format('woff2');
//  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//}
///* greek-ext */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 300;
//  src: local('Open Sans Light'), local('OpenSans-Light'), url('/fonts_folder/googleapis/DXI1ORHCpsQm3Vp6mXoaTRWV49_lSm1NYrwo-zkhivY.woff2') format('woff2');
//  unicode-range: U+1F00-1FFF;
//}
///* greek */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 300;
//  src: local('Open Sans Light'), local('OpenSans-Light'), url('/fonts_folder/googleapis/DXI1ORHCpsQm3Vp6mXoaTaaRobkAwv3vxw3jMhVENGA.woff2') format('woff2');
//  unicode-range: U+0370-03FF;
//}
///* vietnamese */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 300;
//  src: local('Open Sans Light'), local('OpenSans-Light'), url('/fonts_folder/googleapis/DXI1ORHCpsQm3Vp6mXoaTf8zf_FOSsgRmwsS7Aa9k2w.woff2') format('woff2');
//  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
//}
///* latin-ext */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 300;
//  src: local('Open Sans Light'), local('OpenSans-Light'), url('/fonts_folder/googleapis/DXI1ORHCpsQm3Vp6mXoaTT0LW-43aMEzIO6XUTLjad8.woff2') format('woff2');
//  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
//}
///* latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 300;
//  src: local('Open Sans Light'), local('OpenSans-Light'), url('/fonts_folder/googleapis/DXI1ORHCpsQm3Vp6mXoaTegdm0LZdjqr5-oayXSOefg.woff2') format('woff2');
//  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
//}
///* cyrillic-ext */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 400;
//  src: local('Open Sans'), local('OpenSans'), url('/fonts_folder/googleapis/K88pR3goAWT7BTt32Z01mxJtnKITppOI_IvcXXDNrsc.woff2') format('woff2');
//  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
//}
///* cyrillic */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 400;
//  src: local('Open Sans'), local('OpenSans'), url('/fonts_folder/googleapis/RjgO7rYTmqiVp7vzi-Q5URJtnKITppOI_IvcXXDNrsc.woff2') format('woff2');
//  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//}
///* greek-ext */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 400;
//  src: local('Open Sans'), local('OpenSans'), url('/fonts_folder/googleapis/LWCjsQkB6EMdfHrEVqA1KRJtnKITppOI_IvcXXDNrsc.woff2') format('woff2');
//  unicode-range: U+1F00-1FFF;
//}
///* greek */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 400;
//  src: local('Open Sans'), local('OpenSans'), url('/fonts_folder/googleapis/xozscpT2726on7jbcb_pAhJtnKITppOI_IvcXXDNrsc.woff2') format('woff2');
//  unicode-range: U+0370-03FF;
//}
///* vietnamese */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 400;
//  src: local('Open Sans'), local('OpenSans'), url('/fonts_folder/googleapis/59ZRklaO5bWGqF5A9baEERJtnKITppOI_IvcXXDNrsc.woff2') format('woff2');
//  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
//}
///* latin-ext */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 400;
//  src: local('Open Sans'), local('OpenSans'), url('/fonts_folder/googleapis/u-WUoqrET9fUeobQW7jkRRJtnKITppOI_IvcXXDNrsc.woff2') format('woff2');
//  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
//}
///* latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 400;
//  src: local('Open Sans'), local('OpenSans'), url('/fonts_folder/googleapis/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2') format('woff2');
//  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
//}
///* cyrillic-ext */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 600;
//  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url('/fonts_folder/googleapis/MTP_ySUJH_bn48VBG8sNSq-j2U0lmluP9RWlSytm3ho.woff2') format('woff2');
//  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
//}
///* cyrillic */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 600;
//  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url('/fonts_folder/googleapis/MTP_ySUJH_bn48VBG8sNSpX5f-9o1vgP2EXwfjgl7AY.woff2') format('woff2');
//  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//}
///* greek-ext */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 600;
//  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url('/fonts_folder/googleapis/MTP_ySUJH_bn48VBG8sNShWV49_lSm1NYrwo-zkhivY.woff2') format('woff2');
//  unicode-range: U+1F00-1FFF;
//}
///* greek */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 600;
//  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url('/fonts_folder/googleapis/MTP_ySUJH_bn48VBG8sNSqaRobkAwv3vxw3jMhVENGA.woff2') format('woff2');
//  unicode-range: U+0370-03FF;
//}
///* vietnamese */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 600;
//  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url('/fonts_folder/googleapis/MTP_ySUJH_bn48VBG8sNSv8zf_FOSsgRmwsS7Aa9k2w.woff2') format('woff2');
//  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
//}
///* latin-ext */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 600;
//  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url('/fonts_folder/googleapis/MTP_ySUJH_bn48VBG8sNSj0LW-43aMEzIO6XUTLjad8.woff2') format('woff2');
//  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
//}
///* latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 600;
//  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url('/fonts_folder/googleapis/MTP_ySUJH_bn48VBG8sNSugdm0LZdjqr5-oayXSOefg.woff2') format('woff2');
//  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
//}
///* cyrillic-ext */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 700;
//  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('/fonts_folder/googleapis/k3k702ZOKiLJc3WVjuplzK-j2U0lmluP9RWlSytm3ho.woff2') format('woff2');
//  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
//}
///* cyrillic */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 700;
//  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('/fonts_folder/googleapis/k3k702ZOKiLJc3WVjuplzJX5f-9o1vgP2EXwfjgl7AY.woff2') format('woff2');
//  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//}
///* greek-ext */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 700;
//  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('/fonts_folder/googleapis/k3k702ZOKiLJc3WVjuplzBWV49_lSm1NYrwo-zkhivY.woff2') format('woff2');
//  unicode-range: U+1F00-1FFF;
//}
///* greek */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 700;
//  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('/fonts_folder/googleapis/k3k702ZOKiLJc3WVjuplzKaRobkAwv3vxw3jMhVENGA.woff2') format('woff2');
//  unicode-range: U+0370-03FF;
//}
///* vietnamese */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 700;
//  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('/fonts_folder/googleapis/k3k702ZOKiLJc3WVjuplzP8zf_FOSsgRmwsS7Aa9k2w.woff2') format('woff2');
//  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
//}
///* latin-ext */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 700;
//  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('/fonts_folder/googleapis/k3k702ZOKiLJc3WVjuplzD0LW-43aMEzIO6XUTLjad8.woff2') format('woff2');
//  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
//}
///* latin */
//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 700;
//  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('/fonts_folder/googleapis/k3k702ZOKiLJc3WVjuplzOgdm0LZdjqr5-oayXSOefg.woff2') format('woff2');
//  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
//}


