@import "~common";

.second-menu {
  background-color: #ffffff;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  position: relative;
  z-index: 1;

  @media (max-width: 992px){
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
}


.second-menu__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
  position: relative;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }

  @media (max-width: 480px){

    &::before,
    &::after{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 65px;
      height: 100%;
      pointer-events: none;
      opacity: 0;
      transition: 0.3s opacity;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
      z-index: 10;
    }

    &::before{
      left: 0;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      transform: rotate(180deg);
    }

    &::after{
      right: 50px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }

    &.has-sh-left:before,
    &.has-sh-right:after{
      opacity: 1;
    }
  }
}

.second-menu__notify {
  position: absolute;
  top: 100%;
  margin-top: 15px;

  @include max-w(480) {
    right: 15px;
    left: 15px;
  }
}

.second-menu__tab {
  position: relative;
  z-index: 5;
  padding: 20px 0 24px;
  margin-right: 38px;
  cursor: pointer;
  color: #212121;
  font-size: 15px;
  line-height: 20px;
  text-decoration: none;
  white-space: nowrap;

  @media (max-width: 992px) {
    margin-right: 18px;
  }

  @media (max-width: 480px){
    font-size: 13px;
    padding: 12px 0 16px;
  }
}

.second-menu__tabs-wrap{

  @media (max-width: 480px){
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    margin-right: 50px;
    padding-left: 15px;


    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #FF0000;
    }

    &::-webkit-scrollbar { width: 0 !important }

    &::after{
      content: '';
      width: 50px;
      height: 100%;
      background-color: #fff;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 12;
      display: none;
    }
  }

  &.second-menu__tabs-wrap--open{
    overflow-x: initial;
    overflow-y: initial;
    width: 100%;
  }
}


.second-menu__tabs-wrap--open.second-menu__tabs-wrap{
  @media (max-width: 480px){
    &::after{
      display: block;
    }
  }
}


.second-menu__tabs-wrap::-webkit-scrollbar {
  display: none;
}

.second-menu__container{

  @media (max-width: 480px){
    padding: 0;
  }
}


.second-menu__tab--active {
  color: #389a52;
  border-bottom: 2px solid #389A52;
}

.second-menu__tab--dropdown {
  margin: 0;
  padding-right: 25px;
}

.second-menu__dropdown {
  display: flex;
  position: relative;
  margin-right: 38px;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top:50%;
    z-index: 1;
    width: 12px;
    height: 7px;
    transform: translateY(-50%);
    transition: 300ms;
    background-image: url('./dropdown.svg');
  }

  @media (max-width: 992px){
    margin-right: 18px;
  }
}

.second-menu__dropdown--active {

  &:after {
    transform: translateY(-50%) rotate(180deg);

    @media (max-width: 480px){
      width: 7px;
      height: 4px;
      background-size: cover;
    }
  }
}

.second-menu__list {
  position: absolute;
  z-index: 5;
  top: 100%;
  left: 0;
  display: none;
  flex-direction: column;
  list-style: none;
  background-color: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    top: -10px;
    right: 20px;
    background-color: #ffffff;
    transform: rotate(45deg);
    height: 20px;
  }
}

.second-menu__item {
  padding: 9px 23px;
}

.second-menu__link {
  color: #212121;;
  font-size: 15px;
  line-height: 21px;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    color: #389A52;
  }

  &.is-active {
    color: #389A52;
    font-weight: 600;
  }
}
.second-menu__select {
  width: 260px;
  margin-right: 10px;

  @media (max-width: 1102px) and (min-width: 991px) {
    width: 180px;
  }

  @media (max-width: 991px){
    width: 100%;
    margin-right: 0;
    margin-top: 15px;
  }
}

.second-menu__tabs {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;

  @media (max-width: 991px){
    display: flex;
  }
}

.select-menu__body {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 991px){
    flex-direction: column;
    width: 100%;
    padding: 15px;
    display: none;
    position: relative;
    z-index: 11;
  }

  @media (min-width: 991px){
    display: flex!important;
  }
}

.select_menu__element {
  flex-shrink: 0;

  @media (max-width: 991px){
    flex-grow: 1;
    width: 100%;
  }
}

.select-menu__clear {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: #F5F5F5;
  border-radius: 4px;

  @media (max-width: 991px){
    margin-top: 10px;
    height: 50px;
  }
}

.select-menu__clear {
  margin-left: 10px;

  svg path {

    &:first-child {
      fill: #C1C1C1;
    }

    &:last-child {
      display: none;
    }
  }

  @media (max-width: 991px){
    margin-left: 0;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 4px;
  }
}

.select-menu__clear--selected {
  svg path {

    &:first-child {
      fill: #212121;
    }

    &:last-child {
      display: inherit;
    }
  }
}

.select-menu__clear-icon {


  @media (max-width: 991px){
    display: none;
  }
}

.search__input-mobile {

  @media (min-width: 991px){
    display: none;
    position: relative;
  }
}


.select-menu__clear-text {
  display: none;

  @media (max-width: 991px){
    display: inherit;
    font-size: 15px;
    color: #A5A5A5;
    padding: 13px 0;
  }
}

.select-menu__options {
  display: none;

  path {
    fill: #A5A5A5;
  }

  @media (max-width: 991px) {
    display: block;
    flex-shrink: 0;
    position: absolute;
    right: 10px;
    top: 22px;
    z-index: 10000;
  }
  @media (max-width: 480px){
    top: 14px;
    right: 10px;
  }
}

.select-menu__options--active {
  path {
    fill: #1c1c1c;
  }
}

.select-one {
  width: auto !important;
}

