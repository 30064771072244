@import "~common";

.info-menu {
  border-bottom: 1px solid #389a52; }

.info-menu__list {
  margin: 0 -10px;
  display: flex;
  list-style: none; }

.info-menu__item {
  display: inline-block; }

.info-menu__link {
  position: relative;
  color: rgba(0, 0, 0, 0.38);
  display: inline-block;
  padding: 10px;
  text-decoration: none;

  &:hover {
    color: #000; }

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 10px;
    left: 10px;
    height: 0;
    background: $link; }

  .is-active & {
    color: #000;

    &:after {
      height: 3px; } } }
