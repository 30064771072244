//.bl_ .bl_footer .article-stat + .article-stat,
//.detail_ .detail_footer .article-stat + .article-stat
//  margin-left: 0

.article-stat {
  display: inline-block;
  margin-right: 29px;
  vertical-align: top;
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  color: #acacac;
  text-decoration: none;

  //@media (max-width: 767px)
  //font-size: 14px
  //line-height: 24px
  //margin-right: 20px

  @media (max-width: 480px) {
    margin-right: 10px; }

  &.green {
    color: #389a52; }

  a {
    color: inherit;
    text-decoration: none; }

  .icon {
    vertical-align: middle;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 8px;

    @media (max-width: 767px) {
      width: 20px;
      height: 20px;
      margin-right: 3px; }

    &.icon-eye-disable {
      background-image: url('./eye-disable.svg'); }
    &.icon-eye {
      background-image: url('./eye-enable.svg'); }
    //&.icon-comments-disable
    //  background-image: url('./comments-disable.svg')
    //&.icon-comments
    //  background-image: url('./comments-enable.svg')
    &.icon-likes-disable {
      background-image: url('./likes-disable.svg'); }
    &.icon-likes {
      background-image: url('./likes-enable.svg'); }
    &.icon-sbercoins-disable {
      background-image: url('./sbercoints-gray.svg'); } } }
