@import "~common";

.article {
  &__bookmark {
    &__link {
      text-decoration: none;
      display: block;
      position: relative;
      height: $form_height;
      line-height: $form_height;
      border: 0 none;
      outline: none;
      background: transparent;
      padding: 0 0 0 32px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      color: #a8a8a8;
      &:before {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        left: 0;
        margin-top: -12px;
        background: asset-url(icon-bookmark) no-repeat 50% 50%; }
      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        visibility: hidden;
        background: asset-url(icon-bookmark-enable) no-repeat 50% 50%; }
      &:hover {
        color: $link;
        &:before {
          background: asset-url(icon-bookmark-enable) no-repeat 50% 50%; } }
      &__remove {
        color: $link;
        &:before {
          background: asset-url(icon-bookmark-active) no-repeat 50% 50%; } } } }
  &__bookmarks {
    display: inline-block;
    position: relative;
    height: $form_height;
    vertical-align: middle;
    margin-left: 40px; } }
