.checkbox_toggle {
  display: inline-block;
  position: relative;
  vertical-align: middle;

  input[type="checkbox"] {
    position: absolute; }

  label {
    position: relative;
    display: block;
    width: 33px;
    height: 20px;
    cursor: pointer;
    background: #ECECEC;
    border-radius: 100px;
    transition: 0.3s background-color;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 4px;
      width: 14px;
      height: 14px;
      margin-top: -7px;
      background: #AAAAAA;
      border-radius: 50%;
      transition: 0.3s all; } }

  input[type="checkbox"]:checked + label {
    background: #389A52;


    &:after {
      left: 15px;
      background: #FFFFFF; } } }

