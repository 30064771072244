@import "~common";

.c-banner-action {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background-color: #fff;

  @include max-w(480) {
    border-radius: 0; } }

.c-banner-action__inner {
  display: flex;
  justify-content: space-between;
  padding: 18px 25px;

  @include max-w(800) {
    flex-direction: column;
    padding: 40px 15px; } }

.c-banner-action__left-side,
.c-banner-action__right-side {
  display: flex;
  position: relative; }

.c-banner-action__left-side {
  margin-right: 40px;

  @include max-w(800) {
    flex-direction: column;
    align-items: center;
    margin: 0 0 15px;
    padding: 0 20px; } }

.c-banner-action__right-side {
  flex-shrink: 0;
  display: flex;
  align-items: center;

  @include max-w(800) {
    justify-content: center; } }

.c-banner-action__description {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include max-w(800) {
    text-align: center; } }

.c-banner-action__logo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 20px;

  @include max-w(800) {
    margin: 0 0 15px 0;
    width: 105px;
    height: 105px; } }

.c-banner-action__logo-img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.c-banner-action__title {
  font-size: 18px;
  line-height: 20px;
  color: #000;
  font-weight: bold;
  margin-bottom: 5px;

  @include max-w(800) {
    font-size: 20px;
    line-height: 26px; } }

.c-banner-action__subtitle {
  font-size: 15px;
  line-height: 19px;
  color: #000;

  @include max-w(800) {
    line-height: 22px; } }

.c-banner-action__add-btn {
  display: inline-block;
  height: 40px;
  line-height: 36px;
  padding-right: 25px;
  padding-left: 25px;
  font-size: 15px;
  color: #fff;
  background: #389A52;
  border-radius: 4px;
  border: 1px solid #389A52;
  text-decoration: none;
  transition: 0.15s background;
  text-align: center;

  &:hover {
    background-color: #308446;
    color: #fff; }

  @include max-w(480) {
    width: 100%;
    height: 50px;
    line-height: 50px; } }
