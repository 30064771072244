@import "~common";

.head-category {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px; }

.head-category__wrap {
  display: flex;
  padding: 22px 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);

  @include max-w(700) {
    flex-direction: column;
    align-items: center; } }

.head-category__avatar {
  width: 85px;
  height: 85px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%; } }

.head-category__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;

  @include max-w(700) {
    align-items: center;
    margin-left: 0;
    width: 100%; } }

.head-category__title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #212121;
  margin-bottom: 8px;
  text-decoration: none;

  @include max-w(700) {
    margin-top: 8px; } }

.head-category__detail {
  display: flex;

  @include max-w(700) {
    flex-direction: column;
    align-items: center;
    width: 100%; } }

.head-category__status {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #999999;
  margin-right: 20px;

  @include max-w(700) {
    margin-bottom: 11px;
    margin-right: 0; } }

.head-category__elem {
  text-decoration: none;
  display: flex;
  margin-right: 20px;

  @include max-w(700) {
    margin-right: 0;
    width: 100%;
    justify-content: center;
    padding: 8px 0;
    border: 1px solid rgba(0, 0, 0, 0.06); } }

.head-category__elem:last-child {
  margin-right: 0; }

.head-category__count {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #308446;
  margin-right: 5px; }

.head-category__descr {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #999999; }

