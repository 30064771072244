@import "~common";

//MARKET
.product_item {
	margin-bottom: 30px;
	.bl_ {
		padding: 0;
		overflow: hidden;
		img {
			display: block;
			width: 100%;
			height: auto;
			margin: 0 0 16px;
			border-bottom: 1px solid #efefef;
		}
		h4 {
			font-size: 18px;
			font-weight: 700;
			line-height: 24px;
			color: #000;
			margin-bottom: 16px;
		}
		.product_tags {
			overflow: hidden;
			span {
				display: block;
				float: left;
				line-height: 18px;
				text-transform: uppercase;
				font-size: 12px;
				color: #a0a0a0;
				& + span {
					&:before {
						content: ', ';
						padding-right: 4px;
					}
				}
			}
		}
		.product_text_equal {
			padding-left: 24px;
			padding-right: 24px;
			margin-bottom: 16px;
		}
		.product_footer {
			margin: 0 24px;
			padding-top: 16px;
			padding-bottom: 16px;
			border-top: 1px solid #efefef;
			form {
				display: inline;
			}
			.product_coins {
				font-size: 14px;
				color: $link;
				font-size: 600;
				line-height: 24px;
				vertical-align: top;
				&:before {
					content: '';
					display: inline-block;
					width: 24px;
					height: 24px;
					margin-right: 8px;
					vertical-align: middle;
					background: asset-url(sbercoints-green) no-repeat 50% 50%;
				}
			}
			.buy_product {
				display: inline-block;
				border: 0 none;
				outline: none;
				background: transparent;
				cursor: pointer;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				color: $link;
				font-size: 15px;
				line-height: 24px;
				height: 24px;
				vertical-align: top;
				&:hover {
					color: $link_dark;
				}
			}
			.buyed {
				display: block;
				text-align: center;
				color: #c1c1c1;
				line-height: 24px;
				font-size: 15px;
				vertical-align: top;
			}
			.published {
				display: inline-block;
				margin-left: 12px;
				width: 24px;
				height: 24px;
				color: transparent;
				text-indent: -9999em;
				background: asset-url(icon-published) no-repeat 50% 50%;
				background-size: 28px 28px;
				vertical-align: top;
				overflow: hidden;
				cursor: default;
			}
			.edit_product {
				display: inline-block;
				width: 24px;
				height: 24px;
				@include rounded(50%);
				border: 0 none;
				outline: none;
				cursor: pointer;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				color: transparent;
				text-indent: -9999em;
				background: #b0b0b0 asset-url(icon-edit) no-repeat 50% 50%;
				background-size: 16px 16px;
				vertical-align: top;
				overflow: hidden;
				&:hover {
					background-color: $link;
				}
			}
		}
	}
}
