.recommendation {
  margin: 0 0 50px;
  background: #fafafa;
  letter-spacing: 0; }

.recommendation__head {
  padding: 21px 25px;
  border-bottom: 1px solid #f0f0f0; }

.recommendation__title,
h4.recommendation__title {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  letter-spacing: 0; }

.recommendation__content {
 }  // padding: 0 25px
