$link: #389a52;
$link_dark: #0b753f;

.presentation {
  width: 100%;
  height: auto;
  background: #FAFAFA;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  position: relative;
  font-size: 0;
  line-height: 0;
  border-radius: 4px;
  overflow: hidden;

  &__pdfjs {
    width: 100%;
    height: 450px;
    display: block;
    margin: 0 auto; }

  &__progress-bar {
    margin: 16px 0;
    width: 100%;
    height: 5px; }
  &__progress-status {
    width: 0;
    height: 5px;
    background: #389a52; }

  &__navigation {
    text-align: center;
    margin: 16px 0;
    padding: 0 70px;
    font-size: 24px;
    line-height: 30px;
    color: #a0a0a0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;

    > * {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      vertical-align: top; } }

  &__button {
    background: transparent;
    border: 0 none;
    outline: none;
    height: 30px;
    width: 40px;
    text-align: center;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    color: transparent;
    text-indent: -9999em;
    cursor: pointer; }

  &__prev {
    background-image: url("prev-arrow.svg"); }
  &__next {
    background-image: url("next-arrow.svg"); }

  &__fullscreen {
    position: absolute;
    top: 0;
    right: 16px;
    display: block;
    background-image: url("ppt-fullscreen.svg");
    .presentation--fullscreen & {
      display: none; } }

  &__fullscreen-exit {
    position: absolute;
    top: 0;
    right: 16px;
    display: none;
    background-image: url("ppt-fullscreen-exit.svg");
    .presentation--fullscreen & {
      display: block; } }

  a {
    position: absolute;
    top: 7px;
    left: 16px;
    display: block;
    height: 20px;
    line-height: 20px;
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    color: $link;
    &:hover {
      color: $link_dark; } }
  &__current-page {
    color: $link; }
  &__total-pages {}

  &__remove {
    display: block;
    position: absolute;
    z-index: 10;
    text-indent: -9999em;
    color: transparent;
    display: block;
    text-decoration: none;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.54) url("close-white.svg") no-repeat 50% 50%;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 100;
    border: 0 none;
    outline: none;
    &:hover {
      background-color: $link; } }

  &--fullscreen {
    .presentation__pdfjs {
      min-height: calc(100vh - 60px); } } }
