@import "~common";

.PopoverMenu {

  .Popover-tip {
    fill: #fff;
    z-index: 1; } }

.PopoverMenu__List {
  padding: 15px 20px;
  list-style: none;
  background: #FFFFFF;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15); }

.PopoverMenu__Item {
  padding: 5px 0;
  font-size: 15px;
  line-height: 21px;
  color: #A5A5A5;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #212121; }

  &--Danger {
    color: $color-error; } }
