@import "~common";

.info {
  padding: 16px 24px;
  background: #fff;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
  display: block !important;

  @include max-w(767) {
    padding-left: 15px;
    padding-right: 15px; } }

.info__head {
  margin-bottom: 15px;
  width: 100%;

  h1 {
    margin-bottom:  15px; } }

.info__content {
  width: 100%; }
