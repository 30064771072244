@import "~common";

.options-menu {
  display: inline-block;
  position: relative;
  vertical-align: middle; }

.options-menu--open {
  z-index: 2; }

.options-menu__btn {
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  color: #C1C1C1;
  cursor: pointer;
  user-select: none;

  &:hover,
  .options-menu--open & {
    color: #212121; }

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2px;
    margin-left: -9px; } }

.options-menu__list {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: 20px;
  background: #FFFFFF;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, 0);

  @include max-w(700) {
    left: auto;
    transform: none;
    right: -32px; }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    background-color: #fff;
    transform: rotate(-45deg);

    @include max-w(700) {
      left: 105px; } }


  .options-menu--open & {
    display: block; }

  > ul {
    padding: 15px 20px;
    margin: 0;
    list-style: none;

    > li > a, span {
      display: inline-block;
      padding: 5px 0;
      color: #A5A5A5;
      font-size: 15px;
      line-height: 21px;
      text-decoration: none;
      cursor: pointer;

      &.danger {
        color: $color-error; }

      &:hover {
        color: $color-main; } } } }
