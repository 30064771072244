.top-menu {
  display: flex;
  position: relative;
  width: 100%;
  background: #FFF;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0 25px;
  margin-bottom: 20px;
  border-radius: 4px;
  overflow-x: auto;

  @media (max-width: 500px) {
    margin-bottom: 0;
  }

  a {
    flex-shrink: 0;
    display: flex;
    position: relative;
    padding: 23px 0;
    margin-right: 25px;
    font-size: 15px;
    font-weight: 400;
    line-height: (24 / 15);
    color: #A0A0A0;
    text-decoration: none;
    white-space: nowrap;
    border: 0 none;
    background: transparent;
    outline: none;
    cursor: pointer;
    overflow: hidden;

    @media (max-width: 500px) {
      font-size: 14px;
      padding: 18px 0;
    }
  }

  span {
    display: block;
    line-height: inherit;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;

    &.right {
      color: #389a52;
      text-align: right;
      font-weight: 400;
      margin-left: 10px;
    }
  }
  .active {
    color: #389a52;
    font-weight: 700;
    border-bottom: 2px solid #389a52;
  }
}
