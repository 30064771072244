.SelectMaterial {
  background: #FAFAFA;
  border-radius: 4px; }

.SelectMaterial__ControlWrap {
  padding: 20px; }

.SelectMaterial__Control {}

.SelectMaterial__ControlInfo {
  margin-top: 10px;
  font-size: 12px;
  line-height: 1;
  color: #A1A1A1;

  img {
    line-height: 1.2;
    vertical-align: middle;
    margin-right: 9px; } }

.SelectMaterial__Materials {
  padding: 5px 20px;
  border-top: 1px solid #EBEBEB; }

.SelectMaterial__Material {
  padding: 20px 0;

  & + & {
    border-top: 1px solid #EBEBEB; } }
