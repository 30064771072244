@import "~common";

#loader {
	position: relative;
	margin: auto;
	.f_circleG {
		position:absolute;
		background-color:rgb(255,255,255);
		height:5px;
		width:5px;
		@include rounded(3px);
		animation-name:f_fadeG;
		-o-animation-name:f_fadeG;
		-ms-animation-name:f_fadeG;
		-webkit-animation-name:f_fadeG;
		-moz-animation-name:f_fadeG;
		animation-duration:0.622s;
		-o-animation-duration:0.622s;
		-ms-animation-duration:0.622s;
		-webkit-animation-duration:0.622s;
		-moz-animation-duration:0.622s;
		animation-iteration-count:infinite;
		-o-animation-iteration-count:infinite;
		-ms-animation-iteration-count:infinite;
		-webkit-animation-iteration-count:infinite;
		-moz-animation-iteration-count:infinite;
		animation-direction:normal;
		-o-animation-direction:normal;
		-ms-animation-direction:normal;
		-webkit-animation-direction:normal;
		-moz-animation-direction:normal;
	}
}

@keyframes f_fadeG{
	0%{
		background-color:rgba(56,154,82,0.99);
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-o-keyframes f_fadeG{
	0%{
		background-color:rgba(56,154,82,0.99);
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-ms-keyframes f_fadeG{
	0%{
		background-color:rgba(56,154,82,0.99);
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-webkit-keyframes f_fadeG{
	0%{
		background-color:rgba(56,154,82,0.99);
	}

	100%{
		background-color:rgb(255,255,255);
	}
}

@-moz-keyframes f_fadeG{
	0%{
		background-color:rgba(56,154,82,0.99);
	}

	100%{
		background-color:rgb(255,255,255);
	}
}
@keyframes cssAnimation {
    0%{
		opacity: 1;
	}
	100%{
		opacity: 0;
	}
}
@-webkit-keyframes cssAnimation {
    0%{
		opacity: 1;
	}
	100%{
		opacity: 0;
	}
}
@-webkit-keyframes spin-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
