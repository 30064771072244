@import "~common";
.answer_likes {
  position: relative;
  display: inline-block;
  line-height: 24px;
  height: 24px;
  color: $link;
  font-size: 14px;
  font-weight: 600; }
.comment {
  &__like-wrapper {
    display: inline; }
  &__like-icon {
    background: asset-url(icons-favorite-disable) no-repeat 50% 50%; }
  &__unlike-icon {
    background: asset-url(likes-enable) no-repeat 50% 50%; }
  &__action-icon {
    border: 0 none;
    outline: none;
    display: inline-block;
    height: 24px;
    padding: 0;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    transition: color .2s;
    line-height: 24px;
    appearance: none;
    width: 24px;
    text-indent: -9999em;
    color: transparent;
    margin-left: 0;
    margin-right: 6px;
    vertical-align: middle;
    &:hover {
      color: transparent;
      background: asset-url(icons-favorite) no-repeat 50% 50%; }
    @media (max-width: 767px) {
      margin-right: 2px; } }
  @media (max-width: 767px) {
    font-size: 12px; } }
.like_icon_readonly {
  background: asset-url(icons-favorite) no-repeat 50% 50%;
  cursor: default; }
