.QuizProgressBar {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 20px;
  background-color: rgba(#fff, 0.15);
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  overflow: hidden;
  transition: background-color 0.3s;

  & + & {
    margin-top: 10px; }

  .QuizShow--ThemeLight & {
    background: #F6F6F6;
    color: #212121; } }

.QuizProgressBar--Clickable {
  cursor: pointer;

  &:hover {
    background-color: rgba(#fff, 0.3);

    .QuizShow--ThemeLight & {
      background: #EBEBEB; } } }

.QuizProgressBar__bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 4px;
  background-color: rgba(#fff, 0.2);
  transition: background-color 0.3s;

  .QuizShow--ThemeLight & {
    background: #EBEBEB; }

  .QuizShow--ThemeLight .QuizProgressBar--Clickable:hover & {
    background: #DBDBDB; }

  .QuizProgressBar--chosen & {
    background-color: #E2B441;

    .QuizShow--ThemeLight & {
      background: #FFE0A3; } } }

.QuizProgressBar__info {
  position: relative;
  flex-grow: 1;
  min-width: 0;
  text-align: left;
  padding-right: 10px; }

.QuizProgressBar__info-label {}

.QuizProgressBar__info-separator {
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin: 0 0.25em;
  vertical-align: middle;
  background-color: currentColor;
  opacity: 0.5;

  .QuizProgressBar--chosen & {
    opacity: 1; } }

.QuizProgressBar__info-count {
  opacity: 0.5;

  .QuizProgressBar--chosen & {
    opacity: 1; } }

.QuizProgressBar__percentage {
  position: relative;
  font-weight: 700; }

.QuizProgressBar__checkmark {
  display: inline-block;
  width: 18px;
  height: 12px;
  margin-right: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("./svg/progress-bar.svg");

  .QuizShow--ThemeLight & {
    background-image: url("./svg/checkmark.svg"); } }

.QuizProgressBar__percentage-label {}
