@import "~common";

#top_panel {
	background: #FFF;
	padding: 24px 0;
	width: 100%;
	box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.06);
	h3 {
		line-height: 30px;
		font-size: 24px;
		font-weight: 700;
		color: #000;
		color: rgba(0,0,0,.88);
		margin: 0 0 6px;
		padding: 0; }

	h4 {
		line-height: 20px;
		margin: 0;
		padding: 0;
		font-size: 14px;
		font-weight: 600;
		color: #b0b0b0;
		color: rgba(0,0,0,.38); }

	a {
		@include btn();
		margin-top: 8px; }

	.ui-selectmenu-button {
		display: inline-block;
		zoom: 1;
		*display: inline;
		height: 30px;
		line-height: 30px;
		position: relative;
		background: transparent !important;
		border: 0 none !important;
		padding: 0 30px 0 0 !important;
		margin: 0 !important;
		max-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: auto !important;
		.ui-selectmenu-text {
			color: #212121;
			font-size: 24px;
			letter-spacing: -.2px;
			font-weight: 700;
			line-height: 30px;
			cursor: pointer;
			margin: 0; }

		.ui-icon-triangle-1-s {
			position: absolute;
			width: 30px;
			height: 30px;
			cursor: pointer;
			top: 0;
			right: 0;
			margin: 0;
			float: none;
			background: asset-url(arrow-down) no-repeat 50% 50%;
			transition: all .2s;
			-webkit-transition: all .2s;
			-moz-transition: all .2s; }

		// &.ui-state-focus {
		// 	.ui-icon {
		// 		transform: rotate(180deg);
		// 		-webkit-transform: rotate(180deg);
		// 		-moz-transform: rotate(180deg);
		// 	}
 } }		// }
#top_user {
	background: #FFF;
	padding: 24px 0;
	width: 100%;
	box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.06);
	h3 {
		line-height: 1.2;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #212121;
		font-weight: 700;
		font-size: 24px;
		padding: 22px 0 6px;
		margin: 0; }

	h4 {
		font-size: 14px;
		font-weight: 600;
		line-height: 1.2;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #acacac;
		margin: 0;
		padding: 0; }
	.avatar {
		float: right; } }

#content {
	&.white, #content_white {
		background: #fff; }

	#content_white {
		border-top: 2px solid rgba(0, 0, 0, 0.06);
		padding-top: 48px;
		padding-bottom: 32px;
		margin-top: 48px; } }

@media (max-width: 767px) {
  #content {
    width: auto;
    //padding: 24px 0 0
    overflow: hidden;
    min-height: 100%;

    #page_content {
      margin-left: -15px;
      margin-right: -15px;
      padding-bottom: 60px; } } }


.show-more-wrap {
  margin-top: 30px;
  padding: 0 15px;

  @include max-w(480) {

    .btn_edit {
      width: 100%;
      background: transparent;
      height: 50px;
      line-height: 47px; } } }

.text-center {
  text-align: center; }
