$link: #389a52;

.rec-block {
  position: relative;
  padding: 15px 25px 12px;
  letter-spacing: 0;

  & + &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 25px;
    left: 25px;
    height: 1px;
    background: #f0f0f0; } }

.rec-block__header {
  margin-bottom: 13px;

  &:after {
    content: "";
    display: table;
    width: 100%; } }

.rec-block__avatar {
  float: left; }

.rec-block__info {
  margin-left: 55px; }

.rec-block__author-info {
  .badge {
    margin-right: 7px; } }

.rec-block__author-name {
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle;

  [type="submit"] {
    padding: 0;
    margin: 0;
    color: #1e1e1e;
    font-size: 15px;
    font-weight: 400;
    background: transparent;
    appearance: none;
    border: none; } }

.rec-block__date {
  color: #1e1e1e;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.65;
  letter-spacing: 0.025em; }

.rec-block__title-wrap {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.01em; }

.rec-block__title-category {
  color: $link;
  text-decoration: none; }

.rec-block__title {
  color: #000000;
  text-decoration: none; }

.rec-block__footer {
  margin-top: 20px; }
