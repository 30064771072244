@import "~common";

.subscribe-form,
.unsubscribe-form {
  display: inline-block;

  [type="submit"], {
    display: inline-block;
    height: 40px;
    background: white;
    color: #000;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 0 12px;
    cursor: pointer;
    line-height: 38px;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    text-decoration: none;
    &:hover {
      background: #000;
      color: white; } } }

.subscribe-form {

  [type="submit"] {
    color: $link;
    border-color: $link;

    &:hover {
      background: $link;
      color: white; } } }

.unsubscribe-form {

  [type="submit"] {
    color: $red-unsubscribe;
    border-color: $red-unsubscribe;

    &:hover {
      background: $red-unsubscribe;
      color: white; } } }
