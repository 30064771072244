@import "~common";

.user-sidebar {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06); }

.user-position {
  @include max-w(700) {
    display: none; } }
.user-position__user-title {
  background: #fff;
  padding: 0 25px;
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  @include max-w(992) {
    justify-content: center;
    margin-top: 20px; } }
.user-position__user-title-img {
  width: 40px;
  height: 40px;
  z-index: 4;
  position: relative;
  flex-shrink: 0;
  & img {
    width: 100%; } }
.user-position__user-title-box {
  margin-left: 10px; }
.user-position__user-title-login {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #000000; }
.user-position__user-title-text {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #7F7F7F; }
