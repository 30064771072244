@import "~common";

.share-popup-wrap {

  .mfp-content {
    padding: 30px 0;

    @include max-w(576) {
      padding: 15px; } }

  .mfp-container {
    padding: 0; } }

.share-popup {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 550px;
  background-color: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
  border-radius: 4px; }

.share-popup__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 22px 17px;
  position: relative;
  border-bottom: 1px solid #EFEFEF; }

.share-popup__close {
  cursor: pointer;
  padding: 0;
  width: 18px;
  height: 18px; }

.share-popup__close {

  &:active {
    top: 0; } }

.share-popup__field {
  display: flex;
  flex-direction: column;
  padding: 23px 22px 17px; }

.share-popup__field--name {
  margin-top: 0;
  padding-top: 0;
  width: 100%; }

.share-popup__label {
  font-size: 15px;
  color: #000000; }

.share-popup__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-image: url('./search.svg');
    background-repeat: no-repeat; } }

.share-popup__input {
  margin-top: 20px;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  font-size: 15px;
  color: #212121;
  padding: 13px 18px 16px;

  &::placeholder {
    opacity: 1;
    color: #A1A1A1; }

  &:focus::placeholder {
    opacity: 0.5; } }


.share-popup__input--name {
  margin-top: 0;
  padding-left: 47px; }


.share-popup__logo {
  flex-shrink: 0;
  margin-right: 15px;

  .avatar {
    width: 35px;
    height: 35px;
    line-height: 35px; } }

.share-popup__user,
.share-popup__user.ph-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 0;
  border: none;
  border-bottom: 1px solid #EFEFEF;

  .ph-avatar,
  .ph-row,
  .ph-row > div {
    margin-bottom: 0; } }

.share-popup__users-wrap {
  height: 350px; }

.share-popup__users {
  //width: 100% !important
  padding-left: 22px;
  padding-right: 22px;
  cursor: pointer;
  overflow-y: auto !important;
  min-height: 200px;

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #ffffff; }

  &::-webkit-scrollbar-thumb {
    background-color: #389A52; } }


.share-popup__checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid #C1C1C1;
  border-radius: 4px;
  margin-left: auto; }

.share-popup__checkbox--checked {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #389A52;
  border: none;

  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 8px;
    background-image: url('./check1.svg');
    background-repeat: no-repeat;
    background-position: center; } }


.user-popup__buttons {
  justify-content: flex-end;
  position: relative;
  display: flex;
  padding: 28px 25px 20px;
  border-top: 1px solid #EFEFEF;
  z-index: 10;

  @include max-w(576) {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding-top: 0;
    flex-direction: column-reverse;
    box-shadow: 0 -35px 30px rgba(255,255,255,0.9);
    background-color: #ffffff; }

  @include max-w(320); }

.user-popup__button {
  background: none;
  border: none;
  height: auto;
  margin-left: 25px;
  line-height: 1.4;
  font-size: 15px;
  color: #c1c1c1;
  cursor: pointer;

  @include max-w(576) {
    padding: 15px 0 !important;
    margin-top: 10px;
    margin-left: 0;
    width: 100%;
    border-radius: 4px;
    color: #a0a0a0;
    border: 1px solid #EBEBEB !important; } }



.user-popup__button--send {
  color: #389a52;

  @include max-w(576) {
    color: #ffffff;
    margin-top: 0;
    background-color: #389A52;
    border-color: transparent !important;
    border-radius: 4px; } }


.user-popup__button--close {
  position: static !important; }


