@import "~common";

.info-moderation {
  padding: 16px 24px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  margin-bottom: 20px;

  @include max-w(767) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.info-moderation-img {
  flex-shrink: 0;
  width: 55px;
  height: 55px;
  img {
    width: 100%;
    height: 100%;
  }
}

.info-moderation-descr {
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  margin-left: 20px;

  @media (max-width: 320px) {
    width: 70%;
  }
}
