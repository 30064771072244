@import "~common";

.popup-upload {
  //display: flex
  position: relative;
  padding: 50px 15px 50px;
  height: 100%;
  min-height: 356px;
  max-width: 783px;
  margin: 0 auto;
  background: #fff;
  text-align: center;

  @media (max-height: 658px) {
    //width: 100%
    max-width: none;
    margin: 0 -6px;
    padding-bottom: 100px; } }

.popup-upload__inner {
  //display: flex
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;
  justify-content: center; }

.popup-upload__title {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 24px;
  line-height: (33 / 24);
  color: #000; }

.popup-upload__descr {
  margin-bottom: 9px;
  font-size: 15px;
  line-height: (22 / 15);
  color: #000;

  @include max-w(480) {
    padding: 0 10px; }

  @include max-w(360) {
    font-size: 13px; } }

.popup-upload__rec {
  font-size: 12px;
  line-height: (16 / 12);
  color: #A0A0A0;

  @include max-w(480) {
    font-size: 13px;
    line-height: (20 / 13); }

  @include max-w(360) {
    font-size: 12px; } }

.popup-upload__action {
  margin-top: 24px;

  .btn_edit {
    font-size: 15px;
    padding-left: 24px;
    padding-right: 24px; }

  @media (max-height: 658px) {
    position: absolute;
    right: 15px;
    bottom: 15px;
    left: 15px;

    .btn_edit {
      background: $link;
      color: #fff;
      width: 100%;
      height: 50px;
      line-height: 48px; } } }

.popup-upload__close,
.popup-upload__close.mfp-close {
  position: absolute;
  width: 21px;
  height: 21px;
  top: 30px;
  right: 30px;
  background: {
    image: url("./icon-close.svg");
    repeat: no-repeat;
    position: center; }
  cursor: pointer;

  &:active {
    top: 30px; } }


.popup-upload__crop-wrap {

  .editor__crop {
    display: block; }

  .ReactCrop {
    width: auto;
    margin: 0; } }

.popup-upload .popup-upload__load-button.btn_edit {
  color: #000 !important; }

.popup-upload  .btn_edit {
  margin-right: 20px;
  margin-top: 20px; }

.popup-upload__crop-wrap .ReactCrop__crop-selection {
  border-width: 2px; }

.popup-upload .dropzone.dz-started {
  overflow: hidden; }

