@import "~common";

.button {
  display: inline-block;
  height: 40px;
  padding: 0 20px;
  background: #FAFAFA;
  color: #212121;
  font-size: 15px;
  line-height: 1;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  appearance: none;
  outline: none;
  cursor: pointer;
  vertical-align: middle;
  transition: 0.2s background-color;
  user-select: none;

  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle; }

  &:hover {
    color: #212121;
    background: #EBEBEB; }

  &[disabled] {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
    pointer-events: none; }

  &--md {
    padding: 0 15px;
    height: 35px; }

  &--lg {
    padding: 0 15px;
    height: 50px; }

  &--wide {
    width: 100%; }

  &--outline {
    background-color: transparent;
    padding-right: 15px;
    padding-left: 15px; }

  &--outline2 {
    background-color: transparent;
    border-color: $color-main;
    color: $color-main;
    padding-right: 15px;
    padding-left: 15px;

    &:hover {
      color: $color-main; } }

  &--prime {
    color: #fff;
    background: $color-main;
    border-color: $color-main;

    &:hover {
      color: #fff;
      border-color: lighten($color-main, 5%);
      background: lighten($color-main, 5%); }

    &:active {
      border-color: lighten($color-main, 15%);
      background: lighten($color-main, 15%); }

    &.button--outline {
      background-color: transparent;
      color: #000;

      &:hover {
        color: #fff;
        border-color: lighten($color-main, 5%);
        background: lighten($color-main, 5%); }

      &:active {
        border-color: lighten($color-main, 15%);
        background: lighten($color-main, 15%); } } }

  &--danger {
    color: #fff;
    background: $color-error;
    border-color: $color-error;

    &:hover {
      color: #fff;
      border-color: lighten($color-error, 5%);
      background: lighten($color-error, 5%); }

    &.button--outline {
      background-color: transparent;
      color: $color-error;

      &:hover {
        color: #fff;
        border-color: lighten($color-error, 5%);
        background: lighten($color-error, 5%); } } }

  &--default {
    color: #212121;
    background: #fff;
    border-color: #C1C1C1;

    &:hover {
      color: #212121;
      border-color: darken(#C1C1C1, 3%);
      background: darken(#C1C1C1, 3%); } }

  &--default-fill {
    color: #212121;
    background: #F5F5F5;
    border-color: #F5F5F5;

    &:hover {
      color: #212121;
      border-color: darken(#F5F5F5, 3%);
      background: darken(#F5F5F5, 3%); } }

  &--default-fill2 {
    color: #212121;
    background: #E1E1E1;
    border-color: #E1E1E1;

    &:hover {
      color: #212121;
      border-color: darken(#E1E1E1, 3%);
      background: darken(#E1E1E1, 3%); } }

  &--default-fill3 {
    color: #212121;
    background: #F8F8F8;
    border-color: rgba(0, 0, 0, 0.06);

    &:hover {
      color: #212121;
      border-color: darken(#F8F8F8, 3%);
      background: darken(rgba(0, 0, 0, 0.06), 3%); } }

  &--link {
    color: #C1C1C1;
    background-color: transparent;
    border-color: transparent;
    text-decoration: underline;

    &:hover {
      color: #C1C1C1;
      text-decoration: none;
      background-color: transparent;
      border-color: transparent; } } }
