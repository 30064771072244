@import "~common";

.ModalWindow {
  display: flex;
  position: relative;
  width: 100%;
  background-color: #fff;

  &--Center {
    align-items: center; } }

.ModalWindow__Main {
  width: 100%;
  padding: 80px 100px;

  @include max-w(700) {
    padding: 25px 15px; } }

.ModalWindow__Title {
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 24px;
  line-height: (33 / 24);
  color: #212121;
  text-align: center;

  @include max-w(700) {
    font-size: 20px;
    padding-right: 50px;
    text-align: left; }

  @include max-w(360) {
    font-size: 16px; } }

.ModalWindow__Title--WithLine {
  position: relative;
  padding-bottom: 40px;

  @include max-w(700) {
    padding-bottom: 30px; }

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: -100px;
    right: -100px;
    height: 1px;
    background: #E5E5E5; } }

.ModalWindow__Close {
  position: absolute;
  top: 25px;
  right: 23px;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 44px;
  font-weight: 300;
  line-height: 0;
  color: #C2C2C2;
  background-color: transparent;
  border: none;
  appearance: none;
  cursor: pointer;
  outline: none;

  @include max-w(700) {
    right: 15px; }

  @include max-w(360) {
    top: 18px; }

  &:hover {
    color: #000; } }

.main-color {
  color: #389A52; }

.bold-main-color {
  color: #389A52;
  font-weight: bold; }
