@import "~common";

.SelectMaterialOption {
  display: flex;
  position: relative;
  padding: 15px 0;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: rgba(#000, 0.06); } }

.SelectMaterialOption__PreviewWrap {
  flex-shrink: 0; }

.SelectMaterialOption__Preview {
  display: block;
  width: 35px;
  height: 35px;
  margin-right: 13px;
  border-radius: 4px;
  background: #f2f2f2;
  object-fit: cover;
  object-position: center; }

.SelectMaterialOption__InfoWrap {
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0; }

.SelectMaterialOption__Info {
  width: 100%; }

.SelectMaterialOption__InfoTitle {
  font-size: 13px;
  font-weight: 700;
  color: $color-main;
  text-overflow: ellipsis;
  overflow: hidden; }

.SelectMaterialOption__InfoDescr {
  font-size: 13px;
  line-height: (18 / 13);
  color: #212121;
  text-overflow: ellipsis;
  overflow: hidden; }

.SelectMaterialOption__CheckedWrap {
  flex-shrink: 0;
  padding-top: 10px;
  margin-left: 20px; }

.SelectMaterialOption__Checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid #C1C1C1;
  border-radius: 4px;

  &--Selected {
    border-color: $color-main;
    background: {
      color: $color-main;
      image: url("./icon-check.svg");
      repeat: no-repeat;
      position: center; } } }
