@import "~common";
.user-position-mini {
  display: none;
  @include max-w(992) {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding-bottom: 20px;
    margin-top: 20px; } }
.user-position-mini__user-title {
  background: #fff;
  padding: 0 25px;
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  @include max-w(768) {
    justify-content: flex-start; } }
.user-position-mini__user-title-img {
  width: 40px;
  height: 40px;
  z-index: 4;
  position: relative;
  & img {
    width: 100%; } }
.user-position-mini__user-title-box {
  margin-left: 10px; }
.user-position-mini__user-title-login {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #000000; }
.user-position-mini__user-title-text {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #7F7F7F; }
.about-top-mini__mini-block {
  background: #fff;
  margin: 0px 24px;
  border-radius: 4px;
  justify-content: center;
  display: flex; }
.about-top-mini__mini-text {
  color: black;
  text-decoration: none;
  background: #F5F5F5;
  padding: 17px 23px 17px 43px;
  border-radius: 4px;
  & span {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      background-image: url("./sou.svg");
      left: -24px;
      top: -1px;
      width: 20px;
      height: 19px; }
    &:before {
      position: absolute;
      content: '';
      background-image: url("./point.svg");
      left: -20px;
      top: 6px;
      width: 13px;
      height: 3px; } } }
.about-top-mini__mini-text {
  width: 100%;
  text-align: center; }
