@import "~common";

.communities {
  display: flex;
  flex-wrap: wrap;
}

.community {
  position: relative;
  width: calc(50% - 30px);
  padding-top: 24px;
  margin: 0 15px 30px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 0 0 20px;
  }

  @media screen and (max-width: 480px) {
    margin-bottom: 12px;
  }
}

.community__header {
  display: flex;
  align-items: center;
  margin: 0 25px;
}

.community__image-link {
  flex-shrink: 0;
  margin-right: 20px;
}

.community__image {
  width: 80px;
  height: 80px;
  border: none;
  background-color: #EEEEEE;
  border-radius: 50%;
  overflow: hidden;
  outline: none;
  object-fit: cover;
  object-position: center;

  @media(max-width: 480px){
    width: 60px;
    height: 60px;
  }
}

.community__info {
  display: flex;
  flex-direction: column;
}

.community__title {
  color: #212121;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
}

.community__quantity {
  font-size: 13px;
  line-height: 18px;
  color: #A0A0A0;
}

.community__quantity--posts {
  margin-left: 5px;
}

.community__number {
  color: #308446;
  text-decoration: none;
  cursor: pointer;
}

.community__policy {
  margin-top: 10px;
  margin-bottom: 2px;
  font-size: 13px;
  line-height: 15px;
  color: #A0A0A0;
}

.community__description {
  position: relative;
  margin: 20px 25px 0;
  padding: 11px 15px;
  background-color: #fafafa;
  height: 62px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  &:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 30px;
    transform: rotate(45deg);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: none;
    border-right: none;
    background-color: #fafafa;
    width: 15px;
    height: 15px;

    @media (max-width: 480px) {
      left: 20px
    }
  }

  @media (max-width: 992px) {
    height: 90px;
  }

  @media (max-width: 767px) {
    height: inherit;
    padding-bottom: 10px;
  }
}

.community__text {
  font-size: 13px;
  line-height: 19px;
  color: #000000;
  overflow: hidden;

  @include line-clamp(2, (19 / 13))
}

.community__users {
  display: flex;
  align-items: center;
}

.community__footer {
  display: flex;
  align-items: center;
  padding: 16px 26px;
  margin-top: 16px;
}

.community__subscribe {

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.hide-subscribe-btn {
  @media (max-width: 600px) {
    display: none;
  }
}

.community__user {
  display: block;
  position: relative;
  z-index: 3;
  width: 34px;
  height: 34px;
  flex-shrink: 0;
  margin-left: -15px;
  border-radius: 50%;
  background-color: #CDD8B7;
  border: 2px solid #fff;
  line-height: 30px;

  &:nth-child(1) {
    margin-left: 0;
  }

  &:nth-child(2) {
    z-index: 2;
  }
  &:nth-child(3) {
    z-index: 1;
  }
}

.community__button {
  position: relative;
  display: inline-block;
  padding: 10px 12px;
  cursor: pointer;
  text-align: center;
  color: #000;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 4px;
  font-size: 15px;
  line-height: 20px;

  > input[type="checkbox"] {
    position: absolute;
    appearance: none;
    width: 1px;
    height: 1px;
    opacity: 0.01;
    overflow: hidden;
  }

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 15px;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-top: 15px;
  }
}

.community__button--unsubscribe {
  color: #F0443D;
  border-color:  #F0443D;
  background-color: #ffffff;
}

.community__button--subscribe {
  color: #fff;
  border-color:  $color-main;
  background-color: $color-main;

  &.community__button--not-fill {
    color: $color-main;
    background-color: #fff;
  }
}

.community__button--unsubscribe,
.community__button--subscribe {

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

}

.community__button--locked {
  color: #A0A0A0;
  border-color:  #EBEBEB;
  background-color: #EBEBEB;
  cursor: default;
}

.community__subscribed {
  color: #A0A0A0;
  margin-left: 10px;
  font-size: 13px;
  text-decoration: none;
  line-height: 18px;

  &.is-simple {
    margin-left: 0;
  }

  &:not(.is-simple) {
    cursor: pointer;
    &:hover {
      color: #389A52;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
}

.community__subscribe--disable {

}

.community__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  justify-content: space-between;

  @media (min-width: 768px) and (max-width: 875px) {
    align-items: flex-start;
    flex-direction: column;
  }

  @media (max-width: 480px) {
    align-items: flex-start;
    flex-direction: column;
  }
}
