@import "~common";

.block-moderate {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center; }


.block-moderate-box {
  display: flex;
  flex-direction: column;
  margin-top: 20px; }


.block-moderate-title {
  width: 100%;
  text-align: center;
  margin-bottom: 10px; }


.block-moderate-descr {
  width: 100%;
  text-align: center; }

.icon-modarated-new {
  background-image: url("../../../app/assets/images/modarate-icon.png");
  width: 147px;
  height: 150px;
  background-size: auto;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position-y: 40px;
  background-position-x: 40px; }

.moderate-img {
  margin: 0 auto; }

.moderate-order {
  display: flex;
  flex-direction: column; }

.moderate-title {
  color: #c1c1c1!important; }
