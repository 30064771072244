.form-search-r {
  display: flex;
  padding: 22px 25px; }

.form-search-r__input {
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #000;

  &:-ms-input-placeholder {
    color: #A0A0A0 !important; } }

