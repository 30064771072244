@import "~common";

.search {
  margin-bottom: 30px;
}

.search--category {
  display: none;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    display: block;
  }

  .search__wrapper {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 0;
  }

  &.show {
    display: block;
  }

  @media(min-width: 768px) {
    .search__input,
    input.search__input {
      padding-top: 22px;
      padding-bottom: 22px;
    }
  }
}

.search__close {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 25px;
  top: 50%;
  margin-top: -10px;
  opacity: 0.25;
  cursor: pointer;
  user-select: none;

  @media (max-width: 767px) {
    display: none;
  }

  &:hover {
    opacity: 0.5;
  }
}

.search__wrapper {
  position: relative;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: hidden;

  @media(max-width: 480px) {
    flex-direction: column;
  }
}

.search--publication {
  display: none;
  margin-bottom: 3px;

  @media (max-width: 767px) {
    display: block;
    margin-top: 30px;
    margin-bottom: 8px;
  }
}

.search__input,
input.search__input {
  justify-content: center;
  width: 100%;
  font-size: 15px;
  flex-basis: 850px;
  padding: 20px 15px 20px 55px;
  border: none;
  outline: none;

  .search--publication & {
    flex-basis: auto;
  }

  &::placeholder {
    font-size: 15px;
    border-radius: 4px;
    color: #A1A1A1;
  }

  @media(max-width: 480px) {
    padding: 18px 16px;
    flex-basis: inherit;
  }
}


.search__submit {
  position: absolute;
  top: 50%;
  left: 25px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-image: asset-url(search);
  border: none;
  background-color: #ffffff;

  @media(max-width: 480px) {
    top: 17px;
    left: inherit;
    right: 25px;
    transform: inherit;
  }
}

.search__advice {
  position: relative;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  padding-right: 25px;
  background-color: #ffffff;
  text-decoration: none;

  @media(min-width: 480px){

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top:16px;
      bottom: 16px;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.06);
    }
  }


  @media(max-width: 480px) {
    margin-top: 12px;
    padding: 13px 16px;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.search__text {
  font-size: 15px;
  margin-left: 12px;
  color: #212121;

  @media(max-width: 480px) {
    margin-left: 9px;
  }
}

.search__button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  width: 30px;
  height: 30px;
  background-color: #F5F5F5;
  border-radius: 4px;

  @media (max-width: 480px) {
    margin-left: 0;
    background-color: #ffffff;
    border: 1px solid #429F5B;
  }
}

.search__icon {
  display: block;
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  background-image: asset-url(close-black);
  background-size: cover;

  @media (max-width: 480px) {
    background-image: asset-url(close-green);
  }
}

.search__mobile {
  width: 100%;
  border: none;
  padding: 14px 19px;
  margin-top: 15px;
  margin-bottom: 100px;
  background-color: #F5F5F5;
  border-radius: 4px;
  font-size: 15px;
  color: #A1A1A1;

  &::placeholder {
    color: #A1A1A1;
  }
}

.search__input-desctop {

  @media (max-width: 872px) {
    display: none;
  }
}


.search__input-mobile {
	display: none;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    width: 23px;
    height: 23px;
    top: 28px;
    right: 35px;
    background-image: url('./search.svg');
  }
}

//SEARCH
#search_popup {
	background: #fafafa;
	padding: 0;
	border: 1px solid #ececec;
	top: 55px !important;
	box-shadow: 0 0 4px rgba(0,0,0,.2);
	position: absolute;
	z-index: 100;
	display: block;
	width: 100%;
  min-width: 300px;
	left: 0;
	display: none;

  @media (max-width: 872px) {
		left: 50% !important;
		transform: translateX(-50%);
		top: 76px !important;
		width: calc(100% - 30px) !important;
	}

	@media (max-width: 872px) {
		bottom: auto !important;
	}

	&:before, &:after  {
		z-index: 100;
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 8px 8px 8px;
		border-color: transparent transparent #ececec transparent;
		position: absolute;
		top: -8px;
		left: 50%;
		margin-left: -8px;
	}
	&:after {
		top: -7px;
		border-color: transparent transparent #fafafa transparent;
		z-index: 101;
	}
	#search_popup_inner {
		overflow: hidden;
		overflow-y: auto;
    background: #ffffff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

		&::-webkit-scrollbar {
		    width: 6px;
		}
		&::-webkit-scrollbar-track {
		    background: rgba(0,0,0,.1)
		}
		&::-webkit-scrollbar-thumb {
		    border-radius: 3px;
		    background: rgba(0,0,0,.3);
		}
	}
	.search_popup_sec {
		width: 100%;
		.search_popup_sec_head {
			overflow: hidden;
			padding: 16px;

			@media (max-width: 872px) {
				border-top: 1px solid rgba(0, 0, 0, .1)
			}

			&.no_found {
				text-align: center;
				font-size: 15px;
				color: #808080;
			}
			strong, a {
				display: block;
				line-height: 13px !important;
				font-size: 13px !important;
				margin-top: 0 !important;
				color: #389a52 !important;
			}
			strong {
				font-weight: 600;
				color: #000 !important;
				float: left;
			}
			a {
				text-decoration: none;
				color: $link;
				float: right;
				&:hover {
					color: $link_dark;
				}
			}
		}
		.search_popup_sec_body {
			width: 100%;
			background: #FFF;
			padding: 16px;
			&.search_popup_materials {
				a {
					display: block;
					text-decoration: none;
					font-size: 15px;
					color: #000;
					@media (max-width: 872px){
						line-height: 15px !important;
					}
					font {
						color: #a0a0a0;
					}
					span {
						color: $link;
					}
					& + a {
						margin-top: 16px;
					}
					&:hover {
						color: $link_dark;
						span, font {
							color: $link_dark;
						}
					}
				}
			}
			&.search_popup_authors {
				@media (max-width: 872px){
					padding-top: 0;

					a {
						line-height: 32px !important;
						color: #000 !important;
						font-weight: bold !important;
						padding-left: 45px !important;
					}
				}
				a {
					display: block;
					text-decoration: none;
					font-size: 15px;
					color: #000;
					font-weight: 600;
					position: relative;
					padding-left: 56px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					height: 40px;
					line-height: 40px;
					& + a {
						margin-top: 16px;
					}
					.avatar {
						position: absolute;
						top: 0;
						left: 0;
					}
					&:hover {
						color: $link_dark;
					}
				}
			}
			&.search_popup_tags {
				padding: 24px 16px 8px;
				overflow: hidden;

				@media (max-width: 872px){
					padding-top: 0;
				}
				a {
					line-height: 24px !important;
					display: block !important;
					float: left;
					margin-right: 16px;
					margin-bottom: 16px;
				}
			}
		}
	}
}

.ui-widget.search_autocomplete {
	&.ui-widget-content {
		background: #fafafa;
		padding: 0;
		border: 1px solid #ececec;
		top: 66px !important;
		box-shadow: 0 0 4px rgba(0,0,0,.2);
		&:before, &:after  {
			z-index: 100;
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 6px 6px 6px;
			border-color: transparent transparent #ececec transparent;
			position: absolute;
			top: -6px;
			left: 50%;
			margin-left: -6px;
		}
		&:after {
			top: -5px;
			border-color: transparent transparent #fafafa transparent;
			z-index: 101;
		}
		.ui-menu-item {
			display: block;
			line-height: 32px;
			white-space: nowrap;
			&:hover {
				background: lighten($link,38%);
			}
			&:before, &:after {
				content: '';
				display: table;
				width: 100%;
				clear: both;
				float: none;
			}
			span, strong {
				display: block;
				cursor: pointer;
				line-height: 32px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				padding: 0 10px;
				border-color: transparent !important;
				background: transparent !important;
			}
			strong {
				float: left;
				width: 65%;
				font-size: 15px;
				color: #212121;
				font-weight: 400;
			}
			span {
				float: right;
				width: 35%;
				text-align: right;
				color: #b0b0b0;
				font-size: 13px;
				font-weight: 300;
			}
		}
	}
}

.author-search {
  color: #389A52;
  text-decoration: none;
  font-weight: 700;
  font-size: 15px;
}

.search__ajax {
  display: none;
  position: absolute;
  right: 20px;
  top: 50%;
  font-size: 20px;
  color: $color-main;
  transform: translate(0, -50%);

  @media (max-width: 480px) {
    right: 50px;
  }
}
