.info-content {

  h4 {
    margin-bottom: 0.5em;

    & + p {
      margin-top: 1em; } }

  p,
  ul {
    font-size: 15px;
    font-weight: 400;
    color: #212121;
    line-height: 22px;

    & + p {
      margin-top: 1em; }

    & + h4 {
      margin-top: 1.65em; }

    a {
      text-decoration: none; }

    li {
      margin-left: 18px; } }

  table {
    border-spacing: 0px;
    border-top: 1px #212121 solid;
    border-left: 1px #212121 solid;

    td {
      border-right: 1px #212121 solid;
      border-bottom: 1px #212121 solid;

      p {
        text-align: center; }

      li {
        margin-left: 28px;

        p {
          text-align: start; } } } } }
