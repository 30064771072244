.checkbox-group {
  display: flex; }

.checkbox-group__control {
  flex-shrink: 0;
  display: inline-block;
  margin-right: 9px; }

.checkbox-group__label {
  display: inline-block;
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0;

  label {
    font-size: 15px;
    color: #000;
    cursor: pointer;
    user-select: none; } }
