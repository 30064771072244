@import "~common";

.Dropzone {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  background: #FAFAFA;
  border: 1px dashed #D0D0D0;
  border-radius: 4px;
  overflow: hidden;

  @media (max-width: 700px) {
    background: transparent;
    border: none;
    height: auto;
    align-items: stretch;
    flex-direction: column; } }

.Dropzone--CanDrop:hover,
.Dropzone--DragAccept {
  cursor: pointer;
  border-color: $color-main; }

.Dropzone--DragReject {
  cursor: pointer;
  background-color: #FFF9F9;
  border: 1px solid $color-error; }

.Dropzone__Input {}

.Dropzone__Message {
  text-align: center;
  pointer-events: none;

  @media (max-width: 700px) {
    width: 100%; } }

.Dropzone__DropMessage {
  pointer-events: none; }

.Dropzone__DropIcon {
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto 10px; }

.Dropzone__DropText {
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #C1C1C1; }

.Dropzone__MessageIcon {
  margin-bottom: 15px;
  color: #C1C1C1;

  @media (max-width: 700px) {
    float: left;
    margin-top: 13px;
    margin-left: 20px;
    margin-bottom: 0;
    width: 25px;
    height: 20px; }

  .Dropzone:hover & {
    color: #000; }

  > svg {
    display: block;
    margin: 0 auto;

    @media (max-width: 700px) {
      width: 100%;
      height: 100%; } } }

.Dropzone__MessageTitle {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 700;
  color: #C1C1C1;

  @media (max-width: 700px) {
    background: #FAFAFA;
    border: 1px dashed #D0D0D0;
    padding: 14px 10px;
    font-size: 12px; }

  .Dropzone:hover & {
    color: #000; } }

.Dropzone__MessageText {
  font-size: 12px;
  line-height: 18px;
  color: #C1C1C1;

  @media (max-width: 700px) {
    text-align: left; } }

.Dropzone__Loader {
  text-align: center; }

.Dropzone__LoaderProgress {
  color: $color-main;
  margin-bottom: 15px; }

.Dropzone__FileName {
  margin-bottom: 3px;
  font-size: 14px;
  line-height: 19px;
  color: $color-main; }

.Dropzone__Progress {
  font-size: 12px;
  line-height: 16px;
  color: #A0A0A0; }

.Dropzone__PreviewImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: {
    size: cover;
    position: center; } }

.Dropzone__PreviewVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000; }

.Dropzone__Btn {
  position: absolute;
  top: 20px;
  right: 20px; }

.Dropzone__SubMsg {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #C1C1C1;

  @media (max-width: 700px) {
    position: static;
    text-align: left;
    margin-top: 6px; } }
