@import "~common";
@import 'new-comp-popup';

.competition_ {
	margin-bottom: 16px;
	.competition_title {
		color: $link;
		font-weight: 700;
		line-height: 1.1;
		font-size: 24px;
		margin-bottom: 24px;
		a {
			text-decoration: none;
		}
	}
	.competition_cover {
		width: 100%;
		height: 240px;
		overflow: hidden;
		margin: 0 auto 24px;
		background: #20532c;
		position: relative;
		&:before, &:after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transition: all .3s;
			@include rounded(50%);
		}
		&:before {
			width: 556px;
			height: 556px;
			margin: -278px 0 0 -278px;
			background: #0b753f;
		}
		&:after {
			width: 360px;
			height: 360px;
			margin: -180px 0 0 -180px;
			background: #389a52;
		}
		span {
			display: block;
			position: absolute;
			background: asset-url(competition_icon) no-repeat 50% 50%;
			width: 140px;
			height: 140px;
			z-index: 4;
			margin: -70px 0 0 -70px;
			top: 50%;
			left: 50%;
			transition: all .3s;
		}
	}
	.competition_text {
		p {
			color: #212121;
			font-size: 18px;
			font-weight: 500;
			margin: 0;
			padding: 0;
			& + p {
				padding-top: 16px;
			}
			&.bold {
				font-weight: 700;
			}
			a {
				font-weight: inherit;
				text-decoration: none;
			}
		}
		.competition_description {
			font-size: 15px;
			color: #212121;

			h1, h2, h3, h4, h5, h6 {
				margin: 20px 0;
			}

			p {
				font-size: 15px;
				color: #212121;
				line-height: 27px;
				padding-top: 16px;
			}

			ul{
				padding-left: 18px;
				line-height: 22px;
			}

			ol {
				padding-left: 18px;
				line-height: 22px;
			}

			ul, ol {
				> li + li {
					margin-top: 10px;
				}
			}

		}
	}
	.competition_btn {
		padding-top: 24px;
		a {
			display: inline-block;
			height: 44px;
			line-height: 44px;
			text-decoration: none;
			border-radius: 4px;
			color: #fff;
			padding: 0 16px;
			background: $link;
			transition: all .3s;
			&:hover {
				background: darken($link,5%);
			}
		}
	}
	.competition_footer {
		padding-top: 32px;
		em {
			display: inline-block;
			line-height: 24px;
			font-style: normal;
			font-size: 15px;
			color: #212121;
		}
		span {
			display: inline-block;
			line-height: 24px;
			margin-left: 24px;
			color: #a0a0a0;
			font-size: 14px;
			i {
				display: inline-block;
				height: 24px;
				width: 24px;
				margin-right: 8px;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				vertical-align: top;
				&.icon_view {
					background-image: asset-url(eye-disable);
				}
				&.icon_comment {
					background-image: asset-url(comments-disable);
				}
				&.icon_sbercoins_gray {
					background-image: asset-url(sbercoints-gray);
				}
				&.icon_sbercoins {
					background-image: asset-url(sbercoints-new);
				}
			}
			&.green {
				color: $link;
				.icon_sbercoins {
					margin-left: 8px;
					margin-right: 0;
				}
			}
		}
	}
	&.hover {
		.competition_cover {
			&:before, &:after {
				transform: scale(1.1);
			}
			span {
				transform: scale(0.9);
			}
		}
	}
}
