@import "~common";

.lieader-categories {

  @include max-w(768) {
    margin-top: 20px; } }


.lieader-categories__user-top {
  display: flex;
  margin-top: 27px;
  text-align: center;
  justify-content: space-between;

  @include max-w(768) {
    flex-direction: column;
    margin-top: 0; } }

.lieader-categories__user-block {
  margin-right: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  transition: 200ms;
  cursor: pointer;
  position: relative;

  &:hover {
    transition: 200ms; }

  &:last-child {
    margin-right: 0; }

  @include max-w(768) {
    width: 100%;
    margin: 0 0 10px 0;
    flex-direction: row;
    padding: 15px 15px 0 15px;
    flex-wrap: wrap; }

  @include max-w(480) {
    border-radius: 0; } }



.lieader-categories__avatar {
  border-radius: 50%;
  z-index: 2;
  width: 53px;
  height: 53px;
  overflow: hidden;

  @include max-w(480) {
    width: 40px;
    height: 40px; } }

.lieader-categories__user-img {
  position: relative;
  flex-shrink: 0;
  margin-top: 20px;

  @include max-w(768) {
    margin: 0 20px; }

  @include max-w(480) {
    margin: 0 15px; }

  &::after {
    position: absolute;
    content: '';
    width: 29px;
    height: 56px;
    top: -2px;
    right: -11px;
    z-index: 1;

    @include max-w(480) {
      width: 22px;
      height: 42px;
      background-size: contain;
      top: 0;
      right: -9px; } }

  &::before {
    position: absolute;
    content: '';
    width: 29px;
    height: 56px;
    top: -2px;
    left: -11px;
    z-index: 1;

    @include max-w(480) {
      width: 22px;
      height: 42px;
      background-size: contain;
      top: 0;
      left: -9px; } }


  span {
    background: #FEC962;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    padding: 1px 5px;
    bottom: -5px;
    border-radius: 50px;
    border: 2px #fff solid;
    content: "";
    z-index: 3;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF; }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; } }

.lieader-categories__user-img--1.lieader-categories__user-img {

  &::after {
    background-image: url("./gold-r.svg"); }

  &::before {
    background-image: url("./gold-l.svg"); } }

.lieader-categories__user-img--2.lieader-categories__user-img {

  &::after {
    background-image: url("./silver-r.svg"); }

  &::before {
    background-image: url("./silver-l.svg"); } }

.lieader-categories__user-img--3.lieader-categories__user-img {

  &::after {
    background-image: url("./bronze-r.svg"); }

  &::before {
    background-image: url("./bronze-l.svg"); } }

.lieader-categories__user-content {

  @include max-w(768) {
    max-width: 175px;
    width: 100%;
    text-align: left; }

  @include max-w(480) {
    max-width: 170px; } }

.lieader-categories__user-text {
  text-decoration: none;
  margin-top: 8px;
  padding: 0 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #212121;
  @include max-w(768) {
    text-align: left;
    padding: 0; } }

.lieader-categories__user-community {
  margin-top: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #A0A0A0;
  @include max-w(768) {
    text-align: left;
    padding: 0; } }

.lieader-categories__rating {
  position: relative;
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #212121;
  padding: 8px 15px 8px 40px;
  background: #F5F5F5;
  border-radius: 50px;

  & span {
    &:after {
      position: absolute;
      content: '';
      background-image: url(./awards.svg);
      top: 8px;
      left: 14px;
      width: 19px;
      height: 17px;
      z-index: 3; } }

  @include max-w(768) {
    margin: 0;
    margin-left: auto; }

  @include max-w(480) {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    padding: 0;

    span {
      padding-top: 20px;
      position: relative;

      &:after {
        top: 0;
        bottom: initial;
        left: 0; } } }

  &--bottom {
    margin: 0 20px 0 0;

    @include max-w(768) {
      margin-left: auto;
      margin-right: 15px; } } }

.lieader-categories__column-box {
  margin-top: 17px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
  overflow: hidden;

  @include max-w(768) {
    margin-top: 0; } }

.lieader-categories__author {
  padding: 0 28px;
  background-color: #fff;

  @include max-w(768) {
    overflow: hidden;
    margin: 0 0 11px 0;
    padding: 0; }

  &:last-child {

    .lieader-categories__author-inner {
      border-bottom: none; } } }

.lieader-categories__author-inner {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 20px 0;

  @include max-w(768) {
    flex-wrap: wrap;
    padding-bottom: 0; } }


.lieader-categories__column-number-img {
  display: flex;
  align-items: center;

  @include max-w(768) {
    margin-left: 15px; }

  @include max-w(320) {
    margin-left: 5px; } }

.lieader-categories__column-number {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #A5A5A5;
  width: 50px;
  padding: 0 15px;
  margin-right: 15px;

  @include max-w(768) {
    padding: 0;
    margin: 0;
    width: 50px; } }

.lieader-categories__column-img {
  margin-right: 15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgba(0,0,0, .1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

  @include max-w(480) {
    width: 35px;
    height: 35px;
    margin-right: 10px; } }


.lieader-categories__column-content {
  flex: 1;
  margin-right: 6%;

  @include max-w(768) {
    max-width: 175px;
    width: 100%; }

  @include max-w(480) {
    margin-right: 10px; } }

.lieader-categories__column-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #212121;
  text-decoration: none; }
.lieader-categories__column-community {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #A0A0A0; }

.lieader-categories__column-rating {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #212121;
  background: #F5F5F5;
  padding: 8px 15px 8px 40px;
  border-radius: 50px;
  margin-right: 15px;

  & span {
    position: absolute;
    content: '';
    background-image: url(./awards.svg);
    top: 10px;
    left: 12px;
    width: 19px;
    height: 17px; }

  @include max-w(480) {
    background-color: transparent;
    display: flex;
    flex-direction: column;

    span {
      padding-top: 20px;
      position: relative;

      &:after {
        top: 0;
        bottom: initial;
        left: 0; } } } }

.lieader-categories__column-link {
  text-decoration: none;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #A5A5A5;
  background: #F5F5F5;
  padding: 8px 15px 8px 15px;
  border-radius: 50px;
  transition: 200ms;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    width: 6px;
    height: 10px;
    margin-left: 10px; }

  &:hover {
    background: #389A52;
    color: #fff;
    transition: 200ms;

    svg {
      path {
        stroke: #fff; } } }

  @include max-w(768) {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;
    background-color: #ffffff;
    justify-content: center;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid rgba(0,0,0, .06);
    padding: 0;
    text-transform: uppercase;
    margin-top: 20px;

    &:hover {
      background-color: #fff;
      color: #389a52; } } }


.lieader-categories__user-link {
  text-decoration: none;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #A5A5A5;
  margin-top: 20px;
  padding: 10px 0px;
  cursor: pointer;

  @include max-w(768) {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    height: 50px;
    line-height: 50px;
    padding: 0; } }




.container--leaderboard {
  @include max-w(480) {
    padding: 0; } }
