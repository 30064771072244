@import "~common";

.PublicationCover {
  position: relative;
  min-height: 200px;

  .ReactCrop__image {
    max-height: none; } }

.PublicationCover__List {
  display: flex;
  flex-direction: column;
  height: 330px;
  background: #FAFAFA;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: hidden; }

.PublicationCover__ListBottom {
  display: none;
  text-align: right;
  flex-shrink: 0;
  padding: 17px 20px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);

  @include max-w(585) {
    display: block; } }

.PublicationCover__Bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06); }

.PublicationCover__BarText {
  font-size: 15px;
  line-height: 20px;
  color: #A1A1A1; }

.PublicationCover__BarButtons {

  @include max-w(585) {
    display: none; } }

.PublicationCover__BarButton {
  margin-left: 10px; }

.PublicationCover__ImageList {
  flex-grow: 1;
  min-height: 0;
  overflow: auto; }

.PublicationCover__ImageListWrap {
  display: flex;
  flex-wrap: wrap;
  padding: 15px; }

.PublicationCover__ImageItemWrap {
  flex-shrink: 0;
  padding: 5px;
  width: (100% / 6);

  @include max-w(585) {
    width: 25%; } }

.PublicationCover__ImageItem {
  position: relative;
  border-radius: 4px;
  background: {
    color: #f2f2d2;
    size: cover;
    position: center; }
  cursor: pointer;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    padding-top: 100%; }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: {
      image: url("./icon-check.svg");
      color: rgba(#000000, 0.6);
      repeat: no-repeat;
      position: center; } }

  &:hover:after,
  &.PublicationCover__ImageItem--Check:after {
    opacity: 1;
    transition: 0.15s opacity; } }

.PublicationCover__Uploader {
  height: 330px; }

.PublicationCover__ChnagerImage {
  position: absolute;
  top: 20px;
  right: 20px;

  input[type="file"] {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0.01;
    overflow: hidden;
    appearance: none; } }
