@import "~common";

.Modal-Quiz {
  font-family: $font;

  .ModalWindow__Main {
    padding: 0; }

  .ReactModal__Content--Custom {
    max-width: 880px;

    @include max-w(700) {
      max-width: 100%;
      width: 100%;
      max-height: 100vh; } }

  .ReactModal__Overlay--Custom {
    @include max-w(700) {
      overflow: initial; } }

  .ph-item {
    padding: 0;
    border: none;

    &--members {
      width: 100%; } } }

.Modal-Quiz__Head {
  padding: 25px 30px;
  border-bottom: 1px solid #E5E5E5;

  @include max-w(700) {
    padding: 20px 50px 24px 20px; } }

.Modal-Quiz__Title {
  font-size: 22px;
  line-height: 28px;
  font-weight: bold;

  @include max-w(700) {
    font-size: 20px;
    line-height: 27px; } }

.Modal-Quiz__Body {
  padding: 20px 30px 0;
  height: 720px;
  display: flex;
  flex-direction: column;
  position: relative;

  @include max-w(700) {
    padding: 20px 20px 0 20px;
    height: calc(100% - 72px); }

  @include max-w(662) {
    height: calc(100% - 99px); }

  @include max-w(428) {
    height: calc(100% - 126px); } }

.Modal-Quiz__Tabs {
  margin: 0 -10px 35px;
  flex-shrink: 0;

  @include max-w(700) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 0 -20px 30px 0; } }

.Modal-Quiz__Tab {
  font-family: $font;
  font-weight: 600;
  display: inline-block;
  padding: 6px 10px 6px 10px;
  font-size: 12px;
  line-height: 16px;
  color: #A6A6A6;
  border-radius: 2px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .2s, color .2s;
  margin-top: 10px;
  white-space: nowrap;
  cursor: pointer;

  @include max-w(700) {
    margin: 0; }

  &--Active,
  &:focus,
  &:hover {
    background-color: $header_bg;
    color: #fff; } }

.Modal-Quiz__Statistic {
  margin-bottom: 10px;

  @include max-w(480) {
    margin-bottom: 20px; } }

.Modal-Quiz__Statistic-Head {
  display: flex;
  justify-content: space-between; }

.Modal-Quiz__Statistic-Label {
  font-size: 15px;
  line-height: 21px;
  color: #212121; }

.Modal-Quiz__Statistic-Value {
  font-size: 15px;
  line-height: 21px;
  font-weight: bold; }

.Modal-Quiz__Statistic-Range {
  width: 100%;
  height: 12px;
  background-color: #F4F4F4;
  border-radius: 100px;
  position: relative;
  transition: width .3s;
  margin: 10px 0;

  span {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 100px;
    background-color: $header_bg; }

  @include max-w(700) {
    height: 8px; } }

.Modal-Quiz__Statistic-Footnote {
  color: #A6A6A6;

  @include max-w(480) {
    font-size: 14px; } }

.Modal-Quiz__Members {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7px;
  overflow-y: auto;
  padding-bottom: 40px;
  flex: 1;

  &::-webkit-scrollbar {
    display: none; }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    pointer-events: none; }

  @include max-w(700) {
    height: 100%;
    margin: 0; } }

.Modal-Quiz__Member {
  width: 14.28%;
  text-align: center;
  padding: 13px 7px;
  text-decoration: none;

  @include max-w(900) {
    width: 16.66%; }

  @include max-w(750) {
    width: 20%; }

  @include max-w(600) {
    width: 25%; }

  @include max-w(480) {
    width: 100%;
    display: flex;
    padding: 7px 0; } }

.Modal-Quiz__Member-Avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;

  .avatar {
    width: 100%;
    height: 100%;
    font-size: 28px; }

  @include max-w(480) {
    width: 45px;
    height: 45px;
    margin: 0 15px 0 0;

    .avatar {
      font-size: 14px; } } }

.Modal-Quiz__Member-Name {
  max-width: 70px;
  font-size: 12px;
  line-height: 16px;
  color: #212121;
  text-decoration: none;
  text-align: center;
  margin: 0 auto;

  @include max-w(480) {
    max-width: 100%;
    text-align: left;
    margin: 0;
    line-height: 45px; } }
