.RCTooltipCustom {
  opacity: 1;
  font-size: 14px;
  line-height: 20px;

  .rc-tooltip-inner {
    color: #FFFFFF;
    padding: 8px 12px;
    background: #212121;
    box-shadow: -10px 15px 40px rgba(0, 0, 0, 0.12);
    border-radius: 4px; }

  &.rc-tooltip-placement-top,
  &.rc-tooltip-placement-topLeft,
  &.rc-tooltip-placement-topRight {

    .rc-tooltip-arrow {
      border-top-color: #212121; } }

  &.rc-tooltip-placement-right,
  &.rc-tooltip-placement-rightTop,
  &.rc-tooltip-placement-rightBottom {

    .rc-tooltip-arrow {
      border-right-color: #212121; } }

  &.rc-tooltip-placement-left,
  &.rc-tooltip-placement-leftTop,
  &.rc-tooltip-placement-leftBottom {

    .rc-tooltip-arrow {
      border-left-color: #212121; } }

  &.rc-tooltip-placement-bottom,
  &.rc-tooltip-placement-bottomLeft,
  &.rc-tooltip-placement-bottomRight {

    .rc-tooltip-arrow {
      border-bottom-color: #212121; } } }
