@import "~common";

.profile-navigation {
  position: relative;
  margin-bottom: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);

  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 65px;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transition: 0.3s opacity;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%); }

  &:after {
    right: 0; }

  &:before {
    left: 0;
    transform: scale(-1); }

  &.has-sh-right:after {
    opacity: 1; }

  &.has-sh-left:before {
    opacity: 1; } }

.profile-navigation--materials {

  @include max-w(767) {
    margin-bottom: -16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.02); } }

.profile-navigation__list {
  position: relative;
  padding: 0 25px;
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  z-index: 0;

  @include max-w(480) {
    padding: 0 15px; } }

.profile-navigation__item {
  display: inline-block;
  margin-right: 28px;

  @include max-w(480) {
    margin-right: 18px; }

  &:last-child {
    margin-right: 0; } }

.profile-navigation__link {
  position: relative;
  display: block;
  padding: 24px 0;
  color: #A0A0A0;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    color: #212121;

    .count {
      color: #308446; } }

  @include max-w(480) {
    font-size: 14px;
    padding: 16px 0; }

  .count {
    display: inline-block;
    margin-left: 5px;
    color: #479B55;
    font-weight: normal; }

  &.is-active {
    color: #479B55;
    font-weight: 700;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: #479B55; } } }

.profile-navigation__toggle-search {
  position: absolute;
  top: 50%;
  right: 15px;
  padding: 0;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  color: #A0A0A0;
  border: none;
  background: {
    color: transparent;
    image: asset-url(search);
    repeat: no-repeat;
    position: center; }
  appearance: none;
  cursor: pointer;
  outline: none;

  @media (max-width: 767px) {
    display: none; }

  &:hover {
    background-image: asset-url(search-green); }

  &:after {
    position: absolute;
    content: "";
    background-image: asset-url(search-green);
    opacity: 0; } }
