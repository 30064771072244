@import "~common";

.ButtonIcon {
  padding: 9px 20px;
  font-size: 15px;
  line-height: 1;
  color: #fff;
  border-radius: 4px;
  border: none;
  background: rgba(#000, 0.7);
  cursor: pointer;
  transition: 0.15s background-color;
  appearance: none;

  &:hover {
    background-color: $color-main; } }

.ButtonIcon__Icon {
  margin-right: 9px;
  vertical-align: middle; }

.ButtonIcon__Text {
  vertical-align: middle; }
