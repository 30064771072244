.info-popup {
  background: #fff;
  position: relative;
  padding: 70px 16px 40px 16px;
  max-width: 735px;
  margin: auto;
  border-radius: 4px;

  @media screen and (max-width: 375px) {
    padding: 80px 16px 16px 16px; } }


.info-popup .info-popup__image-wrapper {
  max-width: 519px;
  max-height: 315px;
  margin: 0 auto 50px;
  overflow: hidden;
  border: 5px solid black;
  border-radius: 30px; }

.info-popup .info-popup__image {
  max-width: 100%; }

.info-popup .info-popup__title {
  font-size: 32px;
  line-height: 42px;
  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  margin-bottom: 16px;

  @media screen and (max-width: 375px) {
    font-size: 24px;
    line-height: 30px; } }

.info-popup .info-popup__text {
  line-height: 22px;
  text-align: center;
  max-width: 620px;
  margin: 0 auto 24px;
  font-family: 'Open Sans';
  font-size: 15px;
  @media screen and (max-width: 375px) {
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto 70px; } }


.info-popup .info-popup__button {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 375px) {
    display: block; } }

.info-popup .info-popup__link {
  background: #389A52;
  color: #fff;
  cursor: pointer;

  &:hover {
    background: #0b753f; }

  @media screen and (max-width: 375px) {
    display: block;
    height: 50px;
    line-height: 50px; } }

.info-popup .popup-upload__close {
  top: 25px;
  right: 30px;
  cursor: pointer;

  &:active {
    top: 25px; }

  @media screen and (max-width: 375px) {
    top: 15px;
    right: 15px;

    &:active {
      top: 15px; } } }

