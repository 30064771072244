@import "~common";

.invation_link {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  text-decoration: none;
  background: #FFF;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  font-size: 15px;
  font-weight: 400;
  outline: none;
  cursor: pointer;
  padding: 24px;
  padding-left: 54px + 24px;
  line-height: 40px;
  color: $link;
  margin-top: 20px;
  margin-bottom: 20px;
  &:before {
    position: absolute;
    top: 24px;
    left: 24px;
    content: '';
    width: 40px;
    height: 40px;
    @include rounded(50%);
    border: 2px solid $link;
    background: asset-url(icons-add-green) no-repeat 50% 50%;
  }
  &:hover {
    color: $link_dark;
    &:before {
      border-color: $link_dark;
    }
  }
}

.left_menu {
	position: relative;
	width: 100%;
	background: #FFF;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
	border: 1px solid rgba(0, 0, 0, 0.06);
	padding: 8px 24px;
  z-index: 5;
  margin-bottom: 30px;
	& + .left_menu {
		margin-top: 32px;
	}
	& + .left_block_ {
		margin-top: 56px;
	}

    @media (max-width: 991px) {
      padding: 8px 15px;
		margin-bottom:  30px;
    }

	a, button {
		display: block;
		overflow: hidden;
		white-space: nowrap;
		position: relative;
		text-decoration: none;
		color: #b0b0b0;
		color: rgba(0,0,0,.38);
		font-size: 15px;
		font-weight: 400;
		padding: 8px 0 8px 40px;
		position: relative;
		line-height: 24px;
		border: 0 none;
		background: transparent;
		outline: none;
		cursor: pointer;
		&.no_icon {
			padding: 8px 0;
		}
		&.export_users {
			padding: 16px 0 16px 56px;
			line-height: 40px;
			color: $link;
			&:before {
				position: absolute;
				top: 16px;
				left: 0;
				content: '';
				width: 40px;
				height: 40px;
				@include rounded(50%);
				border: 2px solid $link;
				background: asset-url(icon_export) no-repeat 50% 50%;
			}
			&:hover {
				color: $link_dark;
				&:before {
					border-color: $link_dark;
				}
			}
		}
		span {
			display: block;
			line-height: inherit;
			color: inherit;
			font-size: inherit;
			font-weight: inherit;
			&.left {
				float: left;
				width: 80%;
			}
			&.right {
				float: right;
				width: 18%;
				text-align: right;
			}
			&.full {
				width: 100%;
			}
		}
		.icon {
			width: 24px;
			height: 24px;
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -12px;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			&.icon-authors {
				background-image: asset-url(icons-authors-disable);
			}
			&.icon-themes {
				background-image: asset-url(icons-themes-disable);
			}
			&.icon-draft {
				background-image: asset-url(icons-drafts-disable);
			}
			&.icon-moderation {
				background-image: asset-url(icons-moderation-disable);
			}
			&.icon-favorite {
				background-image: asset-url(icons-favorite-disable);
			}
			&.icon-comments {
				background-image: asset-url(comments-disable);
			}
			&.icon-bookmarks {
				background-image: asset-url(icon-bookmark);
			}
			&.icon-allcategory {
				background-image: asset-url(icon-allcategory);
			}
			&.icon-trainings {
				background-image: asset-url(icon-trainings);
			}
			&.icon-books {
				background-image: asset-url(icon-books);
			}
			&.icon-profile {
				background-image: asset-url(icons-moderation-disable);
			}

		}
		&.active {
			color: $link;
			.icon {
				&.icon-authors {
					background-image: asset-url(icons-authors);
				}
				&.icon-themes{
					background-image: asset-url(icons-themes);
				}
				&.icon-draft {
					background-image: asset-url(icons-drafts);
				}
				&.icon-moderation {
					background-image: asset-url(icons-moderation);
				}
				&.icon-favorite {
					background-image: asset-url(icons-favorite);
				}
				&.icon-comments {
					background-image: asset-url(icon-comments);
				}
				&.icon-bookmarks {
					background-image: asset-url(icon-bookmark-enable);
				}
				&.icon-allcategory {
					background-image: asset-url(icon-allcategory-enable);
				}
				&.icon-trainings {
					background-image: asset-url(icon-trainings-enable);
				}
				&.icon-books {
					background-image: asset-url(icon-books-enable);
				}
				&.icon-profile {
					background-image: asset-url(icons-moderation);
				}
			}
		}
	}
	.title {
		font-size: 18px;
		font-weight: 700;
		color: #212121;
		margin-bottom: 24px;
		padding-top: 8px;
		line-height: 1;
	}
	.form_checkbox_toggle {
		margin-bottom: 24px;
		input[type=checkbox]:checked + label {
			color: $link;
		}
	}
	#dashboard_filter {
		select {
			display: none;
			visibility: hidden;
			opacity: 0;
		}
		.ui-selectmenu-button {
			display: block;
			border: 0 none !important;
			background: transparent !important;
			@include rounded(0);
			position: relative;
			padding: 0 28px 0 0;
			margin-bottom: 24px;
			.ui-selectmenu-icon {
				display: block;
				position: absolute;
				width: 20px;
				height: 20px;
				top: 50%;
				right: 0;
				margin: -10px 0 0;
				background: asset-url(arrow-down) no-repeat 50% 50% !important;
				float: none;
				transition: all .2s;
				-webkit-transition: all .2s;
				-moz-transition: all .2s;
			}
			.ui-selectmenu-text {
				display: block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: #a8a8a8;
				font-size: 15px;
				margin: 0;
			}
			&.ui-selectmenu-button-open {
				.ui-selectmenu-icon {
					transform: rotate(180deg);
					-webkit-transform: rotate(180deg);
					-moz-transform: rotate(180deg);
				}
			}
			&.select {
				.ui-selectmenu-text {
					color: $link;
				}
			}
		}
	}
	&.left_menu_coins {
		padding: 16px 24px;
		overflow: hidden;
		margin-bottom: 32px;
		strong {
			display: block;
			float: left;
			line-height: 24px;
			font-size: 15px;
			font-weight: 600;
			color: #000;
		}
		span {
			display: block;
			float: right;
			height: 24px;
			line-height: 24px;
			font-size: 14px;
			font-weight: 600;
			color: $link;
			&:before {
				content: '';
				width: 24px;
				height: 24px;
				display: inline-block;
				margin-right: 8px;
				vertical-align: middle;
				background: asset-url(sbercoints-new) no-repeat 50% 50%;
			}
		}
    }
}
