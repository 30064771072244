@import "~common";

.subscribe_theme_item_placeholder {
	background-image: url('./placeholders-image.svg');
	background-repeat: no-repeat;
	background-position: 50% 50%;
}
.subscribe_theme_item_admin {
  height: 200px;
}
.subscribe_theme_item {
	position: relative;
	width: 100%;
  height: 200px;
  padding-top: 24px;
	margin-bottom: 30px;
	overflow: hidden;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
	input {
		display: none;
		visibility: hidden;
		opacity: 0;
		width: 0;
		height: 0;
		position: absolute;
		top: -9999em;
		left: -9999em;
		& + label {
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;
			cursor: pointer;
			span {
				display: block;
				position: absolute;
				z-index: 5;
				&.img {
					display: block;
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: 3;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;

					img {
						width: 100%;
						height: auto;
						position: absolute;
						display: block;
						left: 0;
						top: 0;
						z-index: 4;
					}
					&:after {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba(0, 0, 0, 0.64);
						//transition: all .2s;
						z-index: 5;
					}
				}
				&.name {
					width: 100%;
					padding: 0 24px;
					font-size: 18px;
					font-weight: 700;
					color: #e0e0e0;
					color: rgba(255,255,255,.7);
					letter-spacing: -.1px;
					line-height: 20px;
          bottom: 20px;
					span {
						display: block;
						color: #e0e0e0;
						color: rgba(255,255,255,.7);
						font-weight: 400;
						font-size: 15px;
						padding-top: 10px;
						line-height: 1;
						position: relative;
						&.desc {
							display: block;
							overflow: hidden;
              color: #000000;
							transition: all .2s;
							width: 100%;
							font-size: 14px;
							letter-spacing: -0.2px;
							color: rgba(255, 255, 255, 0.7);
							font-weight: 400;
							padding: 4px 0 0;
							line-height: (20 / 14);
							overflow: hidden;
						}
					}
				}
			}
		}
		&:checked + label {
			span.img {
        &:after{
          box-shadow: 0 0 0 3px $color_main inset, 0 0 0 6px #fff inset;
        }
			}
			span.name {
				color: $color_main !important;
				span {
					color: $color_main;
				}
			}
		}
		&:disabled + label {
      opacity: .5;
			//cursor: default;
			//.img {
			//	opacity: .5;
			//}
			//.name {
			//	opacity: .6;
			//}
			//.img:after {
			//	background-color: rgba(0, 0, 0, 0.48) !important;
			//}
		}
	}
	.name_cat {
		position: absolute;
		width: 100%;
		padding: 0 24px;
		font-size: 18px;
		font-weight: 700;
		color:#fff;
		color: rgba(255,255,255,.7);
		letter-spacing: -.1px;
		line-height: 24px;
		left: 0;
		bottom: 24px;
		z-index: 5;
		&.white {
			color: #fff;
		}
		span {
			display: block;
			position: relative;
			padding-top: 5px;
			color: #FFF;
			color: rgba(255,255,255,.7);
			font-size: 15px;
			font-weight: normal;
			line-height: 1;
		}
	}
	.status {
		position: absolute;
		top: 24px;
		left: 24px;
		width: 24px;
		height: 24px;
		z-index: 5;
		display: block;
		&.private {
			background: url('./body_icons_lock.svg') no-repeat 50% 50%;
		}
		&.open {
			background: url('./body_icons_unlock.svg') no-repeat 50% 50%;
		}
	}
	.moderate-label {
		position: absolute;
		display: block;
		z-index: 5;
		height: 24px;
		left: 46px + 16;
		top: 24px;
		line-height: 24px;
		border-radius: 2px;
		padding: 0 8px;
		background: rgba(56, 154, 82, 0.88);
		text-transform: uppercase;
		font-size: 10px;
		color: #fff;

    &.is-disabled {
      background: #c9655e;
    }
	}
	a.edit_category_link {
		display: block;
		text-decoration: none;
		overflow: hidden;
		white-space: nowrap;
		text-indent: -9999em;
		color: transparent;
		position: absolute;
		top: 24px;
		right: 24px;
		width: 40px;
		height: 40px;
		z-index: 5;
		background: rgba(0, 0, 0, 0.54) url('./body_icons_edit.svg') no-repeat 50% 50%;
		border-radius: 50%;
	}
	.img_cat {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		img {
			width: 100%;
			height: auto;
			position: absolute;
			display: block;
			left: 0;
			top: 0;
			z-index: 1;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.48);
			transition: all .2s;
			-webkit-transition: all .2s;
			-moz-transition: all .2s;
			z-index: 2;
		}
	}
}
