@import "~common";

.QuizEdit {
  padding: 45px 30px;
  background: #FAFAFA;

  @media (max-width: 480px) {
    padding: 15px 20px 35px;
    margin: 0 -20px; } }

.QuizEdit__HeadWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5px; }

.QuizEdit__Head {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #A1A1A1;

  @media (max-width: 480px) {
    font-size: 15px;
    line-height: 22px; } }

.QuizEdit__Close {
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #EEEEEE;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 40px;
    height: 40px; }

  &:before,
  &:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 20px;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -10px;
    background-color: #C1C1C1;

    @media (max-width: 480px) {
      width: 15px;
      height: 1px;
      margin-top: -0.5px;
      margin-left: -7.5px; } }

  &:before {
    transform: rotate(45deg); }

  &:after {
    transform: rotate(-45deg); } }

.QuizEdit__QuestionWrap {
  position: relative;
  padding-right: 115px;
  padding-bottom: 25px;
  border-bottom: 1px solid #E5E5E5;

  @media (max-width: 480px) {
    padding-right: 75px;
    padding-bottom: 20px; } }

.QuizEdit__QuestionInput {
  display: block;
  font-weight: bold;
  font-size: 38px;
  line-height: 45px;
  padding: 0;
  margin: 0;
  width: 100%;
  color: #212121;
  border: none;
  outline: none;
  resize: none;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  appearance: none;
  overflow: hidden;

  @media (max-width: 480px) {
    font-size: 26px;
    line-height: 32px; }

  &::placeholder {
    color: #A1A1A1; }

  &:focus::placeholder {
    opacity: 0.5; }

  &--Error {
    border-bottom-color: $color-error; } }

.QuizEdit__QuestionCounter {
  position: absolute;
  padding: 0 20px;
  top: 12px;
  right: 0;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  color: #212121;
  background: #ECECEC;
  border-radius: 4px;

  @media (max-width: 480px) {
    padding: 0 10px;
    font-size: 15px;
    line-height: 22px; } }

.QuizEdit__Group {
  margin-top: 30px;

  @media (max-width: 480px) {
    margin-top: 25px; } }

.QuizEdit__GroupTitle {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #212121;

  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 28px; } }

.QuizEdit__Answer {
  position: relative;

  & + & {
    margin-top: 15px; } }

.QuizEdit__AnswerInput {
  display: block;
  width: 100%;
  height: 55px;
  padding: 0 20px;
  font-size: 15px;
  line-height: 22px;
  color: #212121;
  background-color: #fff;
  appearance: none;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  &::placeholder {
    color: #A0A0A0; }

  &--Error {
    border-color: $color-error; } }

.QuizEdit__AnswerRemove {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 55px;
  border-left: 1px solid #F0F0F0;
  cursor: pointer;

  &:before,
  &:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 20px;
    top: 50%;
    left: 50%;
    margin-top: -0.5px;
    margin-left: -10px;
    background-color: #C1C1C1; }

  &:before {
    transform: rotate(45deg); }

  &:after {
    transform: rotate(-45deg); } }

.QuizEdit__AnswerAdd {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 100%;
  margin-top: 15px;
  text-align: center;
  font-size: 15px;
  line-height: 22px;
  color: #212121;
  border: 1px solid #EBEBEB;
  background-color: transparent;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.06));
  border-radius: 4px;
  cursor: text;
  appearance: none;
  outline: none;

  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; } }

.QuizEdit__Backgrounds {
  display: flex;
  margin: 0 -7.5px; }

.QuizEdit__BGItem {
  padding: 0 7.5px; }

.QuizEdit__BG {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer; }

.QuizEdit__BGPreview {
  display: block; }

.QuizEdit__BGInputWrap {
  position: relative;
  margin-top: 5px; }

.QuizEdit__BGInput {
  appearance: none;
  position: absolute;
  padding: 0;
  margin: 0;
  width: 1px;
  height: 1px;
  opacity: 0.005;
  overflow: hidden; }

.QuizEdit__BGControl {
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  background: #FFFFFF;
  border: 1px solid #C1C1C1;
  border-radius: 50%;

  input:checked + & {
    border-color: #389A52;

    &:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      top: 50%;
      left: 50%;
      margin-top: -5px;
      margin-left: -5px;
      border-radius: 50%;
      background-color: #389A52; } } }

.QuizEdit__GroupRow {
  display: flex;
  margin: 0 -10px;

  @include max-w(640) {
    display: block;
    margin: 0; } }

.QuizEdit__GroupCol {
  width: 50%;
  padding: 0 10px;

  @include max-w(640) {
    width: auto;
    padding: 0;

    & + & {
      margin-top: 20px; } } }

.QuizEdit__GroupInputLabel {
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #212121;

  span {
    color: #A1A1A1; } }

.QuizEdit__Switcher {
  display: flex;
  height: 55px;
  width: 100%;
  padding: 5px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  &--Disabled {
    opacity: 0.5;
    cursor: not-allowed; } }

.QuizEdit__SwitcherBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  min-width: 0;
  font-size: 15px;
  line-height: 22px;
  color: #212121;
  cursor: pointer;

  &--Selected {
    background: rgba(#389A52, 0.1);
    border-radius: 4px;

    &:before {
      display: none; } }

  &--Selected + &:before {
    display: none; }

  &:not(:first-child):before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    bottom: 5px;
    width: 1px;
    background: #EBEBEB; } }

.QuizEdit__GroupCheckRow {
  line-height: 1;
  margin-top: 17px; }

.QuizEdit__DateEnd {

  &--Error {

    .QuizEditDateTimeInput .react-datepicker-wrapper {
      border-color: $color-error; } } }
