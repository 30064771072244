@import "~common";

.delete_this-selector {
  display: none; }

.table__top {
  font-family: $font; }



.table__top {
  width: 100%;
  margin: 0;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  border: 0 none !important;
  .table__head {
    th {
      padding: 16px 24px;
      text-align: left;
      border: 0 none;
      vertical-align: middle;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54);
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      background: transparent;
      .icon {
        display: block;
        width: 24px;
        height: 24px;
        &.icon_bookmark {
          background: asset-url(icon-bookmark) no-repeat 50% 50%; }
        &.icon_comment {
          background: asset-url(comments-disable) no-repeat 50% 50%; }
        &.icon_raiting {
          background: asset-url(star-default) no-repeat 50% 50%; }
        &.icon_eye {
          background: asset-url(eye-disable) no-repeat 50% 50%; } }
      .th-inner.sortable {
        position: relative;
        cursor: pointer;
        padding-right: 24px;
        display: inline-block;
        &.sorted {
          color: $link;
          .icon {
            &.icon_bookmark {
              background: asset-url(icon-bookmark-enable) no-repeat 50% 50%; }
            &.icon_comment {
              background: asset-url(icon-comments) no-repeat 50% 50%; }
            &.icon_raiting {
              background: asset-url(star-over) no-repeat 50% 50%; }
            &.icon_eye {
              background: asset-url(eye-enable) no-repeat 50% 50%; } }
          .order {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -8px;
            width: 16px;
            height: 16px;
            &:before {
              position: absolute;
              width: 0;
              height: 0;
              content: '';
              top: 0;
              left: 0;
              border-style: solid;
              border-width: 0 4px 4px 4px;
              border-color: transparent transparent rgba(0, 0, 0, 0.24) transparent; }
            &:after {
              position: absolute;
              width: 0;
              height: 0;
              content: '';
              bottom: 0;
              left: 0;
              border-style: solid;
              border-width: 4px 4px 0 4px;
              border-color: rgba(0, 0, 0, 0.54) transparent transparent transparent; }
            &.dropup {
              &:before {
                border-color: transparent transparent rgba(0, 0, 0, 0.54) transparent; }
              &:after {
                border-color: rgba(0, 0, 0, 0.24) transparent transparent transparent; } } } } } } }
  tbody {
    tr {
      td {
        padding: 14px 24px;
        text-align: left;
        font-weight: 600;
        font-size: 14px;
        color: #212121;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
        a {
          text-decoration: none;
          font-weight: 600;
          color: $link;

          &:hover {
            color: #308446; } }

        &.normal {
          font-weight: 500; }
        &.gray {
          color: rgba(0, 0, 0, 0.24); } }
      &:hover {
        td {
          background: #fafafa; } } } } }

.fixed-table-header {
  height: 0; }
