.empty-data {
  position: relative;
  padding: 72px 24px 80px;
  width: 100%;
  background: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box; }

.empty-data__icon {
  max-width: 100%;
  margin-bottom: 15px; }

.empty-data__title {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 15px;
  color: #C1C1C1; }

.empty-data__description {
  font-size: 13px;
  line-height: 22px;
  color: #C1C1C1; }
