@import "~common";

.article_admin_panel {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: 0 0 0 -580px;
  width: 76px;
  @include rounded(38px);
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px rgba(0, 0, 0, 0.09);
  padding: 8px 8px 24px;
  transform: translateY(-50%);
  @media (max-width: 1199px) {
    left: 0.3%;
    margin: 0; }
  @media (max-width: 767px) {
    opacity: 0.5;
    &:hover {
      opacity: 1; } }
  a {
    display: block;
    width: 100%;
    position: relative;
    padding-top: 60px;
    text-align: center;
    text-decoration: none;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.88);
    &:before {
      content: '';
      position: absolute;
      width: 60px;
      height: 60px;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -30px; }
    &.admin_stat {
      &:before {
        background: asset-url(body_icons_tools_statistic) no-repeat 50% 50%; } }
    &.admin_move {
      &:before {
        background: asset-url(body-icons-tools-transfer) no-repeat 50% 50%; } }
    &.admin_video {
      &:before {
        background: asset-url(body-icons-tools-downloadvideo) no-repeat 50% 50%; } }
    & + a {
      margin-top: 16px; } } }

.article_or_comments_description,
.detail_material .article_or_comments_description {
  border: none;
  outline: none;
  resize: none;
  overflow: hidden;
  display: block;
  box-shadow: none;
  margin-bottom: 16px;
  font-size: 15px;
  font-weight: 400;
  color: #212121;
  line-height: 26px;
  p {
    font-size: 16px;
    font-weight: 400;
    color: #212121;
    line-height: 27px;
    word-break: break-word;
    margin-bottom: 10px;

    & + ul, & + ol {
      margin-bottom: 6px; }

    a {
      color: $link_dark;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $link; } } }
  ul {
    li {
      list-style: outside disc;
      margin-left: 25px;
      font-size: 16px;
      font-weight: 400;
      color: #212121;
      line-height: 22px;
      margin-bottom: 9px; } }

  ol {
    li {
      list-style: outside decimal;
      margin-left: 25px;
      font-size: 15px;
      font-weight: 400;
      color: #212121;
      line-height: 22px;
      margin-bottom: 9px; } }

  li {
    a {
      color: $link_dark;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $link; } } } }

.add_comment_form,
.add_subcomment_form {
  .mce-panel {
    border-color: transparent;
    background-color: transparent; }
  .mce-tinymce {
    box-shadow: none; }
  .mce-top-part {
    margin-bottom: 10px;
    &::before {
      box-shadow: none; } }
  .mce-btn {
    background: transparent; }

  .mce-edit-area {
    background: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-width: 1px !important; }

  .mce-btn-group:not(:first-child) {
    border-left: none; } }
