@import "~common";

.greet-wrap {

  @media (max-width: 600px) {

    .mfp-content {
      height: 100%;
      padding: 10px 0; } } }

.greet {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 735px;
  width: 100%;
  height: 643px;
  margin: 0 auto;
  padding-top: 15px;
  border-radius: 4px;
  background-color: #ffffff;

  @media (max-width: 600px) {
    height: 100%; } }

.greet__step {
  margin: 60px 0 0 60px;
  color: #389a52;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px; }

.greet__slider {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  min-height: 0;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 105px;
    background-image: linear-gradient(to top, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
    z-index: 1; } }

.greet__info {
  position: relative;
  width: 100%;
  padding-bottom: 30px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 1024px) {
    &::-webkit-scrollbar {
      width: 8px; }

    &::-webkit-scrollbar-track {
        background: rgba(255,255,255,.1); }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: $link; } }

  &.active {
    display: block; } }

.greet__info--offScroll {
  &::-webkit-scrollbar {
    display: none; } }


.greet__info-wrap {
  margin: 0 auto;
  padding: 0 60px; }

.greet__user {
  display: flex;
  width: 120px;
  height: 120px;
  margin: 31px auto 22px;
  background-color: #cdd8b7;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%; } }

.greet__initial {
  margin: auto;
  color: #ffffff;
  font-size: 34px;
  font-weight: 700; }

.greet__welcome {
  width: 349px;
  margin: 0 auto 17px;
  text-align: center;
  color: #212121;
  font-size: 32px;
  font-weight: 700;
  line-height: 42px; }

.greet__desc {
  width: 470px;
  margin: 0 auto;
  text-align: center;
  color: #212121;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px; }

.greet__shadow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 105px;
  background-image: linear-gradient(to top, #ffffff 0%, rgba(255, 255, 255, 0) 100%); }


.greet__control {
  flex-shrink: 0;
  max-height: 71px;
  height: 100%;
  padding: 0 60px;
  border-radius: 0 0 4px 4px;
  background-color: #fafafa;
  border-top: 1px #e6e6e6 solid; }

.greet__control-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%; }

.greet__points {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90px; }

.greet__point {
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #a0a0a0; }

.greet__point--active {
  background-color: #389a52;
  width: 14px;
  height: 14px; }

.greet__point--done {
  background-color: #389a52; }

.greet__button {
  display: inline-block;
  padding: 13px 30px;
  border-radius: 4px;
  background-color: #038952;
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  border: none;

  &:hover {
    color: #ffffff; } }

.greet__button--disable {
  background-color: #e6e6e6;
  color: black;

  &:hover {
    color: black; } }

.greet__title {
  margin-bottom: 8px;
  margin-top: 20px;
  font-size: 24px;
  font-weight: 700; }

.greet__subtitle {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px; }

.greet__text {
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px; }

.greet__choice {
  margin-bottom: 25px;
  color: #a0a0a0;
  font-size: 15px;
  font-weight: 400; }

.greet__item {
  width: 100%;
  height: 100%;
  outline: none;
  margin: unset; }

.greet__form {
  margin: 0 -5px;
  overflow: hidden; }

.greet__form-wrap {
  padding: 5px;
  float: left;
  width: 20%; }

.greet__form-item {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 115px; }

.greet__form-item input + label {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer; }

.greet__form-item input:checked + label:after {
  content: '';
  position: absolute;
  width: 28px;
  height: 21px;
  top: 10px;
  right: 10px;
  z-index: 6;
  background: url('greet__check.png') no-repeat 50% 50%; }

.greet__form-item input + label .greet__image:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6); }

.greet__form-item input:checked + label .greet__image:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(49, 137, 73, 0.7); }

.greet__image {
  background-image: url("greet__image.png");
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.greet__name {
  position: absolute;
  width: 100%;
  padding: 0 9px 12px;
  left: 0;
  bottom: 0px;
  right: 0;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  z-index: 10;
  word-wrap: break-word; }

.greet__form-item input:checked + label .greet__name:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 35px;
  background-image: linear-gradient(to top, #389a52 0%, rgba(56, 154, 82, 0) 100%); }

//.mfp-close
//display: none!important


@media (max-width: 600px) {
  .greet__info {
    padding-bottom: unset; }

  .greet__step {
    margin: 35px 0 0 15px; }

  .greet__info-wrap {
    padding: 0 15px;
    margin: unset; }

  .greet__user {
    width: 90px;
    height: 90px;
    margin: 23px auto 15px; }

  .greet__initial {
    font-size: 24px;
    font-weight: 700; }

  .greet__welcome {
    width: 270px;
    margin: 0 auto 17px;
    font-size: 24px;
    line-height: 30px; }

  .greet__desc {
    width: 100%;
    margin: unset;
    text-align: unset; }

  .greet__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 13px; }

  .greet__choice {
    line-height: 22px; }

  .greet__subtitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px; }

  .greet__slider::after {
    height: 40px; }

  .greet__control {
    height: 64px;
    padding: 0 16px; }

  .greet__points {
    display: none; }

  .greet__button {
    width: 100%; } }

@media (max-width: 730px) {
  .greet__form-wrap {
    width: 25%; }

  .greet__form-item {
    height: 125px; } }


@media (max-width: 530px) {
  .greet__form-wrap {
    width: (100%/3); } }


@media (max-width: 420px) {
  .greet__form-wrap {
    width: 50%; }

  .greet__form-item {
    height: 140px; } }
