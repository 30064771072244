@import "~common";

.leader-users {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-top: 20px; }

.leader-users__found-box {
  padding: 22px 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06); }

.leader-users__found {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #000000; }

.leader-users__list-users {
  list-style: none; }

.leader-users__list-elem {
  padding: 25px 25px 0 30px;

  @include max-w(700) {
    padding: 10px 10px 0 10px; }

  &:hover {
    .leader-users__awards {
      display: none; }

    .leader-users__subscribe {
      display: block;
      color: #FFFFFF; }

    .leader-users__unsubscribe {
      display: block;
      color: #F0443D; } } }

.leader-users__list-elem.leader-users__list-elem--gold {
  .leader-users__avatar {
    &::after {
      position: absolute;
      content: '';
      background-image: url("./gold-r.svg");
      width: 29px;
      height: 56px;
      top: -5px;
      right: -11px;
      z-index: 1; }

    &::before {
      position: absolute;
      content: '';
      background-image: url("./gold-l.svg");
      width: 29px;
      height: 56px;
      top: -5px;
      left: -11px;
      z-index: 1; } } }

.leader-users__list-elem.leader-users__list-elem--silver {
  .leader-users__avatar {
    &::after {
      position: absolute;
      content: '';
      background-image: url("./silver-r.svg");
      width: 29px;
      height: 56px;
      top: -5px;
      right: -11px;
      z-index: 1; }

    &::before {
      position: absolute;
      content: '';
      background-image: url("./silver-l.svg");
      width: 29px;
      height: 56px;
      top: -5px;
      left: -11px;
      z-index: 1; } } }

.leader-users__list-elem.leader-users__list-elem--bronze {
  .leader-users__avatar {
    &::after {
      position: absolute;
      content: '';
      background-image: url("./bronze-r.svg");
      width: 29px;
      height: 56px;
      top: -5px;
      right: -11px;
      z-index: 1; }

    &::before {
      position: absolute;
      content: '';
      background-image: url("./bronze-l.svg");
      width: 29px;
      height: 56px;
      top: -5px;
      left: -11px;
      z-index: 1; } } }

.leader-users__list-elem:nth-of-type(1) .leader-users__count {
  color: #FEC962; }

.leader-users__list-elem:nth-of-type(2) .leader-users__count {
  color: #B5BECF; }

.leader-users__list-elem:nth-of-type(3) .leader-users__count {
  color: #C27726; }

.leader-users__wrap {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding-bottom: 20px; }

.leader-users__count {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #A5A5A5;
  margin-right: 30px;
  @include max-w(700) {
    margin-right: 15px; } }

.leader-users__avatar {
  position: relative;
  margin-right: 15px;
  text-decoration: none; }

.leader-users__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1; }

.leader-users__title {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: #212121;
  text-decoration: none; }

.leader-users__descr {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #212121;
  text-decoration: none; }

.leader-users__date {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #A0A0A0;
  text-decoration: none; }

.leader-users__awards {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: right;
  color: #212121;
  padding-left: 25px;

  &::before {
    position: absolute;
    content: '';
    width: 19px;
    height: 17px;
    background-image: url("./awards.svg");
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    left: 0; } }

.leader-users__subscribe,
.leader-users__unsubscribe {
  display: none;
  position: relative;
  height: 40px;
  line-height: 36px;
  padding-right: 25px;
  padding-left: 25px;
  font-size: 15px;
  color: #fff;
  background: #389A52;
  border-radius: 4px;
  border: 1px solid #389A52;
  text-decoration: none;
  -webkit-transition: 0.15s background;
  transition: 0.15s background; }

.leader-users__unsubscribe {
  background-color: transparent;
  border-color: #F0443D;
  color: #F0443D; }

.avatar_leader-users__avatar-img {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 52px !important;
  height: 52px !important;
  position: relative;
  z-index: 2; }
