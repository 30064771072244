@import "~common";

.MentionItem {
  display: flex;
  align-items: center; }

.MentionItem__Avatar {
  flex-shrink: 0;

  .avatar {
    width: 30px;
    height: 30px;
    line-height: 30px; } }

.MentionTooltipWrap {
  opacity: 1;

  .rc-tooltip-inner {
    padding: 0;
    background: transparent;
    box-shadow: none; }

  .rc-tooltip-arrow {
    border: none;
    width: 20px;
    height: 20px;
    background: #fff;
    margin-left: -5;
    transform: rotate(-45deg); }

  &.rc-tooltip-placement-bottom .rc-tooltip-arrow,
  &.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  &.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    top: 0;
    margin-left: -10px; }

  &.rc-tooltip-placement-bottom .rc-tooltip-arrow {
    border-top: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5; } }


.MentionTooltip {
  width: 440px;
  padding: 35px 30px 25px;
  color: #212121;
  background: #ffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  overflow: hidden; }

.MentionTooltip__Main {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #EBEBEB; }

.MentionTooltip__Avatar {
  flex-shrink: 0;
  margin-right: 20px;

  > .avatar {
    width: 70px;
    height: 70px;
    line-height: 68px; } }

.MentionTooltip__MainInfo {
  min-width: 0;
  flex-grow: 1; }

.MentionTooltip__Name {
  display: inline-block;
  margin-bottom: 5px;
  color: #212121;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none; }

.MentionTooltip__Description {
  color: #A0A0A0;
  font-size: 12px;
  line-height: 16px; }

.MentionTooltip__Bottom {
  display: flex;
  padding-top: 20px; }

.MentionTooltip__Stats {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;
  min-width: 0; }

.MentionTooltip__Stat {
  margin-right: 15px;
  color: #212121;
  font-size: 12px;
  line-height: 20px;

  > span {
    color: $color-main;
    font-size: 15px; } }

.MentionTooltip__Action {
  flex-shrink: 0; }
