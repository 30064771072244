@import "~common";

.c-banner-condition {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background-color: #fff; }

.c-banner-condition__inner {
  display: flex;
  justify-content: space-between;
  padding: 18px 25px;

  @include max-w(768) {
    flex-direction: column;
    align-items: center;
    padding: 40px 15px 20px; } }

.c-banner-condition__left-side,
.c-banner-condition__right-side {
  display: flex;
  position: relative; }

.c-banner-condition__left-side {
  margin-right: 40px;

  @include max-w(768) {
    margin: 0 0 13px 0;
    flex-direction: column;
    align-items: center; } }

.c-banner-condition__right-side {
  flex-shrink: 0;
  display: flex;
  align-items: center;

  @include max-w(480) {
    width: 100%; } }

.c-banner-condition__description {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include max-w(768) {
    text-align: center; } }

.c-banner-condition__logo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 20px;

  @include max-w(768) {
    margin: 0;
    width: 105px;
    height: 105px;
    margin-bottom: 15px; }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; } }

.c-banner-condition__title {
  font-size: 18px;
  line-height: 20px;
  color: #000;
  font-weight: bold;
  margin-bottom: 5px;

  @include max-w(768) {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 5px; } }

.c-banner-condition__subtitle {
  font-size: 15px;
  line-height: 19px;
  color: #000;

  @include max-w(480) {
    line-height: 24px; } }

.c-banner-condition__add-btn {
  display: inline-block;
  height: 40px;
  line-height: 36px;
  padding-right: 25px;
  padding-left: 25px;
  font-size: 15px;
  color: #fff;
  background: #389A52;
  border-radius: 4px;
  border: 1px solid #389A52;
  text-decoration: none;
  text-align: center;
  transition: 0.15s background;

  &:hover {
    background-color: #308446;
    color: #fff; }

  @include max-w(480) {
    width: 100%;
    height: 50px;
    line-height: 50px; } }

