@import "~common";

.switch-scope-notify {
  max-width: 430px; }

.switch-scope-notify__wrapper {
  display: flex;
  position: relative;
  padding: 25px 40px 25px 20px;
  background-color: #fff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15); }

.switch-scope-notify__icon-wrap {
  flex-shrink: 0;
  margin-right: 15px;

  @include max-w(385) {
    display: none; }

  img {
    display: block; } }

.switch-scope-notify__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  min-width: 0; }

.switch-scope-notify__title {
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;
  color: #212121;
  margin-bottom: 2px; }

.switch-scope-notify__description {
  font-size: 13px;
  line-height: 18px;
  color: #212121; }

.switch-scope-notify__close {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 20px;
  right: 20px;
  background: {
    image: url("./icon-close.svg");
    repeat: no-repeat; }
  cursor: pointer; }

.tooltipster-default.swithcer-noty {
  margin-top: 3px;
  border: none;
  padding: 0;
  border-radius: 0;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
  z-index: 1000;

  .tooltipster-content {
    padding: 0; }

  .tooltipster-arrow-bottom span {
      border-bottom-color: #fff !important;
      border-left-width: 16px !important;
      border-right-width: 16px !important;
      border-bottom-width: 16px;
      top: -15px; } }
