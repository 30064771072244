.import-user {
  position: relative; }

.import-user__close {
  position: absolute;
  top: -20px;
  right: -20px;
  cursor: pointer; }

.import-user__label {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 20px; }

.import-user__wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%; }

.import-user__submit {
  display: inline-block;
  position: relative;
  height: 40px;
  line-height: 36px;
  padding-right: 25px;
  padding-left: 25px;
  font-size: 15px;
  color: #fff;
  background-color: #389A52;
  border-radius: 4px;
  border: 1px solid #389A52;
  text-decoration: none;
  -webkit-transition: 0.15s background;
  transition: 0.15s background;
  vertical-align: top;
  cursor: pointer;

  &:hover {
    color: #308446;
    background-color: transparent; } }
