@import "~common";

.tooltipster-base.tooltipster-rs {
	padding: 0;
	font-size: 0;
	line-height: 0;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 9999999;
	pointer-events: none;
	width: auto;
	overflow: visible;
	.tooltipster-content {
		overflow: hidden;
		position: relative;
	}
}


.tooltipster-rs {
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
	border: solid 1px rgba(0, 0, 0, 0.09);
	background: #fff;
	color: #212121;
	position: absolute;
	@include rounded(4px);
	.tooltipster-content {
		padding: 16px;
		line-height: 1.2;
		position: relative;
		z-index: 1000;
		.avatar {
			top: 0;
			left: 0;
			position: absolute;
		}
		.nowrap {
			padding: 0 0 2px;
			vertical-align: middle;
			line-height: 20px;
			span {
				vertical-align: middle;
			}

		}
		.author_info_tooltip {
			position: relative;
			padding: 0 0 0 56px;
		}
		.roles {
			padding-top: 32px;
			position: relative;
			&:before {
				position: absolute;
				content: '';
				left: 8px;
				right: 8px;
				width: auto;
				height: 1px;
				top: 16px;
				display: block;
				background: #f0f0f0;
			}
			.badge {
				margin-bottom: 8px;
				margin-right: 4px;
			}
			.roles_item {
				& + .roles_item {
					padding-top: 8px;
				}
			}
		}
		form.hide {
			display: block;
			button {
				@include btn();
			}
		}
		span.hide {
			display: block;
			opacity: 0;
			font-size: 13px;
			line-height: 36px;
			color: #b0b0b0;
			-webkit-transition: .5s ease-out;
			-o-transition: .5s ease-out;
			-moz-transition: .5s ease-out;
			transition: .5s ease-out;
			&.ready {
				opacity: 1;
			}
		}
	}
	&.tooltipster-lightgray {
		background: #fafafa;
	}
}

.tooltipster__subscribe-btn {
    @include btn()
}
