@import '~common';

.detail_ {

  #presentation_in_videopresentation {
    position: relative;
    width: 100%;
    margin: 32px 0;
    z-index: 1;
    .detail_edit_presentation, #ppt_wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 0;
      padding: 0 0 56.25%;
      canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        height: 100%; }
      .remove_item {
        display: block;
        position: absolute;
        z-index: 10;
        text-indent: -9999em;
        color: transparent;
        display: block;
        text-decoration: none;
        position: absolute;
        width: 40px;
        height: 40px;

        @include rounded(50%);
        background: rgba(0, 0, 0, 0.54) asset-url(close-white) no-repeat 50% 50%;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 100;
        border: 0 none;
        outline: none;

        &:hover {
          background-color: $link; } }

      #navigation {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #FFF;
        margin: 0;
        height: 40px; }
      #progress_bar {
        background: #FFF;
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 40px;
        margin: 0; }
      .dropzone {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 100px !important;
        padding-bottom: 0 !important;
        z-index: 1;
        .dz-message {
          &:before {
            background-size: 60px 60px; }
          button {
            display: none !important; } } } } }
  .detail_edit_video {
    position: relative;
    width: 100%;
    margin: 32px 0;
    z-index: 1;
    &_inner {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 0;
      padding: 0 0 56.25%;
      .remove_item {
        display: block;
        position: absolute;
        z-index: 10;
        text-indent: -9999em;
        color: transparent;
        display: block;
        text-decoration: none;
        position: absolute;
        width: 40px;
        height: 40px;
        @include rounded(50%);
        background: rgba(0, 0, 0, 0.54) asset-url(close-white) no-repeat 50% 50%;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 100;
        border: 0 none;
        outline: none;
        &:hover {
          background-color: $link; } }
      iframe, video, object, embed {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
        top: 0;
        left: 0;
        z-index: 1; }
      .dropzone {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 100px !important;
        padding-bottom: 0 !important;
        z-index: 1;
        .dz-message {
          &:before {
            background-image: asset-url(placeholders-video) !important; } }
        &.dz-started + .video_insert_link {
          display: none !important; } }
      .video_insert_link {
        position: absolute;
        width: auto;
        left: 2px;
        right: 2px;
        top: 280px;
        text-align: center;
        z-index: 10;
        .form_item {
          width: 100%;
          padding: 0;
          &:before {
            display: none; }
          label, input {
            text-align: center;
            font-size: 15px;
            width: 100%;
            padding-left: 0 !important; }
          label {
            top: 0;
            left: 0;
            color: #c1c1c1; }
          input {
            color: #212121; } } }
      &.detail_edit_videopresentation {
        .dropzone {
          padding-top: 150px !important; } } } }
  .add_timeline_item {
    margin-bottom: 32px;
    overflow: hidden;
    span {
      font-size: 24px;
      font-weight: 600;
      color: #000;
      line-height: $form_height;
      letter-spacing: -.5px; }
    #add_time {
      @include btn;
      float: right; } }
  #timelines {
    .timeline_item {
      margin-bottom: 24px;
      span {
        color: #c1c1c1;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -.1px; }
      strong {
        color: #000;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -.1px;
        padding-left: 3px; }
      .remove_timeline {
        width: 24px;
        height: 24px;
        display: inline-block;
        zoom: 1;
        *display: inline;
        text-indent: -9999em;
        color: transparent;
        text-decoration: none;
        border: 0 none;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        background: transparent asset-url(close-black) no-repeat 50% 50%; }
      &:last-child {
        margin-bottom: 40px; } } }
  .detail_header {
    position: relative;
    padding: 6px 0 0 76px;
    min-height: 60px;
    .avatar {
      position: absolute;
      top: 0;
      left: 0; }
    &.with_back {
      padding: 6px 0 0 116px;
      .avatar {
        left: 40px; }
      .back {
        height: 60px;
        line-height: 60px;
        width: 24px;
        position: absolute;
        top: 0;
        left: 0;
        a {
          text-indent: -9999em;
          display: block;
          position: relative;
          line-height: 60px;
          height: 60px;
          text-decoration: none;
          color: transparent;
          background: asset-url(icon-back) 0 50% no-repeat; } } }
    form {
      display: inline;
      input[type=submit] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0 none;
        background: transparent;
        outline: none;
        cursor: pointer;
        padding: 0;
        margin: 0;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        font-weight: inherit; }
      &.hide {
        display: none; } }
    div {
      .badge {
        margin-left: 13px;
        & + .badge {
          margin-left: 7px; } }
      & + div {
        padding-top: 2px; } } }
  a.publish_link, .subscribe_user_header button {
    @include btn;
    margin-top: 10px;
    & + a.publish_link {
      margin-left: 12px; } }
  .process_info {
    color: #c1c1c1;
    display: none;
    background: #FFF;
    line-height: 60px;
    font-size: 13px;
    font-weight: 400;
    position: absolute;
    top: 0;
    right: 8px;
    z-index: 3;
    width: 100%; }

  //.detail_cover,
  .detail_title,
  .detail_desc,
  .detail_material,
  .detail_tags {
    //.longread_cover
    margin: 32px 0 0; }
  .detail_material {
    margin: 0; }
  .detail_tags + .detail_footer {
    margin: 32px 0 0; }
  .detail_material {
    .material_img {
      margin-top: 21px; }
    .image_desc {
      display: block;
      text-align: center;
      font-weight: 600;
      color: #9f9f9f;
      padding: 12px 0 0;
      clear: both;
      margin-bottom: 35px;
      &:empty {
        padding: 0;
        margin: 0; } } }
  .longread_cover {
    max-height: 810px;
    overflow: hidden; }
  .detail_cover {
    img {
      display: block;
      width: 100%;
      height: auto; } }
  .detail_title {
    margin-top: 24px;
    word-break: break-word;
    overflow: hidden;
    span {
      vertical-align: top;
      display: inline;
      line-height: 34px;
      &.ndash {
        display: inline-block;
        width: 16px;
        line-height: 34px;
        height: 34px;
        margin: 0 15px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          height: 3px;
          width: 100%;
          margin-top: -1px;
          background: #212121; } } }
    a {
      text-decoration: none; }
    form {
      display: inline;
      input[type=submit] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0 none;
        background: transparent;
        outline: none;
        cursor: pointer;
        padding: 0;
        margin: 0;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        font-weight: inherit; } } }
  .detail_desc {
    margin: 18px 0 7px;
    margin: 18px 0 0px;
    p {
      font-size: 15px;
      font-weight: 400;
      color: #212121;
      line-height: 22px;

      a {
        color: $link_dark;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: $link; } } }
    ul {
      li {
        list-style: outside disc;
        margin-left: 25px;
        font-size: 15px;
        font-weight: 400;
        color: #212121;
        line-height: 22px;
        margin-bottom: 9px; } }

    ol {
      margin-bottom: 14px;

      li {
        list-style: outside decimal;
        margin-left: 25px;
        font-size: 15px;
        font-weight: 400;
        color: #212121;
        line-height: 22px;
        margin-bottom: 9px; } } }

  .detail_material {
    &.video_player {
      //width: 100%
      //height: 0
      //position: relative
      //overflow: hidden
      //padding-bottom: 56.25%
      //iframe, object, embed, video
      //  position: absolute
      //  top: 0
      //  left: 0
      //  width: 100% !important
 }      //  height: 100%
    &.ppt_presentation #ppt_wrapper {
      width: 100%;
      height: 0;
      position: relative;
      overflow: hidden;
      padding-bottom: 56.25%;
      canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        height: 100%; } }
    &.photogrid {
      overflow: hidden;
      position: relative;
      a {
        border: 0 none;
        text-decoration: none; }
      img {
        display: block;
        float: left;
        margin: 0;
        border: 0 none;
        &.fat_grid {
          width: 100%;
          height: auto; }
        &.slim_grid {
          width: 33.333333%;
          height: auto; } } }
    ol, ul {
      margin-top: 0px;
      margin-bottom: 28px; }
    h4 {
      font-size: 24px;
      font-weight: 600;
      color: #212121;
      line-height: 34px;
      padding: 0;
      margin: 16px 0 6px; }
    h5 {
      font-size: 18px;
      font-weight: 600;
      color: #212121;
      line-height: 24px;
      padding: 0;
      margin: 16px 0 6px; }
    p {
      font-size: 15px;
      line-height: 1.6;
      font-weight: 400;
      color: #212121;
      margin-bottom: 10px; }
    img.meterial_img {
      display: block;
      margin: 16px auto 0;
      max-height: 90vh;
      max-width: 100%;
      width: auto;
      & + img.meterial_img {
        margin-top: 32px; } }
    .iterview_block_item {
      padding-top: 24px;
      div {
        & + div {
          padding-top: 32px; }
        strong {
          text-transform: uppercase;
          color: #212121;
          font-size: 18px;
          font-weight: 700;
          line-height: 24px; }
        span {
          font-size: 15px;
          line-height: 24px;
          color: #212121;
          font-weight: 400;
          padding-left: 8px; } } }
    .accent {
      padding: 40px 0;
      margin: 15px 0 20px;
      position: relative;
      font-size: 24px;
      font-weight: 700;
      width: 100%;
      text-align: center;
      color: $link;
      &:before, &:after {
        content: '';
        position: absolute;
        width: 134px;
        background: $link !important;
        border: 0 none !important;
        height: 1px;
        left: 50%;
        margin-left: -67px;
        display: block !important; }
      &:before {
        top: 0; }
      &:after {
        bottom: 0; } } }
  #videopresentation_show {
    position: relative;
    margin-bottom: 32px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px rgba(0, 0, 0, 0.1);
    margin-top: 32px;
    #ppt_wrapper {
      border: 0 none;
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none; }
    .detail_material {
      margin-top: 0; }
    #progress_bar {
      margin: 0;
      width: 100%;
      height: 6px;
      background: #FFF;
      border-top: 1px solid #f5f5f5;
      #status {
        width: 0;
        height: 5px;
        background: $link; } }
    #navigation {
      text-align: center;
      margin: 0;
      padding: 0 70px;
      font-size: 24px;
      line-height: 60px;
      color: #a0a0a0;
      overflow: hidden;
      position: relative;
      & > * {
        display: inline-block;
        height: 60px;
        line-height: 60px;
        vertical-align: top; }
      button {
        background: transparent;
        border: 0 none;
        outline: none;
        height: 60px;
        width: 40px;
        text-align: center;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        color: transparent;
        text-indent: -9999em;
        cursor: pointer;
        &#prev {
          background-image: asset-url(prev-arrow); }
        &#next {
          background-image: asset-url(next-arrow); }
        &#fullscreen_btn {
          position: absolute;
          top: 0;
          right: 16px;
          display: block;
          background-image: asset-url(ppt-fullscreen); }
        &#fullscreen_btn_exit {
          position: absolute;
          top: 0;
          right: 16px;
          display: none;
          background-image: asset-url(ppt-fullscreen-exit); } }
      span#current_page {
        color: $link; } } }
  .detail_tags {
    .tag {
      margin-right: 14px;
      margin-bottom: 8px; }
    form {
      display: inline;
      input[type=submit] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        cursor: pointer;
        background: #fff; } } }
  .detail_footer {
    //margin-top: 32px;
    span:not(.article-stat),
    a:not(.article-stat) {
      display: inline-block;
      vertical-align: top;
      line-height: 24px;
      font-size: 14px;
      font-weight: 600;
      color: #acacac;
      text-decoration: none;
      & + span:not(.article-stat), & + a:not(.article-stat) {
        margin-left: 40px; }
      &.green {
        color: $link; }
      .icon {
        vertical-align: middle;
        display: inline-block;
        width: 24px;
        height: 24px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        margin-right: 8px;
        &.icon-eye-disable {
          background-image: asset-url(eye-disable); }
        &.icon-comments-disable {
          background-image: asset-url(comments-disable); }
        &.icon-comments {
          background-image: asset-url(comments-enable); }
        &.icon-likes-disable {
          background-image: asset-url(likes-disable); }
        &.icon-likes {
          background-image: asset-url(likes-enable); }
        &.icon-sbercoins-disable {
          background-image: asset-url(sbercoints-gray); } } }
    #article_rating {
      white-space: nowrap;
      line-height: 24px;
      height: 24px;
      span, .br-wrapper, .br-widget {
        display: inline-block;
        vertical-align: middle;
        line-height: 24px; }
      .br-current-rating {
        display: none; }
      .br-widget {
        a {
          display: block;
          float: left;
          width: 24px;
          height: 24px;
          margin-left: 8px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-image: asset-url(star-default);
          overflow: hidden;
          position: relative;
          text-decoration: none;
          &:before, &:after {
            position: absolute;
            top: -9999em;
            left: -9999em;
            content: '';
            width: 0;
            height: 0; }
          &:before {
            background-image: asset-url(star-over); }
          &:after {
            background-image: asset-url(star-enable); }
          &.br-active {
            background-image: asset-url(star-over); }
          &.br-selected {
            background-image: asset-url(star-enable); } } }
      #exist_rating {
        color: #acacac;
        vertical-align: middle;
        line-height: 24px;
        &:before {
          content: '';
          display: inline-block;
          height: 24px;
          width: 24px;
          margin-right: 8px;
          background: asset-url(star-default) no-repeat 50% 50%;
          vertical-align: middle; }
        &.voted {
          color: $link;
          &:before {
            background: asset-url(star-enable) no-repeat 50% 50%; } } } } }
  .detail_edit_content {
    margin-top: 32px;
    #ppt_wrapper {
      margin-top: 40px;
      position: relative;
      a {
        display: none; }
      canvas {
        margin: 0 auto;
        display: block; }
      a.remove_ppt {
        text-indent: -9999em;
        color: transparent;
        display: block;
        text-decoration: none;
        position: absolute;
        width: 40px;
        height: 40px;
        @include rounded(50%);
        background: rgba(0, 0, 0, 0.54) asset-url(close-white) no-repeat 50% 50%;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 100;
        &:hover {
          background-color: $link; } } }
    .detail_edit_title {
      form {
        display: inline-block;
        line-height: 30px;
        vertical-align: middle;
        &.hide {
          display: none;
          visibility: hidden; } }
      #article_category_id {
        display: none; }
      .ui-selectmenu-button {
        display: inline-block;
        vertical-align: middle;
        height: 30px;
        border: 0 none;
        outline: none;
        background: transparent !important;
        margin: 0 !important;
        padding: 0 !important;
        position: relative;
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        color: #c1c1c1;
        width: auto !important;
        max-width: 100% !important;
        span {
          padding: 0 !important;
          margin: 0 !important;
          line-height: inherit;
          //color: inherit;
          font-size: inherit;
          font-weight: inherit; }
        .ui-selectmenu-icon {
          display: none !important; }
        &.select {
          color: #212121; } }
      & > span {
        display: inline-block;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        color: #c1c1c1;
        vertical-align: middle;
        &.ndash {
          display: inline-block;
          width: 16px;
          line-height: 30px;
          height: 30px;
          margin: 0 15px;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            height: 3px;
            width: 100%;
            margin-top: -1px;
            background: #212121;
            left: 0; } }
        &.contenteditable {
          display: inline;
          font-size: 24px;
          font-weight: 700;
          color: #212121;
          line-height: 30px;
          cursor: text;
          white-space: normal;
          &.not_value {
            color: #c1c1c1;
            &:before {
              content: attr(data-placeholder);
              content: "Название поста"; } } } } }
    .detail_edit_presentation {
      margin-top: 32px; }
    .detail_edit_link {
      margin-top: 32px;
      .form_item {
        &:before {
          left: 0; }
        label {
          left: 0;
          padding-left: 5px;
          font-size: 15px;
          text-transform: none; }
        input {
          padding-left: 5px; } } }
    .detail_edit_cover {
      margin-top: 32px;
      .dropzone.dz-started {
        .dz-preview-custom {
          max-height: none !important; } }
      .detail_edit_cover_exist {
        position: relative;
        img {
          width: 100%;
          height: auto;
          z-index: 1; }
        form {
          display: block;
          width: 40px !important;
          height: 40px !important;
          @include rounded(50%);
          background: rgba(0, 0, 0, 0.54) asset-url(icon-edit) no-repeat 50% 50% !important;
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
          cursor: pointer;
          position: absolute;
          top: 16px;
          right: 16px;
          z-index: 5;
          min-height: 0 !important;
          padding: 0 !important;
          border: 0 none !important;
          &:hover {
            background-color: $link !important; }
          & > * {
            display: none !important; }
          &:hover {
            background-color: $link; } } } }
    .detail_edit_desc {
      margin-top: 32px;
      .form_item {
        padding: 0;
        width: 100%;
        height: auto !important;
        &:before {
          display: none !important; }
        label {
          padding: 0 5px !important;
          top: 4px;
          font-size: 15px;
          text-transform: none; }
        textarea {
          font-family: $font; } } }
    .detail_edit_materials {
      margin-top: 24px;
      .pre_add_block {
        width: 100px;
        height: auto;
        margin: 24px auto;
        cursor: pointer;
        outline: none;
        background: transparent;
        border: 0 none;
        outline: none;
        display: block;
        span {
          display: block;
          text-align: center;
          &.icon {
            display: block;
            margin: 0 auto;
            width: 40px;
            height: 40px;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
            border: solid 1px rgba(0, 0, 0, 0.09);
            cursor: pointer;
            background: white asset-url(addblock-icon) no-repeat 50% 50%;
            @include rounded(50%);
            position: relative;
            overflow: hidden;
            transition: all .2s;
            &:before {
              content: '';
              width: 0;
              height: 0;
              background: asset-url(addblock-icon-black);
              position: absolute;
              top: -9999em;
              left: -9999em; } }
          &.text {
            color: #c1c1c1;
            color: rgba(0, 0, 0, 0.24);
            font-size: 12px;
            font-weight: 400;
            line-height: 1;
            white-space: nowrap;
            text-align: center;
            padding-top: 8px; } }
        &:hover {
          .icon {
            background-image: asset-url(addblock-icon-black);
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1); }
          .text {
            color: rgba(0, 0, 0, 0.54); } }
        &.active {
          .icon {
            background-color: #fafafa;
            background-image: asset-url(addblock-icon-black);
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
            border: solid 1.3px rgba(0, 0, 0, 0.09);
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg); }
          .text {
            color: rgba(0, 0, 0, 0.54); } } }
      .form_item {
        width: auto;
        padding-right: 40px;
        margin-bottom: 10px;
        height: auto;
        &:before {
          display: block;
          left: 0; }
        input, textarea {
          padding-left: 5px;
          font-family: $font; }
        label {
          padding-left: 5px; }
        &.form_title {
          margin-bottom: 24px;
          margin-top: 24px;
          label {
            font-size: 24px;
            font-weight: 600; }
          input {
            font-size: 24px;
            font-weight: 600; } }
        a {
          display: block;
          position: absolute;
          width: 30px;
          height: 40px;
          top: 0;
          right: 0;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-image: asset-url(close-black);
          background-size: 18px 18px;
          text-indent: -9999em;
          text-decoration: none;
          z-index: 10; }
        &.active {
          &:before {
            display: none; } }
        &.form_text {
          &:before {
            display: none; } } }
      .image_dropzone {
        position: relative;
        margin-bottom: 24px;
        a.remove_img {
          text-indent: -9999em;
          color: transparent;
          display: block;
          text-decoration: none;
          position: absolute;
          z-index: 90;
          width: 40px;
          height: 40px;
          @include rounded(50%);
          background: rgba(0, 0, 0, 0.54) asset-url(close-white) no-repeat 50% 50%;
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
          cursor: pointer;
          position: absolute;
          top: 16px;
          right: 16px;
          z-index: 100;
          &:hover {
            background-color: $link; } }
        .dz-preview {
          z-index: 5 !important; } }
      .image_dropzone_desc, .photogrid_dropzone_desc {
        display: block;
        margin-top: -16px;
        .form_item {
          padding-right: 0;
          &:before {
            display: none; }
          label {
            text-align: center;
            font-weight: 600;
            color: #c1c1c1;
            padding-left: 0;
            padding-right: 0;
            width: 100%; }
          input {
            padding-right: 0;
            padding-left: 0;
            text-align: center;
            font-weight: 600;
            color: #9f9f9f; } } }
      .detail_edit_material_image_exist {
        position: relative;
        margin-bottom: 24px;
        img {
          width: 100%;
          height: auto;
          z-index: 1; }
        form {
          display: block;
          width: 40px;
          height: 40px;
          @include rounded(50%);
          background: rgba(0, 0, 0, 0.54) asset-url(icon-edit) no-repeat 50% 50%;
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
          cursor: pointer;
          position: absolute;
          top: 16px;
          right: 16px;
          z-index: 5;
          & > * {
            display: none !important; }
          &:hover {
            background-color: $link; } }
        a.remove_img {
          text-indent: -9999em;
          color: transparent;
          display: block;
          text-decoration: none;
          position: absolute;
          z-index: 90;
          width: 40px;
          height: 40px;
          @include rounded(50%);
          background: rgba(0, 0, 0, 0.54) asset-url(close-white) no-repeat 50% 50%;
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
          cursor: pointer;
          position: absolute;
          top: 72px;
          right: 16px;
          z-index: 100;
          &:hover {
            background-color: $link; } } }
      .grid_dropzone {
        position: relative;
        .dz-preview {
          float: none !important;
          z-index: 1;
          position: static;
          .dz-image {
            z-index: 1;
            img {
              z-index: 1; } } }
        a.remove_img {
          text-indent: -9999em;
          color: transparent;
          display: block;
          text-decoration: none;
          position: absolute;
          width: 40px;
          height: 40px;
          @include rounded(50%);
          background: rgba(0, 0, 0, 0.54) asset-url(close-white) no-repeat 50% 50%;
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
          cursor: pointer;
          top: 16px;
          right: 16px;
          z-index: 100;
          &:hover {
            background-color: $link; } } }
      .detail_edit_material_grid_exist {
        position: relative;
        margin-bottom: 24px;
        overflow: hidden;
        img {
          z-index: 1;
          float: left;
          display: block;
          height: auto;
          margin: 0;
          &.fat_grid {
            width: 100%; }
          &.slim_grid {
            width: 33.3333%; } }
        form {
          display: block;
          width: 40px;
          height: 40px;
          @include rounded(50%);
          background: rgba(0, 0, 0, 0.54) asset-url(icon-edit) no-repeat 50% 50%;
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
          cursor: pointer;
          position: absolute;
          top: 16px;
          right: 16px;
          z-index: 5;
          & > * {
            display: none !important; }
          &:hover {
            background-color: $link; } }
        a.remove_img {
          text-indent: -9999em;
          color: transparent;
          display: block;
          text-decoration: none;
          position: absolute;
          z-index: 90;
          width: 40px;
          height: 40px;
          @include rounded(50%);
          background: rgba(0, 0, 0, 0.54) asset-url(close-white) no-repeat 50% 50%;
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
          cursor: pointer;
          position: absolute;
          top: 72px;
          right: 16px;
          z-index: 100;
          &:hover {
            background-color: $link; } } }
      .grid_dropzone {
        .dz-preview {
          position: relative;
          float: left; } }
      .photogrid_dropzone_desc {
        .form_item {
          padding: 0;
          margin: 0;
          &:before {
            display: none; }
          label, input {
            text-align: center; }
          label {
            width: 100%;
            left: 0; } } }
      .meterial_interview_item {
        position: relative;
        padding-right: 40px;
        margin-top: 56px;
        .meterial_interview_item_block {
          position: relative;
          & + .meterial_interview_item_block {
            margin-top: 16px; }
          .content_edit {
            display: inline;
            font-size: 16px;
            font-weight: 400;
            color: #212121;
            line-height: 22px;
            cursor: text;
            white-space: normal;
            vertical-align: top;
            &.not_value {
              color: #c1c1c1;
              &:before {
                content: attr(data-placeholder);
                font-weight: inherit;
                text-transform: none; } }
            &.bold {
              font-weight: 700;
              display: inline-block;
              min-width: 120px;
              text-transform: uppercase; } }
          textarea + .content_edit {
            padding-left: 8px; } }
        .remove_block {
          display: block;
          width: 24px;
          height: 24px;
          position: absolute;
          top: 5px;
          right: 5px;
          text-indent: -9999em;
          color: transparent;
          text-decoration: none;
          background: asset-url(close-black) no-repeat 50% 50%; } }
      .detail_edit_link {
        margin-top: 32px; } }
    .detail_edit_tag {
      margin-top: 40px;
      #add_tag {
        background: #ededed !important;
        color: #a1a1a1 !important;
        cursor: pointer; }
      #article_tag {
        display: inline-block; }
      .tag_edit {
        display: inline-block;
        position: relative;
        em {
          display: block;
          visibility: hidden;
          opacity: 0;
          z-index: -1;
          color: transparent;
          padding: 4px 25px 4px 7px;
          min-width: 120px;
          font-weight: 600;
          font-size: 12px;
          position: absolute;
          top: 0;
          left: 0;
          font-style: normal; }
        input {
          opacity: 1 !important;
          display: inline-block;
          line-height: 20px;
          padding: 4px 25px 4px 7px;
          height: 28px;
          border: 2px solid #ededed;
          @include rounded(4px);
          font-weight: 600;
          font-size: 12px;
          text-decoration: none;
          color: #a6a6a6;
          text-transform: uppercase;
          outline: none;
          background: #fff;
          width: 120px;
          z-index: 2; }
        a {
          text-decoration: none;
          color: transparent;
          display: block;
          position: absolute;
          height: 28px;
          width: 20px;
          top: 0;
          right: 0;
          text-align: center;
          text-indent: -9999em;
          background: asset-url(close-black) no-repeat 50% 50%;
          background-size: 11px 11px; } } }
    .detail_edit_question_data {
      margin-top: 32px;
      &_item {
        @include rounded(4px);
        background: #f5f5f5;
        padding: 24px;
        width: 100%;
        overflow: hidden;
        span {
          display: block;
          float: left;
          line-height: 24px;
          color: #000;
          font-size: 15px;
          width: 60%; }
        & > div {
          float: right;
          width: 40%;
          line-height: 24px;
          text-align: right;
          padding-right: 30px;
          position: relative;
          color: $link;
          font-size: 15px;
          &:before {
            position: absolute;
            content: '';
            width: 24px;
            height: 24px;
            top: 0;
            right: 0;
            background: asset-url(sbercoints-new) no-repeat 50% 50%;
            background-size: 24px 24px; } } } } }
  .detail_material_action {
    //height: $form_height
    //line-height: $form_height
    margin-top: 24px; }

  .mobile_post_user_action {
    @media (max-width: 480px) {
      & #share {
        display: block;
        margin-left: 16px; }

      & #change_bookmark {
        margin-left: 16px; } } } }

.mobile_post_user_action {
  float: left;
  padding: 0 15px;

  @media (max-width: 767px) {
    float: none;
    padding: 0 30px; } }

.action_article {
  float: right;
  padding: 0 15px;

  @media (max-width: 767px) {
    float: none;
    margin-top: 20px; }

  .edit_button,
  #complaint {
    display: inline-block;

    @media (max-width: 767px) {
      display: block;

      .btn_edit,
      .btn_delete {
        display: block;
        width: 100%;
        height: 50px;
        border: none;
        border-top: 1px solid #f4f4f4;
        border-radius: 0; } } } }


/*.longread_photos
  width: 100%
  height: 498px
  position: relative
  overflow: hidden
  margin-bottom: 32px
  a.remove_photogrig
    text-indent: -9999em
    color: transparent
    display: block
    text-decoration: none
    position: absolute
    z-index: 90
    width: 40px
    height: 40px
    @include rounded(50%)
    background: rgba(0, 0, 0, 0.54) asset-url(close-white) no-repeat 50% 50%
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06)
    cursor: pointer
    position: absolute
    top: 16px
    right: 16px
    z-index: 100
    &:hover
      background-color: $link
  .grid_bullets
    width: 100%
    height: 14px
    text-align: center
    position: absolute
    bottom: 0
    left: 0
    margin: 0
    top: auto
    vertical-align: top
    span
      display: inline-block
      width: 12px
      height: 12px
      @include rounded(50%)
      background: #c1c1c1
      cursor: pointer
      margin: 0 5px
      vertical-align: top
      &:hover
        background: #a8a8a8
      &.current
        background: $link !important
  .grid_arrows
    width: 944px
    height: 0
    position: absolute
    top: 0
    left: 50%
    margin-left: -472px
    overflow: visible
    a
      display: block
      height: 420px
      width: 30px
      position: absolute
      top: 0
      background-repeat: no-repeat
      background-position: 50% 50%
      display: none
      &.prev_arrow
        left: 0
        background-image: asset-url(prev-arrow)
      &.next_arrow
        right: 0
        background-image: asset-url(next-arrow)
  & > div
    width: 1000%
    height: 462px
    overflow: hidden
    position: absolute
    top: 0
    left: 50%
    margin-left: -390px
    transition: all .3s
    .photo_item
      width: 780px
      height: 462px
      float: left
      position: relative
      padding: 0 15px
      @media screen and (max-width: 780px)
        width: 560px
        height: 400px

      img
        width: 750px !important
        height: 420px !important
        margin: 0 auto

        @media screen and (max-width: 780px)
          width: 530px !important
          height: 370px !important

      &.public
        &:after
          content: ''
          position: absolute
          width: 750px
          height: 420px
          background: rgba(255, 255, 255, 0.8)
          top: 0
          left: 15px
        &.current
          &:after
            display: none
      .add_photo_to_grid
        display: block
        border: 0 none
        background: transparent asset-url(addblock-icon-green) 67px 50% no-repeat
        text-indent: -9999em
        cursor: pointer
        width: 750px
        height: 420px
        appearance: none
        -moz-appearance: none
        -webkit-appearance: none
        border: 2px dotted #d0d0d0
        outline: none
        transition: all .3s
        &:disabled
          opacity: .3
          cursor: default
      .dropzone
        width: 750px !important
        height: 420px !important
        position: absolute
        top: 0
        left: 15px
        z-index: 1
        padding-top: 120px
      .photogrid_photo_edit
        display: block
        width: 40px
        height: 40px
        @include rounded(50%)
        background: rgba(0, 0, 0, 0.54) asset-url(icon-edit) no-repeat 50% 50%
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06)
        cursor: pointer
        position: absolute
        top: 16px
        left: 31px
        z-index: 5
        &:hover
          background-color: $link
        input
          display: none
          visibility: hidden
          opacity: 0
      .photogrid_photo_desc
        position: absolute
        width: 100%
        top: 432px
        left: 0
        span
          display: block
          text-align: center
          font-weight: 600
          color: #212121
          font-size: 15px
        .form_item
          width: 100%
          margin: 0 auto
          text-align: center
          padding: 0
          label
            text-align: center
            font-weight: 600
            color: #c1c1c1
            padding-left: 0
            padding-right: 0
            width: 100%
            top: 0
            left: 0
          input
            padding-right: 0
            padding-left: 0
            text-align: center
            font-weight: 600
            color: #212121
            font-size: 15px */

.longread_edit {
  width: 100%;
  position: relative;
  margin: -32px 0 0;
  background: #FFF;
  .detail_header {
    position: relative;
    padding: 6px 0 0 76px;
    min-height: 60px;
    .avatar {
      position: absolute;
      top: 0;
      left: 0; }
    &.with_back {
      padding: 6px 0 0 116px;
      .avatar {
        left: 40px; }
      .back {
        height: 60px;
        line-height: 60px;
        width: 24px;
        position: absolute;
        top: 0;
        left: 0;
        a {
          text-indent: -9999em;
          display: block;
          position: relative;
          line-height: 60px;
          height: 60px;
          text-decoration: none;
          color: transparent;
          background: asset-url(icon-back) 0 50% no-repeat; } } }
    form {
      display: inline;
      input[type=submit] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0 none;
        background: transparent;
        outline: none;
        cursor: pointer;
        padding: 0;
        margin: 0;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        font-weight: inherit; }
      &.hide {
        display: none; } }
    div {
      .badge {
        margin-left: 13px;
        & + .badge {
          margin-left: 7px; } }
      & + div {
        padding-top: 2px; } } }
  a.publish_link, .subscribe_user_header button {
    @include btn;
    margin-top: 10px; }

  .process_info {
    color: #c1c1c1;
    display: none;
    background: #FFF;
    line-height: 60px;
    font-size: 13px;
    font-weight: 400;
    position: absolute;
    top: 0;
    right: 8px;
    z-index: 3;
    width: 100%; }
  .detail_edit_title {
    margin: 24px 0 32px;
    form {
      display: inline-block;
      line-height: 30px;
      vertical-align: middle;
      &.hide {
        display: none;
        visibility: hidden; } }
    #article_category_id {
      display: none; }
    .ui-selectmenu-button {
      display: inline-block;
      vertical-align: middle;
      height: 30px;
      border: 0 none;
      outline: none;
      background: transparent !important;
      margin: 0 !important;
      padding: 0 !important;
      position: relative;
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      color: #c1c1c1;
      width: auto !important;
      max-width: 100% !important;
      span {
        padding: 0 !important;
        margin: 0 !important;
        line-height: inherit;
        color: inherit;
        font-size: inherit;
        font-weight: inherit; }
      .ui-selectmenu-icon {
        display: none !important; }
      &.select {
        color: #212121; } }
    & > span {
      display: inline-block;
      font-size: 24px;
      font-weight: 400;
      line-height: 30px;
      color: #c1c1c1;
      vertical-align: middle;
      &.ndash {
        display: inline-block;
        width: 16px;
        line-height: 30px;
        height: 30px;
        margin: 0 15px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          height: 3px;
          width: 100%;
          margin-top: -1px;
          background: #212121;
          left: 0; } }
      &.contenteditable {
        display: inline;
        font-size: 24px;
        font-weight: 700;
        color: #212121;
        line-height: 30px;
        cursor: text;
        white-space: normal;
        &.not_value {
          color: #c1c1c1;
          &:before {
            content: attr(data-placeholder); } } } } }
  .longread_edit_cover {
    width: 100%;
    max-height: 810px;
    overflow: hidden;
    margin: 0 auto 32px;
    .detail_edit_cover_exist {
      position: relative;
      img {
        width: 100%;
        height: auto;
        z-index: 1; }
      form {
        display: block;
        width: 40px;
        height: 40px;
        @include rounded(50%);
        background: rgba(0, 0, 0, 0.54) asset-url(icon-edit) no-repeat 50% 50%;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 5;
        & > * {
          display: none !important; }
        &:hover {
          background-color: $link; } } } }
  .detail_edit_materials {
    .pre_add_block {
      width: 100px;
      height: auto;
      margin: 24px auto;
      cursor: pointer;
      outline: none;
      background: transparent;
      border: 0 none;
      outline: none;
      display: block;
      span {
        display: block;
        text-align: center;
        &.icon {
          display: block;
          margin: 0 auto;
          width: 40px;
          height: 40px;
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
          border: solid 1px rgba(0, 0, 0, 0.09);
          cursor: pointer;
          background: white asset-url(addblock-icon) no-repeat 50% 50%;
          @include rounded(50%);
          position: relative;
          overflow: hidden;
          transition: all .2s;
          &:before {
            content: '';
            width: 0;
            height: 0;
            background: asset-url(addblock-icon-black);
            position: absolute;
            top: -9999em;
            left: -9999em; } }
        &.text {
          color: #c1c1c1;
          color: rgba(0, 0, 0, 0.24);
          font-size: 12px;
          font-weight: 400;
          line-height: 1;
          white-space: nowrap;
          text-align: center;
          padding-top: 8px; } }
      &:hover {
        .icon {
          background-image: asset-url(addblock-icon-black);
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1); }
        .text {
          color: rgba(0, 0, 0, 0.54); } }
      &.active {
        .icon {
          background-color: #fafafa;
          background-image: asset-url(addblock-icon-black);
          box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
          border: solid 1.3px rgba(0, 0, 0, 0.09);
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg); }
        .text {
          color: rgba(0, 0, 0, 0.54); } } }
    .form_item {
      width: auto;
      padding-right: 40px;
      margin-bottom: 0;
      height: auto;
      &:before {
        display: none; }
      input, textarea {
        padding-left: 5px;
        font-family: $font; }
      label {
        padding-left: 5px; }
      &.form_title {
        margin-bottom: 24px;
        margin-top: 24px;
        label {
          font-size: 26px;
          font-weight: 600; }
        input {
          font-size: 26px;
          font-weight: 600; }
        &.form_title_2 {
          label, input {
            font-size: 22px;
            font-weight: 600; } }
        &.form_title_3 {
          label, input {
            font-size: 18px;
            font-weight: 700; } } }
      &.form_accent {
        padding: 40px 0;
        position: relative;
        &:before, &:after {
          content: '';
          position: absolute;
          width: 134px;
          background: $link !important;
          border: 0 none !important;
          height: 1px;
          left: 50%;
          margin-left: -67px;
          display: block !important; }
        &:before {
          top: 0; }
        &:after {
          bottom: 0; }
        label {
          color: #c1c1c1; }
        label, input {
          font-size: 24px;
          font-weight: 700;
          width: 100%;
          text-align: center; }
        input {
          color: $link; } }
      a {
        display: block;
        position: absolute;
        width: 30px;
        height: 40px;
        top: 0;
        right: 0;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-image: asset-url(close-black);
        background-size: 18px 18px;
        text-indent: -9999em;
        text-decoration: none;
        z-index: 10; }
      &.active {
        &:before {
          display: none; } } }
    .image_dropzone, .detail_edit_material_image_exist {
      margin: 0 auto;
      width: 100% !important; }
    .image_dropzone {
      .dz-message span button {
        display: none !important;
        opacity: 0 !important; }
      a.remove_img {
        text-indent: -9999em;
        color: transparent;
        display: block;
        text-decoration: none;
        position: absolute;
        z-index: 90;
        width: 40px;
        height: 40px;
        @include rounded(50%);
        background: rgba(0, 0, 0, 0.54) asset-url(close-white) no-repeat 50% 50%;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 100;
        &:hover {
          background-color: $link; } }
      .dz-preview {
        z-index: 5 !important; } }
    .detail_edit_material_image_exist {
      form.had_attach {
        display: block;
        width: 40px;
        height: 40px;
        @include rounded(50%);
        background: rgba(0, 0, 0, 0.54) asset-url(icon-edit) no-repeat 50% 50%;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 5;
        & > * {
          display: none !important; }
        &:hover {
          background-color: $link; } }
      a.remove_img {
        text-indent: -9999em;
        color: transparent;
        display: block;
        text-decoration: none;
        position: absolute;
        z-index: 90;
        width: 40px;
        height: 40px;
        @include rounded(50%);
        background: rgba(0, 0, 0, 0.54) asset-url(close-white) no-repeat 50% 50%;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
        cursor: pointer;
        position: absolute;
        top: 72px;
        right: 16px;
        z-index: 100;
        &:hover {
          background-color: $link; } } }
    .toggle_img_size {
      width: 750px !important;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      &.middle {
        width: 1140px !important; }
      &.big {
        width: 100% !important;
        max-height: 810px; }
      img {
        display: block;
        width: 100% !important;
        height: auto !important;
        margin: 0; }
      &.darker {
        &.detail_edit_material_image_exist:before {
          z-index: 2;
          content: '';
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.54);
          position: absolute; }
        & + .cover_text {
          display: block !important; } } }
    .image_dropzone_desc {
      margin: 0 auto;
      .form_item {
        width: 754px;
        margin: 0 auto;
        text-align: center;
        padding: 0;
        label {
          text-align: center;
          font-weight: 600;
          color: #c1c1c1;
          padding-left: 0;
          padding-right: 0;
          width: 100%;
          top: 0;
          left: 0; }
        input {
          padding-right: 0;
          padding-left: 0;
          text-align: center;
          font-weight: 600;
          color: #9f9f9f; } }
      .form_textarea {
        display: none; }
      &.cover_text {
        height: 250px;
        width: 600px;
        text-align: center;
        margin: -350px auto 120px;
        z-index: 100;
        position: relative;
        display: none;
        .form_item {
          height: 46px;
          background: transparent;
          margin-bottom: 32px;
          width: 100%;
          input {
            font-size: 34px;
            color: #FFF;
            height: 46px;
            line-height: 46px;
            font-weight: 700; }
          label {
            font-size: 34px;
            height: 46px;
            font-weight: 700; } }
        .form_textarea {
          height: 94px;
          text-align: center;
          display: block;
          label {
            font-size: 15px;
            line-height: 23px;
            font-weight: normal; }
          textarea {
            display: block;
            text-align: center;
            height: 94px;
            padding: 0;
            overflow: hidden;
            resize: none;
            border: 0 none;
            background: transparent;
            font-size: 15px;
            line-height: 23px;
            color: #FFF;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            font-weight: normal; } } } } } }

.toggle_img_size.public {
  width: 750px !important;
  margin: 0 auto 24px;
  position: relative;
  overflow: hidden;
  &.middle {
    width: 1140px !important; }
  &.big {
    width: 100% !important;
    max-height: 810px; }
  img {
    display: block;
    width: auto !important;
    height: auto !important;
    margin: 0 auto;
    z-index: 1;
    max-height: 90vh;
    max-width: 100%; }
  .image_desc_ {
    display: none; }
  &.darker {
    &:before {
      z-index: 2;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.54);
      position: absolute; }
    .toggle_img_desc {
      display: table;
      width: 100%;
      height: 810px;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 3;
      margin-top: -405px;
      .toggle_img_desc_inner {
        display: table-cell;
        width: 100%;
        vertical-align: middle;
        span {
          display: block;
          text-align: center;
          width: 750px;
          margin-left: auto;
          margin-right: auto; }
        .image_desc {
          color: #FFF !important;
          font-size: 34px;
          line-height: 46px;
          font-weight: 700; }
        .image_desc_ {
          font-size: 15px;
          line-height: 23px;
          color: #FFF;
          display: block;
          padding-top: 32px; } } } } }

.toggle_video_size {
  position: relative;
  height: auto;
  padding: 0;
  width: 750px;
  margin: 0 auto 24px;
  .toggle_video_size_inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    padding: 0 0 56.25%; }
  &.middle {
    width: 1140px !important;
    .toggle_video_size_inner {} }
  //padding: 0 0 44%;
  &.big {
    width: 100% !important;
    .toggle_video_size_inner {} }
  //padding: 0 0 36%;
  iframe, object, embed, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }
  .dropzone {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 58px !important;
    padding-bottom: 0 !important;
    z-index: 1;
    .dz-message {
      &:before {
        background-image: asset-url(placeholders-video) !important; } }
    a.remove_video {
      text-indent: -9999em;
      color: transparent;
      display: block;
      text-decoration: none;
      position: absolute;
      z-index: 90;
      width: 40px;
      height: 40px;
      @include rounded(50%);
      background: rgba(0, 0, 0, 0.54) asset-url(close-white) no-repeat 50% 50%;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
      cursor: pointer;
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 100;
      &:hover {
        background-color: $link; } } }
  .video_insert_link {
    position: absolute;
    width: auto;
    left: 2px;
    right: 2px;
    top: 230px;
    text-align: center;
    z-index: 10;
    .form_item {
      width: 100%;
      padding: 0;
      &:before {
        display: none; }
      label, input {
        text-align: center;
        font-size: 15px;
        width: 100%; }
      label {
        top: 0;
        left: 0;
        color: #c1c1c1; }
      input {
        color: #212121; } } }
  .remove_exist_video {
    a.remove_video {
      text-indent: -9999em;
      color: transparent;
      display: block;
      text-decoration: none;
      position: absolute;
      z-index: 90;
      width: 40px;
      height: 40px;
      @include rounded(50%);
      background: rgba(0, 0, 0, 0.54) asset-url(close-white) no-repeat 50% 50%;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
      cursor: pointer;
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 100;
      &:hover {
        background-color: $link; } } } }
//начало слайдера
.longread_photos {
  width: 100%;
  height: 498px;
  position: relative;
  overflow: hidden;
  margin-bottom: 32px;

  @media screen and (max-width: 780px) {
    height: 260px; }

  @media screen and (max-width: 450px) {
    height: 200px; }

  a.remove_photogrig {
    text-indent: -9999em;
    color: transparent;
    display: block;
    text-decoration: none;
    position: absolute;
    z-index: 90;
    width: 40px;
    height: 40px;
    @include rounded(50%);
    background: rgba(0, 0, 0, 0.54) asset-url(close-white) no-repeat 50% 50%;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 100;
    &:hover {
      background-color: $link; } }
  .grid_bullets {
    width: 100%;
    height: 14px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    top: auto;
    vertical-align: top;
    span {
      display: inline-block;
      width: 12px;
      height: 12px;
      @include rounded(50%);
      background: #c1c1c1;
      cursor: pointer;
      margin: 0 5px;
      vertical-align: top;
      &:hover {
        background: #a8a8a8; }
      &.current {
        background: $link !important; } } }
  .grid_arrows {
    max-width: 944px;
    width: 100%;
    height: 0;
    position: absolute;
    top: 0;
    left: 50% !important;
    //margin-left: -472px
    transform: translateX(-50%);
    margin-left: 0;
    overflow: visible;
    //блок со стрелками

    a {
      display: block;
      height: 420px;
      width: 30px;
      position: absolute;
      top: 0;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      display: none;

      @media  screen and (max-width: 780px) {
        height: 240px; }

      @media screen and (max-width: 450px) {
        height: 160px; }

      &.prev_arrow {
        left: 0;
        background-image: asset-url(prev-arrow); }
      &.next_arrow {
        right: 0;
        background-image: asset-url(next-arrow); } } }
  & > div {
    width: 1000%;
    height: 462px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -390px;
    transition: all .3s;
    //родительский блок для всех фото
    @media screen and (max-width: 780px) {
      margin-left: -200px;
      height: 300px; }

    @media screen and (max-width: 450px) {
      margin-left: -150px;
      height: 250px; }



    //блок с фото
    .photo_item {
      width: 780px;
      height: 462px;
      float: left;
      position: relative;
      padding: 0 15px;


      @media screen and (max-width: 780px) {
        width: 400px;
        height: 300px; }

      @media screen and (max-width: 450px) {
        width: 100%;
        max-width: 300px;
        height: auto; }

      img {
        width: 750px;
        height: 420px;
        margin: 0 auto; }
      &.public {
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: calc(100% - 40px);
          background: rgba(255, 255, 255, 0.8);
          top: 0;
          left: 15px;

          @media screen  and (min-width: 451px ) and ( max-width: 780px) {
            height: calc(100% - 90px);
            width: calc(100% - 15px); }

          @media screen and (max-width: 450px) {
            width: calc(100% - 15px);
            height: 100%; } }

        &.current {
          &:after {
            display: none; } } }
      .add_photo_to_grid {
        display: block;
        border: 0 none;
        background: transparent asset-url(addblock-icon-green) 67px 50% no-repeat;
        text-indent: -9999em;
        cursor: pointer;
        width: 750px;
        height: 420px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border: 2px dotted #d0d0d0;
        outline: none;
        transition: all .3s;
        &:disabled {
          opacity: .3;
          cursor: default; } }
      .dropzone {
        width: 750px !important;
        height: 420px !important;
        position: absolute;
        top: 0;
        left: 15px;
        z-index: 1;
        padding-top: 120px; }
      .photogrid_photo_edit {
        display: block;
        width: 40px;
        height: 40px;
        @include rounded(50%);
        background: rgba(0, 0, 0, 0.54) asset-url(icon-edit) no-repeat 50% 50%;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.06);
        cursor: pointer;
        position: absolute;
        top: 16px;
        left: 31px;
        z-index: 5;
        &:hover {
          background-color: $link; }
        input {
          display: none;
          visibility: hidden;
          opacity: 0; } }
      .photogrid_photo_desc {
        position: absolute;
        width: 100%;
        top: 432px;
        left: 0;
        span {
          display: block;
          text-align: center;
          font-weight: 600;
          color: #212121;
          font-size: 15px; }
        .form_item {
          width: 100%;
          margin: 0 auto;
          text-align: center;
          padding: 0;
          label {
            text-align: center;
            font-weight: 600;
            color: #c1c1c1;
            padding-left: 0;
            padding-right: 0;
            width: 100%;
            top: 0;
            left: 0; }
          input {
            padding-right: 0;
            padding-left: 0;
            text-align: center;
            font-weight: 600;
            color: #212121;
            font-size: 15px; } } } } } }

.detail_archive_action {
  text-align: right;

  @media (max-width: 767px) {
    text-align: center; }

  .edit_button {
    display: inline-block;

    @media (max-width: 767px) {
      display: block;

      .btn_edit,
      .btn_delete {
        display: block;
        width: 100%;
        height: 50px;
        border: none;
        border-top: 1px solid #f4f4f4;
        border-radius: 0; } } } }

@media (max-width: 767px) {
  .detail_ {
    .detail_header {
      min-height: 42px;
      padding: 0 15px 0 57px;
      &.with_back {
        padding: 0 15px 0 70px;
        .back a, .back {
          height: 32px;
          line-height: 32px; }
        .back {
          top: 6px; }
        .avatar {
          left: 32px; } }
      .avatar {
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 13px;
        top: 6px;
        left: 15px; }
      .author {
        a {
          font-weight: 600;
          color: #000;
          line-height: 16px;
          font-size: 12px; } }
      .gray.size12 {
        font-size: 10px;
        line-height: 16px; } }
    .detail_title {
      margin-top: 24px;
      padding-left: 15px;
      padding-right: 15px;
      span {
        font-size: 20px !important;
        line-height: 28px !important;
        &.ndash {
          height: 28px;
          line-height: 28px;
          margin: 0 5px; } } }
    .detail_cover {
      margin-top: 16px; }
    .detail_desc {
      margin-top: 16px; }
    .detail_material {
      margin-top: 16px;
      &.photogrid {
        span {
          display: block;
          text-align: center;
          font-weight: 600;
          color: #9f9f9f;
          padding: 12px 0 0;
          clear: both; } }
      h4 {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 12px; }
      h5 {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 12px; }
      .toggle_img_size {
        width: 100% !important;
        padding: 0 30px;
        &.darker {
          .toggle_img_desc {
            height: 500px;
            top: 50%;
            margin-top: -250px;
            .toggle_img_desc_inner {
              span {
                width: 100% !important;
                padding-left: 15px;
                padding-right: 15px;
                .image_desc_ {
                  padding-top: 14px; } } } } } }
      .toggle_video_size {
        width: 100% !important; }
      .longread_photos {
        & > div {}
        .grid_bullets span {
          display: inline-block;
          width: 12px;
          height: 12px;
          @include rounded(50%);
          background: #c1c1c1;
          cursor: pointer;
          margin: 0 5px;
          vertical-align: top; }
        .grid_arrows {
          width: 100%;
          left: 0;
          margin-left: 0;
          a.next_arrow {
            right: 15px; }
          a.prev_arrow {
            left: 15px; } } }
      .accent {
        padding: 26px 15px;
        font-size: 20px;
        line-height: 28px; }

      blockquote {
        padding: 0 15px 0 63px;
        margin-bottom: 28px;

        &:before {
          width: 40px;
          height: 40px;
          background-size: 40px 40px;
          top: 50%;
          left: 15px;
          margin-top: -20px; }
        p {
          font-style: normal !important;
          font-size: 20px !important;
          line-height: 28px !important;
          font-weight: 500 !important; } }
      .iterview_block_item {
        padding: 16px 15px 0;
        div {
          span, strong {
            font-size: 16px;
            line-height: 28px; } } }
      #ppt_wrapper {
        canvas {
          width: 100% !important;
          height: auto !important; }
        #progress_bar {
          margin: 0; }
        #navigation {
          padding: 0 30px;
          margin: 12px 0 16px;
          line-height: 24px;
          height: 24px;
          font-size: 16px;
          a {
            display: none; }
          & > * {
            height: 24px;
            line-height: 24px; }
          button {
            height: 24px;
            line-height: 24px;
            padding: 0; }
          #fullscreen_btn {
            right: 15px;
            width: 24px;
            height: 24px;
            background-size: 24px 24px; }
          #next, #prev {
            width: 20px;
            background-size: contain; } } } }
    #videopresentation_show {
      .detail_material.ppt_presentation {
        #ppt_wrapper {
          height: auto;
          padding: 0;
          canvas {
            position: static;
            width: 100% !important;
            height: auto !important;
            top: auto;
            left: auto; } } }
      #navigation {
        padding: 0 30px;
        margin: 12px 0 16px;
        line-height: 24px;
        height: 24px;
        font-size: 16px;
        a {
          display: none; }
        & > * {
          height: 24px;
          line-height: 24px; }
        button {
          height: 24px;
          line-height: 24px;
          padding: 0; }
        #fullscreen_btn {
          right: 15px;
          width: 24px;
          height: 24px;
          background-size: 24px 24px; }
        #next, #prev {
          width: 20px;
          background-size: contain; } } }
    .article_or_comments_description {
      padding-right: 15px;
      padding-left: 15px;
      p, li {
        font-size: 16px;
        line-height: 28px;
        word-break: break-word; } }
    .detail_tags {
      margin-top: 16px;
      padding-left: 15px;
      padding-right: 15px; }
    .detail_footer {
      margin-top: 16px;
      padding-right: 15px;
      padding-left: 15px;
      span:not(.article-stat) {
        line-height: 20px;
        font-size: 12px;
        & + span:not(.article-stat) {
          margin-left: 24px; }
        .icon {
          background-size: 20px 20px;
          margin-right: 3px; } }
      #article_rating {
        padding: 16px 0 0;
        text-align: left;
        height: auto;
        line-height: 1;
        word-spacing: normal;
        span {
          display: block;
          font-size: 12px;
          line-height: 18px;
          font-weight: 600; }
        .br-wrapper {
          display: block;
          width: 100%;
          padding-top: 16px;
          text-align: center;
          max-width: 380px;
          margin: 0 auto;
          .br-widget {
            display: block;
            width: 100%;
            overflow: hidden;
            a {
              float: left;
              width: 20%;
              height: 32px;
              margin: 0;
              &:before, &:after {
                background-size: 32px 32px;
                background-position: 50% 50%; } }
            .br-current-rating {
              display: none !important; } } } } }
    .detail_material_action {
      .mobile_post_action {
        position: fixed;
        z-index: 8;
        background: #FFF;
        box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.16);
        width: 100%;
        height: 48px;
        left: 0;
        bottom: 0;
        overflow: hidden;
        #share, #change_bookmark {
          line-height: 48px;
          height: 48px;
          margin: 0;
          button {
            line-height: 48px;
            height: 48px;
            font-size: 12px;
            padding-left: 28px;
            &:before {
              width: 20px;
              height: 20px;
              background-size: 20px 20px;
              margin-top: -10px; } } }
        #share {
          float: left; }
        #change_bookmark {
          float: right; } }
      #complaint {
        text-align: center;
        form {
          button {
            border: 0 none;
            display: block;
            height: 50px;
            @include rounded(0);
            border: 0 none;
            border-top: 1px solid #f4f4f4;
            background: #FFF;
            width: 100%;
            line-height: 50px;
            &:hover {
              background: #FFF;
              color: $link_dark; } } } } } } }

.subscribe_user_status {
  display: block;
  text-align: right;
  line-height: 40px;
  font-size: 12px;
  color: #c1c1c1;
  opacity: 0;
  transition: all .3s;
  margin-top: 10px;
  &.ready {
    opacity: 1; } }

.detail_ a.publish_link, .detail_ .unsubscribe button {
  color: $red-unsubscribe;
  border-color: $red-unsubscribe;

  &:hover {
    background: $red-unsubscribe;
    color: #fff; } }

.detail_ a.publish_link, .detail_ .unsubscribe-form button {
  color: $red-unsubscribe;
  border-color: $red-unsubscribe;

  &:hover {
    background: $red-unsubscribe;
    color: #fff; } }
