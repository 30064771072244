.Material {
  position: relative; }

.Material__Remove {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -5px;
  right: -5px;
  background: {
    image: url("./icon-remove.svg");
    repeat: no-repeat;
    position: center; }
  cursor: pointer; }

.Material__Community {
  display: flex;
  margin-bottom: 15px; }

.Material__CommunityPreviewWrap {
  flex-shrink: 0;
  margin-right: 15px; }

.Material__CommunityPreview {
  display: block;
  width: 40px;
  height: 40px;
  background-color: #f2f2f2;
  border-radius: 50%; }

.Material__CommunityInfo {
  flex-grow: 1;
  min-width: 0;
  padding-right: 20px; }

.Material__CommunityTitle {
  margin-bottom: 4px;
  font-size: 15px;
  line-height: 20px;
  color: #212121; }

.Material__DatePublication {
  font-size: 12px;
  line-height: 16px;
  color: #A5A5A5; }

.Material__Title {
  margin-bottom: 18px;
  font-weight: bold;
  font-size: 15px;
  line-height: 25px;
  color: #212121; }

.Material__Counters {
  display: flex;
  align-items: center; }

.Material__Counter {
  display: flex;
  align-items: center;
  margin-right: 20px; }

.Material__CounterIcon {
  flex-shrink: 0;
  margin-right: 10px; }

.Material__CounterValue {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #C1C1C1; }
