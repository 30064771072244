@import "~common";

.leader-tab {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  .is-active {
    color: #479B55;
    padding-bottom: 24px;
    border-bottom: 2px solid #389A52; }

  @include max-w(480) {
    border-radius: 0; } }

.leader-tab__head {
  display: flex;
  flex-wrap: wrap;
  padding: 22px 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06); }

.leader-tab__title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  flex-grow: 1;

  @include max-w(700) {
    margin-bottom: 10px; } }

.leader-tab__tab-list {
  display: flex;
  list-style: none;

  li {
    a {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      text-transform: uppercase;
      color: #A0A0A0;
      margin-right: 35px;
      text-decoration: none;

      @include max-w(700) {
        font-size: 12px; } } } }

.leader-tab__tab-list li:last-child a {
  margin-right: 0; }

