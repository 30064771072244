@import "~common";

.community-rec {
  position: relative;
  display: flex;
  min-height: 285px;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  background-color: #E1E1E1;
  background-position: center;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.75; }

  &:hover:before {
    opacity: 0.5; } }

.community-rec__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.community-rec__wrapper {
  position: relative;
  padding: 25px;
  text-align: center;
  pointer-events: none;

  @include max-w(991) {
    padding: 25px 15px; } }

.community-rec__title {
  font-weight: bold;
  font-size: 18px;
  line-height: (28 / 18);
  text-align: center;
  color: #FFFFFF;
  word-break: break-word;
  text-decoration: none;
  pointer-events: auto;

  @include max-w(991) {
    font-size: 14px; } }

.community-rec__action {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  pointer-events: auto; }
