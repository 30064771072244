@import '~common';

.anchor {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 30px;
  bottom: 30px;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  transition: opacity .2s, background .2s;
  z-index: 1000;

  &--show {
    opacity: 1;
    pointer-events: auto; }

  &:hover {
    background-color: #fafafa; }

  @include max-w(991) {
    width: 60px;
    height: 60px;
    right: 15px;
    bottom: 70px;

    svg {
      max-height: 24px; } } }

