@import "~common";

.c-post {
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 25px 25px 0px;
  box-sizing: border-box;
  width: calc(50% - 30px);
  margin: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include max-w(800) {
    width: 100%;
    padding: 20px 15px 0; }

  @include max-w(480) {
    border-radius: 0; } }

.c-post__body {
  display: flex;
  flex-direction: column;
  align-items: center; }

.c-post__label {
  margin-bottom: 35px;
  height: 26px;
  line-height: 26px;
  display: flex;
  align-items: center;

  img {
    margin-right: 7px; }

  @include max-w(768) {
    margin-bottom: 25px; } }


.c-post__img {
  width: 105px;
  height: 105px;
  margin-bottom: 15px;

  @include max-w(480) {
    width: 85px;
    height: 85px;
    margin-bottom: 10px; } }

.c-post__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%; }

.c-post__title {
  font-size: 18px;
  line-height: 28px;
  color: #000;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;

  @include max-w(480) {
    font-size: 15px;
    line-height: 22px; } }

.c-post__subtitle {
  font-size: 15px;
  line-height: 24px;
  color: #000;
  margin-bottom: 20px;
  text-align: center;

  @include max-w(768) {
    margin-bottom: 10px; } }

.c-post__footer {
  width: 100%;
  padding: 20px 0;
  margin: 30px 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);

  @include max-w(768) {
    padding: 13px 0; } }

.c-post__socials {
  display: flex; }

.c-social__count {
  margin-left: auto; }

.c-post__edit {
  padding: 2px;
  margin-left: auto;
  cursor: pointer;
  user-select: none;

  &:hover {
    svg {
      path {
        fill: #212121; } } } }

.c-post__social {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: none;
  font-size: 12px;
  color: #c1c1c1;
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;

  &:first-child {
    margin-left: 0; }

  &:hover {
    color: #389A52;
    svg {
      path {
        fill: #389A52; } } } }

.c-post__social--comments {

  &:hover {
    color: #389A52;
    svg {
      path {
        fill: none;
        stroke: #389A52; } } } }

.c-post__reactions {
  display: flex;
  margin-left: auto; }

.c-post__link {
  font-size: 15px;
  color: #212121;
  height: 40px;
  line-height: 40px;
  background-color: #F8F8F8;
  border: 1px solid rgba(0, 0, 0, 0.06);
  text-decoration: none;
  border-radius: 4px;
  padding: 0 20px;
  transition: background-color .2s, color .2s;

  &:hover {
    background-color: #efefef;
    color: inherit; }

  @include max-w(480) {
    font-size: 13px;
    height: 35px;
    line-height: 35px; } }
