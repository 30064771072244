@import "~common";

.category { }

.category__header {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.category__header-main {
  padding-bottom: 27px;
}

.category__top {
  display: flex;
  padding: 25px 25px 0;
  width: 100%;
  align-items: center;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.category__title {
  color: #212121;
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;

  @media (max-width: 991px) {
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }
}


.category__logo {
  width: 105px;
  height: 105px;
  flex-shrink: 0;
  margin-right: 25px;
  border: none;
  background-color: #f2f2f2;
  border-radius: 50%;
  overflow: hidden;

  @media (max-width: 991px) {
    width: 90px;
    height: 90px;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.category__wrapper {
  width: 100%;
  align-items: center;
}


.category__text {
  margin-left: 20px;
  color: #999999;
  font-size: 15px;
  line-height: 20px;
  &:first-of-type {
    margin-left: 0;
  }
}

.category__number {
  color: #308446;

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.category__subscribers {
  display: flex;
  align-items: center;
  margin-top: 15px;

  @media (max-width: 991px) {
    justify-content: center;
  }
}

.category__subscriber,
.category__subscriber.avatar {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: -10px;
  border: 2px solid #fff;
  &:first-child {
    margin-left: 0;
    z-index: 2  ;
  }
  &:nth-child(2) {
    z-index: 1;
  }
  &:nth-child(3) {
  }
}

.category__subscribed {
  margin-left: 5px;
  color: #A0A0A0;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;

  &:hover {
    color: $color-main;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.category_button {
}


.category__button {
  color: #389A52;
  margin-left: auto;
  padding: 10px 12px;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #389A52;
  border-radius: 4px;
  font-size: 13px;
  line-height: 18px;

  @media (max-width: 991px) {
    margin: 24px auto 0;
    width: 100%;
  }
}

.category__button--disabled {
  cursor: not-allowed;
  color: #a0a0a0;
  border: none;
  background-color: #EBEBEB;
}

.category__button--checked {
  color: #ffffff;
  border: none;
  background-color: #389A52;
}


.category__description {
  position: relative;
  padding: 17px 20px;
  margin: 20px 25px 0;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background: #FAFAFA;

  &:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 43px;
    transform: rotate(45deg);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: none;
    border-right: none;
    background-color: #FAFAFA;
    width: 15px;
    height: 15px;
  }

  @media (max-width: 991px) {
    padding: 0;
    border: none;
    text-align: center;
    margin: 6px auto 0;
    background-color: transparent;

    &:before {
      content: initial;
    }
  }
}

.category__about {
  font-size: 18px;
  line-height: 28px;
  color: #000000;

  @media (max-width: 991px){
    font-size: 15px;
    line-height: 24px;
  }
}

.category__moderators {
  padding: 16px 26px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.category__moderators-title {
  font-size: 15px;
  font-weight: bold;
  line-height: 28px;
  text-transform: uppercase;
  color: #A8A8A8;

  @media (max-width: 991px){
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.category__moderators-wrapper {
  margin-top: 15px;
  overflow: hidden;

  @media (max-width: 991px) {
    display: none;
  }
}

.category__moderators-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -15px;

  @media (max-width: 585px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.category__moderator {
  display: flex;
  align-items: center;
  margin-right: 40px;
  margin-top: 15px;

  @include max-w(585) {
    margin-right: 0;
  }
}

.category__moderator-link-avatar {
  flex-shrink: 0;
  text-decoration: none;
  margin-right: 15px;
}


.category__moderators-logo {
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #C4C4C4;
  margin-left: 40px;

  &:first-child {
    margin-left: 0;
  }
}


.category__moderators-name {
  color: #212121;
  font-size: 15px;
  line-height: 20px;
  text-decoration: none;
}

.category__nav {
  position: relative;
  margin-top: 17px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #ffffff;

  @media (max-width: 991px){
    margin-top: 0;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 25px;
    z-index: 1;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  }
}

.category__nav-scroll {
  display: flex;
  overflow: auto;
}

.category__nav-scroll--open {
  overflow: initial;
}

.category__nav-inner {
  display: flex;
  padding-right: 25px;
}

.category__dropdown {
  display: flex;
  position: relative;
  transition: 300ms;
}

.category__tab {
  text-decoration: none;
  position: relative;
  padding: 24px 0;
  font-size: 15px;
  cursor: pointer;
  line-height: 20px;
  color:  #212121;
  white-space: nowrap;

  @media (max-width: 600px) {
    font-size: 13px;
  }
}

.category_tab--dropdown {
  padding-right: 24px;
  margin-left: 25px;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 41%;
    width: 15px;
    height: 15px;
    transition: 300ms;
    background-image: url('./dropdown.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

  }
}

.category__tab-drop-selected {
  display: inline-block;
  border: 1px solid #EDEDED;
  border-radius: 4px;
  padding: 4px 12px;
  margin: -6px 0px;
  cursor: default;

  > a {
    display: inline-block;
    margin-right: 6px;
  }
}

.category__tab--active {
  color: #389A52;
  border-bottom: 2px solid #389A52;
}

.category__tab-not-first {
  margin-left: 40px;

  @media (max-width: 600px) {
    margin-left: 20px;
  }
}

.closed {
  padding: 50px 0;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.closed__buttons {
  margin-top: 20px;
}

.closed__image {
  width: 45px;
  height: 59px;
}

.closed__header {
  text-align: center;
  color: #C1C1C1;
  font-weight: bold;
  font-size: 15px;
  line-height: 42px;
}

.closed__text {
  max-width: 350px;
  margin:  auto;
  text-align: center;
  font-size: 13px;
  line-height: 22px;
  color: #C1C1C1;
}

.category__dropdown-list {
  padding: 10px 0;
  display: none;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 50%;
  list-style: none;
  transform: translateX(-50%);
  background-color: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 10;
}

.category__dropdown-item {
  padding: 7px 24px;
}


.category_tab--open {

  &:after {
    transform: rotate(180deg);
  }
}


.hide-sm{
  @media (max-width: 991px) {
    display: none;
  }
}

.show-sm {
  display: none;
  @media (max-width: 991px) {
    display: inherit;
  }
}

.category__info {
  display: flex;
  margin-top: 10px;
  @media (max-width: 991px){
    display: flex;
    margin-top: 18px;
    flex-wrap: wrap;
  }

  .head-category__elem {
    margin-left: 20px;
    display: flex;

    @media (max-width: 910px) {
      margin-left: 0;
      justify-content: center;
    }
  }
}


.category__text--block {
  display: inline-block;
  a {
    display: inline-block;
    text-decoration: none;
    color: #999999;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 991px) {
    flex-basis: 50%;
    flex-grow: 1;
    padding: 8px;
    margin-left: 0;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.06);
  }
}


.category__moderators-icon {
  cursor:  pointer;
  transition: 300ms;
}

.category__moderators-icon--open {
  transform: rotate(180deg);
}


.category__competition{
  margin: 14px 0 15px;
}
