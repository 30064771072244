@import "~common";

.modal {
	position: fixed;
	z-index: 1100;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(46, 126, 67, 0.9);
	display: none;
	overflow: auto;
	.modal_wrap {
		display: table;
		position: absolute;
		width: 100%;
		height: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		.modal_inner {
			display: table-cell;
			width: 100%;
			height: 100%;
			vertical-align: middle;
			padding: 0;
			.modal_content {
				max-width: 555px;
				background: #FFF;
				box-shadow: 0 6px 32px 0 rgba(0, 0, 0, 0.24);
				border: solid 1px rgba(0, 0, 0, 0.09);
				@include rounded(2px);
				padding: 24px 32px;
				margin: 0 auto;
				overflow: hidden;
				overflow-y:auto;
				&.big_modal {
          width: 100%;
					max-width: 800px;
				}
				h3 {
					font-size: 24px;
					font-weight: 700;
					line-height: 1.25;
					color: #212121;
					color: rgba(0,0,0,.88);
					margin: 0 0 32px;
				}
				p {
					font-size: 15px;
					line-height: 21px;
					color: #000;
					& + p {
						margin-top: 12px;
					}
				}
				form#select_category {
					display: block;
					overflow: hidden;
					overflow-y: auto;
					width: auto;
					margin-left: -32px;
					margin-right: -32px;
					margin-top: -24px;
					padding: 24px 24px 5px;
					background: transparent;
					border-bottom: 1px solid rgba(0, 0, 0, 0.08);
					&::-webkit-scrollbar {
						width: 8px;
					}
					&::-webkit-scrollbar-track {
						background: rgba(255,255,255,.1);
					}
					&::-webkit-scrollbar-thumb {
						border-radius: 8px;
						background: $link;
					}
					h3, p {
						margin: 0;
						b {
							font-weight: 600;
						}
						& + p {
							margin-top: 16px;
						}
						& + .row {
							margin-top: 32px;
						}
					}
				}
				form#article_reject_comment {
					.form_item {
						&:before {
							display: none;
						}
						&.form_textarea {
							height: 94px;
							text-align: center;
							display: block;
							label {
								font-size: 15px;
								line-height: 23px;
								font-weight: normal;
								padding-left: 0;
								top: 0;
								left: 0;
							}
							textarea {
								display: block;
								text-align: left;
								height: 94px;
								padding: 0;
								overflow: hidden;
								overflow-y: auto;
								resize: none;
								border: 0 none;
								background: transparent;
								font-size: 15px;
								line-height: 23px;
								color: #212121;
								-webkit-appearance: none;
								-moz-appearance: none;
								appearance: none;
								font-weight: normal;
							}
						}
					}
				}
				form.invite_form {
					.form_item {
						height: auto;
						&:before {
							display: none !important;
						}
						input {
							padding: 5px 0px;
						}
						label {
							padding: 0;
						}
					}
				}
				form#new_category_offer {
					.form_item {
						&:before {
							display: none;
						}
						input {
							padding-left: 0;
						}
						label {
							padding-left: 0;
						}
					}
				}
				form#create_category, form#update_category {
					.form_item {
						&:before {
							display: none;
						}
						input {
							padding-left: 0;
						}
						label {
							padding-left: 0;
						}
					}
					#category_bg, #category_bg_edit {
						margin-top: 16px;
						padding: 68px 30px !important;
						&.dz-started {
							padding: 0 !important;
							margin-left: -32px;
							margin-right: -32px;
							width: auto !important;
							padding: 0 !important;
							max-height: 280px;
							overflow: hidden;
							.dz-details {
								img {
									width: 100%;
									height: auto;
								}
							}
						}
					}
				}
				form#new_product, form.edit_product {
					.form_item {
						&:before {
							display: none;
						}
						input {
							padding-left: 0;
						}
						label {
							padding-left: 0;
						}
					}
					#product_bg {
						margin-top: 16px;
						margin-bottom: 24px;
						padding: 68px 30px !important;
						&.dz-started {
							padding: 0 !important;
							margin-left: -32px;
							margin-right: -32px;
							width: auto !important;
							padding: 0 !important;
							max-height: 280px;
							overflow: hidden;
							.dz-details {
								img {
									width: 100%;
									height: auto;
								}
							}
						}
					}
					.cover_exist {
						margin: 16px -32px 24px !important;
						width: auto;
						max-height: 280px;
						overflow: hidden;
						position: relative;
						img {
							width: 100%;
							height: auto;
							z-index: 1;
						}
						#product_bg.dropzone {
							position: absolute;
							z-index: 10;
							cursor: pointer;
							width: 24px !important;
							height: 24px !important;
							background: $link asset-url(icon-edit) no-repeat 50% 50% !important;
							background-size: 18px 18px !important;
							top: 16px;
							right: 16px;
							@include rounded(50%);
							border: 0 none !important;
							padding: 0 !important;
							min-height: 0 !important;
							margin: 0 !important;
							&:hover {
								background-color: $link_dark !important;
							}
							.dz-message, .dz-image-preview {
								display: none !important;
								width: 0;
								height: 0;
								overflow: hidden;
							}
						}
					}
					.product_tags {
						margin-bottom: 24px;
						& > * {
							vertical-align: middle;
						}
						.tag_wrapper {
							display: inline-block;
							position: relative;
							em {
								display: block;
								visibility: hidden;
								opacity: 0;
								z-index: -1;
								color: transparent;
								padding: 4px 25px 4px 7px;
								min-width: 120px;
								font-weight: 600;
								font-size: 12px;
								position: absolute;
								top: 0;
								left: 0;
								font-style: normal;
							}
							input {
								opacity: 1 !important;
								display: inline-block;
								line-height: 20px;
								padding: 4px 25px 4px 7px;
								height: 28px;
								border: 2px solid #ededed;
								@include rounded(4px);
								font-weight: 600;
								font-size: 12px;
								text-decoration: none;
								color: #a6a6a6;
								text-transform: uppercase;
								outline: none;
								background: #fff;
								width: 120px;
								z-index: 2;
							}
							a {
								text-decoration: none;
								color: transparent;
								display: block;
								position: absolute;
								height: 28px;
								width: 20px;
								top: 0;
								right: 0;
								text-align: center;
								text-indent: -9999em;
								background: asset-url(close-black) no-repeat 50% 50%;
								background-size: 11px 11px;
							}
						}
					}
					.competition_rules_row {
						margin-bottom: 24px;
						.competition_rule {
							input {
								text-align: center;
								width: 90px;
							}
						}
					}
				}
				.form_submit {
					form {
						display: inline-block;
						& + form {
							margin-left: 32px;
						}
					}
					a, button, input[type=submit] {
						display: inline-block;
						line-height: 20px;
						font-size: 15px;
						font-weight: 400;
						padding: 0;
						text-decoration: none;
						background: transparent;
						border: 0 none;
						color: $link;
						outline: none;
						cursor: pointer;
						& + a, & + button, & + input[type=submit], & + form {
							margin-left: 32px;
						}
						&:hover {
							color: $link_dark;
						}
						&.gray {
							color: #b0b0b0;
							&:hover {
								color: #a0a0a0;
							}
						}
						&:disabled {
							color: #b0b0b0 !important;
							cursor: default;
						}
					}
				}
				.competition_date {
					font-size: 15px;
					font-weight: 400;
					color: #212121;
					margin-bottom: 24px;
					vertical-align: top;
					line-height: 24px;
					span {
						display: inline-block;
						width: 24px;
						line-height: 24px;
						color: #d0d0d0;
						font-size: 14px;
						font-weight: 600;
						text-align: center;
					}
					.form_item {
						display: inline-block;
						height: 24px;
						position: relative;
						vertical-align: top;
						&:before {
							display: none;
						}
						label {
							padding: 0;
							line-height: 24px;
							color: #c1c1c1;
						}
						&.first {
							label {
								left: auto;
								right: 0;
							}
						}
						input {
							padding: 0;
							height: 24px;
							line-height: 24px;
							width: 86px;
							cursor: pointer;
							&#competition_start_date {
								text-align: right;
							}
						}
					}
				}
				.competition_rules_row {
					margin-bottom: 24px;
					position: relative;
					&.form_item {
						height: auto;
						&:before {
							display: none;
						}
					}
					label {
						position: absolute;
						color: #c1c1c1;
						line-height: 24px;
						top: 0;
						left: 0;
						padding: 0;
					}
					textarea {
						padding: 0;
						overflow: hidden;
						overflow-y: auto;
						display: block;
						width: 100%;
						-webkit-appearance: none;
						-moz-appearance: none;
						appearance: none;
						position: relative;
						z-index: 2;
						border: 0 none;
						outline: none;
						background: transparent;
						line-height: 24px;
					}
					&.active {
						label {
							display: none;
						}
					}
					p {
						line-height: 24px;
						font-size: 15px;
						font-weight: 400;
						padding: 0;
						margin: 0;
					}
				}
				.competition_section {
					font-size: 15px;
					font-weight: 400;
					color: #212121;
					margin-bottom: 40px;
					vertical-align: top;
					line-height: 24px;
					select {
						display: none;
					}
					.ui-selectmenu-button.ui-button {
						display: inline;
						background: transparent;
						border: 0 none;
						@include rounded(0);
						width: auto !important;
						padding: 0;
						margin: 0;
						font-size: inherit;
						line-height: inherit;
						font-weight: inherit;
						vertical-align: top;
						.ui-selectmenu-text {
							color: #c1c1c1;
							display: inline;
							font-size: inherit;
							font-weight: inherit;
							line-height: inherit;
							padding: 0;
							margin: 0;
						}
						.ui-selectmenu-icon {
							display: none;
						}
					}
					.theme_edit {
						text-align: left;
	                    cursor: text;
	                    color: #000;
	                    font-size: 15px;
	                    font-weight: 400;
	                    display: block;
	                    min-height: 24px;
	                    &.not_value {
	                        &:before {
	                            content: attr(data-placeholder);
	                            color: #c1c1c1;
	                        }
	                    }
					}
					.gray {
						color: #c1c1c1;
					}
				}
				.competition_rule {
					display: inline-block;
					vertical-align: middle;
					height: 24px;
					line-height: 24px;
					position: relative;
					input {
						height: 24px;
						line-height: 24px;
						background: transparent;
						border: 0 none;
						outline: none;
						font-size: 14px;
						font-weight: 600;
						padding: 0 0 0 32px;
						color: #a3a3a3;
						color: rgba(0,0,0,.38);
						width: 74px;
					}
					.icon {
						position: absolute;
						display: block;
						width: 24px;
						height: 24px;
						background-position: 50% 50%;
						background-repeat: no-repeat;
						&.icon-likes {
							background-image: asset-url(icons-favorite-disable);
						}
						&.icon-views {
							background-image: asset-url(eye-disable);
						}
						&.icon-comments {
							background-image: asset-url(comments-disable);
						}
						&.icon-sbercoins-gray {
							background-image: asset-url(sbercoints-gray);
						}
						&.icon-sbercoins {
							background-image: asset-url(sbercoints-new);
						}
					}
				}
				#list_users, #list_articles {
					width: auto;
					margin: 0 -32px;
					max-height: 300px;
					padding: 10px 0;
					overflow: hidden;
					overflow-y: auto;
					&::-webkit-scrollbar {
						width: 8px;
					}
					&::-webkit-scrollbar-track {
						background: rgba(255,255,255,.1);
					}
					&::-webkit-scrollbar-thumb {
						border-radius: 8px;
						background: $link;
					}
				}
				#category_users_action, #share_users_action, #list_articles_action, #export_users_action {
					margin: 0 -32px;
					border-top: 1px solid #efefef;
					padding: 0 32px;
				}
				#search_in_modal {
					display: block;
					position: relative;
					height: 73px;
					border-bottom: 1px solid #efefef;
					width: auto;
					margin: -24px -32px 0;
					form {
						display: block;
						position: relative;
						height: 72px;
						width: 100%;
						z-index: 1;
						background: #fff;
						&:before {
							position: absolute;
							content: '';
							width: 24px;
							height: 24px;
							top: 24px;
							left: 32px;
							z-index: 2;
							background: asset-url(poup-icons-search) no-repeat 50% 50%;
						}
						.form_item {
							border: 0 none;
							height: 72px;
							line-height: 72px;
							padding: 0;
							width: 100%;
							&:before {
								display: none;
							}
							label {
								font-weight: 600;
								height: 72px;
								line-height: 72px;
								top: 0;
								left: 0;
								padding-left: 80px;
								color: #c1c1c1;
							}
							input {
								font-weight: 600;
								padding: 15px 15px 15px 80px;
								position: relative;
								z-index: 3;
								line-height: 42px;
								height: 72px;
							}
						}
					}
				}
				.simpleeditor {
					margin: 26px 0 0;
				}
				#export_users_form {
					padding: 0 48px;
					position: relative;
					margin-bottom: 24px;
					&:before {
						content: '';
						position: absolute;
						left: 0;
						bottom: 0;
						width: 24px;
						height: 24px;
						cursor: default;
						background: asset-url(search) no-repeat 0 0;
					}
					#empty_export_users {
						display: block;
						position: absolute;
						width: 24px;
						height: 24px;
						cursor: pointer;
						bottom: 0;
						right: 0;
						background: asset-url(close-gray) no-repeat 0 0;
					}
					div {
						min-height: 48px;
						line-height: 24px;
						max-height: 110px;
						height: auto;
						width: 100%;
						padding: 24px 0 0;
						font-size: 15px;
						font-weight: 600;
						color: #212121;
						font-family: $font;
						overflow: hidden;
						overflow-y: auto;
						margin-top: -24px;
						background: transparent;
						border: 0 none;
						outline: none;
						cursor: text;
						&::-webkit-scrollbar {
							width: 8px;
						}
						&::-webkit-scrollbar-track {
							background: rgba(0,0,0,.05);
						}
						&::-webkit-scrollbar-thumb {
							border-radius: 8px;
							background: $link;
						}
						&.not_value {
							color: #c1c1c1;
							&:before {
								content: attr(data-placeholder);
							}
						}
					}
					textarea {
						height: 48px;
						line-height: 24px;
						max-height: 110px;
						width: 100%;
						padding: 24px 0 0;
						font-size: 15px;
						font-weight: 600;
						color: #212121;
						font-family: $font;
						overflow: hidden;
						overflow-y: auto;
						margin-top: -24px;
						background: transparent;
						border: 0 none;
						outline: none;
						cursor: text;
						display: block;
						resize: none;
						&::-webkit-scrollbar {
							width: 8px;
						}
						&::-webkit-scrollbar-track {
							background: rgba(0,0,0,.05);
						}
						&::-webkit-scrollbar-thumb {
							border-radius: 8px;
							background: $link;
						}
					}
				}
			}
		}
	}

    .pagination {
      padding: 10px 0;
    }

	&.modal_img {
		overflow: hidden;
		overflow-y: auto;
		.modal_wrap {
			.modal_inner {
				.modal_content {
					width: 780px;
					padding: 52px 15px;
					position: relative;
					img {
						width: 100%;
						height: auto;
					}
					.close_modal {
						display: block;
						position: absolute;
						width: 20px;
						height: 20px;
						top: 16px;
						right: 16px;
						background: asset-url(close-black) no-repeat 50% 50%;
					}
				}
			}
		}
	}
}

.modal_dialog {
	position: fixed;
	z-index: 1100;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #FFF;
	background-color: rgba(255,255,255, 0.95);
	display: none;
	.modal_dialog_wrap {
		display: table;
		position: absolute;
		width: 100%;
		height: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		.modal_dialog_inner {
			display: table-cell;
			width: 100%;
			height: 100%;
			vertical-align: middle;
			padding: 0;
			.modal_dialog_content {
				width: 700px;
				max-width: 100%;
				max-height: 100vw;
				background: transparent;
				margin: 0 auto;
				h3 {
					color: #000;
					font-size: 24px;
					line-height: 30px;
					text-align: center;
					margin: 0 0 16px;
					padding:0;
					font-weight: 700;
				}
				#top_panel_dialog {
					a {
						display: block;
						margin-top: 32px;
						text-align: center;
						text-decoration: none;
						i.icon {
							display: block;
							width: 124px;
							height: 124px;
							@include rounded(50%);
							background-color: $link;
							background-position: 50% 50%;
							background-repeat: no-repeat;
							margin: 0 auto 8px;
							transition: all .2s;
							&.icon-post {
								background-image: asset-url(menu-create-post);
							}
							&.icon-presentation {
								background-image: asset-url(menu-create-presentations);
							}
							&.icon-video {
								background-image: asset-url(menu-create-video);
							}
							&.icon-longread {
								background-image: asset-url(menu-create-longread);
							}
							&.icon-interview {
								background-image: asset-url(menu-create-interview);
							}
							&.icon-videopresentation {
								background-image: asset-url(menu-create-videopresentation);
							}
							&.icon-videopresentation-disabled {
								background-image: asset-url(menu-create-videopresentation-disabled);
								background-color: #eaeaea;
							}
							&.icon-question {
								background-image: asset-url(menu-create-question);
							}
							&.icon-question-disabled {
								background-image: asset-url(menu-create-question-disabled);
								background-color: #eaeaea;
							}
						}
						span {
							display: block;
							width: 100%;
							text-align: center;
							font-size: 15px;
							color: #a0a0a0;
							transition: all .2s;
							&.disabled {
								color: #c1c1c1 !important;
							}
						}
						&:hover {
							i {
								background-color: $link_dark;
							}
							span {
								color: #000;
							}
						}
					}
				}
			}
		}
	}
	.close_modal {
		display: block;
		text-decoration: none;
		position: absolute;
		top: 40px;
		right: 40px;
		width: 24px;
		height: 24px;
		background: asset-url(close-black) no-repeat 50% 50%;
	}
}
